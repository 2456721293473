import { Flex, Text, BanIcon, CheckmarkCircleIcon, Provider } from '@fluentui/react-northstar';
import { panelHeadingStyle } from './employeeProfileStyles';
import { useTranslation } from 'react-i18next';
import { EmployeeDto } from '../../data-access/open-api/queries/models/EmployeeDto';
import { RequestStatusType } from '../../enum/RequestStatus';
import { useHistory } from 'react-router-dom';

export const EmployeeProfileOpenRequests = ({ employee }: { employee?: EmployeeDto }) => {
  const { t } = useTranslation();
  const navigate = useHistory();
  function openRequest(requestId: string, requestName: string) {
    navigate.push({
      pathname: `/resourceRequestDetailView/${requestId}/${requestName}`,
    });
  }
  return (
    <Flex column gap="gap.medium">
      <Text weight="semibold" size="large" styles={panelHeadingStyle}>
        {t('employee-profile.open-requests')}
      </Text>
      <Flex column>
        {employee &&
          employee.openRequests?.map((request) =>
            request.openRequestStatus.map((ors) => (
              <Provider.Consumer
                render={(theme) => (
                  <Text
                    onClick={() =>
                      openRequest(request.resourceRequestId, request.resourceRequestName)
                    }
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      color: theme.siteVariables.colorScheme.brand.foreground,
                    }}
                  >
                    {request.resourceRequestName} {ors.roleRequestName}
                    {RequestStatusType[ors.requestStatus] === RequestStatusType.Rejected && (
                      <BanIcon
                        outline
                        styles={() => ({
                          color: '#A4262C',
                          marginLeft: '8px',
                        })}
                      />
                    )}
                    {RequestStatusType[ors.requestStatus] === RequestStatusType.Accepted && (
                      <CheckmarkCircleIcon
                        outline
                        styles={() => ({
                          color: '#107C10',
                          marginLeft: '8px',
                        })}
                      />
                    )}
                  </Text>
                )}
              />
            ))
          )}
      </Flex>
    </Flex>
  );
};
