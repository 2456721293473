import { List, Pill, Tooltip } from '@fluentui/react-northstar';
import { Ti8mPill } from '../../Common/Ti8mPill';
import { overflowPillStyle, skillPillStyle } from '../resourceRequestDetailStyles';
import { Ti8mTruncatedList } from '../../Common/Ti8mTruncatedList';

interface PotentialEmployeeSkillsProps {
  skills: string[];
}

export const PotentialEmployeeSkills = ({ skills }: PotentialEmployeeSkillsProps) => {
  return (
    <>
      <Ti8mTruncatedList
        renderTruncator={({ hiddenItemsCount }) => (
          <Tooltip
            content={
              <List
                items={skills.slice(skills.length - hiddenItemsCount).map((skill) => ({
                  key: skill,
                  content: skill,
                  styles: { minHeight: 'unset' },
                }))}
                styles={{ '> li': { padding: 0 } }}
              />
            }
            trigger={
              <Pill
                rectangular
                content={`+${hiddenItemsCount}`}
                size="small"
                styles={overflowPillStyle}
              />
            }
          />
        )}
        styles={{
          padding: 0,
          margin: 0,
          maxHeight: '64px',
          alignContent: 'flex-start',
          alignItems: 'center',
          '& > li': { listStyleType: 'none' },
        }}
      >
        {skills.length > 0
          ? skills.map((skill) => (
              <Ti8mPill
                key={skill}
                itemId={skill}
                content={skill}
                showTooltip
                maxTextLength={16}
                size="small"
                styles={(data) => ({
                  ...skillPillStyle(data),
                  minWidth: '2rem',
                  minHeight: '1rem',
                })}
              />
            ))
          : null}
      </Ti8mTruncatedList>
    </>
  );
};
