import { Button, EditIcon, Flex, Text } from '@fluentui/react-northstar';
import { panelHeadingStyle } from './employeeProfileStyles';
import { useTranslation } from 'react-i18next';
import { useState, useCallback } from 'react';
import { TemplateStatesDialog } from './TemplateStatesDialog/TemplateStatesDialog';
import { EmployeeTemporaryStateDto } from '../../data-access/open-api/commands/models/EmployeeTemporaryStateDto';
import { isEmpty } from 'ramda';
import { convertDayMonthYear } from '../../utils/dateConverter';
import { Ti8mDialog } from '../Common/Ti8mDialog';

export const EmployeeProfileTemporaryStates = ({
  projectChangeRequest,
  openEducation,
  terminationOfEmployment,
  notes,
  onTemporaryStateChanged,
}: {
  projectChangeRequest?: string;
  openEducation?: string;
  terminationOfEmployment?: string;
  notes?: string;
  onTemporaryStateChanged: (state: EmployeeTemporaryStateDto) => void;
}) => {
  const { t } = useTranslation();
  const [openEditTemplateStates, setOpenEditTemplateStates] = useState(false);
  const [isTouched, setTouched] = useState(false);
  const [dialogFormState, setDialogFormState] = useState({
    projectChangeRequest: projectChangeRequest,
    openEducation: openEducation,
    notes: notes,
  });

  const onFormPropertyChanged = useCallback(
    (prop: string, value: any) => {
      setDialogFormState({
        ...dialogFormState,
        [prop]: value,
      });
      setTouched(true);
    },
    [setDialogFormState, setTouched, dialogFormState]
  );

  const onDialogConfirm = () => {
    setOpenEditTemplateStates(false);
    onTemporaryStateChanged({
      projectChangeRequest: dialogFormState.projectChangeRequest ?? projectChangeRequest ?? '',
      openEducation: dialogFormState.openEducation ?? openEducation ?? '',
      notes: dialogFormState.notes ?? notes ?? '',
    });
  };

  return (
    <Flex column gap="gap.medium">
      <Flex>
        <Text weight="semibold" size="large" styles={panelHeadingStyle}>
          {t('employee-profile.temporary-states')}
        </Text>
        <Flex.Item push>
          <Button
            circular
            primary
            flat
            icon={<EditIcon outline />}
            onClick={() => setOpenEditTemplateStates(true)}
          />
        </Flex.Item>
      </Flex>

      <Flex column>
        <Text weight="semibold">{t('employee-profile.project-change-request')}</Text>
        <Text>
          {projectChangeRequest && !isEmpty(projectChangeRequest) ? projectChangeRequest : '-'}
        </Text>
      </Flex>
      <Flex column>
        <Text weight="semibold">{t('employee-profile.open-education')}</Text>
        <Text>{openEducation && !isEmpty(openEducation) ? openEducation : '-'}</Text>
      </Flex>
      <Flex column>
        <Text weight="semibold">{t('employee-profile.termination-employment')}</Text>
        <Text>{terminationOfEmployment ? convertDayMonthYear(terminationOfEmployment) : '-'}</Text>
      </Flex>
      <Flex column>
        <Text weight="semibold">{t('employee-profile.notes')}</Text>
        <Text>{notes && !isEmpty(notes) ? notes : '-'}</Text>
      </Flex>
      <Ti8mDialog
        open={openEditTemplateStates}
        onCancel={() => setOpenEditTemplateStates(false)}
        onConfirm={onDialogConfirm}
        cancelButton={t('employee-profile.dialog.cancel')}
        confirmButton={{
          disabled: !isTouched,
          content: t('employee-profile.dialog.confirm'),
        }}
        header={t('employee-profile.dialog.title')}
        content={
          <TemplateStatesDialog
            projectChangeRequest={projectChangeRequest}
            openEducation={openEducation}
            notes={notes}
            onPropertyChanged={onFormPropertyChanged}
          />
        }
      />
    </Flex>
  );
};
