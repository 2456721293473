import { ComponentSlotStyle, ComponentSlotStyleFunction } from '@fluentui/react-northstar';

export const avatarStyle = {
  marginLeft: '-1.2rem',
};

export const flexStyleProjectHeader = {
  marginBottom: '0.5rem',
};

export const detailsTable: ComponentSlotStyle = (data) => ({
  '> .ui-table__row': {
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    borderBottomWidth: 1,
    borderColor: data.theme.siteVariables.dividerColor,
    backgroundColor: 'transparent',
  },
  '> .ui-table__row:hover': {
    backgroundColor: 'transparent',
    borderColor: data.theme.siteVariables.dividerColor,
  },
});

export const detailsTableStyleRole: ComponentSlotStyle = () => ({
  maxWidth: '18%',
});

export const detailsTableStyleDuration: ComponentSlotStyle = () => ({
  maxWidth: '14%',
});

export const detailsTableStyleWorkload: ComponentSlotStyle = () => ({
  maxWidth: '8%',
});

export const detailsTableStyleSkills: ComponentSlotStyle = () => ({
  maxWidth: '28%',
});

export const detailsTableStylePotential: ComponentSlotStyle = () => ({
  maxWidth: '24%',
});

export const detailsTableStyleEdit: ComponentSlotStyle = () => ({
  maxWidth: '8%',
});

export const addRoleButton: ComponentSlotStyle = {
  width: '1.4rem',
  marginBottom: '1.0rem',
  marginLeft: 2,
};

export const addCommentButton: ComponentSlotStyle = {
  width: '10rem',
};

export const requiredRolesHeader = {
  marginTop: 0,
};

export const overflowPillStyle: ComponentSlotStyle = {
  background: '#989AC9',
  minWidth: '2',
  color: 'white',
  '&:hover': {
    backgroundColor: '#989AC9',
  },
};

export const skillPillStyle: ComponentSlotStyleFunction = (data) => ({
  background: data.theme.siteVariables.colorScheme.default.background2,
});

export const commentsTable: ComponentSlotStyle = (data) => ({
  '> .ui-table__row': {
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    borderBottomWidth: 1,
    borderColor: data.theme.siteVariables.dividerColor,
    backgroundColor: 'transparent',
  },
  '> .ui-table__row:hover': {
    backgroundColor: 'transparent',
    borderColor: data.theme.siteVariables.dividerColor,
  },
});

export const commentsTableDate: ComponentSlotStyle = () => ({
  maxWidth: '8%',
});

export const commentsTableName: ComponentSlotStyle = () => ({
  maxWidth: '12%',
});

export const commentsTableComment: ComponentSlotStyle = () => ({
  maxWidth: '80%',
  paddingRight: '5rem',
});
