import { ComponentSlotStyle } from '@fluentui/react-northstar';

export const nameHeadingStyle: ComponentSlotStyle = (data) => ({
  margin: 0,
  fontWeight: data.theme.siteVariables['fontWeightSemibold'],
  fontSize: data.theme.siteVariables.fontSizes['large'],
});

export const importantInfoFloatingStyle: ComponentSlotStyle = {
  marginLeft: 'auto',
  width: '50%',
  position: 'absolute',
  zIndex: 10,
  right: '1.5rem',
  top: '1.5rem',
};

export const menteeBadgeLabelStyle: ComponentSlotStyle = (data) => ({
  backgroundColor: '#AE3EC8',
  color: data.theme.siteVariables.colors['white'],
});
