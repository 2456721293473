import { Button, Flex, ArrowRightIcon } from '@fluentui/react-northstar';
import { detailPaneRootStyle } from './employeeTableDetailPaneStyles';
import { ProbabilityOfExtensionSection } from './ProbabilityOfExtensionTable';
import { ImportantInfoAccordion } from './ImportantInfoAccordion';
import { NameSection } from './NameSection';
import { TopGrid } from './TopGrid';
import { CoreCompetencesGrid } from './CoreCompetencesGrid';
import { CapacityOutlookSection } from './CapacityOutlookSection';
import { useEmployeeOverviewData } from '../hooks/useEmployeeOverviewData';
import { useHistory } from 'react-router-dom';
import { EmployeeOverviewDetailsDto } from '../../../data-access/open-api/queries';
import { useEffect, useState } from 'react';
import { Ti8mCard } from '../../Common/Ti8mCard';
import { useTranslation } from 'react-i18next';

export const EmployeeTableDetailPane = ({ employeeId }: { employeeId?: string }) => {
  const { t } = useTranslation();
  const employeeOverviewData = useEmployeeOverviewData({ employeeId });
  const navigate = useHistory();
  function openEmployeeDetail() {
    navigate.push({ pathname: `/employeeDetails/${employeeId}` });
  }

  const [employeeData, setEmployeeData] = useState<EmployeeOverviewDetailsDto>();

  // Prevents box-size change(flicker) on employee data change
  useEffect(() => {
    if (employeeOverviewData) {
      setEmployeeData(employeeOverviewData);
    }
  }, [employeeOverviewData, setEmployeeData]);

  return (
    <Ti8mCard cardStyles={detailPaneRootStyle}>
      <Flex column gap={'gap.large'}>
        {employeeData ? (
          <>
            {/* TODO: Mentorship data missing in DTO */}
            <NameSection
              firstName={employeeData.firstName}
              lastName={employeeData.lastName}
              role={employeeData.role}
              isMentee={employeeData.isMentee}
              id={employeeId}
              importantInformationSection={
                <ImportantInfoAccordion
                  projectChangeRequest={employeeData.projectChangeRequest}
                  openEducation={employeeData.openEducation}
                  openRequests={[]}
                  // TODO: Find out where Open Requests should come from
                />
              }
              isIgnored={employeeData.isIgnored}
              photo={employeeData.photoUri}
            />
            <TopGrid
              domicile={employeeData.domicile}
              department={employeeData.organizationName}
              pensum={employeeData.pensum}
              manager={employeeData.manager}
              timeBalances={employeeData.timeBalances}
            />
            <CoreCompetencesGrid
              coreCompetences={employeeData.coreCompetences}
              notes={employeeData.notes}
            />
            <ProbabilityOfExtensionSection
              projects={employeeData.projectsWithProbabilityOfExtension}
            />
            <CapacityOutlookSection
              data={employeeData.capacityOutlook}
              employeePensum={employeeData.pensum}
            />
            <Flex hAlign="end">
              <Button
                flat
                icon={<ArrowRightIcon />}
                primary
                onClick={() => openEmployeeDetail()}
                content={t('employee-overview-table-detail.view-details')}
              />
            </Flex>
          </>
        ) : (
          <Flex styles={{ height: '100%' }} vAlign="center" hAlign="center"></Flex>
        )}
      </Flex>
    </Ti8mCard>
  );
};
