import {
  Avatar,
  Button,
  ComponentSlotStyle,
  EyeIcon,
  EyeSlashIcon,
  Flex,
  FlexItem,
  Text,
} from '@fluentui/react-northstar';
import { ReactNode, useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useIgnoreEmployee,
  useUnignoreEmployee,
} from '../../../data-access/hooks/commands/use-update-employee';
import { EmployeeAvatar } from '../EmployeeAvatar';
import { importantInfoFloatingStyle, menteeBadgeLabelStyle } from './nameSectionStyles';
import { app, authentication } from '@microsoft/teams-js';
import jwt from 'jwt-decode';

const ADMIN_ROLE = 'Admin';

interface NameSectionProps {
  firstName: string;
  lastName: string;
  id?: string;
  role: string;
  isMentee: boolean;
  isIgnored: boolean;
  importantInformationSection?: ReactNode;
  photo: string;
}

export const colorGrey: ComponentSlotStyle = (data) => ({
  color: data.theme.siteVariables.colors.grey['450'],
});

export const NameSection = ({
  firstName,
  lastName,
  role,
  isMentee,
  isIgnored,
  id,
  importantInformationSection,
  photo,
}: NameSectionProps) => {
  const { t } = useTranslation();
  const { mutate: ignoreEmployee } = useIgnoreEmployee();
  const { mutate: unignoreEmployee } = useUnignoreEmployee();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (app.isInitialized()) {
      const getIsAdmin = async () =>
        authentication.getAuthToken().then((token) => {
          setIsAdmin(jwt<{ roles: Array<string> }>(token).roles.includes(ADMIN_ROLE));
        });
      getIsAdmin();
    }
  }, []);

  const onClickIgnoreEmployee = useCallback(() => {
    ignoreEmployee({ employeeId: id ?? '' });
  }, [id, ignoreEmployee]);

  const onClickUnignoreEmployee = useCallback(() => {
    unignoreEmployee({ employeeId: id ?? '' });
  }, [id, unignoreEmployee]);

  return (
    <Flex gap="gap.small">
      <EmployeeAvatar firstName={firstName} lastName={lastName} size="large" photo={photo} />
      <Flex column>
        <Text weight="semibold" size="large">
          {firstName} {lastName}
        </Text>
        <Flex gap="gap.small">
          <Text styles={colorGrey}>{role}</Text>
          {isMentee && (
            <Avatar
              size="smallest"
              name={t('employee-profile.mentee')}
              label={{ styles: menteeBadgeLabelStyle }}
            />
          )}
        </Flex>
      </Flex>
      {isAdmin && (
        <FlexItem push align="end">
          {isIgnored ? (
            <Button
              icon={<EyeSlashIcon outline />}
              onClick={onClickUnignoreEmployee}
              iconOnly
              primary
              flat
              title={t('employee-profile.unignore-employee-tooltip')}
            />
          ) : (
            <Button
              icon={<EyeIcon outline />}
              onClick={onClickIgnoreEmployee}
              iconOnly
              primary
              flat
              title={t('employee-profile.ignore-employee-tooltip')}
            />
          )}
        </FlexItem>
      )}
      {importantInformationSection && (
        <Flex styles={importantInfoFloatingStyle}>{importantInformationSection}</Flex>
      )}
    </Flex>
  );
};
