import { Alert, Button, Flex, SpotlightIcon, Text } from '@fluentui/react-northstar';
import { retrieveAttachmentUrl } from '../../data-access/hooks/queries/retrieve-attachment-url';
import { ApiError } from '../../data-access/open-api/queries';
import { ResourceRequestDto } from '../../data-access/open-api/queries/models/ResourceRequestDto';
import { downloadFile } from '../Common/download-file';

type ResourceRequestAttachmentsProps = {
  resourceRequest: ResourceRequestDto;
};

const handleDownloadAttachment = async (resourceRequestId: string, attachmentId: string) => {
  try {
    const attachmentUrl = retrieveAttachmentUrl(resourceRequestId, attachmentId);
    const { payload: url } = await attachmentUrl;
    downloadFile(url);
  } catch (error) {
    if (error instanceof ApiError) {
      return (
        <Alert
          content={error.body?.reason ?? error.message}
          dismissible
          dismissAction={{
            'aria-label': 'close',
          }}
          variables={{
            urgent: true,
          }}
        />
      );
    }
  }
};

export const ResourceRequestAttachments = ({
  resourceRequest,
}: ResourceRequestAttachmentsProps) => {
  return (
    <Flex column hAlign="start" styles={{ maxWidth: '100%', overflow: 'hidden' }}>
      {resourceRequest?.attachments?.map((attachment) => (
        <Button
          as="a"
          key={attachment.id}
          content={<Text content={attachment.attachmentFile.displayName} truncated />}
          icon={<SpotlightIcon />}
          title={attachment.attachmentFile.displayName}
          text
          primary
          styles={{
            textDecoration: 'underline',
            height: 'initial',
            minWidth: 'initial',
            padding: 0,
            maxWidth: '100%',
          }}
          onClick={() => handleDownloadAttachment(resourceRequest.id, attachment.id)}
        />
      ))}
    </Flex>
  );
};
