import { ComponentSlotStyle } from '@fluentui/react-northstar';

export const paginationRootStyle: ComponentSlotStyle = (data) => ({
  fontSize: data.theme.siteVariables.fontSizes['medium'],
});

export const paginationButtonStyle: ComponentSlotStyle = () => ({
  minWidth: '2.3rem',
  boxSizing: 'border-box',
});

export const paginationButtonSelectedStyle: ComponentSlotStyle = (data) => ({
  ...paginationButtonStyle(data),
  '::after': {
    content: `""`,
    position: 'absolute',
    bottom: '5px',
    display: 'block',
    width: '20px',
    height: '2px',
    backgroundColor: data.theme.siteVariables.colorScheme['brand']['background'],
  },
});
