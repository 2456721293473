import { Flex, FlexItem, Text } from '@fluentui/react-northstar';
import { RoleRequestDto } from '../../../data-access/open-api/queries/models/RoleRequestDto';
import { useTranslation } from 'react-i18next';

interface RequestedRolesProps {
  roleRequests: Array<RoleRequestDto>;
}

export const RequiredRoles = ({ roleRequests }: RequestedRolesProps) => {
  type Role = {
    id: string;
    quantity: number;
    roleName: string;
    workload: number;
  };

  const { t } = useTranslation();
  const roles = (): Role[] => {
    const roleCollector: Role[] = [];
    roleRequests.forEach((role) => {
      const index = roleCollector.findIndex(
        (r) => r.roleName === role.role && r.workload === role.workload
      );
      if (index === -1) {
        roleCollector.push({
          id: role.id,
          quantity: 1,
          roleName: role.role,
          workload: role.workload,
        });
      } else {
        roleCollector[index].quantity++;
      }
    });
    return roleCollector;
  };

  return (
    <Flex column gap="gap.small">
      <Text weight="semibold" size="medium" content={t('resource-details-pane.required-roles')} />
      <Flex column>
        {roles().map((r) => (
          <Flex gap="gap.smaller" key={`required_roles_${r.id}`}>
            <Text size="small" content={r.quantity} />
            <Text size="small" content={r.roleName} />
            <FlexItem push>
              <Text size="small" content={`${r.workload} %`} />
            </FlexItem>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
