import { ComponentSlotStyle } from '@fluentui/react-northstar';

export const detailPaneRootStyle: ComponentSlotStyle = () => {
  return {
    '& dd': {
      marginLeft: 0,
    },
  };
};

export const arrowButtonStyle: ComponentSlotStyle = () => ({
  minWidth: 0,
  boxSizing: 'border-box',
});

export const panelHeadingStyle: ComponentSlotStyle = () => ({
  marginTop: '5px',
});

export const gridSeperator: ComponentSlotStyle = (data) => ({
  ':not(:last-child)': {
    borderRight: '1px solid',
    borderColor: data.theme.siteVariables['dividerColor'],
    paddingRight: '21px',
  },
});

export const textAreaStyle: ComponentSlotStyle = (data) => ({
  border: `1px solid ${data.theme.siteVariables.colors.grey['400']}`,
});
