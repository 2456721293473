import { useResourceRequests } from '../../../data-access/hooks/queries/use-resource-requests';

export interface UseProjectOverviewDataHookParams {
  resourceRequestId?: string;
}

export type ResourceRequestData = ReturnType<typeof useResourceRequests>['data'];

export const useResourceRequestData = ({
  resourceRequestId,
}: UseProjectOverviewDataHookParams): ResourceRequestData => {
  const result = useResourceRequests(resourceRequestId);
  return result.data;
};
