import { Grid } from '@fluentui/react-northstar';
import { FunctionComponent } from 'react';

interface GridLayoutProps {
  children?: React.ReactNode;
}

export const GridLayout: FunctionComponent<GridLayoutProps> = (props) => {
  return (
    <Grid rows="2" columns="3" style={{ gap: 28 }}>
      {props.children}
    </Grid>
  );
};
