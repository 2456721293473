import { ComponentSlotStyle } from '@fluentui/react-northstar';

export const potentialEmployeesDialogTable: ComponentSlotStyle = (data) => ({
  paddingBottom: '1rem',
  '> .ui-table__row': {
    paddingTop: '2rem',
    paddingBottom: '2rem',
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    borderBottomWidth: 1,
    borderColor: data.theme.siteVariables.dividerColor,
    backgroundColor: 'transparent',
  },
});

export const potentialEmployeesDialogTableHeaderStyleAvatar: ComponentSlotStyle = (data) => ({
  fontWeight: data.theme.siteVariables['fontWeightSemibold'],
  fontSize: data.theme.siteVariables.fontSizes['medium'],
  maxWidth: '8%',
});

export const potentialEmployeesDialogTableHeaderStyleName: ComponentSlotStyle = (data) => ({
  fontWeight: data.theme.siteVariables['fontWeightSemibold'],
  fontSize: data.theme.siteVariables.fontSizes['medium'],
  maxWidth: '20%',
});

export const potentialEmployeesDialogTableHeaderStyleSkills: ComponentSlotStyle = (data) => ({
  fontWeight: data.theme.siteVariables['fontWeightSemibold'],
  fontSize: data.theme.siteVariables.fontSizes['medium'],
  maxWidth: '50%',
});

export const potentialEmployeesDialogTableHeaderStyleAction: ComponentSlotStyle = (data) => ({
  fontWeight: data.theme.siteVariables['fontWeightSemibold'],
  fontSize: data.theme.siteVariables.fontSizes['medium'],
  maxWidth: '12%',
});

export const potentialEmployeesDialogTableHeaderStyleStatus: ComponentSlotStyle = (data) => ({
  fontWeight: data.theme.siteVariables['fontWeightSemibold'],
  fontSize: data.theme.siteVariables.fontSizes['medium'],
  maxWidth: '14%',
});

export const potentialEmployeesDialogSubHeader: ComponentSlotStyle = (data) => ({
  fontWeight: data.theme.siteVariables['fontWeightSemibold'],
  fontSize: data.theme.siteVariables.fontSizes['medium'],
  maxWidth: '30%',
});

export const statusPillStyle: ComponentSlotStyle = () => ({
  background: '#989AC9',
  color: 'white',
  ':hover': {
    background: '#989AC9',
  },
});
