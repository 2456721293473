import React, { useCallback } from 'react';
import BasicLayout from '../../components/Layout/Layout';
import { Flex, Text, ChevronStartIcon, Button, Card } from '@fluentui/react-northstar';
import { EmployeeProfileDetails } from './EmployeeProfileDetails';
import { useEmployeeData } from './hooks/useEmployeeData';
import { EmployeeProfileProjects } from './EmployeeProfileProjects';
import { EmployeeProfileTemporaryStates } from './EmployeeProfileTemporaryStates';
import { EmployeeProfileOpenRequests } from './EmployeeProfileOpenRequests';
import { useHistory, useParams } from 'react-router-dom';
import { detailPaneRootStyle } from './employeeProfileStyles';
import { useTranslation } from 'react-i18next';
import { EmployeeTemporaryStateDto } from '../../data-access/open-api/commands/models/EmployeeTemporaryStateDto';
import { useUpdateEmployeeTemporaryState } from '../../data-access/hooks/commands/use-update-employee';
import { GridLayout } from '../Layout/GridLayout';
import { Ti8mCard } from '../Common/Ti8mCard';

export const EmployeeProfilePage = () => {
  const { t } = useTranslation();
  const navigation = useHistory();
  const { employeeId } = useParams<{ employeeId: string }>();
  const employee = useEmployeeData({ employeeId });

  const { mutate: mutateEmployee } = useUpdateEmployeeTemporaryState(
    t('alert.request-objects.employee'),
    t('alert.update-types.edit')
  );

  const onEmployeeStateChanged = useCallback(
    (employeeState: EmployeeTemporaryStateDto) => {
      mutateEmployee({
        id: employeeId,
        requestBody: employeeState,
      });
    },
    [mutateEmployee, employeeId]
  );

  return (
    <BasicLayout>
      <Flex column>
        <Flex>
          <Button
            text
            onClick={() => navigation.push(`/employeeWorkload/${employeeId}`)}
            primary
            styles={{ paddingLeft: '-16px' }}
          >
            <Text size="large">
              <ChevronStartIcon styles={{ marginBottom: '2px' }} />
              {t('search.title')}
            </Text>
          </Button>
        </Flex>
        <GridLayout>
          <Ti8mCard
            style={{ gridRow: '1 / 3', marginTop: '46px' }}
            cardStyles={detailPaneRootStyle}
          >
            <EmployeeProfileDetails employee={employee} />
          </Ti8mCard>
          <Ti8mCard style={{ gridColumn: '2 / 4', marginTop: '46px', gridRow: '1' }}>
            <EmployeeProfileProjects employeeId={employeeId} pensum={employee?.pensum} />
          </Ti8mCard>
          <Card style={{ gridRow: '2', gridColumn: '2' }}>
            <EmployeeProfileTemporaryStates
              projectChangeRequest={employee?.projectChangeRequest}
              openEducation={employee?.openEducation}
              terminationOfEmployment={employee?.endDate}
              notes={employee?.notes}
              onTemporaryStateChanged={onEmployeeStateChanged}
            ></EmployeeProfileTemporaryStates>
          </Card>
          <Card style={{ gridRow: '2', gridColumn: '3' }}>
            <EmployeeProfileOpenRequests employee={employee}></EmployeeProfileOpenRequests>
          </Card>
        </GridLayout>
      </Flex>
    </BasicLayout>
  );
};
