import { Flex, Text, Tooltip } from '@fluentui/react-northstar';
import { TrashCanIcon } from '@fluentui/react-icons-northstar';
import { AlertOn20Regular, Clock20Regular } from '@fluentui/react-icons';
import { ClosingReason } from '../../enum/ClosingReason';
import { PersonAvailableIcon } from '../../icons';
import { useTranslation } from 'react-i18next';

export type PrioritySymbolProps = {
  submissionDate: string | undefined;
  hasText: boolean;
  closingInfo?: {
    reason: string;
    date: string;
    description: string;
  };
};

export const PrioritySymbol = ({ submissionDate, hasText, closingInfo }: PrioritySymbolProps) => {
  const { t } = useTranslation();
  const dateObj = new Date();
  const urgentDate = new Date(dateObj.setDate(dateObj.getDate() + 14));
  const submissionDateAsDate = new Date(submissionDate ?? '');

  const closingReason = (): ClosingReason => {
    if (closingInfo?.reason === 'Staffed') return ClosingReason.Staffed;
    else if (closingInfo?.reason === 'Cancelled') return ClosingReason.Cancelled;
    else return ClosingReason.Open;
  };

  const closingText = (): string => {
    if (closingReason() === 'Staffed') return 'Staffed';
    else if (closingReason() === 'Cancelled') return 'Canceled';
    else if (submissionDateAsDate < urgentDate && closingReason() === 'Open') return 'Urgent';
    else if (submissionDateAsDate > urgentDate && closingReason() === 'Open') return 'Open';
    else return '';
  };

  return (
    <>
      {submissionDate !== undefined && (
        <Flex gap="gap.smaller">
          <Flex>
            {closingReason() === 'Open' &&
              (submissionDateAsDate < urgentDate ? (
                <Tooltip
                  pointing
                  content={t('resource-request-table.cells.urgent')}
                  trigger={<AlertOn20Regular style={{ color: '#A4262C' }} />}
                />
              ) : (
                <Clock20Regular style={{ color: '#107C10' }} />
              ))}
            {closingReason() === 'Staffed' && <PersonAvailableIcon />}
            {closingReason() === 'Cancelled' && <TrashCanIcon outline />}
          </Flex>
          <Flex>{hasText && <Text content={closingText()} size="small" weight="regular" />}</Flex>
        </Flex>
      )}
    </>
  );
};
