import { ArchiveIcon, Button, EditIcon, Flex, TrashCanIcon } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { DeleteRequestDialog } from './DeleteRequestDialog/DeleteRequestDialog';
import { CloseRequestDialog } from './CloseRequestDialog/CloseRequestDialog';
import { ResourceRequestDto } from '../../data-access/open-api/queries/models/ResourceRequestDto';
import { CloseRequestData } from './CloseRequestDialog/model/CloseRequestData';
import { ResourceRequestDialog } from '../ResourceRequestFilter/ResourceRequestDialog/ResourceRequestDialog';

type ResourceRequestDetailViewButtonBarProps = {
  onCloseResourceRequest: () => void;
  closeRequestData: CloseRequestData;
  setCloseRequestData: (closeRequestData: CloseRequestData) => void;
  onDeleteResourceRequest: () => void;
  editRequestData: ResourceRequestDto;
  isButtonsVisible: boolean;
  setButtonsVisibility: (visibility: boolean) => void;
};

export const ResourceRequestDetailViewButtonBar = ({
  onCloseResourceRequest,
  closeRequestData,
  setCloseRequestData,
  onDeleteResourceRequest,
  editRequestData,
  isButtonsVisible,
  setButtonsVisibility,
}: ResourceRequestDetailViewButtonBarProps) => {
  const { t } = useTranslation();
  const [isDeleteRequestDialogOpen, setDeleteDialogVisibility] = useState<boolean>(false);
  const [isCloseDialogOpen, setCloseDialogVisibility] = useState<boolean>(false);
  const [isEditDialogOpen, setEditDialogVisibility] = useState<boolean>(false);

  const handleCloseDialogOpen = () => {
    setCloseDialogVisibility(() => true);
  };

  const handleCloseDialogClose = () => {
    setCloseDialogVisibility(() => false);
  };

  const handleEditDialogOpen = () => {
    setEditDialogVisibility(() => true);
  };

  const handleEditDialogCancelButton = useCallback(() => {
    setEditDialogVisibility(() => false);
  }, [setEditDialogVisibility]);

  const handleOpenDeleteRequestDialog = () => {
    setDeleteDialogVisibility(true);
  };

  const handleCloseDeleteRequestDialog = () => {
    setDeleteDialogVisibility(false);
  };

  return (
    <Flex gap="gap.small">
      <Button
        flat
        icon={<EditIcon outline />}
        content={t('resources-detail-view.edit-request')}
        primary
        disabled={!isButtonsVisible}
        onClick={handleEditDialogOpen}
      />
      <Button
        flat
        icon={<ArchiveIcon outline />}
        content={t('resources-detail-view.close request')}
        defaultChecked
        disabled={!isButtonsVisible}
        onClick={handleCloseDialogOpen}
      />
      <Button
        flat
        icon={<TrashCanIcon outline />}
        content={t('resources-detail-view.delete-request')}
        defaultChecked
        disabled={!isButtonsVisible}
        onClick={handleOpenDeleteRequestDialog}
      />
      {isCloseDialogOpen && (
        <CloseRequestDialog
          isDialogOpen={isCloseDialogOpen}
          handleCancelButton={handleCloseDialogClose}
          handleCloseButton={onCloseResourceRequest}
          closeRequestData={closeRequestData}
          setCloseRequestData={setCloseRequestData}
          setButtonsVisibility={setButtonsVisibility}
        />
      )}
      {isDeleteRequestDialogOpen && (
        <DeleteRequestDialog
          isDialogOpen={isDeleteRequestDialogOpen}
          handleCancelButton={handleCloseDeleteRequestDialog}
          onDeleteResourceRequest={onDeleteResourceRequest}
        />
      )}
      {isEditDialogOpen && (
        <ResourceRequestDialog
          isDialogOpen={isEditDialogOpen}
          handleCloseRequest={handleEditDialogCancelButton}
          editRequestData={editRequestData}
          handleHeaderActionRequest={handleEditDialogCancelButton}
        />
      )}
    </Flex>
  );
};
