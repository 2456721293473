import React from 'react';
import { Flex, Table, Text } from '@fluentui/react-northstar';
import { Ti8mCard } from '../../Common/Ti8mCard';
import { useTranslation } from 'react-i18next';
import {
  capacityStyle,
  durationStyle,
  projectDetailsStyle,
  requiredRolesTableStyle,
  roleStyle,
  skillsStyle,
} from './resourceRequestDialogStyles';
import { PrioritySymbol } from '../../ResourceTable/PrioritySymbol';
import { convertMonthYear } from '../../../utils/dateConverter';
import { ResourceRequestViewModel } from './viewModels/ResourceRequestViewModel';
import { buildSkillsString } from './skillsStringBuilder';

interface ProjectOverviewDialogFormProps {
  resourceRequest: ResourceRequestViewModel;
}

export const ProjectOverviewDialogForm = ({ resourceRequest }: ProjectOverviewDialogFormProps) => {
  const { t } = useTranslation();

  const projectDetails = [
    {
      key: 1,
      items: [
        {
          key: 1,
          content: <Text content={resourceRequest.client} weight="semibold" />,
        },
        {
          key: 2,
          content: resourceRequest.project,
        },
        {
          key: 3,
          content: (
            <Text
              content={`${convertMonthYear(resourceRequest.projectStart)} - ${convertMonthYear(
                resourceRequest.projectEnd
              )}`}
            />
          ),
        },
        {
          key: 4,
          content: (
            <Flex>
              <PrioritySymbol
                submissionDate={convertMonthYear(resourceRequest.submission)}
                hasText={false}
              />
              <Text content={convertMonthYear(resourceRequest.submission)} />
            </Flex>
          ),
        },
      ],
    },
  ];

  const headerProjectDetails = {
    items: [
      {
        key: 'clientHeader',
        content: (
          <Text
            weight="semibold"
            size="medium"
            content={t('resources.request-dialog.client-label')}
          />
        ),
      },
      {
        key: 'projectHeader',
        content: (
          <Text
            weight="semibold"
            size="medium"
            content={t('resources.request-dialog.project-label')}
          />
        ),
      },
      {
        key: 'durationHeader',
        content: (
          <Text
            weight="semibold"
            size="medium"
            content={t('resources.request-dialog.duration-label')}
          />
        ),
      },
      {
        key: 'submissionHeader',
        content: (
          <Text
            weight="semibold"
            size="medium"
            content={t('resources.request-dialog.submission-date-label')}
          />
        ),
      },
    ],
  };

  return (
    <Ti8mCard>
      <Flex column gap="gap.large">
        <Flex column gap="gap.small">
          <Text
            content={t('resources.request-dialog.project-description-label')}
            size="large"
            weight="semibold"
          />
          <Text content={resourceRequest.description} />
        </Flex>
        <Table
          styles={projectDetailsStyle}
          style={{ backgroundColor: 'transparent' }}
          header={headerProjectDetails}
          rows={projectDetails}
        ></Table>
        <Flex column gap="gap.small">
          <Text
            content={t('resources.request-dialog.role-dialog.required-roles')}
            size="large"
            weight="semibold"
          />
          <Table
            styles={requiredRolesTableStyle}
            style={{ backgroundColor: 'transparent' }}
            rows={resourceRequest.roles.map((role, index) => ({
              key: `${index}_role`,
              compact: true,
              items: [
                {
                  key: `${index}_roleName`,
                  content: <Text content={role.role} />,
                  truncateContent: true,
                  styles: roleStyle,
                },
                {
                  key: `${index}_skills`,
                  content: buildSkillsString(role),
                  truncateContent: true,
                  styles: skillsStyle,
                },
                {
                  key: `${index}_duration`,
                  content: (
                    <Text
                      content={`${convertMonthYear(role.roleStart)} - ${convertMonthYear(
                        role.roleEnd
                      )}`}
                      size="small"
                    />
                  ),
                  styles: durationStyle,
                },
                {
                  key: `${index}_capacity`,
                  content: <Text content={`${role.workload} %`} size="small" />,
                  styles: capacityStyle,
                },
              ],
            }))}
          ></Table>
        </Flex>
      </Flex>
    </Ti8mCard>
  );
};
