import { useQuery, UseQueryOptions } from 'react-query';
import { env } from '../../../utils/env';
import { ApiError } from '../../open-api/queries';
import { OrganizationDto } from '../../open-api/queries/models/OrganizationDto';
import { OrganizationsResult } from '../../open-api/queries/models/OrganizationsResult';
import { OrganizationService } from '../../open-api/queries/services/OrganizationService';
import { QueryKeys } from '../../query-keys';

const getOrganizations = () =>
  OrganizationService.organizations({
    version: env('REACT_APP_API_VERSION'),
  });

export const useOrganizations = (
  queryOptions?: UseQueryOptions<
    OrganizationsResult,
    ApiError,
    Array<OrganizationDto>,
    [QueryKeys.Organization]
  >
) =>
  useQuery([QueryKeys.Organization], ({ queryKey: [_] }) => getOrganizations(), {
    select: (data) => data.payload,
    ...queryOptions,
  });
