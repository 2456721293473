import { FunctionComponent } from 'react';
import { Flex } from '@fluentui/react-northstar';
import { ResourceRequestDialogMenu } from './ResourceRequestDialogMenu';
import { AttachmentFile, MenuStateType, ResourceRequestSetter } from './ResourceRequestDialog';
import { ProjectOverviewDialogForm } from './ProjectOverviewDialogForm';
import { ProjectInfoDialogForm } from './ProjectInfoDialogForm';
import { ProjectRolesDialog } from './ProjectRolesDialog';
import { ResourceRequestViewModel } from './viewModels/ResourceRequestViewModel';

interface RequestDialogFormProps {
  resourceRequest: ResourceRequestViewModel;
  isEditMode: boolean;
  handleSetResourceRequest: ResourceRequestSetter;
  menuState: MenuStateType;
  setMenuState: (menuState: MenuStateType) => void;
  isTouchedInfo: boolean;
  setTouchedInfo: (isTouched: boolean) => void;
  attachmentFiles: AttachmentFile[];
  setAttachmentFiles: (attachments: AttachmentFile[]) => void;
}

export const ResourceRequestDialogForm: FunctionComponent<RequestDialogFormProps> = ({
  resourceRequest,
  isEditMode,
  handleSetResourceRequest,
  menuState,
  setMenuState,
  isTouchedInfo,
  setTouchedInfo,
  attachmentFiles,
  setAttachmentFiles,
}: RequestDialogFormProps) => {
  return (
    <Flex column gap="gap.large">
      <ResourceRequestDialogMenu
        isEditMode={isEditMode}
        menuState={menuState}
        setMenuState={setMenuState}
        isTouchedInfo={isTouchedInfo}
        isTouchedRole={resourceRequest.roles.length >= 1}
      />
      {(() => {
        switch (menuState) {
          case 'projectInfo':
            return (
              <ProjectInfoDialogForm
                isEditMode={isEditMode}
                resourceRequest={resourceRequest}
                handleResourceRequest={handleSetResourceRequest}
                attachmentFiles={attachmentFiles}
                setAttachmentFiles={setAttachmentFiles}
                setTouched={setTouchedInfo}
              />
            );
          case 'projectRole':
            return (
              <ProjectRolesDialog
                resourceRequest={resourceRequest}
                handleResourceRequest={handleSetResourceRequest}
              />
            );
          case 'requestSubmission':
            return <ProjectOverviewDialogForm resourceRequest={resourceRequest} />;
        }
      })()}
    </Flex>
  );
};
