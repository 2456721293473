import { ComponentSlotStyle } from '@fluentui/react-northstar';

const common = {
  marginLeft: '0.5rem',
  cursor: 'pointer',
  borderRadius: '2px',
};
export const filterStyles: ComponentSlotStyle = (data) => ({
  ...common,
  background: data.theme.siteVariables.colorScheme['default']['foreground5'],
  ':hover': {
    background: data.theme.siteVariables.colorScheme['default']['foreground5'],
  },
});

export const activeFilterStyles: ComponentSlotStyle = (data) => {
  const filter = data.theme.componentVariables['FilterPill']();
  return {
    ...common,
    color: 'white',
    background: filter.activeColor,
    ':hover': {
      color: 'black',
      backgroundColor: filter.activeHoverColor,
    },
  };
};
