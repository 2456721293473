import { ComponentSlotStyle } from '@fluentui/react-northstar';

export const suggestedEmployeesSubHeader: ComponentSlotStyle = (data) => ({
  fontWeight: data.theme.siteVariables['fontWeightSemibold'],
  fontSize: data.theme.siteVariables.fontSizes['medium'],
  maxWidth: '30%',
  marginBottom: '0.5rem',
  paddingTop: '2rem',
});

export const suggestedEmployeesButton: ComponentSlotStyle = () => ({
  maxWidth: '18px',
  maxHeight: '18px',
  minWidth: 0,
  minHeight: 0,
  padding: 0,
  marginBottom: '1rem',
});

export const suggestedEmployeesDeleteButtonStyle: ComponentSlotStyle = (data) => ({
  border: 'none',
  boxShadow: 'none',
  background: 'transparent',
  cursor: 'pointer',
  ':disabled': {
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
  },
  ':active': {
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
  },
  color: data.theme.siteVariables.colors['accentColor'],
});
