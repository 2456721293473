import { ComponentSlotStyle } from '@fluentui/react-northstar';
import { statusDarkColorVariable } from '../../Common/statusStyles';

export const detailPaneRootStyle: ComponentSlotStyle = () => {
  return {
    borderRadius: '0px 20px 20px 20px',
    marginTop: '56px',
    '& dd': {
      marginLeft: 0,
    },
    '&:hover': {
      borderRadius: '0px 20px 20px 20px',
    },
    width: '500px',
    height: '800px',
    '& > .ui-flex': {
      height: '100%',
    },
  };
};

export const successColorStyle: ComponentSlotStyle = (data) => ({
  color: statusDarkColorVariable(data.theme.siteVariables).green,
});
