import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { executivesDashboardNavigationPointerAtom } from '../../../state/atoms/executivesDashboardNavigationPointerAtom';
import { NodeNavigationSegment, NodeNavigation } from '../models/NavigationPointer';

export function useDrillDown() {
  const [, setNavigationPointer] = useRecoilState<NodeNavigation>(
    executivesDashboardNavigationPointerAtom
  );

  const drillDown = useCallback(
    (pointer: NodeNavigationSegment) => setNavigationPointer((prev) => [...prev, pointer]),
    [setNavigationPointer]
  );

  return drillDown;
}
