import { Accordion, Box, ChevronDownIcon, Flex, InfoIcon, Text } from '@fluentui/react-northstar';
import { successColorStyle } from './employeeTableDetailPaneStyles';
import {
  accordionContentStyles,
  accordionRootStyles,
  accordionTitleClosedStyles,
  accordionTitleOpenStyles,
  importantInfoTitleTextStyle,
} from './importantInfoAccordionStyles';
import { useTranslation } from 'react-i18next';
import { PeopleTeamAddIcon, PeopleSwapIcon, CertificateIcon } from '../../../icons';

interface ImportantInfoProps {
  openRequests?: string[];
  projectChangeRequest?: string;
  openEducation?: string;
}

export const ImportantInfoAccordion = ({
  openRequests,
  projectChangeRequest,
  openEducation,
}: ImportantInfoProps) => {
  const { t } = useTranslation();
  if ([openRequests, projectChangeRequest, openEducation].some((x) => Boolean(x))) {
    return <></>;
  }

  return (
    <Accordion
      defaultActiveIndex={[0]}
      panels={[
        {
          title: t('employee-overview-table-detail.important-information'),
          content: (
            <Flex column gap="gap.medium">
              {openRequests && (
                <Flex gap="gap.smaller">
                  <div>
                    <PeopleTeamAddIcon size="medium" />
                  </div>
                  <div>
                    <Text weight="semibold" size="smaller">
                      {t('employee-overview-table-detail.open-requests')}
                    </Text>
                    <ul>
                      {openRequests?.map((item) => (
                        <li>
                          <Text as="div" size="smaller">
                            {item}
                          </Text>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Flex>
              )}
              {projectChangeRequest && (
                <Flex gap="gap.smaller">
                  <div>
                    <PeopleSwapIcon />
                  </div>
                  <div>
                    <Text weight="semibold" size="smaller">
                      {t('employee-overview-table-detail.project-change-request')}
                    </Text>
                    <Text as="div" size="smaller">
                      {projectChangeRequest}
                    </Text>
                  </div>
                </Flex>
              )}
              {openEducation && (
                <Flex gap="gap.smaller">
                  <div>
                    <CertificateIcon />
                  </div>
                  <div>
                    <Text weight="semibold" size="smaller">
                      {t('employee-overview-table-detail.open-education')}
                    </Text>
                    <Text as="div" size="smaller">
                      {openEducation}
                    </Text>
                  </div>
                </Flex>
              )}
            </Flex>
          ),
        },
      ]}
      styles={accordionRootStyles}
      renderPanelTitle={(_, props) => {
        return (
          <Box
            onClick={(e: any) => props.onClick?.(e, props)}
            styles={(data) =>
              !props.active ? accordionTitleClosedStyles(data) : accordionTitleOpenStyles(data)
            }
            key={`importantinfo_title_${props.active}`}
          >
            <Flex hAlign="center" vAlign="center" space="between">
              <Flex gap="gap.small" vAlign="center">
                <InfoIcon styles={successColorStyle} />
                <Text styles={importantInfoTitleTextStyle}>{props.content}</Text>
              </Flex>
              <ChevronDownIcon
                styles={successColorStyle}
                style={{
                  transform: props.active ? 'rotate(180deg)' : 'inherit',
                }}
              />
              {/* Note: Believe it or not, the library has no ChevronUpIcon! */}
            </Flex>
          </Box>
        );
      }}
      renderPanelContent={(_, props) => {
        return (
          <Box styles={accordionContentStyles} key={`importantinfo_content_${props.active}`}>
            <Flex hAlign="center" vAlign="center" space="between">
              <Text styles={successColorStyle}>{props.content}</Text>
            </Flex>
          </Box>
        );
      }}
    />
  );
};
