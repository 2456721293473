import * as CommandParams from './command-params';
import { ApiError, CommentService } from '../../open-api/commands';
import { env } from '../../../utils/env';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { Result } from '../../open-api/commands/models/Result';
import { QueryKeys } from '../../query-keys';
import { ResourceRequestService as QueryResourceRequestService } from '../../open-api/queries';
import { GuidResult } from '../../open-api/commands/models/GuidResult';
import { waitForEvent } from './wait-for-event';

const editComment = (input: CommandParams.EditCommentInput) =>
  CommentService.editComment({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const addComment = (input: CommandParams.AddCommentInput) =>
  CommentService.addComment({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const deleteComment = (input: CommandParams.DeleteCommentInput) =>
  CommentService.deleteComment({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const getEventPosition = (id: string) =>
  QueryResourceRequestService.getEventPosition({
    version: env('REACT_APP_API_VERSION'),
    id,
  });

type GuidResultWithId = Result & {
  id: string;
};

export const useEditComment = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, CommandParams.EditCommentInput>(
    async (input) => {
      const result = await editComment(input);

      return { ...result, id: input.resourceRequestId };
    },
    {
      onSuccess: async ({ id, storePosition }) =>
        waitForEvent({ id, storePosition }, getEventPosition).then(() =>
          queryClient.refetchQueries([QueryKeys.ResourceRequest, id])
        ),
      meta: {
        type: t('alert.update-types.edit'),
        requestObject: t('alert.request-objects.request'),
      },
    }
  );
};

export const useAddComment = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, CommandParams.AddCommentInput>(
    async (input) => {
      const result: GuidResult = await addComment(input);

      return { ...result, id: input.resourceRequestId };
    },
    {
      onSuccess: async ({ id, storePosition }) =>
        waitForEvent({ id, storePosition }, getEventPosition).then(() =>
          queryClient.refetchQueries(QueryKeys.ResourceRequest)
        ),
      meta: {
        type: t('alert.update-types.edit'),
        requestObject: t('alert.request-objects.request'),
      },
    }
  );
};

export const useDeleteComment = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, CommandParams.DeleteCommentInput>(
    async (input) => {
      const result = await deleteComment(input);

      return { ...result, id: input.commentId };
    },
    {
      onSuccess: async ({ id }) =>
        waitForEvent({ id }, getEventPosition, true).then(() =>
          queryClient.refetchQueries(QueryKeys.ResourceRequest)
        ),
      meta: {
        type: t('alert.update-types.delete'),
        requestObject: t('alert.request-objects.comment'),
      },
    }
  );
};
