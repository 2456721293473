import { orderBy } from 'lodash';
import { useMemo } from 'react';
import { useResourceRequestsOverview } from '../../../data-access/hooks/queries/use-resource-requests-overview';
import { ResourceRequestOverviewDto } from '../../../data-access/open-api/queries/models/ResourceRequestOverviewDto';
import { convertMonthYear } from '../../../utils/dateConverter';

export type UseRequestOverviewLocalSortParams<T> = {
  paginationOptions: {
    currentPage: number;
    pageSize: number;
  };
  open?: boolean;
  sortBy: T;
  sortDirection?: 'asc' | 'desc';
};

export const useResourceRequestOverviewLocalSort = <T extends string>({
  paginationOptions,
  open,
  sortBy,
  sortDirection = 'asc',
}: UseRequestOverviewLocalSortParams<T>) => {
  const result = useResourceRequestsOverview(
    {
      // Fetch all data for client-side sorting due to small dataset size
      skip: 0,
      take: 10_000,
      open,
      orderBy: 'SubmissionDate',
    },
    {
      keepPreviousData: true,
    }
  );

  const transformedData: (ResourceRequestOverviewDto & { duration: string })[] = useMemo(() => {
    return (
      result.data?.items.map((resourceRequest) => {
        return {
          ...resourceRequest,
          duration: calculateDuration(resourceRequest.projectStart, resourceRequest.projectEnd),
        };
      }) ?? []
    );
  }, [result.data]);

  const sortedData = useMemo(() => {
    return orderBy(transformedData, [sortBy], [sortDirection]);
  }, [transformedData, sortBy, sortDirection]);

  const { currentPage, pageSize } = paginationOptions;

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return sortedData.slice(startIndex, endIndex);
  }, [sortedData, currentPage, pageSize]);

  return {
    data: paginatedData,
    totalItems: result.data?.totalCount ?? 0,
  };
};

function calculateDuration(start: string, end: string) {
  return `${convertMonthYear(start)} - ${convertMonthYear(end)}`;
}
