import { useState } from 'react';

export type SortDirection = 'asc' | 'desc';

export const useSortState = <T extends string>(
  initialSortBy: T,
  initialSortDirection: SortDirection = 'asc'
) => {
  const [sortState, setSortState] = useState<{
    sortBy: T;
    sortDirection: SortDirection;
  }>({
    sortBy: initialSortBy,
    sortDirection: initialSortDirection,
  });

  const toggleSortDirection = (previousSortDirection: SortDirection) =>
    previousSortDirection === 'asc' ? 'desc' : 'asc';

  const setSortBy = (newSortBy: T) => {
    setSortState((prev) => ({
      sortBy: newSortBy,
      sortDirection: prev.sortBy === newSortBy ? toggleSortDirection(prev.sortDirection) : 'asc',
    }));
  };

  return { sortState, setSortBy };
};
