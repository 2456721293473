import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import de from './translations/de.json';
import en from './translations/en.json';

export enum Language {
  de = 'de',
  en = 'en',
}

const options = {
  order: ['querystring', 'navigator'],
  lookupQuerystring: 'lng',
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    detection: options,
    fallbackLng: 'en',
    ns: ['common'],
    defaultNS: 'common',
    nsSeparator: '.',
    resources: {
      de: {
        common: de,
      },
      en: {
        common: en,
      },
    },
  });

export { i18n as i18nInit };
