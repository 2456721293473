/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CommentDtoCommand } from '../models/CommentDtoCommand';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { Result } from '../models/Result';
import { Result_1 } from '../models/Result_1';
import { GuidResult } from '../models/GuidResult';

export class CommentService {
  /**
   *
   * @param version
   * @param resourceRequestId
   * @param commentBody
   */
  public static addComment({
    version,
    resourceRequestId,
    commentBody,
  }: {
    version: string;
    resourceRequestId: string;
    commentBody: CommentDtoCommand;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/resource-requests/{resourceRequestId}/comments',
      path: {
        version: version,
        resourceRequestId: resourceRequestId,
      },
      body: commentBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static deleteComment({
    version,
    resourceRequestId,
    commentId,
  }: {
    version: string;
    resourceRequestId: string;
    commentId: string;
  }): CancelablePromise<Result | Result_1> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/c/api/v{version}/resource-requests/{resourceRequestId}/comments/{commentId}',
      path: {
        version: version,
        resourceRequestId: resourceRequestId,
        commentId: commentId,
      },
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static editComment({
    version,
    resourceRequestId,
    commentId,
    commentBody,
  }: {
    version: string;
    resourceRequestId: string;
    commentId: string;
    commentBody: CommentDtoCommand;
  }): CancelablePromise<Result | Result_1> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/c/api/v{version}/resource-requests/{resourceRequestId}/comments/{commentId}',
      path: {
        version: version,
        resourceRequestId: resourceRequestId,
        commentId: commentId,
      },
      body: commentBody,
      mediaType: 'application/json-patch+json',
    });
  }
}
