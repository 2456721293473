import { Text } from '@fluentui/react-northstar';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Ti8mDialog } from '../../Common/Ti8mDialog';

type DeleteRequestDialogProps = {
  isDialogOpen: boolean;
  handleCancelButton: () => void;
  onDeleteResourceRequest: () => void;
};

export const DeleteRequestDialog = ({
  isDialogOpen,
  handleCancelButton,
  onDeleteResourceRequest,
}: DeleteRequestDialogProps) => {
  const { t } = useTranslation();
  const onConfirmDelete = () => {
    onDeleteResourceRequest();
    handleCancelButton();
  };

  return (
    <Ti8mDialog
      cancelButton={t('delete-request-dialog.cancel-button')}
      showCloseHeaderAction={false}
      onCancel={handleCancelButton}
      confirmButton={t('delete-request-dialog.delete-button')}
      onConfirm={onConfirmDelete}
      content={
        <>
          <Text content={t('delete-request-dialog.notification')}></Text>
        </>
      }
      header={t('delete-request-dialog.title')}
      open={isDialogOpen}
    />
  );
};
