import { createSvgIcon } from '@fluentui/react-northstar';

export const CertificateIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="0 0 16 16" className={classes.svg}>
      <path d="M3.2 2.4A1.6 1.6 0 0 0 1.6 4v6.4A1.6 1.6 0 0 0 3.2 12h6v-.537a3.603 3.603 0 0 1-.194-.263H3.2a.8.8 0 0 1-.8-.8V4a.8.8 0 0 1 .8-.8h9.6a.8.8 0 0 1 .8.8v1.975c.291.144.56.327.8.542V4a1.6 1.6 0 0 0-1.6-1.6H3.2Zm11.6 6.8a2.8 2.8 0 1 1-5.6 0 2.8 2.8 0 0 1 5.6 0ZM12 12.8c.74 0 1.428-.223 2-.606V14.4a.4.4 0 0 1-.64.32l-1.12-.84a.4.4 0 0 0-.48 0l-1.12.84a.4.4 0 0 1-.64-.32v-2.206c.572.383 1.26.606 2 .606ZM4 5.2c0-.22.18-.4.4-.4h7.2a.4.4 0 0 1 0 .8H4.4a.4.4 0 0 1-.4-.4Zm.4 3.6a.4.4 0 1 0 0 .8h3.2a.4.4 0 0 0 0-.8H4.4Z" />
    </svg>
  ),
  displayName: 'CertificateIcon',
});
