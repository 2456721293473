import {
  AddIcon,
  Button,
  DropdownProps,
  Flex,
  Table,
  Text,
  TrashCanIcon,
} from '@fluentui/react-northstar';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectDto } from '../../../data-access/open-api/queries/models/ProjectDto';
import { workloads } from '../../../data/workloads';
import { ProbabilityOfExtensionType } from '../../../enum/ProbabilityOfExtensionType';
import { Ti8mDatepicker } from '../../Common/Ti8mDatepicker';
import { Ti8mDropdown } from '../../Common/Ti8mDropdown';
import { EmployeeProfileViewModel } from '../viewModels/EmployeeProfileViewModel';
import { transparentTableStyle } from './transparentTableStyle';
import { useInfiniteProjects } from '../../../data-access/hooks/queries/use-infinite-projects';

interface EmployeeProfileAddProjectDialogProps {
  project: EmployeeProfileViewModel;
  setProject?: (data: string) => void;
  setStartDate: (data: Date | null, i: number) => void;
  setEndDate: (data: Date | null, i: number) => void;
  setPensum: (data: string, i: number) => void;
  setProbabilityOfExtension: (value?: ProbabilityOfExtensionType) => void;
  addWorkload: () => void;
  removeWorkload: (i: number) => void;
}

export const EmployeeProfileAddProjectDialog = ({
  project,
  setProject = undefined,
  setStartDate,
  setEndDate,
  setPensum,
  setProbabilityOfExtension,
  addWorkload,
  removeWorkload,
}: EmployeeProfileAddProjectDialogProps) => {
  const { t } = useTranslation();
  const [projectSearchString, setProjectSearchString] = useState('');
  const { projects, fetchMoreProjects, hasMoreProjects, isProjectsError } =
    useInfiniteProjects(projectSearchString);

  const onProjectSearchQueryChange = useCallback(
    (data: DropdownProps) => {
      if (data.searchQuery !== undefined) {
        setProjectSearchString(data.searchQuery);
      }
    },
    [setProjectSearchString]
  );

  const onProjectDropdownSelectionChanged = (data: DropdownProps) => {
    if (setProject) {
      if (data.value) {
        setProject((data.value as { key: string }).key);
      } else {
        setProject('');
      }
    }
  };

  const onPensumChanged = useCallback(
    (data: string, i: number) => {
      setPensum(data, i);
    },
    [setPensum]
  );

  const getDateDefaultValue = (data: string) => {
    if (data && data !== '') {
      return new Date(data);
    }
    return undefined;
  };

  const getProjectItemText = (project: ProjectDto) => {
    let result = `${project.projectNumber}`;
    if (project.customerName) {
      result = result + ` - ${project.customerName}`;
    }
    result = result + ` - ${project.projectName}`;
    return result;
  };

  const getProbabilityOfExtensionDefault = () => {
    return {
      key: project.probabilityOfExtension,
      header: t(
        `probability-of-extension-types.${
          project.probabilityOfExtension
            ? ProbabilityOfExtensionType[project.probabilityOfExtension]
            : 0
        }`
      ),
    };
  };

  const removeWorkloadRow = useCallback(
    (i: number) => {
      removeWorkload(i);
    },
    [removeWorkload]
  );

  const addWorkloadRow = useCallback(() => {
    addWorkload();
  }, [addWorkload]);

  const probabilitiesOfExtension = [
    {
      key: ProbabilityOfExtensionType[1],
      header: t(`probability-of-extension-types.1`),
    },
    {
      key: ProbabilityOfExtensionType[2],
      header: t(`probability-of-extension-types.2`),
    },
    {
      key: ProbabilityOfExtensionType[3],
      header: t(`probability-of-extension-types.3`),
    },
    {
      key: 'undefined',
      header: t(`probability-of-extension-types.0`),
    },
  ];

  return (
    <Flex column gap="gap.medium">
      {!project.id && (
        <Flex column gap="gap.smaller">
          <Text
            size="small"
            weight="semibold"
            content={t('employee-profile.projects.dialog.project-label')}
          />
          <Ti8mDropdown
            items={projects.map((p) => ({
              header: getProjectItemText(p),
              key: p.id,
            }))}
            onScrollLoadMoreItems={fetchMoreProjects}
            canLoadMoreItems={hasMoreProjects}
            hasDataError={isProjectsError}
            onSearchQueryChange={onProjectSearchQueryChange}
            placeholder={t('employee-profile.projects.dialog.project-dropdown-placeholder')}
            onItemChange={onProjectDropdownSelectionChanged}
            search={true}
          />
        </Flex>
      )}
      <Table
        key={`table_workload_${project.workloads?.length}`}
        compact
        style={{ background: 'transparent' }}
        styles={transparentTableStyle}
        header={{
          items: [
            <Text
              key={'table_workload_pensum_header'}
              weight="semibold"
              content={t('employee-profile.projects.dialog.pensum-dropdown-placeholder')}
              size="small"
            />,
            <Text
              key={'table_workload_start_date_header'}
              weight="semibold"
              content={t('employee-profile.projects.dialog.start-date-label')}
              size="small"
            />,
            <Text
              key={'table_workload_end_date_header'}
              weight="semibold"
              content={t('employee-profile.projects.dialog.end-date-label')}
              size="small"
            />,
            {
              key: 'table_workload_delete_header',
              content: '',
              styles: { maxWidth: '32' },
            },
          ],
        }}
        rows={project.workloads?.map((w, i) => ({
          key: `table_workload_row${i}`,
          items: [
            {
              key: `workload_row_workload_cell${i}`,
              content: (
                <Ti8mDropdown
                  items={workloads}
                  placeholder={t('employee-profile.projects.dialog.pensum-dropdown-placeholder')}
                  onItemChange={(data) => onPensumChanged(data.value as string, i)}
                  defaultValue={`${w.percentage}%`}
                />
              ),
            },
            {
              key: `workload_row_startdate_cell${i}`,
              content: (
                <Ti8mDatepicker
                  placeholder={t('employee-profile.projects.dialog.start-date-placeholder')}
                  onDateChange={(data) => setStartDate(data, i)}
                  defaultSelectedDate={getDateDefaultValue(w.start)}
                  error={w.hasErrorStart}
                />
              ),
            },
            {
              key: `workload_row_enddate_cell${i}`,
              content: (
                <Ti8mDatepicker
                  placeholder={t('employee-profile.projects.dialog.end-date-placeholder')}
                  onDateChange={(data) => setEndDate(data, i)}
                  defaultSelectedDate={getDateDefaultValue(w.end)}
                  error={w.hasErrorEnd}
                />
              ),
            },
            {
              key: `workload_row_delete_cell${i}`,
              content: (
                <Button
                  disabled={project.workloads?.length === 1}
                  text
                  iconOnly
                  icon={<TrashCanIcon />}
                  onClick={() => removeWorkloadRow(i)}
                />
              ),
              style: { maxWidth: '32' },
            },
          ],
        }))}
      />
      {project.workloads.find((w) => w.hasErrorEnd || w.hasErrorStart) && (
        <Text
          error={true}
          content={t('employee-profile.projects.dialog.pensum-time-periods-error')}
        />
      )}
      <Button
        iconOnly
        flat
        size="small"
        icon={<AddIcon size="small" />}
        style={{ maxWidth: '24px' }}
        onClick={addWorkloadRow}
        primary
      />
      <Flex column gap="gap.smaller">
        <Text
          weight="semibold"
          size="small"
          content={t('employee-profile.projects.dialog.probability-of-extension-label')}
        />
        <Ti8mDropdown
          items={probabilitiesOfExtension}
          position="above"
          align="top"
          placeholder={'select probability of extension'}
          onItemChange={(data) => {
            const value = data.value as {
              key: ProbabilityOfExtensionType | 'undefined';
              header: string;
            };
            setProbabilityOfExtension(value.key === 'undefined' ? undefined : value.key);
          }}
          defaultValue={getProbabilityOfExtensionDefault()}
        />
      </Flex>
    </Flex>
  );
};
