import {
  ComponentSlotStyle,
  ComponentSlotStyleFunction,
  ComponentStyleFunctionParam,
} from '@fluentui/react-northstar';

export const outlookTableStyle: ComponentSlotStyle = (data) => ({
  backgroundColor: 'transparent',
  '> .ui-table__row': {
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    borderBottomWidth: 1,
    backgroundColor: 'transparent',
    maxHeight: '2.5rem',
  },
  '> .ui-table__row:not(:first-child)': {
    borderColor: data.theme.siteVariables.dividerColor,
  },
});

const baseCellStyle = (data: ComponentStyleFunctionParam, fontSize: string) => ({
  backgroundColor: data.theme.siteVariables.colors['activeBackgroundColor'],
  fontSize: data.theme.siteVariables.fontSizes[fontSize],
});

export const baseHeaderStyle: ComponentSlotStyleFunction = (data) => ({
  ...baseCellStyle(data, 'medium'),
  fontWeight: data.theme.siteVariables.fontWeightSemibold,
});

export const billabilityClickableCellStyle: ComponentSlotStyleFunction = (data) => ({
  ...baseCellStyle(data, 'medium'),
  color: data.theme.siteVariables.colorScheme.brand.foreground,
});

export const billabilityNameColumnStyle: ComponentSlotStyleFunction = (data) => ({
  ...baseCellStyle(data, 'medium'),
  flexGrow: 1,
});

export const billabilityNameHeaderStyle: ComponentSlotStyleFunction = (data) => ({
  ...baseHeaderStyle(data),
  flexGrow: 1,
});

export const billabilityFrequencyColumnStyle: ComponentSlotStyleFunction = (data) => ({
  ...baseCellStyle(data, 'small'),
  flexGrow: 0.22,
  justifyContent: 'end',
});

export const billiabilityFrequencyHeaderStyle: ComponentSlotStyleFunction = (data) => ({
  ...baseHeaderStyle(data),
  flexGrow: 0.22,
  '> div': {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
});

export const workloadClickableCellStyle: ComponentSlotStyleFunction = (data) => ({
  ...baseCellStyle(data, 'medium'),
  color: data.theme.siteVariables.colorScheme.brand.foreground,
});

export const workloadNameColumnStyle: ComponentSlotStyleFunction = (data) => ({
  ...baseCellStyle(data, 'medium'),
  flexGrow: 1,
});

export const workloadNameHeaderStyle: ComponentSlotStyleFunction = (data) => ({
  ...baseHeaderStyle(data),
  flexGrow: 1,
});

export const workloadMonthsColumnStyle: ComponentSlotStyleFunction = (data) => ({
  ...baseCellStyle(data, 'small'),
  flexGrow: 0.33,
});

export const workloadMonthsHeaderStyle: ComponentSlotStyleFunction = (data) => ({
  ...baseHeaderStyle(data),
  flexGrow: 0.33,
  whiteSpace: 'nowrap',
  textOverflow: 'unset',
});
