import { slice } from 'ramda';
import { useRoleRequests } from '../../../data-access/hooks/queries/use-role-requests';
import { PotentialEmployeeStatus } from '../../../enum/PotentialEmployeeStatus';

interface UsePotentialEmployeesDataHookParams {
  resourceRequestId: string;
  roleRequestId: string;
  isAvailable: boolean;
  paginationOptions: { currentPage: number; pageSize: number };
}

export const usePotentialEmployeesData = ({
  resourceRequestId,
  roleRequestId,
  isAvailable,
  paginationOptions,
}: UsePotentialEmployeesDataHookParams) => {
  const { currentPage, pageSize } = paginationOptions;
  const offset = (currentPage - 1) * pageSize;

  const { data = [] } = useRoleRequests({
    resourceRequestId,
    roleRequestId,
  });

  const filteredData = data.filter(
    (item) => (item.requestStatus === PotentialEmployeeStatus.AVAILABLE) == isAvailable
  );

  const items = slice(offset, offset + pageSize, filteredData);

  return {
    potentialEmployees: items,
    totalItems: filteredData.length,
  };
};
