import {
  AddIcon,
  Button,
  EditIcon,
  Flex,
  Table,
  Text,
  TrashCanIcon,
} from '@fluentui/react-northstar';
import {
  addCommentButton,
  commentsTable,
  commentsTableComment,
  commentsTableDate,
  commentsTableName,
  detailsTableStyleEdit,
  requiredRolesHeader,
} from './resourceRequestDetailStyles';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { convertDayMonthYear } from '../../utils/dateConverter';
import { CommentDialog } from '../ResourceTable/ResourceTableDetailPane/CommentDialog';
import {
  useAddComment,
  useDeleteComment,
  useEditComment,
} from '../../data-access/hooks/commands/use-update-comments';
import { CommentDto } from '../../data-access/open-api/queries/models/CommentDto';

interface ResourceRequestCommentsProps {
  resourceRequestId: string;
  comments?: CommentDto[];
  isButtonsVisible: boolean;
}

export const ResourceRequestComments = ({
  resourceRequestId,
  comments,
  isButtonsVisible,
}: ResourceRequestCommentsProps) => {
  const { t } = useTranslation();
  const [isEditDialogVisible, setEditDialogVisibility] = useState<boolean>(false);
  const [isAddDialogVisible, setAddDialogVisibility] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(true);
  const [commentData, setCommentData] = useState<string>('');
  const [commentId, setCommentId] = useState<string>('');

  const { mutate: addComment } = useAddComment();
  const { mutate: editComment } = useEditComment();
  const { mutate: deleteComment } = useDeleteComment();

  const onEditComment = useCallback((comment: CommentDto) => {
    setCommentData(comment.content);
    setCommentId(comment.id);
    setEditDialogVisibility(true);
  }, []);

  const onChangeCommentData = useCallback((comment: string) => {
    setCommentData(comment);
    setIsEmpty(comment === '');
  }, []);

  const onConfirmEditComment = () => {
    editComment({
      resourceRequestId: resourceRequestId,
      commentId: commentId,
      commentBody: { id: commentId, content: commentData },
    });
    setEditDialogVisibility(false);
  };

  const onAddComment = useCallback(() => {
    setAddDialogVisibility(true);
  }, []);

  const onConfirmAddComment = () => {
    addComment({
      resourceRequestId: resourceRequestId,
      commentBody: { id: '', content: commentData },
    });
    setAddDialogVisibility(false);
    setIsEmpty(true);
  };

  const onDeleteComment = useCallback(
    (commentId: string) => {
      deleteComment({
        resourceRequestId: resourceRequestId,
        commentId: commentId,
      });
      setCommentId('');
      setCommentData('');
    },
    [deleteComment, resourceRequestId]
  );

  const onCancel = useCallback(() => {
    setCommentData('');
    setEditDialogVisibility(false);
    setAddDialogVisibility(false);
    setIsEmpty(true);
  }, []);

  const getCommentText = (commentText: string) => {
    if (commentText.length > 600) {
      return `${commentText.substring(0, 600)} ...`;
    } else {
      return commentText;
    }
  };

  return (
    <Flex column gap="gap.small">
      <Text
        weight="semibold"
        styles={requiredRolesHeader}
        content={t('resources-detail-view.comments')}
      />
      <Button
        flat
        icon={<AddIcon />}
        onClick={onAddComment}
        styles={addCommentButton}
        content={t('resources-detail-view.add-comment-button')}
        disabled={!isButtonsVisible}
      ></Button>
      <CommentDialog
        isEdit={false}
        isEmpty={isEmpty}
        commentData={commentData}
        onChangeCommentData={onChangeCommentData}
        onConfirm={onConfirmAddComment}
        isCommentsDialogVisible={isAddDialogVisible}
        onCancel={onCancel}
      />
      <Table
        styles={commentsTable}
        style={{ backgroundColor: 'transparent' }}
        rows={comments
          ?.slice()
          .sort(
            (a, b) =>
              new Date(b.modifiedAt || b.createdAt).getTime() -
              new Date(a.modifiedAt || a.createdAt).getTime()
          )
          .map((item) => ({
            key: item.id,
            items: [
              {
                key: `${item.id}_date`,
                content: (
                  <Text
                    content={
                      item.modifiedAt
                        ? convertDayMonthYear(item.modifiedAt)
                        : convertDayMonthYear(item.createdAt)
                    }
                  />
                ),
                styles: commentsTableDate,
              },
              {
                key: `${item.id}_name`,
                content: <Text content={`${item.author}`} />,
                styles: commentsTableName,
              },
              {
                key: `${item.id}_comment`,
                content: <Text content={getCommentText(item.content)} />,
                styles: commentsTableComment,
              },
              {
                key: `${item.id}_icons`,
                content: (
                  <Flex gap={'gap.small'}>
                    <Button
                      iconOnly
                      text
                      primary
                      icon={<EditIcon size="large" />}
                      onClick={() => onEditComment(item)}
                    />
                    <CommentDialog
                      isEdit={true}
                      isEmpty={isEmpty}
                      commentData={commentData}
                      onChangeCommentData={onChangeCommentData}
                      onConfirm={onConfirmEditComment}
                      isCommentsDialogVisible={isEditDialogVisible}
                      onCancel={onCancel}
                    />
                    <Button
                      iconOnly
                      text
                      primary
                      icon={<TrashCanIcon size="large" />}
                      onClick={() => onDeleteComment(item.id)}
                    />
                  </Flex>
                ),
                styles: detailsTableStyleEdit,
              },
            ],
          }))}
      ></Table>
    </Flex>
  );
};
