import { CalendarIcon, Datepicker, Position } from '@fluentui/react-northstar';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { FunctionComponent, useCallback, useState } from 'react';
import { customFormatter } from '../../utils/customFormatter';

dayjs.extend(customParseFormat);

const customParseFormats = [
  'D.M.YYYY',
  'DD.M.YYYY',
  'D.MM.YYYY',
  'DD.MM.YYYY',
  'D.M.YY',
  'DD.M',
  'D.M',
  'D.MM.',
  'DD.M.',
  'D.M.',
  'D.MM.',
];

interface Ti8mDatepickerProps {
  placeholder?: string;
  defaultSelectedDate?: Date;
  error?: boolean;
  popupPosition?: Position;
  onDateChange: (date: Date | null) => void;
}

export const Ti8mDatepicker: FunctionComponent<Ti8mDatepickerProps> = ({
  placeholder,
  defaultSelectedDate,
  error,
  popupPosition,
  onDateChange,
}: Ti8mDatepickerProps) => {
  const [isCalendarPopupOpen, setIsCalendarPopupOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(defaultSelectedDate);
  const [parsedDate, setParsedDate] = useState<{ value?: Date; valid: boolean }>({
    value: undefined,
    valid: false,
  });

  const handleClick = useCallback(
    async (value?: boolean) => {
      // small timeouthack to handle order of execution
      await setTimeout(() => {
        setIsCalendarPopupOpen(value ?? isCalendarPopupOpen);
      }, 10);
    },
    [setIsCalendarPopupOpen, isCalendarPopupOpen]
  );

  return (
    <Datepicker
      inputOnly
      input={{
        icon: (
          <CalendarIcon
            outline
            onClick={() => {
              setIsCalendarPopupOpen(!isCalendarPopupOpen);
            }}
          />
        ),
        fluid: true,
        error: error,
        errorIndicator: (
          <CalendarIcon
            outline
            onClick={() => {
              setIsCalendarPopupOpen(!isCalendarPopupOpen);
            }}
          />
        ),
      }}
      calendar={{
        calendarCellButton: {
          onClick: () => setIsCalendarPopupOpen(false),
        },
      }}
      popup={{
        onOpenChange: (_, d) => {
          handleClick(d?.open ?? isCalendarPopupOpen);
        },
        unstable_pinned: true,
        // default to 'below' here, because providing undefined here does change the behaviour!
        position: popupPosition ?? 'below',
      }}
      calendarOpenState={isCalendarPopupOpen}
      inputPlaceholder={placeholder}
      allowManualInput={true}
      onDateChange={(_, data) => {
        onDateChange(data?.value ?? null);
      }}
      parseDate={(dateString) => {
        const date = dayjs(dateString, customParseFormats, true);
        if (date.isValid()) {
          setParsedDate({ value: date.toDate(), valid: true });
        } else {
          setParsedDate({ value: undefined, valid: false });
        }
        return null;
      }}
      onBlur={() => {
        if (parsedDate.valid) {
          setSelectedDate(parsedDate.value);
          onDateChange(parsedDate.value ? parsedDate.value : null);
        }
      }}
      formatMonthDayYear={customFormatter}
      defaultSelectedDate={defaultSelectedDate}
      selectedDate={selectedDate}
    />
  );
};
