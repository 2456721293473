import { ComponentSlotStyle } from '@fluentui/react-northstar';

export const inputStyles: ComponentSlotStyle = {
  width: '18.75rem',
  marginRight: '3rem',
};

export const layoutStyles: ComponentSlotStyle = {
  display: 'inline-flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'start',
  marginRight: '3rem',
  marginBottom: '32px',
};

export const resourceButtonStyles: ComponentSlotStyle = {
  margin: 0,
  width: '13rem',
  marginTop: '1.5rem',
  marginBottom: '32px',
};
