import { useEmployees } from '../../../data-access/hooks/queries/use-employees';
import { FavouriteFilterDto } from '../../../data-access/open-api/queries/models/FavouriteFilterDto';
import { SortAtomState } from '../../../state/atoms/sortAtom';
import { LocalFilter } from '../../SearchWithFilter/LocalFilter';

export interface UseEmployeeListDataHookParams {
  searchString?: string;
  filteringOptions?: LocalFilter | FavouriteFilterDto;
  sortingOptions?: SortAtomState;
  paginationOptions: { currentPage: number; pageSize: number };
}

export const useEmployeeListData = ({
  filteringOptions,
  searchString,
  sortingOptions,
  paginationOptions,
}: UseEmployeeListDataHookParams) => {
  const result = useEmployees(
    {
      organizationNumber: filteringOptions?.selectedOrganization?.number,
      remainingHolidays: filteringOptions?.selectedHolidaysNumber,
      hasOpenEducation: filteringOptions?.openEducation,
      hasProjectChangeRequest: filteringOptions?.changeRequest,
      isOnMentoringProgram: filteringOptions?.mentoringProgram,
      hasEndDate: filteringOptions?.workloadTermination,
      includeIgnored: filteringOptions?.ignoredEmployees,
      skip: (paginationOptions.currentPage - 1) * paginationOptions.pageSize,
      take: paginationOptions.pageSize,
      sortColumn: sortingOptions?.sortBy,
      sortDescending: sortingOptions?.sortDescending ?? true,
      searchString: searchString,
    },
    { keepPreviousData: true }
  );
  return {
    data: result.data?.items ?? [],
    totalItems: result.data?.totalCount ?? 0,
  };
};
