import { Button, Flex, FlexItem, Text, TrashCanIcon, EditIcon } from '@fluentui/react-northstar';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { convertMonthYear } from '../../../utils/dateConverter';
import { useCallback, useState } from 'react';
import { buildSkillsString } from './skillsStringBuilder';
import { RoleDialog } from './RoleDialog';
import { RoleRequestDto } from '../../../data-access/open-api/queries/models/RoleRequestDto';

interface RequiredRolesProps {
  role: RoleRequestDto;
  setRole: (role: RoleRequestDto) => void;
  roles?: RoleRequestDto[];
  onDeleteRole: (key: string) => void;
  onEditRole: (role: RoleRequestDto) => void;
  onConfirmRole: () => void;
}

export const RequiredRoles = ({
  role,
  setRole,
  roles,
  onDeleteRole,
  onEditRole,
  onConfirmRole,
}: RequiredRolesProps) => {
  const { t } = useTranslation();
  const [isDialogOpen, setDialogVisibility] = useState<boolean>(false);
  const [isTouched, setTouched] = useState<boolean>(false);

  const handleDataChanged = useCallback(
    (roleRequestData: RoleRequestDto) => {
      setRole(roleRequestData);
      setTouched([role.role, role.roleStart, role.roleEnd].every((value) => value !== ''));
    },
    [role, setRole]
  );

  const onConfirmRoleEditRole = () => {
    setDialogVisibility(false);
    onConfirmRole();
  };

  return (
    <Flex column gap="gap.large">
      <Text
        content={t('resources.request-dialog.role-dialog.required-roles')}
        size="medium"
        weight="semibold"
      />
      <Flex column gap="gap.medium">
        {roles ? (
          roles.map((role) => (
            <Flex key={role.id}>
              <Flex gap="gap.large">
                <Text content={role.role} styles={{ minWidth: '8rem', maxWidth: '8rem' }} />
                <Text
                  content={buildSkillsString(role)}
                  size="small"
                  styles={{
                    marginTop: '3px',
                    minWidth: '18rem',
                    maxWidth: '18rem',
                  }}
                />
                <Text
                  content={`${convertMonthYear(role.roleStart)} - ${convertMonthYear(
                    role.roleEnd
                  )}`}
                  size="small"
                  styles={{
                    marginTop: '3px',
                    minWidth: '8rem',
                    maxWidth: '8rem',
                  }}
                />
                <Text content={`${role.workload} %`} size="small" styles={{ marginTop: '3px' }} />
              </Flex>
              <FlexItem push>
                <Flex>
                  <Button
                    iconOnly
                    text
                    primary
                    size="small"
                    icon={<EditIcon size="medium" />}
                    onClick={() => {
                      onEditRole(role);
                      setDialogVisibility(true);
                    }}
                  />
                  <Button
                    iconOnly
                    text
                    primary
                    size="small"
                    icon={<TrashCanIcon size="medium" />}
                    onClick={() => onDeleteRole(role.id)}
                  />
                </Flex>
              </FlexItem>
            </Flex>
          ))
        ) : (
          <Text content={t('resources-request-dialog.role-dialog.no-roles')} />
        )}
      </Flex>
      <RoleDialog
        isEditMode={true}
        isTouched={isTouched}
        isDialogOpen={isDialogOpen}
        roleData={role}
        handleDataChanged={handleDataChanged}
        onCancel={() => setDialogVisibility(false)}
        onConfirm={onConfirmRoleEditRole}
      />
    </Flex>
  );
};
