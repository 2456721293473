/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { UInt64NullableResult } from '../../queries/core/UInt64NullableResult';
import type { FavouriteFilterDto } from '../models/FavouriteFilterDto';
import type { GuidResult } from '../models/GuidResult';
import type { Result } from '../models/Result';
import type { Result_1 } from '../models/Result_1';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FavouriteFilterService {
  /**
   * @returns GuidResult Success
   * @throws ApiError
   */
  public static addFavouriteFilter({
    version,
    requestBody,
  }: {
    version: string;
    requestBody?: FavouriteFilterDto;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/favourite-filter',
      path: {
        version: version,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * @returns GuidResult Success
   * @throws ApiError
   */
  public static changeDefaultFilter({
    version,
    currentFilterId,
    newFilterId,
  }: {
    version: string;
    currentFilterId?: string;
    newFilterId?: string;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/c/api/v{version}/favourite-filter',
      path: {
        version: version,
      },
      query: {
        currentFilterId: currentFilterId,
        newFilterId: newFilterId,
      },
    });
  }

  /**
   * @returns GuidResult Success
   * @throws ApiError
   */
  public static updateFavouriteFilter({
    id,
    version,
    requestBody,
  }: {
    id: string;
    version: string;
    requestBody?: FavouriteFilterDto;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/c/api/v{version}/favourite-filter/{id}',
      path: {
        id: id,
        version: version,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static deleteFavouriteFilter({
    id,
    version,
  }: {
    id: string;
    version: string;
  }): CancelablePromise<Result | Result_1> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/c/api/v{version}/favourite-filter/{id}',
      path: {
        id: id,
        version: version,
      },
    });
  }

  /**
   * @returns UInt64NullableResult Success
   * @throws ApiError
   */
  public static getEventPosition({
    id,
    version,
  }: {
    id: string;
    version: string;
  }): CancelablePromise<UInt64NullableResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/favourite-filter/{id}/event-position',
      path: {
        id: id,
        version: version,
      },
    });
  }
}
