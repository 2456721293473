import { ChartsService } from '../../open-api/queries/services/ChartsService';
import { env } from '../../../utils/env';
import { useQuery, UseQueryOptions } from 'react-query';
import { QueryKeys } from '../../query-keys';
import { WorkloadRelativeFrequenciesForestDtoResult } from '../../open-api/queries/models/WorkloadRelativeFrequenciesForestDtoResult';
import { ApiError } from '../../open-api/queries';
import { WorkloadRelativeFrequenciesForestDto } from '../../open-api/queries/models/WorkloadRelativeFrequenciesForestDto';
import type * as QueryParams from './query-params';

const getBillabilityDrillDown = (input: QueryParams.BillabilityDrillDown) =>
  ChartsService.billabilityDrillDown({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

export const useBillabilityDrillDown = (
  queryParams: QueryParams.BillabilityDrillDown,
  queryOptions?: UseQueryOptions<
    WorkloadRelativeFrequenciesForestDtoResult,
    ApiError,
    WorkloadRelativeFrequenciesForestDto,
    [QueryKeys.BillabilityDrillDown, QueryParams.BillabilityDrillDown]
  >
) =>
  useQuery(
    [QueryKeys.BillabilityDrillDown, queryParams],
    ({ queryKey: [, input] }) => getBillabilityDrillDown(input),
    { select: (data) => data.payload, ...queryOptions }
  );
