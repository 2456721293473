import { Flex, ListItem, Status } from '@fluentui/react-northstar';

export type BarChartLegendProps = {
  props: Array<{
    key: number;
    dataKey: string;
    color: string;
  }>;
};
export const BarChartLegend = ({ props }: BarChartLegendProps) => {
  return (
    <Flex wrap={true} style={{ gridRow: '3/4' }}>
      {props.map((prop, i) => (
        <ListItem
          key={`bar-chart.legend.${i}`}
          content={prop.dataKey}
          media={<Status style={{ marginTop: '2px' }} color={prop.color} />}
          index={i}
        />
      ))}
    </Flex>
  );
};
