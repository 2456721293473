import { AddIcon, Button, Flex } from '@fluentui/react-northstar';
import { ChangeEvent, useCallback, useRef } from 'react';
import { generateUUID } from '@microsoft/microsoft-graph-client/lib/es/src/middleware/MiddlewareUtil';
import { AttachmentFile } from './ResourceRequestDialog';

interface AttachmentsProps {
  attachmentFiles: AttachmentFile[];
  setAttachmentFiles: (attachmentFiles: AttachmentFile[]) => void;
}

export const AttachmentsUpLoader = ({ attachmentFiles, setAttachmentFiles }: AttachmentsProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    inputRef?.current?.click();
  };

  const onFileSelected = useCallback(
    (file?: File) => {
      if (file) {
        setAttachmentFiles([
          ...attachmentFiles,
          {
            attachmentId: generateUUID(),
            originalFileName: file.name,
            file: file,
          },
        ]);
      }
    },
    [setAttachmentFiles, attachmentFiles]
  );

  return (
    <Flex column>
      <input
        ref={inputRef}
        style={{ display: 'none' }}
        type="file"
        accept=".pdf,.jpg,.png"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (e.target.files) {
            onFileSelected(e.target.files[0]);
          }
        }}
      />
      <Button
        flat
        iconOnly
        primary
        size="small"
        styles={{ maxWidth: '1rem' }}
        content={<AddIcon size="small" />}
        onClick={handleClick}
      />
    </Flex>
  );
};
