import { useQuery, UseQueryOptions } from 'react-query';
import { env } from '../../../utils/env';
import { QueryKeys } from '../../query-keys';
import { ResourceRequestService } from '../../open-api/queries';
import { ApiError } from '../../open-api/queries';
import { ResourceRequestDtoResult } from '../../open-api/queries/models/ResourceRequestDtoResult';
import { ResourceRequestDto } from '../../open-api/queries/models/ResourceRequestDto';

const getResourceRequest = (id: string) =>
  ResourceRequestService.resourceRequestsById({
    version: env('REACT_APP_API_VERSION'),
    id,
  });

export const useResourceRequests = (
  resourceRequestId?: string,
  queryOptions?: UseQueryOptions<
    ResourceRequestDtoResult,
    ApiError,
    ResourceRequestDto,
    [QueryKeys.ResourceRequest, string | undefined]
  >
) =>
  useQuery(
    [QueryKeys.ResourceRequest, resourceRequestId],
    () => getResourceRequest(resourceRequestId!),
    {
      enabled: !!resourceRequestId,
      select: (data) => data.payload,
      ...queryOptions,
    }
  );
