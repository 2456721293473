import { ComponentSlotStyle, Pill, Tooltip } from '@fluentui/react-northstar';
import { ShorthandValue } from '@fluentui/react-northstar/dist/es/types';
import { PillIconProps } from '@fluentui/react-northstar/dist/es/components/Pill/PillIcon';

type Ti8mPillProps = {
  content: string;
  maxTextLength: number;
  showTooltip?: boolean;
  toolTipContent?: React.ReactNode;
  tooltipSubtle?: boolean;
  color?: string;
  size?: 'small' | 'smaller' | 'medium';
  styles?: ComponentSlotStyle;
  itemId?: string;
  appearance?: 'outline' | 'filled';
  icon?: ShorthandValue<PillIconProps>;
};

export const Ti8mPill = ({
  content,
  maxTextLength,
  showTooltip,
  toolTipContent,
  tooltipSubtle,
  size,
  styles,
  itemId,
  color,
  appearance,
  icon,
}: Ti8mPillProps) => {
  const pill = (
    <Pill
      key={itemId}
      rectangular
      size={size}
      styles={styles}
      color={color}
      appearance={appearance}
      icon={icon}
    >
      {content.length < maxTextLength
        ? content
        : content.substring(0, maxTextLength - 4).concat('…')}
    </Pill>
  );

  if (showTooltip) {
    return <Tooltip trigger={pill} content={toolTipContent ?? content} subtle={tooltipSubtle} />;
  } else {
    return pill;
  }
};
