import { Button, Flex, Text, EditIcon, Divider, TrashCanIcon } from '@fluentui/react-northstar';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeProjectAssignmentsChangesDto } from '../../../data-access/open-api/commands/models/EmployeeProjectsChangesDto';
import { EmployeeProjectAssignmentDto } from '../../../data-access/open-api/queries';
import { ProbabilityOfExtensionType } from '../../../enum/ProbabilityOfExtensionType';
import { dialogStyle } from './dialogsStyles';
import { EditEmployeeProjectDialog } from './EditEmployeeProjectDialog';
import dayjs from 'dayjs';
import { Ti8mDialog } from '../../Common/Ti8mDialog';

interface EmployeeProfileAddProjectDialogProps {
  employeeProjects: Array<EmployeeProjectAssignmentDto>;
  isDialogOpen: boolean;
  onHandleClose: () => void;
  onHandleSave: (projectChanges: EmployeeProjectAssignmentsChangesDto) => void;
}

export const ProjectsListToEditDialog = ({
  employeeProjects,
  isDialogOpen,
  onHandleClose,
  onHandleSave,
}: EmployeeProfileAddProjectDialogProps) => {
  const { t } = useTranslation();
  const [isTouched, setTouched] = useState<boolean>(false);
  const [selectedProject, setSelectedProject] = useState<EmployeeProjectAssignmentDto>();
  const [isProjectEditDialogOpen, setProjectEditDialogOpen] = useState<boolean>(false);
  const [projects, setProjects] = useState<Array<EmployeeProjectAssignmentDto>>([
    ...employeeProjects.map((v) => ({
      ...v,
      workloads: [
        ...v.workloads.map((v) => {
          return { ...v };
        }),
      ],
    })),
  ]);
  const [deletedProjects, setDeletedProjects] = useState<Array<string>>([]);

  const onEditProject = useCallback(
    (project: EmployeeProjectAssignmentDto) => {
      setSelectedProject(project);
      setProjectEditDialogOpen(true);
    },
    [setSelectedProject, setProjectEditDialogOpen]
  );

  const onDeleteProject = useCallback(
    (project: EmployeeProjectAssignmentDto) => {
      const deletedProjectIndex = projects.findIndex((p) => p.id === project.id);
      const newArray = projects;
      delete newArray[deletedProjectIndex];
      setProjects(newArray);
      setDeletedProjects((prev) => [...prev, project.id ?? '']);
      setProjectEditDialogOpen(false);
      setTouched(true);
    },
    [setProjectEditDialogOpen, setDeletedProjects, setProjects, setTouched, projects]
  );

  const handleCloseEditDialog = useCallback(() => {
    setProjectEditDialogOpen(false);
  }, [setProjectEditDialogOpen]);

  const handleConfirmEditDialog = useCallback(
    (project: EmployeeProjectAssignmentDto) => {
      const editedProjectIndex = projects.findIndex((p) => p.id === project.id);
      const newArray = projects;
      let workloadsOrdered = project.workloads
        .filter((e) => e.end !== '' || e.start !== '' || e.percentage !== 0)
        .sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime());
      if (workloadsOrdered.length > 0) {
        project.startDate = workloadsOrdered[0].start;
        project.endDate = workloadsOrdered[workloadsOrdered.length - 1].end;
      }
      newArray[editedProjectIndex] = project;
      setProjects(newArray);
      setProjectEditDialogOpen(false);
      setTouched(true);
    },
    [setProjectEditDialogOpen, setProjects, setTouched, projects]
  );

  const onConfirmDialog = useCallback(() => {
    onHandleSave({
      modifiedProjectAssignmentsDtos: projects
        .filter((p) => p !== null)
        .map((p) => ({
          id: p.id,
          projectId: p.projectId ?? '',
          workloads: p.workloads ?? [],
          probabilityOfExtension: p.probabilityOfExtension,
        })),
      deletedProjectAssignmentsIds: deletedProjects,
    });
  }, [onHandleSave, projects, deletedProjects]);

  const getDurationValue = (project: EmployeeProjectAssignmentDto) => {
    if (project.startDate && project.endDate) {
      return `${dayjs(project.startDate).format('MMMM YYYY')} - ${dayjs(project.endDate).format(
        'MMMM YYYY'
      )}`;
    }

    return '';
  };

  const getWorkloadsRange = (project: EmployeeProjectAssignmentDto) => {
    if (project.workloads) {
      let smallest = 100;
      let largest = 0;
      project.workloads.forEach((w) => {
        if (w.percentage < smallest) {
          smallest = w.percentage;
        }
        if (w.percentage > largest) {
          largest = w.percentage;
        }
      });
      if (smallest === largest) {
        return `${smallest} %`;
      }
      return `${smallest} - ${largest} %`;
    }
    return '%';
  };

  return (
    <Ti8mDialog
      styles={dialogStyle}
      open={isDialogOpen}
      header={t('employee-profile.projects.dialog.edit-projects')}
      cancelButton={{
        content: t('employee-profile.projects.dialog.button-cancel'),
      }}
      onCancel={() => onHandleClose()}
      confirmButton={{
        content: t('employee-profile.projects.dialog.button-confirm'),
        disabled: !isTouched,
      }}
      onConfirm={() => onConfirmDialog()}
      content={
        <Flex column gap="gap.medium">
          {projects &&
            projects.length > 0 &&
            projects.map((project, i) => (
              <Flex gap="gap.smaller" column key={`project_${i}`}>
                <Flex>
                  <Text size="small" weight="semibold" content={project.projectName} />
                  <Flex.Item push>
                    <Flex>
                      <Button
                        primary
                        iconOnly
                        text
                        size="small"
                        onClick={() => onEditProject(project)}
                      >
                        <EditIcon outline />
                      </Button>
                      <Button
                        primary
                        iconOnly
                        text
                        size="small"
                        onClick={() => onDeleteProject(project)}
                        style={{ marginRight: 12 }}
                      >
                        <TrashCanIcon outline />
                      </Button>
                    </Flex>
                  </Flex.Item>
                  {selectedProject && (
                    <EditEmployeeProjectDialog
                      key={`${selectedProject.id}_editDialog_${isProjectEditDialogOpen}`}
                      isDialogOpen={isProjectEditDialogOpen}
                      employeeProject={selectedProject}
                      handleCloseDialog={() => handleCloseEditDialog()}
                      handleConfirmDialog={(data) => handleConfirmEditDialog(data)}
                    />
                  )}
                </Flex>
                <Flex column>
                  <Text size="small" weight="semibold" content="Duration" />
                  <Text size="small" content={getDurationValue(project)} />
                </Flex>
                <Flex column>
                  <Text size="small" weight="semibold" content="Pensum" />
                  <Text size="small" content={getWorkloadsRange(project)} />
                </Flex>
                <Flex column>
                  <Text
                    size="small"
                    weight="semibold"
                    content={t('employee-profile.projects.dialog.probability-of-extension-label')}
                  />
                  <Text
                    size="small"
                    content={t(
                      `probability-of-extension-types.${
                        project.probabilityOfExtension
                          ? ProbabilityOfExtensionType[project.probabilityOfExtension]
                          : 0
                      }`
                    )}
                  />
                </Flex>
                {i + 1 < projects.length && <Divider style={{ marginRight: 4 }} />}
              </Flex>
            ))}
        </Flex>
      }
    />
  );
};
