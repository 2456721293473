import { useEffect, useState } from 'react';
import { Flex, Input, FormTextArea, Grid, Text } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import { Ti8mDatepicker } from '../../Common/Ti8mDatepicker';
import { ProjectInfoAttachments } from './ProjectInfoAttachments';
import { AttachmentFile, ResourceRequestSetter } from './ResourceRequestDialog';
import { ResourceRequestViewModel } from './viewModels/ResourceRequestViewModel';
import { AttachmentDto } from '../../../data-access/open-api/queries/models/AttachmentDto';

interface ProjectInfoDialogFormProps {
  isEditMode: boolean;
  resourceRequest: ResourceRequestViewModel;
  handleResourceRequest: ResourceRequestSetter;
  attachmentFiles: AttachmentFile[];
  setAttachmentFiles: (attachments: AttachmentFile[]) => void;
  setTouched: (isTouched: boolean) => void;
}

export const ProjectInfoDialogForm = ({
  isEditMode,
  resourceRequest,
  handleResourceRequest,
  attachmentFiles,
  setAttachmentFiles,
  setTouched,
}: ProjectInfoDialogFormProps) => {
  const { t } = useTranslation();
  const maxCharLength = 1000;
  const [descriptionLength, setDescriptionLength] = useState(resourceRequest.description.length);

  useEffect(() => {
    const isTouched =
      resourceRequest.client !== '' &&
      resourceRequest.project !== '' &&
      resourceRequest.projectStart !== undefined &&
      resourceRequest.projectEnd !== undefined &&
      resourceRequest.submission !== undefined &&
      resourceRequest.projectStart < resourceRequest.projectEnd;
    setTouched(isTouched);
  }, [resourceRequest, setTouched]);

  const onClientChanged = (clientName: string) => {
    handleResourceRequest('client', clientName);
  };
  const onProjectNameChanged = (projectName: string) => {
    handleResourceRequest('project', projectName);
  };
  const onStartDateChanced = (startDate: Date) => {
    handleResourceRequest('projectStart', startDate);
  };
  const onEndDateChanged = (endDate: Date) => {
    handleResourceRequest('projectEnd', endDate);
  };
  const onSubmissionDateChanged = (submissionDate: Date) => {
    handleResourceRequest('submission', submissionDate);
  };
  const onExpectedFeedbackDateChanged = (value: Date | undefined) => {
    handleResourceRequest('expectedFeedbackDate', value);
  };
  const onDescriptionChanged = (description: string) => {
    handleResourceRequest('description', description);
  };
  const onAttachmentsChanged = (attachments: AttachmentDto[]) => {
    handleResourceRequest('attachments', attachments);
  };

  return (
    <Flex column gap="gap.large">
      <Grid columns="repeat(auto-fit, minmax(20rem, 1fr))" styles={{ gap: '1rem' }}>
        <Input
          label={t('resources.request-dialog.client-label')}
          placeholder={t('resources.request-dialog.client-placeholder')}
          value={resourceRequest.client}
          fluid
          onChange={(_, data) => onClientChanged(data?.value ?? '')}
        />
        <Input
          label={t('resources.request-dialog.project-label')}
          placeholder={t('resources.request-dialog.project-placeholder')}
          value={resourceRequest.project}
          fluid
          onChange={(_, data) => onProjectNameChanged(data?.value ?? '')}
        />
      </Grid>
      <Grid columns="repeat(auto-fit, minmax(10rem, 1fr))" styles={{ gap: '1rem' }}>
        <Flex column>
          <Text content={t('resources.request-dialog.start-date-label')} weight="semibold" />
          <Ti8mDatepicker
            placeholder={t('resources.request-dialog.date-placeholder')}
            onDateChange={(date) => onStartDateChanced(date ?? new Date())}
            defaultSelectedDate={resourceRequest.projectStart}
          />
        </Flex>
        <Flex column>
          <Text content={t('resources.request-dialog.end-date-label')} weight="semibold" />
          <Ti8mDatepicker
            placeholder={t('resources.request-dialog.date-placeholder')}
            onDateChange={(date) => onEndDateChanged(date ?? new Date())}
            defaultSelectedDate={resourceRequest.projectEnd}
          />
        </Flex>
        <Flex column>
          <Text content={t('resources.request-dialog.submission-date-label')} weight="semibold" />
          <Ti8mDatepicker
            placeholder={t('resources.request-dialog.date-placeholder')}
            onDateChange={(date) => onSubmissionDateChanged(date ?? new Date())}
            defaultSelectedDate={resourceRequest.submission}
          />
        </Flex>
        <Flex column>
          <Text
            content={t('resources.request-dialog.expected-feedback-date-label')}
            weight="semibold"
          />
          <Ti8mDatepicker
            placeholder={t('resources.request-dialog.date-placeholder')}
            onDateChange={(date) => onExpectedFeedbackDateChanged(date ?? undefined)}
            defaultSelectedDate={resourceRequest.expectedFeedbackDate}
          />
        </Flex>
      </Grid>
      <Flex column>
        <FormTextArea
          label={t('resources.request-dialog.project-description-label')}
          placeholder={t('resources.request-dialog.project-description-placeholder')}
          value={resourceRequest.description}
          maxLength={maxCharLength}
          rows={6}
          fluid
          onChange={(_event, data) => {
            setDescriptionLength(data?.value ? data.value.length : 0);
            return onDescriptionChanged(data?.value ?? '');
          }}
        />
        <Text content={descriptionLength + '/' + maxCharLength} size="small" align="end" />
      </Flex>
      <ProjectInfoAttachments
        isEditMode={isEditMode}
        resourceRequest={resourceRequest}
        attachmentFiles={attachmentFiles}
        setAttachmentFiles={setAttachmentFiles}
        onAttachmentsChanged={onAttachmentsChanged}
      />
    </Flex>
  );
};
