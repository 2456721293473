import { Flex, Text } from '@fluentui/react-northstar';
import { filter, groupBy, head, isEmpty, map, path, pathOr, pipe, values } from 'ramda';
import { useTranslation } from 'react-i18next';
import { EmployeeSkillDto } from '../../data-access/open-api/queries/models/EmployeeSkillDto';
import { TranslationDto } from '../../data-access/open-api/queries/models/TranslationDto';
import { Language } from '../../models/language';
import { Ti8mPill } from '../Common/Ti8mPill';

interface EmployeeProfileSkillsProps {
  skills: EmployeeSkillDto[] | null;
}

export const EmployeeProfileSkills = ({ skills }: EmployeeProfileSkillsProps) => {
  const { t } = useTranslation();
  const groupedSkills = skills ? groupSkills(skills) : null;
  const { i18n } = useTranslation();
  const language = i18n.resolvedLanguage as Language;

  return (
    <Flex column gap="gap.small">
      <Text size="large" weight="semibold">
        {t('employee-profile.skills.skills')}
      </Text>
      {groupedSkills?.map(({ groupName, subGroups, skills }) => (
        <Flex column gap="gap.small" key={`skill-group.${groupName?.en}`}>
          {subGroups ? (
            values(subGroups).map((skills) => {
              const subGroupName = getFirstSubGroupName(skills);

              return (
                <>
                  <Text weight="semibold">{subGroupName?.[language]}</Text>
                  <Flex wrap>
                    {skills.map((skill) => (
                      <Ti8mPill
                        key={`skill-pill.${skill.skillId}`}
                        size="small"
                        content={skill.skillName}
                        maxTextLength={18}
                        showTooltip={true}
                        styles={{ marginLeft: '0' }}
                      />
                    ))}
                  </Flex>
                </>
              );
            })
          ) : (
            <>
              <Text weight="semibold">{groupName?.[language]}</Text>
              <Flex wrap>
                {skills?.map((skill) => (
                  <Ti8mPill
                    key={`skill-pill.${skill.skillId}`}
                    size="small"
                    content={skill.skillName}
                    maxTextLength={18}
                    showTooltip={true}
                    styles={{ marginLeft: '0' }}
                  />
                ))}
              </Flex>
            </>
          )}
        </Flex>
      ))}
    </Flex>
  );
};

const filterByGroupProp = filter<EmployeeSkillDto>(
  ({ skillSubGroupId, skillSubGroupName }) => !!skillSubGroupId && !!skillSubGroupName
);

const getGroupName = path<TranslationDto>(['skillGroupName']);
const getFirstGroupName = pipe(head, getGroupName);

const getSubGroupName = path<TranslationDto>(['skillSubgroupName']);
const getFirstSubGroupName = pipe(head, getSubGroupName);

const getGroupId = pathOr<string>('', ['skillGroupId']);
const groupByGroupId = groupBy<EmployeeSkillDto>(getGroupId);

const getSubGroupId = pathOr<string>('', ['skillSubgroupId']);
const groupBySubGroupId = pipe(filterByGroupProp, groupBy<EmployeeSkillDto>(getSubGroupId));

const groupSkills = pipe(
  groupByGroupId,
  values,
  map((skills) => {
    const groupName = getFirstGroupName(skills);
    const subGroups = groupBySubGroupId(skills);

    return {
      groupName,
      subGroups: isEmpty(subGroups) ? null : subGroups,
      skills,
    };
  })
);
