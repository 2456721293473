import { Text } from '@fluentui/react-northstar';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { BarChartLegend } from './BarChartLegend';
import { useState } from 'react';
import './WorkloadBarChart.css';

export type BarChartData = {
  name: string;
  [key: string]: any;
};

type WorkloadBarChartProps = {
  title: string;
  chartData: Array<BarChartData>;
  tooltips: any;
};

const CustomTooltip = ({
  position,
  content,
  onMouseLeave,
}: {
  position: any;
  content: any;
  onMouseLeave: any;
}) => {
  const { x, y } = position || {};

  return (
    <div
      style={{
        left: x + 10,
        top: y,
        backgroundColor: '#201F1E',
        borderColor: '#201F1E',
        color: '#FAF9F8',
        padding: '10px',
        borderRadius: '4px',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.08)',
        position: 'fixed',
        zIndex: 1,
      }}
      onMouseLeave={onMouseLeave}
    >
      {content}
    </div>
  );
};

const Content = ({
  chunkTitle,
  chunkValue,
  text,
}: {
  chunkTitle: string;
  chunkValue: any;
  text: any;
}) => {
  return (
    <div>
      <p>
        {chunkTitle}: {` ${chunkValue}%`}
      </p>
      {(Object.keys(text) as (keyof typeof text)[]).map((prop, i) => (
        <ul key={`tooltip.prop.${i}`}>
          <li>
            {prop}:<span> {text[prop]}</span>
          </li>
        </ul>
      ))}
    </div>
  );
};

export const WorkloadBarChart = ({ title, chartData, tooltips }: WorkloadBarChartProps) => {
  const chunkColors = ['#6264A6', '#CCB38D', '#BC93E6', '#D7D96A', '#75AED9'];
  const [tooltip, setTooltip] = useState<{
    show: boolean;
    position: {};
    content: JSX.Element | null;
  }>({ show: false, position: {}, content: null });
  const showTooltip = (item: any, e: any, tooltip: any, dataKey: string) => {
    setTooltip({
      show: true,
      position: { x: e.clientX, y: e.clientY },
      content: (
        <Content chunkTitle={dataKey} chunkValue={item[dataKey]} text={tooltip[item.name]} />
      ),
    });
  };

  const hideTooltip = (e: any) => {
    setTooltip({ show: false, ...e });
  };

  const barChunks = () => {
    const barChunks: {
      key: number;
      dataKey: string;
      tooltip: any;
      color: string;
    }[] = [];
    const categories = new Set<any>();
    chartData.forEach((month) => {
      (Object.keys(month) as (keyof typeof month)[]).forEach((key) => {
        categories.add(key);
      });
    });
    categories.delete('name');
    let keyCounter = 0;
    let colorCounter = 0;
    categories.forEach((category) => {
      barChunks.push({
        key: keyCounter,
        dataKey: category,
        tooltip: tooltips[category],
        color: chunkColors[colorCounter],
      });
      keyCounter++;
      colorCounter++;
    });
    return barChunks;
  };

  return (
    <div
      className="chart-container"
      style={{
        gridTemplateRows: '40px 80%',
        gridAutoRows: 'auto',
        maxHeight: '906px',
      }}
    >
      <Text weight="semibold" size="large" content={title} />
      <ResponsiveContainer width="100%" height="100%" className={'chart-area'}>
        <BarChart
          margin={{ top: 20, left: -28, bottom: 4 }}
          data={chartData.map((data) => {
            const { tooltips, ...values } = data;
            return values;
          })}
        >
          <CartesianGrid strokeDasharray="3" vertical={false} />
          <XAxis dataKey="name" tickLine={false} />
          <YAxis
            axisLine={false}
            type="number"
            domain={[0, 100]}
            ticks={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          />
          {barChunks().map((chunk) => {
            return (
              <Bar
                key={chunk.key}
                dataKey={chunk.dataKey}
                stackId="a"
                fill={chunk.color}
                onMouseEnter={(data, _, e) => showTooltip(data, e, chunk.tooltip, chunk.dataKey)}
                onMouseLeave={hideTooltip}
                barSize={24}
              />
            );
          })}
        </BarChart>
      </ResponsiveContainer>
      {tooltip.show && <CustomTooltip onMouseLeave={hideTooltip} {...tooltip} />}
      <BarChartLegend props={barChunks()} />
    </div>
  );
};
