import { useQuery, UseQueryOptions } from 'react-query';
import { env } from '../../../utils/env';
import { ApiError, EmployeeMonthlyWorkloadDto, EmployeeService } from '../../open-api/queries';
import { EmployeeMonthlyWorkloadDtoResult } from '../../open-api/queries/models/EmployeeMonthlyWorkloadDtoResult';
import { QueryKeys } from '../../query-keys';
import type * as QueryParams from './query-params';

const getEmployeeMonthlyWorkloads = (input: QueryParams.EmployeeMonthlyWorkloads) =>
  EmployeeService.employeeMonthlyWorkloads({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

export const useEmployeeMonthlyWorkloads = (
  queryParams: QueryParams.EmployeeMonthlyWorkloads,
  queryOptions?: UseQueryOptions<
    EmployeeMonthlyWorkloadDtoResult,
    ApiError,
    Array<EmployeeMonthlyWorkloadDto>,
    [QueryKeys.EmployeeMonthlyWorkloads, QueryParams.EmployeeMonthlyWorkloads]
  >
) =>
  useQuery(
    [QueryKeys.EmployeeMonthlyWorkloads, queryParams],
    ({ queryKey: [, input] }) => getEmployeeMonthlyWorkloads(input),
    {
      select: (data) => {
        // in js month range: 0-11, in BE: 1-12
        return data.payload.map((p) => ({
          ...p,
          month: p.month - 1,
        }));
      },
      ...queryOptions,
    }
  );
