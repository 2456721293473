import type { CancelablePromise } from '../core/CancelablePromise';
import { GuidResult } from '../models/GuidResult';
import { Result } from '../models/Result';
import { Result_1 } from '../models/Result_1';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { RoleRequestsDtoCommand } from '../models/RoleRequestsDtoCommand';

export class RolesService {
  /**
   *
   * @param version
   * @param resourceRequestId
   * @param roleRequestBody
   */
  public static addRoleRequest({
    version,
    resourceRequestId,
    roleRequestBody,
  }: {
    version: string;
    resourceRequestId: string;
    roleRequestBody: RoleRequestsDtoCommand;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/resource-requests/{resourceRequestId}/role-requests',
      path: {
        version: version,
        resourceRequestId: resourceRequestId,
      },
      body: roleRequestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   *
   * @param version
   * @param resourceRequestId
   * @param roleRequestId
   * @param roleRequestBody
   */
  public static updateRoleRequest({
    version,
    resourceRequestId,
    roleRequestId,
    roleRequestBody,
  }: {
    version: string;
    resourceRequestId: string;
    roleRequestId: string;
    roleRequestBody: RoleRequestsDtoCommand;
  }): CancelablePromise<Result | Result_1> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/c/api/v{version}/resource-requests/{resourceRequestId}/role-requests/{roleRequestId}',
      path: {
        version: version,
        resourceRequestId: resourceRequestId,
        roleRequestId: roleRequestId,
      },
      body: roleRequestBody,
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   *
   * @param version
   * @param resourceRequestId
   * @param roleRequestId
   */
  public static deleteRoleRequest({
    version,
    resourceRequestId,
    roleRequestId,
  }: {
    version: string;
    resourceRequestId: string;
    roleRequestId: string;
  }): CancelablePromise<Result | Result_1> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/c/api/v{version}/resource-requests/{resourceRequestId}/role-requests/{roleRequestId}',
      path: {
        version: version,
        resourceRequestId: resourceRequestId,
        roleRequestId: roleRequestId,
      },
    });
  }
}
