import { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { SkillsListDtoResult } from '../models/SkillsListDtoResult';

export class SkillService {
  public static skills({
    searchString,
    skip,
    take,
    version,
  }: {
    searchString?: string;
    skip?: number;
    take?: number;
    version: string;
  }): CancelablePromise<SkillsListDtoResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/skills',
      path: {
        version: version,
      },
      query: {
        searchString: searchString,
        skip: skip,
        take: take,
      },
    });
  }
}
