import { Button, ChevronEndIcon, ChevronStartIcon, Flex, Text } from '@fluentui/react-northstar';
import { arrowButtonStyle } from '../../EmployeeProfile/employeeProfileStyles';
import { convertMonthYear } from '../../../utils/dateConverter';
import * as React from 'react';
import { useRecoilState } from 'recoil';
import { executivesDashboardDateNavigationAtom } from '../../../state/atoms/executivesDashboardDateNavigationAtom';
import { useState } from 'react';

export const DateNavigation = () => {
  const [currentDate, setCurrentDate] = useRecoilState<Date>(executivesDashboardDateNavigationAtom);

  const [monthCounter, setMonthCounter] = useState<number>(0);

  const forward = () => {
    setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + 1)));
    setMonthCounter(monthCounter + 1);
  };
  const backward = () => {
    setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() - 1)));
    setMonthCounter(monthCounter - 1);
  };

  return (
    <Flex>
      <Button styles={arrowButtonStyle} text onClick={backward} disabled={monthCounter === 0}>
        <ChevronStartIcon />
      </Button>
      <Text
        align="center"
        size="medium"
        content={convertMonthYear(currentDate, true)}
        style={{ marginTop: '5px', width: '144' }}
      ></Text>
      <Button styles={arrowButtonStyle} text onClick={forward} disabled={monthCounter === 12}>
        <ChevronEndIcon />
      </Button>
    </Flex>
  );
};
