import { atom } from 'recoil';

export interface PaginationAtomState {
  currentPage: number;
  pageSize: number;
}

export const employeePaginationAtom = atom<PaginationAtomState>({
  key: 'employeePaginationStateAtom',
  default: {
    currentPage: 1,
    pageSize: 14,
  },
});

export const resourcePaginationAtom = atom<PaginationAtomState>({
  key: 'resourcePaginationStateAtom',
  default: {
    currentPage: 1,
    pageSize: 12,
  },
});

export const potentialEmployeeAtom = atom<PaginationAtomState>({
  key: 'potentialEmployeeAtom',
  default: {
    currentPage: 1,
    pageSize: 6,
  },
});
