import { useQuery, UseQueryOptions } from 'react-query';
import { env } from '../../../utils/env';
import { ApiError } from '../../open-api/queries';
import { QueryKeys } from '../../query-keys';
import { FavouriteFilterService } from '../../open-api/queries/services/FavouriteFilterService';
import { GenericResult } from '../../open-api/queries/models/GenericResult';
import { FavouriteFilterDto } from '../../open-api/queries/models/FavouriteFilterDto';

const getFavouriteFilters = () =>
  FavouriteFilterService.favouriteFilters({
    version: env('REACT_APP_API_VERSION'),
  });

export const useFavouriteFilters = (
  queryOptions?: UseQueryOptions<
    GenericResult<Array<FavouriteFilterDto>>,
    ApiError,
    Array<FavouriteFilterDto>,
    [QueryKeys.FavouriteFilters]
  >
) =>
  useQuery([QueryKeys.FavouriteFilters], () => getFavouriteFilters(), {
    select: (data) => data.payload,
    ...queryOptions,
  });
