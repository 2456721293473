import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { MsalProvider, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Loader } from '@fluentui/react-northstar';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { auth } from '../../auth/auth';
import { env } from '../../utils/env';
import { OpenAPI as Commands } from '../open-api/commands/core/OpenAPI';
import { OpenAPI as Queries } from '../open-api/queries/core/OpenAPI';

export const MsalTokenProviderWrapper: FC = ({ children }) => {
  return (
    <MsalProvider instance={auth}>
      <MsalTokenProvider>{children}</MsalTokenProvider>
    </MsalProvider>
  );
};

export const MsalTokenProvider: FC = ({ children }) => {
  const { t } = useTranslation();
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [token, setToken] = useState<string>();

  useEffect(() => {
    instance
      .handleRedirectPromise()
      .then((response) => {
        if (response !== null) {
          instance.setActiveAccount(response.account);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [instance]);

  useEffect(() => {
    const tryMsalAuthentication = async () => {
      if (!isAuthenticated && inProgress === InteractionStatus.None) {
        try {
          await instance.loginRedirect();
        } catch (error) {
          console.error(error);
          if (error instanceof InteractionRequiredAuthError) {
            console.error(error);
          }
        }
      }

      if (isAuthenticated && inProgress === InteractionStatus.None) {
        const clientId = env('REACT_APP_AZURE_CLIENT_ID');
        const activeAccount = auth.getActiveAccount();
        const requestSettings = {
          scopes: [`api://dev.planning.ti8m.ch/${clientId}/access_as_user`],
          account: activeAccount || auth.getAllAccounts()[0],
        };

        try {
          const { accessToken } = await instance.acquireTokenSilent(requestSettings);
          Queries.TOKEN = accessToken;
          Commands.TOKEN = accessToken;
          setToken(accessToken);
        } catch (error) {
          if (error instanceof InteractionRequiredAuthError) {
            await instance.acquireTokenRedirect(requestSettings);
          } else {
            console.error(error);
          }
        }
      }
    };

    tryMsalAuthentication();
  }, [isAuthenticated, inProgress, instance]);

  return token ? (
    <>{children}</>
  ) : (
    <Loader style={{ height: '100%' }} label={t('auth-in-progress')} />
  );
};
