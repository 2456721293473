import { useMutation, useQueryClient } from 'react-query';
import { env } from '../../../utils/env';
import { FavouriteFilterService } from '../../open-api/commands/services/FavouriteFilterService';
import * as CommandParams from './command-params';
import { ApiError } from '../../open-api/queries';
import { Result } from '../../open-api/commands/models/Result';
import { QueryKeys } from '../../query-keys';
import { GenericResult } from '../../open-api/queries/models/GenericResult';
import { waitForEvent } from './wait-for-event';

const addFavouriteFilter = (input: CommandParams.AddFavouriteFilterInput) =>
  FavouriteFilterService.addFavouriteFilter({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const updateFavouriteFilter = (input: CommandParams.UpdateFavouriteFilterInput) =>
  FavouriteFilterService.updateFavouriteFilter({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const changeDefaultFilter = (input: CommandParams.ChangeDefaultFilterInput) =>
  FavouriteFilterService.changeDefaultFilter({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const deleteFavouriteFilter = (input: CommandParams.DeleteFavouriteFilterInput) =>
  FavouriteFilterService.deleteFavouriteFilter({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const getEventPosition = (id: string) =>
  FavouriteFilterService.getEventPosition({
    version: env('REACT_APP_API_VERSION'),
    id,
  });

type GuidResultWithId = Result & {
  id: string;
};

export const useAddFavouriteFilter = () => {
  const queryClient = useQueryClient();

  return useMutation<GenericResult<string>, ApiError, CommandParams.AddFavouriteFilterInput>(
    async (input) => {
      const result = await addFavouriteFilter(input);

      return { ...result };
    },
    {
      onSuccess: async ({ payload: id, storePosition }) =>
        waitForEvent({ id, storePosition }, getEventPosition).then(() =>
          queryClient.invalidateQueries([QueryKeys.FavouriteFilters])
        ),
      meta: {
        type: '',
        requestObject: '',
      },
    }
  );
};

export const useUpdateFavouriteFilter = (suppressAlert = false) => {
  const queryClient = useQueryClient();

  return useMutation<GenericResult<string>, ApiError, CommandParams.UpdateFavouriteFilterInput>(
    async (input) => {
      const result = await updateFavouriteFilter(input);

      return { ...result };
    },
    {
      onSuccess: async ({ payload: id, storePosition }) =>
        waitForEvent({ id, storePosition }, getEventPosition).then(() =>
          queryClient.invalidateQueries([QueryKeys.FavouriteFilters])
        ),
      meta: {
        type: '',
        suppressAlert: suppressAlert,
        requestObject: '',
      },
    }
  );
};

export const useChangeDefaultFilter = (suppressAlert = false) => {
  const queryClient = useQueryClient();

  return useMutation<GenericResult<string>, ApiError, CommandParams.ChangeDefaultFilterInput>(
    async (input) => {
      const result = await changeDefaultFilter(input);

      return { ...result };
    },
    {
      onSuccess: async ({ storePosition }, variables) => {
        const id = variables.newFilterId || variables.currentFilterId;
        if (!id) return;
        waitForEvent({ id, storePosition }, getEventPosition).then(() =>
          queryClient.invalidateQueries([QueryKeys.FavouriteFilters])
        );
      },
      meta: {
        type: '',
        suppressAlert: suppressAlert,
        requestObject: '',
      },
    }
  );
};

export const useDeleteFavouriteFilter = () => {
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, CommandParams.DeleteFavouriteFilterInput>(
    async (input) => {
      const result = await deleteFavouriteFilter(input);

      return { ...result, id: input.id };
    },
    {
      onSuccess: async ({ id }) =>
        waitForEvent({ id }, getEventPosition, true).then(() =>
          queryClient.invalidateQueries([QueryKeys.FavouriteFilters])
        ),
    }
  );
};
