import { useQuery, UseQueryOptions } from 'react-query';
import { env } from '../../../utils/env';
import { QueryKeys } from '../../query-keys';
import type * as QueryParams from './query-params';
import { ResourceRequestService, ApiError } from '../../open-api/queries';
import { ResourceRequestOverviewDtoResult } from '../../open-api/queries/models/ResourceRequestOverviewDtoResult';
import { ResourceRequestOverviewPayload } from '../../open-api/queries/models/ResourceRequestOverviewPayload';

const getResourceRequests = (input: QueryParams.ResourceRequests) =>
  ResourceRequestService.resourceRequests({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

export const useResourceRequestsOverview = (
  queryParams: QueryParams.ResourceRequests,
  queryOptions?: UseQueryOptions<
    ResourceRequestOverviewDtoResult,
    ApiError,
    ResourceRequestOverviewPayload,
    [QueryKeys.ResourceRequestOverview, QueryParams.ResourceRequests]
  >
) =>
  useQuery(
    [QueryKeys.ResourceRequestOverview, queryParams],
    ({ queryKey: [, input] }) => getResourceRequests(input),
    {
      select: (data) => data.payload,
      ...queryOptions,
    }
  );
