import { useQuery, UseQueryOptions } from 'react-query';
import { env } from '../../../utils/env';
import { ApiError, EmployeeProjectAssignmentDto, EmployeeService } from '../../open-api/queries';
import { EmployeeProjectAssignmentDtoResult } from '../../open-api/queries/models/EmployeeProjectAssignmentDtoResult';
import { QueryKeys } from '../../query-keys';
import type * as QueryParams from './query-params';

const getEmployeeProjectAssignmentGroups = (input: QueryParams.EmployeeProjects) =>
  EmployeeService.employeeProjectAssignments({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

export const useEmployeeProjectAssignments = (
  queryParams: QueryParams.EmployeeProjects,
  queryOptions?: UseQueryOptions<
    EmployeeProjectAssignmentDtoResult,
    ApiError,
    Array<EmployeeProjectAssignmentDto>,
    [QueryKeys.EmployeeProjects, QueryParams.EmployeeProjects]
  >
) =>
  useQuery(
    [QueryKeys.EmployeeProjects, queryParams],
    ({ queryKey: [, input] }) => getEmployeeProjectAssignmentGroups(input),
    {
      select: (data) => data.payload,
      ...queryOptions,
    }
  );
