import { ComponentSlotStyle } from '@fluentui/react-northstar';

export const projectDetailsStyle: ComponentSlotStyle = (data) => ({
  '> .ui-table__row': {
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    borderBottomWidth: 1,
    borderColor: data.theme.siteVariables.dividerColor,
    backgroundColor: 'transparent',
  },
  '> .ui-table__row:hover': {
    backgroundColor: 'transparent',
    borderColor: data.theme.siteVariables.dividerColor,
  },
});

export const requiredRolesTableStyle: ComponentSlotStyle = () => ({
  '> .ui-table__row': {
    backgroundColor: 'transparent',
  },
});

export const roleStyle: ComponentSlotStyle = () => ({
  maxWidth: '30%',
  paddingLeft: 0,
});

export const skillsStyle: ComponentSlotStyle = () => ({
  maxWidth: '36%',
});

export const durationStyle: ComponentSlotStyle = () => ({
  maxWidth: '24%',
});

export const capacityStyle: ComponentSlotStyle = () => ({
  maxWidth: '10%',
});
