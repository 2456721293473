import { useEmployeeMonthlyWorkloads } from '../../../data-access/hooks/queries/use-employee-monthly-workloads';

export interface EmployeeProjectsFilter {
  id?: string;
  startDate: Date;
  endDate: Date;
}

export interface UseEmployeeMonthlyWorkloadsDataHookParams {
  filteringOptions?: EmployeeProjectsFilter;
}

export type EmployeeMonthlyWorkloadsData = ReturnType<typeof useEmployeeMonthlyWorkloads>['data'];

export const useEmployeeMonthlyWorkloadsData = ({
  filteringOptions,
}: UseEmployeeMonthlyWorkloadsDataHookParams): EmployeeMonthlyWorkloadsData | undefined => {
  const result = useEmployeeMonthlyWorkloads({
    id: filteringOptions?.id,
    startDate: filteringOptions?.startDate,
    endDate: filteringOptions?.endDate,
  });
  return result.data;
};
