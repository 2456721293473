import { useQuery, UseQueryOptions } from 'react-query';
import { env } from '../../../utils/env';
import {
  ApiError,
  EmployeeOverviewDtoPaginatedListDto,
  EmployeeOverviewDtoPaginatedListDtoResult,
  EmployeeService,
} from '../../open-api/queries';
import { QueryKeys } from '../../query-keys';
import type * as QueryParams from './query-params';

const getEmployees = (input: QueryParams.Employees) =>
  EmployeeService.employees({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

export const useEmployees = (
  queryParams: QueryParams.Employees,
  queryOptions?: UseQueryOptions<
    EmployeeOverviewDtoPaginatedListDtoResult,
    ApiError,
    EmployeeOverviewDtoPaginatedListDto,
    [QueryKeys.Employees, QueryParams.Employees]
  >
) =>
  useQuery([QueryKeys.Employees, queryParams], ({ queryKey: [, input] }) => getEmployees(input), {
    select: (data) => data.payload,
    ...queryOptions,
  });
