import { useCallback, useState } from 'react';
import { FunctionComponent } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { OrganizationDto } from '../../../data-access/open-api/queries/models/OrganizationDto';
import { activeFilterSelector, allFiltersSelector } from '../../../state/atoms/searchAtom';
import DialogForm, { Inputs } from './DialogForm';
import {
  useAddFavouriteFilter,
  useChangeDefaultFilter,
  useDeleteFavouriteFilter,
  useUpdateFavouriteFilter,
} from '../../../data-access/hooks/commands/use-add-favourite-filter';
import { FavouriteFilterDto } from '../../../data-access/open-api/queries/models/FavouriteFilterDto';
import { LocalFilter, isFavoriteFilter, isLocalFilter } from '../LocalFilter';
import { useRecoilValue } from 'recoil';
import { Ti8mDialog } from '../../Common/Ti8mDialog';

interface FilterDialogProps {
  isDialogOpen: boolean;
  isInEditMode: boolean;
  closeDialog: () => void;
  handleApplySearch: (filter?: LocalFilter) => void;
  removeLocalFilter: (filter: LocalFilter) => void;
  setActiveFilterId: (filterId: string | number | undefined) => void;
  organizations: Array<OrganizationDto>;
}

const FilterDialog: FunctionComponent<FilterDialogProps> = ({
  isDialogOpen,
  isInEditMode,
  handleApplySearch,
  closeDialog,
  removeLocalFilter,
  setActiveFilterId,
  organizations,
}) => {
  const { t } = useTranslation();
  const { mutate: addFavouriteFilter } = useAddFavouriteFilter();
  const { mutate: updateFavouriteFilter } = useUpdateFavouriteFilter();
  const { mutate: deleteFavouriteFilter } = useDeleteFavouriteFilter();
  const { mutateAsync: changeDefaultFilter } = useChangeDefaultFilter(true);

  const filters = useRecoilValue(allFiltersSelector);
  const activeFilter = useRecoilValue(activeFilterSelector);
  const editFilter = isInEditMode ? activeFilter : undefined;

  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);

  const confirmDeleteDialogOpenToggle = useCallback(() => {
    setConfirmDeleteDialogOpen((isOpen) => !isOpen);
  }, []);

  const handleDeleteFilter = useCallback(() => {
    if (isFavoriteFilter(editFilter)) {
      deleteFavouriteFilter({ id: editFilter!.id });
      setActiveFilterId(undefined);
    } else {
      throw new Error("Deleting is only possible for stored ('Favourite') filters");
    }
    confirmDeleteDialogOpenToggle();
    closeDialog();
  }, [
    deleteFavouriteFilter,
    confirmDeleteDialogOpenToggle,
    closeDialog,
    setActiveFilterId,
    editFilter,
  ]);

  const applyFilter = useCallback(
    (data: Inputs) => {
      const localFilter = {
        ...data,
        id: new Date().valueOf(), // dummy ID, so we can differentiate the local filters (without reference equality)
        isLocal: true,
      };

      handleApplySearch(localFilter);
      closeDialog();
    },
    [handleApplySearch, closeDialog]
  );

  const saveFilter = useCallback(
    (data: Inputs) => {
      // check, if we already have a default filter; if not: simply set the new filter as the default
      // (otherwise call changeDefaultFilter when storing a new FavouriteFilter)
      const previousDefaultFilter = filters?.find((f) => isFavoriteFilter(f) && f.default);
      const requestBody = {
        ...data,
        default: !previousDefaultFilter,
      };

      if (editFilter && isFavoriteFilter(editFilter)) {
        requestBody.default = editFilter?.default;
        updateFavouriteFilter(
          { id: editFilter.id, requestBody },
          {
            onSuccess: () => {
              setActiveFilterId(editFilter.id);
              closeDialog();
            },
          }
        );
      } else if (editFilter && isLocalFilter(editFilter)) {
        setActiveFilterId(undefined);
        addFavouriteFilter(
          { requestBody },
          {
            onSuccess: ({ payload: id }) => {
              if (previousDefaultFilter) {
                changeDefaultFilter({
                  newFilterId: id,
                  currentFilterId: previousDefaultFilter.id as string,
                });
              }
              removeLocalFilter(editFilter);
              setActiveFilterId(id);

              closeDialog();
            },
          }
        );
      } else {
        setActiveFilterId(undefined);
        // In that case we already have a default call 'changeDefaultFilter' to switch to the new one
        addFavouriteFilter(
          { requestBody },
          {
            onSuccess: ({ payload: id }) => {
              if (previousDefaultFilter) {
                changeDefaultFilter({
                  newFilterId: id,
                  currentFilterId: previousDefaultFilter.id as string,
                });
              }
              setActiveFilterId(id);
              closeDialog();
            },
          }
        );
      }
    },
    [
      editFilter,
      addFavouriteFilter,
      updateFavouriteFilter,
      closeDialog,
      removeLocalFilter,
      setActiveFilterId,
      changeDefaultFilter,
      filters,
    ]
  );

  const handleRemoveButtonClick = useCallback(() => {
    if (editFilter && isLocalFilter(editFilter)) {
      removeLocalFilter(editFilter);
      setActiveFilterId(undefined);
      closeDialog();
    }
  }, [removeLocalFilter, setActiveFilterId, editFilter, closeDialog]);

  return (
    <>
      <Ti8mDialog
        header={title(editFilter, t)}
        open={isDialogOpen}
        onCancel={closeDialog}
        content={
          <div>
            <DialogForm
              editFilter={editFilter}
              filters={filters}
              organizations={organizations}
              confirmDeleteDialogOpenToggle={confirmDeleteDialogOpenToggle}
              handleRemoveButtonClick={handleRemoveButtonClick}
              closeDialog={closeDialog}
              applyFilter={applyFilter}
              saveFilter={saveFilter}
            />
          </div>
        }
      />
      <Ti8mDialog
        open={confirmDeleteDialogOpen}
        showCloseHeaderAction={false}
        header={t('search.dialog.delete-dialog.title')}
        onCancel={confirmDeleteDialogOpenToggle}
        cancelButton={{
          content: t('search.dialog.delete-dialog.cancel-button'),
        }}
        confirmButton={{
          onClick: handleDeleteFilter,
          content: t('search.dialog.delete-dialog.confirm-button'),
        }}
        content={t('search.dialog.delete-dialog.text')}
      />
    </>
  );
};

function title(
  editFilter: FavouriteFilterDto | LocalFilter | undefined,
  t: TFunction<'resource-management', undefined>
) {
  let title = editFilter ? t('search.dialog.title.edit') : t('search.dialog.title.add');
  if (isLocalFilter(editFilter)) {
    title = t('search.dialog.title.edit-unsaved');
  }
  return title;
}

export default FilterDialog;
