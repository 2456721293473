import { ChartsService } from '../../open-api/queries/services/ChartsService';
import { env } from '../../../utils/env';
import { useQuery, UseQueryOptions } from 'react-query';
import { QueryKeys } from '../../query-keys';
import { ApiError } from '../../open-api/queries';
import type * as QueryParams from './query-params';
import { WorkloadRelativeFrequenciesPerMonthDtoIEnumerableResult } from '../../open-api/queries/models/WorkloadRelativeFrequenciesPerMonthDtoIEnumerableResult';
import { WorkloadRelativeFrequenciesPerMonthDto } from '../../open-api/queries/models/WorkloadRelativeFrequenciesPerMonthDto';

const getBillabilityPerMonthByOrganisation = (
  input: QueryParams.BillabilityPerMonthByOrganisation
) =>
  ChartsService.billabilityPerMonthByOrganization({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

export const useBillabilityPerMonthByOrganisation = (
  queryParams: QueryParams.BillabilityPerMonthByOrganisation,
  queryOptions?: UseQueryOptions<
    WorkloadRelativeFrequenciesPerMonthDtoIEnumerableResult,
    ApiError,
    WorkloadRelativeFrequenciesPerMonthDto[],
    [QueryKeys.BillabilityPerMonthByOrganisation, QueryParams.BillabilityPerMonthByOrganisation]
  >
) =>
  useQuery(
    [QueryKeys.BillabilityPerMonthByOrganisation, queryParams],
    ({ queryKey: [, input] }) => getBillabilityPerMonthByOrganisation(input),
    {
      enabled: queryParams.organizationNumber !== undefined,
      select: (data) => data.payload,
      ...queryOptions,
    }
  );
