import { ComponentSlotStyle } from '@fluentui/react-northstar';

export const labelContainerStyle: ComponentSlotStyle = () => ({
  marginRight: '1rem',
  lineHeight: '1.5rem',
});

export const ellipsis: ComponentSlotStyle = (data) => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: '-webkit-box', // Note: Against all odds, this works in all modern browsers, even Firefox!
  '-webkit-line-clamp': 3,
  '-webkit-box-orient': 'vertical',
  whiteSpace: 'normal',
  fontSize: data.theme.siteVariables.fontSizes['small'],
});
