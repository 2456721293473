import { Button, ChevronStartIcon, Flex, Text } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export const NavigationButton = ({ goBack = false }: { goBack?: boolean }) => {
  const { t } = useTranslation();
  const navigate = useHistory();
  const navigateToOverview = () => {
    if (goBack) {
      navigate.goBack();
    } else {
      navigate.push({ pathname: '/resourceRequests' });
    }
  };

  return (
    <Flex>
      <Button text primary onClick={navigateToOverview} styles={{ paddingLeft: '-16px' }}>
        <Flex vAlign="center" gap='gap.small'>
          <ChevronStartIcon size="larger" />
          <Text size="medium">
            {goBack
              ? t('resources-detail-view.go-back-link-text')
              : t('resources-detail-view.link-back-to-details-pane')}
          </Text>
        </Flex>
      </Button>
    </Flex>
  );
};
