import { Flex, FlexItem, List, Pill, Text, Tooltip } from '@fluentui/react-northstar';
import { take, takeLast } from 'ramda';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { ellipsis, labelContainerStyle } from './coreCompetencesGridStyles';
import { overflowPillStyle } from '../../ResourceRequestDetailView/resourceRequestDetailStyles';
import * as React from 'react';
import { Ti8mPill } from '../../Common/Ti8mPill';

interface CoreCompetencesGridProps {
  coreCompetences: string[];
  notes?: string;
}

export const CoreCompetencesGrid = ({ coreCompetences, notes }: CoreCompetencesGridProps) => {
  const { t } = useTranslation();

  return (
    <Flex style={{ minHeight: '6rem' }}>
      <FlexItem size="size.half">
        <Flex column gap="gap.smaller">
          <Text weight="semibold" size="large">
            {t('employee-overview-table-detail.core-competences')}
          </Text>
          <Text as="div" styles={labelContainerStyle}>
            {coreCompetences !== null &&
              take(5, coreCompetences).map((item) => (
                <Fragment key={`corecompetence_${item}`}>
                  <Ti8mPill
                    key={`corecompetence_${item}`}
                    size="small"
                    content={item}
                    maxTextLength={18}
                    showTooltip={true}
                  />
                  &nbsp;
                </Fragment>
              ))}
            {coreCompetences !== null && coreCompetences.length > 5 && (
              <Tooltip
                trigger={
                  <Pill
                    rectangular
                    size="small"
                    styles={overflowPillStyle}
                    color="brand"
                    content={`+${coreCompetences.length - 5}`}
                  />
                }
                unstable_pinned
                pointing
                content={
                  <List
                    items={takeLast(coreCompetences.length - 5, coreCompetences).map(
                      (c, index) => ({
                        key: index,
                        content: c,
                        styles: { minHeight: '1rem' },
                      })
                    )}
                  />
                }
              />
            )}
          </Text>
        </Flex>
      </FlexItem>
      <FlexItem size="size.half">
        <Flex column gap="gap.smaller">
          <Text weight="semibold" size="large" as="div">
            {t('employee-overview-table-detail.notes')}
          </Text>
          <Text styles={ellipsis} as="div">
            {notes}
          </Text>
        </Flex>
      </FlexItem>
    </Flex>
  );
};
