import { EmployeeProfileViewModel } from "../viewModels/EmployeeProfileViewModel";

/**
 * Function validates the project.workloads and updates the hasErrorStart & hasErrorEnd properties
 * on the individual workloads.
 * 
 * @returns True, if the project.workloads are valid (min 1 entry, no validation errors). Otherwise false.
 */
export const updateWorkloadValidation = (project: EmployeeProfileViewModel):boolean => {
    if (!project?.workloads) {
        return false;
    }

    const workloadsOrdered = project.workloads
        .filter((e) => e.end !== '' || e.start !== '' || e.percentage !== 0)
        .sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime());

    workloadsOrdered.forEach((e) => {
        e.hasErrorEnd = false;
        e.hasErrorStart = false;
    });

    let areWorkloadsValid = workloadsOrdered.length > 0;

    for (let i = 0; i < workloadsOrdered.length - 1; i++) {
        const e = workloadsOrdered[i];
        if (e.end === '' || e.start === '' || e.percentage === 0) {
            areWorkloadsValid = false;
        }

        const currentStart = new Date(e.start);
        const currentEnd = new Date(e.end);
        const nextStart = new Date(workloadsOrdered[i + 1].start);
        const nextEnd = new Date(workloadsOrdered[i + 1].end);
        if (currentStart <= nextStart && currentEnd >= nextEnd) {
            areWorkloadsValid = false;
            e.hasErrorStart = true;
            e.hasErrorEnd = true;
            workloadsOrdered[i + 1].hasErrorStart = true;
            workloadsOrdered[i + 1].hasErrorEnd = true;
        } else if (currentEnd >= nextStart) {
            areWorkloadsValid = false;
            e.hasErrorEnd = true;
            workloadsOrdered[i + 1].hasErrorStart = true;
        }
    }

    return areWorkloadsValid;
}
