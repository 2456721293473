import { QueryFunctionContext, useInfiniteQuery } from 'react-query';
import { QueryKeys } from '../../query-keys';
import { withApiVersion, DEFAULT_TAKE, getNextPageParam, unpack } from '../../helpers';
import { ProjectService } from '../../open-api/queries/services/ProjectService';

const getProjects = (context: QueryFunctionContext) => {
  const withVersion = withApiVersion(ProjectService.projects);
  return withVersion({
    searchString: `${context.queryKey[1]}`,
    skip: context.pageParam,
    take: DEFAULT_TAKE,
  });
};

export const useInfiniteProjects = (searchString?: string) => {
  const { fetchNextPage, hasNextPage, data, isError } = useInfiniteQuery({
    queryKey: [QueryKeys.Projects, searchString],
    queryFn: getProjects,
    getNextPageParam: getNextPageParam,
  });

  return {
    projects: unpack(data),
    hasMoreProjects: hasNextPage,
    fetchMoreProjects: fetchNextPage,
    isProjectsError: isError,
  };
};
