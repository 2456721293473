import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { Provider } from '@fluentui/react-northstar';
import { I18nextProvider } from 'react-i18next';
import { useHistory } from 'react-router';
import { Redirect, Route, Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { EmployeeProfilePage } from '../../components/EmployeeProfile/EmployeeProfile';
import { ApiProvider, TokenProvider } from '../../data-access/providers';
import { DashboardExecutives } from '../../pages/DashboardExecutives';
import { HelpPage } from '../../pages/Help';
import { ResourceRequestDetailView } from '../../pages/ResourceRequestDetailView';
import { ResourceRequestPage } from '../../pages/ResourceRequests';
import { IndexPage } from '../../pages/index/index';
import { i18nInit } from '../locales';
import { usePlatformTheme } from '../theme';
import { generateTheme } from '../theme/generateTheme';
import { AppBrowserHeader, AppBrowserHeaderLink } from './Header';
import { Main } from './Main';

initializeIcons();

export function App() {
  const colorScheme = usePlatformTheme();

  const routes: AppBrowserHeaderLink[] = [
    { name: 'Employee workload', path: '/employeeWorkload' },
    { name: 'Resource Requests', path: '/resourceRequests' },
    { name: 'Dashboard', path: '/dashboardExecutives' },
    { name: 'Help', path: '/help' },
  ];

  return (
    <Provider theme={generateTheme(colorScheme)} lang="en-US">
      <I18nextProvider i18n={i18nInit}>
        <TokenProvider>
          <ApiProvider>
            <RecoilRoot>
              <Main>
                <AppBrowserHeader links={routes} />
                <Router history={useHistory()}>
                  <Route exact path="/">
                    <Redirect to="/employeeWorkload" />
                  </Route>
                  <Route exact path="/employeeWorkload/:employeeId?" component={IndexPage} />
                  <Route exact path="/resourceRequests" component={ResourceRequestPage} />
                  <Route
                    path="/resourceRequestDetailView/:resourceRequestId?"
                    component={ResourceRequestDetailView}
                  />
                  <Route path="/employeeDetails/:employeeId" component={EmployeeProfilePage} />
                  <Route exact path="/dashboardExecutives" component={DashboardExecutives} />
                  <Route exact path="/help" component={HelpPage} />
                </Router>
              </Main>
            </RecoilRoot>
          </ApiProvider>
        </TokenProvider>
      </I18nextProvider>
    </Provider>
  );
}
