import React, { useCallback, useState } from 'react';
import { Button, Flex, Text, Divider, EditIcon, TrashCanIcon } from '@fluentui/react-northstar';
import { CommentDto } from '../../../data-access/open-api/queries/models/CommentDto';
import { convertToMonthDayYear } from '../../../utils/dateConverter';
import { EmployeeAvatar } from '../../EmployeeTable/EmployeeAvatar';
import { CommentDialog } from './CommentDialog';
import {
  useDeleteComment,
  useEditComment,
} from '../../../data-access/hooks/commands/use-update-comments';

interface CommentProps {
  comment: CommentDto;
  resourceRequestId: string;
}

export const Comment = ({ comment, resourceRequestId }: CommentProps) => {
  const [isCommentsDialogVisible, setCommentsDialogVisibility] = useState<boolean>(false);
  const [commentData, setCommentData] = useState<string>('');
  const [commentId, setCommentId] = useState<string>('');
  const [isEmpty, setIsEmpty] = useState<boolean>(false);

  const { mutate: editComment } = useEditComment();
  const { mutate: deleteComment } = useDeleteComment();

  const onEditComment = (comment: CommentDto) => {
    setCommentData(comment.content);
    setCommentId(comment.id);
    setCommentsDialogVisibility(true);
  };

  const onChangeCommentData = useCallback(
    (comment: string) => {
      setCommentData(comment);
      if (comment !== '') {
        setIsEmpty(false);
      } else {
        setIsEmpty(true);
      }
    },
    [setCommentData]
  );

  const onConfirmEditComment = () => {
    editComment({
      resourceRequestId: resourceRequestId,
      commentId: commentId,
      commentBody: { id: commentId, content: commentData },
    });
    setCommentsDialogVisibility(false);
  };

  const onDeleteComment = useCallback(
    (commentId: string) => {
      deleteComment({
        resourceRequestId: resourceRequestId,
        commentId: commentId,
      });
      setCommentId('');
      setCommentData('');
    },
    [setCommentId, setCommentData, deleteComment, resourceRequestId]
  );

  const onCancel = useCallback(() => {
    setCommentData('');
    setCommentsDialogVisibility(false);
    setIsEmpty(true);
  }, [setCommentData, setCommentsDialogVisibility, setIsEmpty]);

  return (
    <Flex column>
      <Flex space="between">
        <Flex gap="gap.small">
          <EmployeeAvatar
            firstName={comment.author.split(' ')[0]}
            lastName={comment.author.split(' ')[1]}
            size="small"
            photo={comment.authorThumbnail}
          />
          <Text content={comment.author} weight="semibold" size="medium" />
          <Text
            content={
              comment.modifiedAt
                ? convertToMonthDayYear(comment.modifiedAt)
                : convertToMonthDayYear(comment.createdAt)
            }
          />
        </Flex>
        <Flex styles={{ marginTop: 0 }}>
          <Button iconOnly text icon={<EditIcon />} onClick={() => onEditComment(comment)} />
          <Button
            iconOnly
            text
            icon={<TrashCanIcon />}
            onClick={() => onDeleteComment(comment.id)}
          />
        </Flex>
      </Flex>
      <Text content={comment.content} styles={{ marginLeft: '2.5rem', marginRight: '2.5rem' }} />
      <Divider size={1} styles={{ marginTop: '1rem' }} />
      <CommentDialog
        isEdit={true}
        isEmpty={isEmpty}
        commentData={commentData}
        onChangeCommentData={onChangeCommentData}
        onConfirm={onConfirmEditComment}
        isCommentsDialogVisible={isCommentsDialogVisible}
        onCancel={onCancel}
      />
    </Flex>
  );
};
