import { ResourceRequestService } from '../../open-api/queries';
import { env } from '../../../utils/env';

const getAttachmentUrl = (resourceRequestId: string, resourceRequestAttachmentId: string) =>
  ResourceRequestService.attachmentUrlByAttachmentId({
    resourceRequestId,
    resourceRequestAttachmentId,
    version: env('REACT_APP_API_VERSION'),
  });

export const retrieveAttachmentUrl = (
  resourceRequestId: string,
  resourceRequestAttachmentId: string
) => {
  return getAttachmentUrl(resourceRequestId, resourceRequestAttachmentId);
};
