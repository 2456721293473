import { ComponentSlotStyle, ComponentSlotStyleFunction } from '@fluentui/react-northstar';
import { statusDarkColorVariable } from '../../Common/statusStyles';

export const accordionRootStyles: ComponentSlotStyle = () => ({
  width: '100%',
  boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)',
  borderRadius: '8px',
});

const accordionCommonStyles: ComponentSlotStyleFunction = (data) => ({
  border: `1px solid ${statusDarkColorVariable(data.theme.siteVariables).green}`,
  backgroundColor: data.theme.siteVariables.colors['green'][50],
  padding: '1rem',
  width: '100%',
});

export const accordionTitleClosedStyles: ComponentSlotStyleFunction = (data) => ({
  ...accordionCommonStyles(data),
  borderRadius: '8px',
  cursor: 'pointer',
});

export const accordionTitleOpenStyles: ComponentSlotStyleFunction = (data) => ({
  ...accordionCommonStyles(data),
  borderRadius: '8px 8px 0 0',
  borderBottom: 0,
  cursor: 'pointer',
});

export const accordionContentStyles: ComponentSlotStyleFunction = (data) => ({
  ...accordionCommonStyles(data),
  borderRadius: '0 0 8px 8px',
  borderTop: 0,
  paddingTop: 0,
});

export const importantInfoTitleTextStyle: ComponentSlotStyle = (data) => ({
  color: statusDarkColorVariable(data.theme.siteVariables).green,
  fontWeight: data.theme.siteVariables['fontWeightSemibold'],
});
