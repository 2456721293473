import { FormTextArea, Text } from '@fluentui/react-northstar';
import { Ti8mDialog } from '../../Common/Ti8mDialog';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface CommentDialogProps {
  isEdit: boolean;
  isEmpty: boolean;
  commentData: string;
  onChangeCommentData: (content: string) => void;
  onConfirm: () => void;
  isCommentsDialogVisible: boolean;
  onCancel: () => void;
}

export const CommentDialog = ({
  isEdit,
  isEmpty,
  commentData,
  onChangeCommentData,
  onConfirm,
  isCommentsDialogVisible,
  onCancel,
}: CommentDialogProps) => {
  const { t } = useTranslation();
  const maxCharLength = 1000;
  const [descriptionLength, setDescriptionLength] = useState<number>(0);
  const onFormValueChanged = useCallback(
    (value: string) => {
      onChangeCommentData(value);
    },
    [onChangeCommentData]
  );

  return (
    <Ti8mDialog
      header={
        isEdit
          ? t('resources-detail-view.add-comment-dialog.header-edit')
          : t('resources-detail-view.add-comment-dialog.header-add')
      }
      content={
        <>
          <FormTextArea
            label={t('resources-detail-view.add-comment-dialog.label')}
            maxLength={maxCharLength}
            rows={8}
            fluid
            onChange={(_event, data) => {
              setDescriptionLength(data?.value ? data.value.length : 0);
              return onFormValueChanged(data?.value ?? '');
            }}
            defaultValue={isEdit ? commentData : ''}
          />
          <Text content={descriptionLength + '/' + maxCharLength} size="small" align="end" />
        </>
      }
      cancelButton={t('resources-detail-view.add-comment-dialog.cancel-button')}
      onCancel={onCancel}
      confirmButton={{
        content: t('resources-detail-view.add-comment-dialog.confirm-button'),
        disabled: isEmpty,
      }}
      onConfirm={onConfirm}
      open={isCommentsDialogVisible}
    />
  );
};
