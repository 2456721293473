import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeProjectAssignmentDto } from '../../../data-access/open-api/queries';
import { EmployeeProfileAddProjectDialog } from '../AddProjectDialog/EmployeeProfileAddProjectDialog';
import { EmployeeProfileViewModel } from '../viewModels/EmployeeProfileViewModel';
import { convertToMonthDayYearSlash } from '../../../utils/dateConverter';
import { ProbabilityOfExtensionType } from '../../../enum/ProbabilityOfExtensionType';
import { Ti8mDialog } from '../../Common/Ti8mDialog';
import { updateWorkloadValidation } from '../AddProjectDialog/update-workload-validation';

interface EditEmployeeProjectDialogProps {
  isDialogOpen: boolean;
  employeeProject: EmployeeProjectAssignmentDto;
  handleCloseDialog: () => void;
  handleConfirmDialog: (project: EmployeeProjectAssignmentDto) => void;
}

export const EditEmployeeProjectDialog = ({
  isDialogOpen,
  employeeProject,
  handleCloseDialog,
  handleConfirmDialog,
}: EditEmployeeProjectDialogProps) => {
  const { t } = useTranslation();
  const [isTouched, setTouched] = useState<boolean>(false);
  const [project, setProject] = useState<EmployeeProfileViewModel>({
    ...employeeProject,
    projectId: employeeProject.projectId ?? '',
    workloads: [
      ...employeeProject.workloads.map((v) => {
        return { ...v, hasErrorStart: false, hasErrorEnd: false };
      }),
    ],
  });

  const updateConfirmButtonEnabled = useCallback(
    (project: EmployeeProfileViewModel) => {
      const isValid = updateWorkloadValidation(project);
      setTouched(isValid);
    },
    [setTouched]
  );

  const updateProject = useCallback(
    (data: any, property: 'workloads' | 'projectId' | 'probabilityOfExtension') => {
      setProject((prev: EmployeeProfileViewModel) => ({
        ...prev,
        [property]: data,
      }));

      setTouched(true);
    },
    [setProject, setTouched]
  );

  const setStartDate = useCallback(
    (data: Date | null, i: number) => {
      const workloads = project.workloads;
      if (workloads) {
        workloads[i].start = data ? convertToMonthDayYearSlash(data) : '';
      }

      updateProject(workloads, 'workloads');
      updateConfirmButtonEnabled({
        ...project,
        workloads: workloads,
      });
    },
    [project, updateProject, updateConfirmButtonEnabled]
  );

  const setEndDate = useCallback(
    (data: Date | null, i: number) => {
      const workloads = project.workloads;
      if (workloads) {
        workloads[i].end = data ? convertToMonthDayYearSlash(data) : '';
      }

      updateProject(workloads, 'workloads');
      updateConfirmButtonEnabled({
        ...project,
        workloads: workloads,
      });
    },
    [project, updateProject, updateConfirmButtonEnabled]
  );

  const setPensum = useCallback(
    (data: string, i: number) => {
      const workloads = project.workloads;
      if (workloads) {
        workloads[i].percentage = parseFloat(data);
      }
      updateProject(workloads, 'workloads');
    },
    [project, updateProject]
  );

  const setProbabilityOfExtension = useCallback(
    (data?: ProbabilityOfExtensionType) => updateProject(data, 'probabilityOfExtension'),
    [updateProject]
  );

  const addWorkload = useCallback(() => {
    let workloads = project.workloads;
    if (workloads) {
      workloads.push({
        percentage: 0,
        start: '',
        end: '',
        hasErrorStart: false,
        hasErrorEnd: false,
      });
    } else {
      workloads = [
        {
          percentage: 0,
          start: '',
          end: '',
          hasErrorStart: false,
          hasErrorEnd: false,
        },
      ];
    }

    updateProject(workloads, 'workloads');
  }, [project, updateProject]);

  const removeWorkload = useCallback(
    (i: number) => {
      const workloads = project.workloads;
      if (workloads) {
        delete workloads[i];
      }

      updateProject(
        workloads.filter((w) => w !== null),
        'workloads'
      );
      updateConfirmButtonEnabled({
        ...project,
        workloads: workloads,
      });
    },
    [project, updateProject, updateConfirmButtonEnabled]
  );

  const onCloseDialog = useCallback(() => {
    handleCloseDialog();
  }, [handleCloseDialog]);

  const onConfirmDialog = useCallback(() => {
    handleConfirmDialog(project);
  }, [handleConfirmDialog, project]);

  return (
    <Ti8mDialog
      open={isDialogOpen}
      header={project.projectName}
      cancelButton={{
        content: t('employee-profile.projects.dialog.button-cancel'),
      }}
      onCancel={() => onCloseDialog()}
      confirmButton={{
        content: t('employee-profile.projects.dialog.button-confirm'),
        disabled: !isTouched,
      }}
      onConfirm={() => onConfirmDialog()}
      content={
        <EmployeeProfileAddProjectDialog
          project={project}
          addWorkload={addWorkload}
          removeWorkload={removeWorkload}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setPensum={setPensum}
          setProbabilityOfExtension={setProbabilityOfExtension}
        />
      }
    />
  );
};
