import { Loader } from '@fluentui/react-northstar';
import { authentication } from '@microsoft/teams-js';
import { FC, createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { runsInTeams } from '../../utils/platform';
import { OpenAPI as Commands } from '../open-api/commands/core/OpenAPI';
import { OpenAPI as Queries } from '../open-api/queries/core/OpenAPI';
import { MsalTokenProviderWrapper } from './msalTokenProvider';

export const RunsInTeamsContext = createContext<boolean>(false);

export const TokenProvider: FC = ({ children }) => {
  const { t } = useTranslation();
  const [inTeams, setInTeams] = useState<boolean>();
  const [token, setToken] = useState<string>();

  useEffect(() => {
    const tryTeamsAuthentication = async () => {
      const runsInsideTeams = await runsInTeams();
      setInTeams(runsInsideTeams);

      if (runsInsideTeams === false) return;

      const authToken = await authentication.getAuthToken({
        silent: true,
      });

      if (authToken) {
        Queries.TOKEN = authToken;
        Commands.TOKEN = authToken;
        setToken(authToken);
      }
    };

    tryTeamsAuthentication();
  }, []);

  switch (inTeams) {
    case true:
      console.log('Running inside MS Teams');
      return token ? (
        <RunsInTeamsContext.Provider value={inTeams}>{children}</RunsInTeamsContext.Provider>
      ) : (
        <Loader style={{ height: '100%' }} label={t('auth-in-progress')} />
      );
    case false:
      console.log('Running outside of MS Teams');
      return (
        <RunsInTeamsContext.Provider value={inTeams}>
          <MsalTokenProviderWrapper>{children}</MsalTokenProviderWrapper>
        </RunsInTeamsContext.Provider>
      );
    default:
      return null;
  }
};
