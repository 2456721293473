import { ArrowDownIcon, ArrowUpIcon, Box, Flex, Text } from '@fluentui/react-northstar';

// https://stackoverflow.com/questions/56687668/a-way-to-disable-type-argument-inference-in-generics
// NoInfer will be natively supported after TS 5.4
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type NoInfer<T> = [T][T extends any ? 0 : never];

type SortableTableHeaderProps<T, S> = {
  title: string;
  sortBy: NoInfer<T>;
  currentSort: {
    sortBy: T;
    sortDirection: S;
  };
};

export const SortableTableHeader = <T extends string, S extends 'asc' | 'desc'>({
  title,
  sortBy,
  currentSort,
}: SortableTableHeaderProps<T, S>) => (
  <Flex vAlign="center" styles={{ gap: '4px' }}>
    <Text weight="semibold" content={title} />
    {currentSort.sortBy === sortBy && (
      <Box as="span">
        {currentSort.sortDirection === 'asc' ? (
          <ArrowUpIcon size="smaller" />
        ) : (
          <ArrowDownIcon size="smaller" />
        )}
      </Box>
    )}
  </Flex>
);
