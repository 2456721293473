import { RolesService } from '../../open-api/commands/services/RolesService';
import { env } from '../../../utils/env';
import * as CommandParams from './command-params';
import { ResourceRequestService as QueryResourceRequestService } from '../../open-api/queries';
import { Result } from '../../open-api/commands/models/Result';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { GuidResult } from '../../open-api/commands/models/GuidResult';
import { ApiError } from '../../open-api/commands';
import { QueryKeys } from '../../query-keys';
import { waitForEvent } from './wait-for-event';

const addRoleRequest = (input: CommandParams.AddRoleRequestInput) =>
  RolesService.addRoleRequest({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const updateRoleRequest = (input: CommandParams.UpdateRoleRequestInput) =>
  RolesService.updateRoleRequest({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const deleteRoleRequest = (input: CommandParams.DeleteRoleRequestInput) =>
  RolesService.deleteRoleRequest({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const getEventPosition = (id: string) =>
  QueryResourceRequestService.getEventPosition({
    version: env('REACT_APP_API_VERSION'),
    id,
  });

type GuidResultWithId = Result & {
  id: string;
};

export const useAddRoleRequest = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<GuidResult, ApiError, CommandParams.AddRoleRequestInput>(
    async (input) => {
      const result = await addRoleRequest(input);

      return { ...result };
    },
    {
      onSuccess: async ({ payload: id, storePosition }) =>
        waitForEvent({ id, storePosition }, getEventPosition).then(() => {
          queryClient.refetchQueries(QueryKeys.ResourceRequest);
          queryClient.refetchQueries(QueryKeys.RoleRequests);
        }),
      meta: {
        type: t('alert.update-types.edit'),
        requestObject: t('alert.request-objects.request'),
      },
    }
  );
};

export const useEditRoleRequest = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, CommandParams.UpdateRoleRequestInput>(
    async (input) => {
      const result = await updateRoleRequest(input);

      return { ...result, id: input.resourceRequestId };
    },
    {
      onSuccess: async ({ id, storePosition }) =>
        waitForEvent({ id, storePosition }, getEventPosition).then(() => {
          queryClient.refetchQueries([QueryKeys.ResourceRequest, id]);
          queryClient.refetchQueries(QueryKeys.RoleRequests);
        }),
      meta: {
        type: t('alert.update-types.edit'),
        requestObject: t('alert.request-objects.request'),
      },
    }
  );
};

export const useDeleteRoleRequest = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, CommandParams.DeleteRoleRequestInput>(
    async (input) => {
      const result = await deleteRoleRequest(input);

      return { ...result, id: input.roleRequestId };
    },
    {
      onSuccess: async ({ id }) =>
        waitForEvent({ id }, getEventPosition).then(() => {
          queryClient.refetchQueries(QueryKeys.ResourceRequest);
          queryClient.refetchQueries(QueryKeys.RoleRequests);
        }),
      meta: {
        type: t('alert.update-types.delete'),
        requestObject: t('alert.request-objects.comment'),
      },
    }
  );
};
