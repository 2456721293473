import { Flex, Table, Text } from '@fluentui/react-northstar';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ResourceRequestOverviewDto } from '../../data-access/open-api/queries';
import {
  PaginationAtomState,
  resourcePaginationAtom,
  selectedRequestFilterAtom,
  selectedResourceRequestAtom,
} from '../../state/atoms';
import { convertToMonthDayYear } from '../../utils/dateConverter';
import {
  primaryCellStyle,
  secondaryCellStyle,
  tableRootStyle,
  tableRowVariablesOverride,
} from '../EmployeeTable/employeeTableStyles';
import { Pagination } from '../EmployeeTable/Pagination';
import { useResourceRequestOverviewLocalSort } from './hooks/useResourceRequestOverviewLocalSort';
import { useSortState } from './hooks/useSortState';
import { PrioritySymbol } from './PrioritySymbol';
import { SortableTableHeader } from '../Common/SortableTableHeader';

export type SortBy = keyof Pick<
  ResourceRequestOverviewDto,
  'submissionDate' | 'expectedFeedbackDate' | 'creationTime'
>;

export const ResourceTable = () => {
  const [paginationData, setPaginationData] =
    useRecoilState<PaginationAtomState>(resourcePaginationAtom);
  const [selectedResourceRequest, setSelectedResourceRequest] = useRecoilState(
    selectedResourceRequestAtom
  );
  const selectedFilter = useRecoilValue(selectedRequestFilterAtom);
  const { sortState, setSortBy } = useSortState<SortBy>('creationTime', 'desc');

  const { data, totalItems } = useResourceRequestOverviewLocalSort({
    paginationOptions: paginationData,
    open: selectedFilter.open,
    sortBy: sortState.sortBy,
    sortDirection: sortState.sortDirection,
  });

  useEffect(() => {
    const isSelectedResourceRequestInData = data.some((d) => d.id === selectedResourceRequest);

    if (data.length > 0 && !isSelectedResourceRequestInData) {
      setSelectedResourceRequest(data[0].id);
    }
  }, [selectedResourceRequest, data, setSelectedResourceRequest]);

  const columnStyles = {
    project: { flexGrow: 2.5 },
    duration: { maxWidth: '10rem' },
    author: { flex: 1 },
    submission: { maxWidth: '10rem', cursor: 'pointer' },
    feedbackDate: { cursor: 'pointer' },
    creationDate: { cursor: 'pointer' },
  };

  const { t } = useTranslation();
  const headers = {
    items: [
      {
        key: 'projectHeader',
        content: <Text weight="semibold" content={t('resource-request-table.headers.project')} />,
        styles: columnStyles.project,
      },
      {
        key: 'durationHeader',
        content: <Text weight="semibold" content={t('resource-request-table.headers.duration')} />,
        styles: columnStyles.duration,
      },
      {
        key: 'authorHeader',
        content: <Text weight="semibold" content={t('resource-request-table.headers.author')} />,
        styles: columnStyles.author,
      },
      {
        key: 'submissionHeader',
        content: (
          <SortableTableHeader
            title={t('resource-request-table.headers.submissionDate')}
            sortBy="submissionDate"
            currentSort={sortState}
          />
        ),
        onClick: () => setSortBy('submissionDate'),
        styles: columnStyles.submission,
      },
      {
        key: 'expectedFeedbackHeader',
        content: (
          <SortableTableHeader
            title={t('resource-request-table.headers.expectedFeedbackDate')}
            sortBy="expectedFeedbackDate"
            currentSort={sortState}
          />
        ),
        onClick: () => setSortBy('expectedFeedbackDate'),
        styles: columnStyles.feedbackDate,
      },
      {
        key: 'creationHeader',
        content: (
          <SortableTableHeader
            title={t('resource-request-table.headers.creationTime')}
            sortBy="creationTime"
            currentSort={sortState}
          />
        ),
        onClick: () => setSortBy('creationTime'),
        styles: columnStyles.creationDate,
      },
    ],
  };

  return (
    <Flex column style={{ flexGrow: 1 }}>
      <Table
        variables={{
          cellContentOverflow: 'none',
        }}
        styles={tableRootStyle}
        header={headers}
        rows={data.map((item) => ({
          key: item.id,
          'aria-selected': item.id === selectedResourceRequest,
          items: [
            {
              key: `${item.project}_project`,
              truncateContent: true,
              content: (
                <Flex column>
                  <Text styles={primaryCellStyle} truncated>
                    {item.project}
                  </Text>
                  <Text styles={secondaryCellStyle} truncated>
                    {item.client}
                  </Text>
                </Flex>
              ),
              styles: columnStyles.project,
            },
            {
              key: `${item.duration}_duration`,
              truncateContent: true,
              content: (
                <Text styles={secondaryCellStyle} truncated>
                  {item.duration}
                </Text>
              ),
              styles: columnStyles.duration,
            },
            {
              key: `${item.author}_author`,
              truncateContent: true,
              content: (
                <Text styles={secondaryCellStyle} truncated>
                  {item.author}
                </Text>
              ),
              styles: columnStyles.author,
            },
            {
              key: `${item.submissionDate}_submission`,
              truncateContent: true,
              styles: columnStyles.submission,
              content: (
                <Flex>
                  <PrioritySymbol
                    submissionDate={item.submissionDate}
                    hasText={false}
                    closingInfo={item.closingInfo}
                  />
                  <Text styles={secondaryCellStyle}>
                    {convertToMonthDayYear(item.submissionDate)}
                  </Text>
                </Flex>
              ),
            },
            {
              key: `${item.expectedFeedbackDate}_expectedFeedback`,
              truncateContent: true,
              content: item.expectedFeedbackDate && (
                <Text styles={secondaryCellStyle}>
                  {convertToMonthDayYear(item.expectedFeedbackDate)}
                </Text>
              ),
            },
            {
              key: `${item.creationTime}_creationTime`,
              truncateContent: true,
              content: item.creationTime && (
                <Text styles={secondaryCellStyle}>{convertToMonthDayYear(item.creationTime)}</Text>
              ),
            },
          ],
          onClick: () => setSelectedResourceRequest(item.id),
          variables: tableRowVariablesOverride,
        }))}
      />

      <Pagination
        currentPage={paginationData.currentPage}
        pageSize={paginationData.pageSize}
        totalItems={totalItems}
        onPageChange={(page) =>
          setPaginationData((prev) => {
            return {
              ...prev,
              currentPage: page,
            };
          })
        }
      />
    </Flex>
  );
};
