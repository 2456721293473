import {
  ArrowUpIcon,
  createComponent,
  Divider,
  Flex,
  FlexItem,
  Text,
} from '@fluentui/react-northstar';
import { take } from 'ramda';
import { TFunction, useTranslation } from 'react-i18next';
import { EmployeeProjectAssignmentDto } from '../../../data-access/open-api/queries';
import { ProbabilityOfExtensionType } from '../../../enum/ProbabilityOfExtensionType';
import { convertMonthYear } from '../../../utils/dateConverter';

interface ProbabilityOfExtensionTableProps {
  projects: EmployeeProjectAssignmentDto[];
  t: TFunction<'resource-management', undefined>;
}

interface ProbabilityOfExtensionProps {
  projects: EmployeeProjectAssignmentDto[];
}

const ProbabilityOfExtensionTable = createComponent<ProbabilityOfExtensionTableProps>({
  displayName: 'ProbabilityOfExtensionTable',
  render: ({ projects, t }) => {
    // TODO: Can't use a React hook here...
    //       Find out how to integrate translations with custom Northstar components. Probably with a HOC.
    return (
      <Flex column>
        {take(4, projects).map((project, i) => (
          <Flex column key={`project_${project.id}_${i}`}>
            <Flex
              style={{ width: '100%' }}
              gap="gap.smaller"
              key={`project_${project.id}_${i}_table`}
            >
              <FlexItem size="size.half">
                <Text
                  key={`project_${project.id}_${i}_name`}
                  weight="semibold"
                  size="small"
                  content={project.projectName}
                />
              </FlexItem>
              <FlexItem push>
                <Text
                  style={{ minWidth: '56' }}
                  key={`project_${project.id}_${i}_date`}
                  size="small"
                  content={project.endDate ? convertMonthYear(project.endDate) : ''}
                />
              </FlexItem>
              <FlexItem>
                <>
                  <Text
                    key={`project_${project.id}_${i}_workload`}
                    size="small"
                    content={'Workload'}
                  />
                  <Text
                    style={{ minWidth: '30' }}
                    key={`project_${project.id}_${i}_workload_number`}
                    size="small"
                    align="end"
                    content={`${project.workloads[project.workloads.length - 1].percentage}%`}
                  />
                </>
              </FlexItem>
              <Text
                key={`project_${project.id}_${i}_outlook`}
                weight="semibold"
                size="small"
                style={{ minWidth: '72' }}
                content={
                  <>
                    <ArrowUpIcon size="smaller" />{' '}
                    {t(
                      `probability-of-extension-types.shorts.${
                        project.probabilityOfExtension
                          ? ProbabilityOfExtensionType[project.probabilityOfExtension]
                          : 0
                      }`
                    )}
                  </>
                }
              />
            </Flex>
            {(i !== projects.length || projects.length <= 3) && (
              <Divider key={`project_${project.id}_${i}_divider`} />
            )}
          </Flex>
        ))}
        {projects.length > 3 && (
          <FlexItem align="start">
            <Text style={{ textDecoration: 'underline' }} size="small" timestamp>
              {projects.length - 3} {t('employee-overview-table-detail.more')}
            </Text>
          </FlexItem>
        )}
      </Flex>
    );
  },
});

export const ProbabilityOfExtensionSection = ({ projects }: ProbabilityOfExtensionProps) => {
  const { t } = useTranslation();

  return (
    <Flex column gap="gap.small" style={{ height: '150' }}>
      <Text weight="semibold" size="large" as="div">
        {t('employee-overview-table-detail.probability-of-extension')}
      </Text>
      <ProbabilityOfExtensionTable projects={projects} t={t} />
    </Flex>
  );
};
