import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbItem,
  ChevronEndMediumIcon,
  ComponentSlotStyle,
} from '@fluentui/react-northstar';
import { NodeNavigation } from './models/NavigationPointer';
import { useRecoilState } from 'recoil';
import { executivesDashboardNavigationPointerAtom } from '../../state/atoms/executivesDashboardNavigationPointerAtom';

const primaryColor: ComponentSlotStyle = (data) => ({
  color: data.theme.siteVariables.colorScheme.brand.foreground,
});

export const NavigationBreadcrumb = () => {
  type Crumb = {
    level: number;
    organisationNumber?: number;
    name?: string;
  };

  const [navigationPointer, setNavigationPointer] = useRecoilState<NodeNavigation>(
    executivesDashboardNavigationPointerAtom
  );

  const navigate = (crumb: Crumb) => {
    const updatedValue = navigationPointer.slice(0, crumb.level + 1);
    setNavigationPointer(updatedValue);
  };

  return (
    <Breadcrumb>
      {navigationPointer.map((item) => {
        return (
          <ul key={item.level} style={{ display: 'inline', padding: '0px', marginLeft: '-8px' }}>
            <BreadcrumbItem
              key={item.level}
              content={item.name}
              onClick={() => navigate(item)}
              active={true}
              style={{ minWidth: '84px' }}
              styles={primaryColor}
            ></BreadcrumbItem>
            {
              // -1 since first item level is 0
              item.level < navigationPointer.length - 1 && (
                <BreadcrumbDivider>
                  <ChevronEndMediumIcon />
                </BreadcrumbDivider>
              )
            }
          </ul>
        );
      })}
    </Breadcrumb>
  );
};
