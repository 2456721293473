import {
  ComponentStyleFunctionParam,
  Flex,
  ShorthandCollection,
  Table,
  TableRowProps,
  Text,
} from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import {
  outlookTableStyle,
  workloadNameColumnStyle,
  workloadClickableCellStyle,
  workloadMonthsHeaderStyle,
  workloadNameHeaderStyle,
  workloadMonthsColumnStyle,
} from '../dashboardStyles';
import { useFtePerMonthDrillDown } from '../../../data-access/hooks/queries/use-fte-per-month-drill-down';
import { useRecoilState } from 'recoil';
import { executivesDashboardDateNavigationAtom } from '../../../state/atoms/executivesDashboardDateNavigationAtom';
import { getMonthNameShort } from '../../../utils/dateConverter';
import { useMemo } from 'react';
import { useExecutivesDashboardNodeNavigation } from '../hooks/useNodeNavigation';
import { useDrillDown } from '../hooks/useDrillDown';

export const DepartmentWorkloadTable = () => {
  const formatCapacity = (capacity: number) => `${capacity.toFixed(1)}`;

  const { t } = useTranslation();

  const [selectedDate] = useRecoilState<Date>(executivesDashboardDateNavigationAtom);

  const { data: fteDataPerMonth } = useFtePerMonthDrillDown({
    startYear: selectedDate.getFullYear(),
    startMonth: selectedDate.getMonth() + 1,
    monthCount: 6,
    includeEmployees: true,
  });

  const drillDown = useDrillDown();

  const fteSelectedData = useExecutivesDashboardNodeNavigation(fteDataPerMonth);

  const rows = useMemo(() => {
    const rows: ShorthandCollection<TableRowProps> = [];
    const fteData = fteSelectedData.at(0);

    const month = selectedDate.getMonth() + 1;
    rows.push(
      [
        '',
        '',
        ...Array.from({ length: 6 }, (_, i) =>
          getMonthNameShort(new Date(0, ((month - 1 + i) % 12) + 1, 0))
        ),
      ].map((content, c) => ({
        key: `month_header_cell_${c}`,
        content: <Text content={content} />,
        styles: (data: ComponentStyleFunctionParam) => ({
          ...(c < 2 ? workloadNameColumnStyle(data) : workloadMonthsColumnStyle(data)),
        }),
      }))
    );

    if (fteData?.units !== undefined) {
      rows.push(
        ...[
          // fake aggregate unit
          {
            organizationNumber: fteData.organizationNumber,
            name: fteData.name,
            principal: fteData.principal,
            level: 9999,
            height: 0,
            isPerson: false,
            unusedCapactyFtePerMonth: fteData.unusedCapactyFtePerMonth,
            units: [],
          },
          ...fteData.units,
        ].map((unit, r) => ({
          height: unit.height,
          level: unit.level,
          key: `unit_row_${r}`,
          name: unit.name,
          items: [
            unit.name,
            unit.principal,
            ...(unit.unusedCapactyFtePerMonth?.map(formatCapacity) ?? []),
          ].map((content, c) => ({
            key: `unit_cell_${r}_${c}`,
            content: <Text content={content} />,
            styles: (data: ComponentStyleFunctionParam) => ({
              ...(c === 0 && unit.height > 0 && workloadClickableCellStyle(data)),
              ...(c < 2 ? workloadNameColumnStyle(data) : workloadMonthsColumnStyle(data)),
            }),
          })),
          style: {
            cursor: unit.height > 0 ? 'pointer' : 'default',
          },
          onClick: () => {
            if (unit.height === 0) return;
            drillDown(unit);
          },
        }))
      );
    }

    return rows;
  }, [drillDown, fteSelectedData, selectedDate]);

  const header = useMemo(
    () => ({
      items: [
        {
          key: 'team_header',
          content: <Text content={t('dashboard-executives.department-workload-team')} />,
          styles: workloadNameHeaderStyle,
        },
        {
          key: 'principal_header',
          content: <Text content={t('dashboard-executives.department-workload-principal')} />,
          styles: workloadNameHeaderStyle,
        },
        {
          key: 'months_header',
          content: <Text content={t('dashboard-executives.department-workload-ftes-to-staff')} />,
          styles: workloadMonthsHeaderStyle,
        },
        ...Array.from({ length: 5 }, (_, i) => ({
          key: `month_header_${i}`,
          content: '',
          styles: workloadMonthsHeaderStyle,
        })),
      ],
    }),
    [t]
  );

  return (
    <Flex column gap="gap.medium">
      <Text weight="semibold" size="large">
        {t('dashboard-executives.unused-capacity')}
      </Text>
      <Table styles={outlookTableStyle} header={header} rows={rows} compact />
    </Flex>
  );
};
