import { CloseIcon, ComponentEventHandler, Dialog, DialogProps } from '@fluentui/react-northstar';
import { CSSProperties, FunctionComponent } from 'react';

type Ti8mDialogProps = Omit<
  DialogProps,
  'backdrop' | 'closeOnOutsideClick' | 'headerAction' | 'onCancel'
> & {
  /** Show an <CloseIcon /> as the header action? Default to true */
  showCloseHeaderAction?: boolean;
  onCancel: ComponentEventHandler<DialogProps>;
  /** if true, will set the style to overflow:unset; this allows e.g. Dropdown-Popups to "overflow" outside the dialog.  */
  resetOverflow?: boolean;
  style?: CSSProperties | undefined;
};

/**
 * Simple wrapper around a fluentui/northstar Dialog that sets some defaults (in order to be more consistent).
 * If you need full flexibility, directly use the northstar Dialog.
 *
 * This component expects an 'onCancel' handler (so e.g. aborting with the ESC key works).
 */
export const Ti8mDialog: FunctionComponent<Ti8mDialogProps> = (props: Ti8mDialogProps) => {
  let style = props.style || {};
  if (props.resetOverflow) {
    style['overflow'] = 'unset';
  }

  const showCloseIcon = props.showCloseHeaderAction === undefined || props.showCloseHeaderAction;

  // create a shallow copy of the props, without 'showCloseHeaderAction' so we can use the spread operator in JSX without harm
  const propCopy = { ...props };
  delete propCopy.showCloseHeaderAction;
  delete propCopy.resetOverflow;

  return (
    <Dialog
      {...propCopy}
      backdrop
      closeOnOutsideClick={false}
      headerAction={showCloseIcon && <CloseIcon onClick={props.onCancel} />}
      style={style}
    />
  );
};
