import { useEmployeeOverview } from '../../../data-access/hooks/queries/use-employee-overview';

export interface UseEmployeeOverviewDataHookParams {
  employeeId?: string;
}

export type EmployeeOverviewDetailsData = ReturnType<typeof useEmployeeOverview>['data'];

export const useEmployeeOverviewData = ({
  employeeId,
}: UseEmployeeOverviewDataHookParams): EmployeeOverviewDetailsData | undefined => {
  const result = useEmployeeOverview(employeeId);
  return result.data;
};
