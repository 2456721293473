import { createSvgIcon } from '@fluentui/react-northstar';

export const PeopleSwapIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="0 0 16 16" className={classes.svg}>
      <path d="M8.496 8c.179 0 .35.031.51.089a1.5 1.5 0 0 0-.544.343L7.886 9H3.5a.5.5 0 0 0-.5.5v.775C3 11.42 3.928 12 6.06 12h.012c.074.207.194.402.361.567l.409.403c-.247.02-.508.03-.782.03C3.446 13 2 12.097 2 10.275V9.5A1.5 1.5 0 0 1 3.5 8h4.996ZM6 2a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Zm0 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm5 0a2 2 0 1 1 0 4 2 2 0 0 1 0-4Zm0 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-4 7.503a.5.5 0 0 0 .147.354l2.003 1.997a.5.5 0 0 0 .706-.708L8.71 12.002h4.584l-1.146 1.144a.5.5 0 1 0 .706.708l2-1.996a.5.5 0 0 0 0-.708l-2-1.999a.5.5 0 0 0-.707.707l1.145 1.144H8.71L9.856 9.86a.5.5 0 0 0-.706-.708l-2.003 1.998a.5.5 0 0 0-.147.354Z" />
    </svg>
  ),
  displayName: 'PeopleSwapIcon',
});
