import {
  CheckmarkCircleIcon,
  Flex,
  Menu,
  ParticipantAddIcon,
  Text,
} from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import { MenuStateType } from './ResourceRequestDialog';
import { useMemo } from 'react';
import { FolderIcon } from '../../../icons';

interface ResourceRequestDialogMenuProps {
  isEditMode: boolean;
  menuState: MenuStateType;
  setMenuState: (menuState: MenuStateType) => void;
  isTouchedInfo: boolean;
  isTouchedRole: boolean;
}

export const ResourceRequestDialogMenu = ({
  isEditMode,
  menuState,
  setMenuState,
  isTouchedInfo,
  isTouchedRole,
}: ResourceRequestDialogMenuProps) => {
  const { t } = useTranslation();

  const activeIndex = useMemo((): number => {
    if (menuState === 'projectRole') return 1;
    else if (menuState === 'requestSubmission') return 2;
    else return 0;
  }, [menuState]);

  return (
    <Menu
      underlined
      primary
      defaultActiveIndex={0}
      activeIndex={activeIndex}
      styles={{
        justifyContent: 'space-around',
        gridTemplateColumns: 'repeat(3, 1fr)',
        '& > *': {
          width: '14.5rem',
          maxWidth: '100%',
          '& > *': {
            width: '100%',
            '& > *': {
              width: '100%',
            },
          },
        },
      }}
      items={[
        {
          key: 'projectinfo',
          content: (
            <Flex column gap="gap.small">
              <Flex hAlign="center">
                <FolderIcon outline={menuState !== 'projectInfo'} />
              </Flex>
              <Flex hAlign="center">
                <Text content={t('resources.request-dialog.menu-project-info')}></Text>
              </Flex>
            </Flex>
          ),
          defaultMenuOpen: true,
          onClick: () => setMenuState('projectInfo'),
        },
        {
          key: 'roles',
          content: (
            <Flex column gap="gap.small">
              <Flex hAlign="center">
                <ParticipantAddIcon outline={menuState !== 'projectRole'} />
              </Flex>
              <Flex hAlign="center">
                <Text content={t('resources.request-dialog.menu-roles')}></Text>
              </Flex>
            </Flex>
          ),
          disabled: !isTouchedInfo && !isEditMode,
          onClick: () => setMenuState('projectRole'),
        },
        {
          key: 'requestsubmission',
          content: (
            <Flex column gap="gap.small">
              <Flex hAlign="center">
                <CheckmarkCircleIcon outline={menuState !== 'requestSubmission'} />
              </Flex>
              <Flex hAlign="center">
                <Text content={t('resources.request-dialog.menu-submission')}></Text>
              </Flex>
            </Flex>
          ),
          disabled: !(isTouchedInfo && isTouchedRole) && !isEditMode,
          onClick: () => setMenuState('requestSubmission'),
        },
      ]}
    />
  );
};
