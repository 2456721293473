import { Card, ComponentSlotStyle } from '@fluentui/react-northstar';
import { CSSProperties, PropsWithChildren } from 'react';

interface Ti8mCardProps {
  style?: CSSProperties;
  cardStyles?: ComponentSlotStyle<any, any>;
}

export const Ti8mCard = ({ style, cardStyles, children }: PropsWithChildren<Ti8mCardProps>) => {
  return (
    <div style={style}>
      <Card styles={cardStyles}>{children}</Card>
    </div>
  );
};
