import { DepartmentWorkloadTable } from '../components/Dashboard/Executives/DepartmentWorkloadTable';
import { DashboardLayout } from '../components/Dashboard/DashboardLayout';
import { NavigationBreadcrumb } from '../components/Dashboard/NavigationBreadcrumb';
import { DateNavigation } from '../components/Dashboard/Executives/DateNavigation';
import { OutlookBillabilityChart } from '../components/Dashboard/Executives/OutlookBillabilityChart';
import { OutlookBillabilityTable } from '../components/Dashboard/Executives/OutlookBillabilityTable';

export const DashboardExecutives = () => {
  return (
    <DashboardLayout
      breadcrumb={<NavigationBreadcrumb />}
      timeNavigation={<DateNavigation />}
      firstQuadrant={<DepartmentWorkloadTable />}
      secondQuadrant={<OutlookBillabilityChart />}
      secondHalf={<OutlookBillabilityTable />}
    />
  );
};
