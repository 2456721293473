/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReleaseNotesDtoIEnumerableResult } from '../models/ReleaseNotesDtoIEnumerableResult';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReleaseNotesService {
  /**
   * @returns ReleaseNotesDtoIEnumerableResult Success
   * @throws ApiError
   */
  public static getLatestReleaseNotes({
    version,
    lastVersion,
  }: {
    version: string;
    lastVersion?: number;
  }): CancelablePromise<ReleaseNotesDtoIEnumerableResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/releasenotes',
      path: {
        version: version,
      },
      query: {
        lastVersion: lastVersion,
      },
    });
  }
}
