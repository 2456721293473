import { useEmployeeProjectAssignments } from '../../../data-access/hooks/queries/use-employee-projects-assignment';

export interface EmployeeProjectsFilter {
  id?: string;
  startDate: Date;
  endDate: Date;
}

export interface UseEmployeeProjectsDataHookParams {
  filteringOptions?: EmployeeProjectsFilter;
}

export type EmployeeProjectGroupsData = ReturnType<typeof useEmployeeProjectAssignments>['data'];

export const useEmployeeProjectsData = ({
  filteringOptions,
}: UseEmployeeProjectsDataHookParams): EmployeeProjectGroupsData | undefined => {
  const result = useEmployeeProjectAssignments({
    id: filteringOptions?.id,
    startDate: filteringOptions?.startDate,
    endDate: filteringOptions?.endDate,
  });
  return result.data;
};
