import { Flex, Text } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import { ResourceRequestDto } from '../../data-access/open-api/queries/models/ResourceRequestDto';
import {
  convertMonthYear,
  convertToMonthDayYear,
  convertToMonthDayYearWithTime,
} from '../../utils/dateConverter';
import { EmployeeAvatar } from '../EmployeeTable/EmployeeAvatar';
import { PrioritySymbol } from '../ResourceTable/PrioritySymbol';
import { ProjectDetailSectionItem } from './ProjectDetailSectionItem';
import { ResourceRequestAttachments } from './ResourceRequestAttachments';

interface ProjectDetailsProps {
  resourceRequest: ResourceRequestDto;
}

export const ProjectDetails = ({ resourceRequest }: ProjectDetailsProps) => {
  const { t } = useTranslation();
  const [firstName, lastName] = resourceRequest.author.split(' ');

  return (
    <Flex>
      <Flex.Item size="size.half">
        <Flex wrap styles={{ gap: '8px' }}>
          <ProjectDetailSectionItem
            title={t('resource-details-pane.author')}
            content={
              <Flex gap={'gap.medium'} vAlign={'start'}>
                <EmployeeAvatar
                  size="medium"
                  firstName={firstName}
                  lastName={lastName}
                  photo={resourceRequest.authorThumbnail}
                />
                <Text content={resourceRequest.author} weight="semibold" />
              </Flex>
            }
          />
          <ProjectDetailSectionItem
            title={t('resource-details-pane.created-on')}
            content={<Text content={convertToMonthDayYearWithTime(resourceRequest.creationTime)} />}
          />
          <ProjectDetailSectionItem
            title={t('resource-details-pane.submission-date')}
            content={
              <Flex>
                <PrioritySymbol
                  submissionDate={resourceRequest.submissionDate}
                  hasText={false}
                  closingInfo={resourceRequest.closingInfo ?? undefined}
                />
                <Text content={convertToMonthDayYear(resourceRequest.submissionDate)} />
              </Flex>
            }
          />
          <ProjectDetailSectionItem
            title={t('resource-details-pane.expected-feedback')}
            content={
              <Text
                content={
                  resourceRequest.expectedFeedbackDate
                    ? convertToMonthDayYear(resourceRequest.expectedFeedbackDate)
                    : t('not-available')
                }
              />
            }
          />
          <ProjectDetailSectionItem
            title={t('resource-details-pane.duration')}
            content={
              <Text
                content={`${convertMonthYear(resourceRequest.projectStart)} - ${convertMonthYear(
                  resourceRequest.projectEnd
                )}`}
              />
            }
          />
          <ProjectDetailSectionItem
            title={t('resource-details-pane.attachment')}
            content={<ResourceRequestAttachments resourceRequest={resourceRequest} />}
          />
        </Flex>
      </Flex.Item>
      <Flex.Item size="size.half">
        <Flex column gap="gap.smaller">
          <Text content={t('resources-detail-view.description')} weight="semibold" size="small" />
          <Text content={resourceRequest.projectDescription} />
        </Flex>
      </Flex.Item>
    </Flex>
  );
};
