import { ApiError, CancelablePromise, ResourceRequestService } from '../../open-api/queries';
import { env } from '../../../utils/env';
import { useQuery, UseQueryOptions } from 'react-query';
import { QueryKeys } from '../../query-keys';
import { RoleRequestDtoResult } from '../../open-api/queries/models/RoleRequestDtoResult';
import * as QueryParams from './query-params';
import { PotentialEmployeeDto } from '../../open-api/queries/models/PotentialEmployeeDto';

const getRoleRequests = (
  input: QueryParams.RoleRequests
): CancelablePromise<RoleRequestDtoResult> =>
  ResourceRequestService.roleRequests({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

export const useRoleRequests = (
  queryParams: QueryParams.RoleRequests,
  queryOptions?: UseQueryOptions<
    RoleRequestDtoResult,
    ApiError,
    PotentialEmployeeDto[],
    [QueryKeys.RoleRequests, QueryParams.RoleRequests]
  >
) =>
  useQuery([QueryKeys.RoleRequests, queryParams], () => getRoleRequests(queryParams), {
    enabled: !!queryParams,
    select: (data) => data.payload.potentialEmployees,
    ...queryOptions,
  });
