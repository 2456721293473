import { DropdownProps, Flex, FlexItem, Text, TextArea } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import { closeRequestTextAreaStyles, notificationStyle } from './closeRequestDialogStyle';
import { CloseRequestData } from './model/CloseRequestData';
import { Ti8mDropdown } from '../../Common/Ti8mDropdown';
import { useState } from 'react';
import { Ti8mDialog } from '../../Common/Ti8mDialog';

type CloseRequestDialogProps = {
  isDialogOpen: boolean;
  handleCancelButton: () => void;
  handleCloseButton: () => void;
  closeRequestData: CloseRequestData;
  setCloseRequestData: (closeRequestData: CloseRequestData) => void;
  setButtonsVisibility: (isButtonsVisible: boolean) => void;
};

export const CloseRequestDialog = ({
  isDialogOpen,
  handleCancelButton,
  handleCloseButton,
  closeRequestData,
  setCloseRequestData,
  setButtonsVisibility,
}: CloseRequestDialogProps) => {
  const { t } = useTranslation();
  const [descriptionCharCount, setDescriptionCharCount] = useState(0);

  const handleCloseDialogOnClick = () => {
    handleCloseButton();
    resetDialogData();
    handleCancelButton();
    setButtonsVisibility(true);
  };

  const onDropDownChanged = (data: DropdownProps) => {
    let reason: string = '';
    if ((data.value as string) === 'Canceled' || (data.value as string) === 'Abgesagt') {
      reason = 'Cancelled';
    } else if ((data.value as string) === 'Staffed' || (data.value as string) === 'Besetzt') {
      reason = 'Staffed';
    }
    setCloseRequestData({ ...closeRequestData, reason: reason });
  };

  const onDescriptionDataChanged = (description: string) =>
    setCloseRequestData({ ...closeRequestData, description: description });

  const resetDialogData = () => {
    setCloseRequestData({ reason: '', date: '', description: '' });
    setDescriptionCharCount(0);
  };

  const maxCharLength: number = 250;

  const reasonDropDownItems = [
    t('close-request-dialog.reason-staffed'),
    t('close-request-dialog.reason-canceled'),
  ];

  return (
    <Ti8mDialog
      cancelButton={t('close-request-dialog.cancel-button')}
      onCancel={() => {
        resetDialogData();
        handleCancelButton();
      }}
      confirmButton={{ content: t('close-request-dialog.close-button') }}
      onConfirm={handleCloseDialogOnClick}
      content={
        <Flex column gap="gap.medium">
          <Text content={t('close-request-dialog.notification')}></Text>
          <Flex column>
            <Text weight="semibold" content={t('close-request-dialog.reason')} />
            <Ti8mDropdown
              items={reasonDropDownItems}
              placeholder={t('close-request-dialog.reason-placeholder')}
              onItemChange={onDropDownChanged}
            />
          </Flex>
          <Flex column>
            <Text weight="semibold" content={t('close-request-dialog.description')} />
            <TextArea
              inverted
              maxLength={maxCharLength}
              onChange={(_t, data) => {
                onDescriptionDataChanged(data?.value ?? '');
                setDescriptionCharCount(data?.value?.length ?? 0);
              }}
              styles={closeRequestTextAreaStyles}
            />
            <FlexItem align="end">
              <Text
                content={descriptionCharCount + '/' + maxCharLength}
                size="smaller"
                weight="light"
              />
            </FlexItem>
          </Flex>
        </Flex>
      }
      header={t('close-request-dialog.title')}
      open={isDialogOpen}
      styles={notificationStyle}
    />
  );
};
