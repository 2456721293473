import { AddIcon, Button, DownloadIcon, EditIcon, Flex, Input } from '@fluentui/react-northstar';
import debounce from 'lodash.debounce';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  activeFilterSelector,
  searchAtom,
  SearchAtomState,
  TogglesState,
} from '../../state/atoms/searchAtom';
import FilterDialog from './FilterDialog/FilterDialog';
import { inputStyles } from './searchWithFilterStyles';
import { useOrganizations } from '../../data-access/hooks/queries/use-organizations';
import React from 'react';
import { employeePaginationAtom, PaginationAtomState } from '../../state/atoms/paginationAtom';
import { OrganizationDto } from '../../data-access/open-api/queries/models/OrganizationDto';
import { useFavouriteFilters } from '../../data-access/hooks/queries/use-favourite-filter';
import FilterBar from './Filter/FilterBar';
import { EMPTY_FILTER_ID, LocalFilter } from './LocalFilter';
import { useEmployeeCapacityExcel } from '../../data-access/hooks/queries/use-employee-capacity-excel';
import { downloadFile } from '../Common/download-file';

export interface SaveData {
  organization: OrganizationDto | undefined;
  toggles: TogglesState;
  holidays: number | undefined;
}

export const SearchWithFilter = React.memo(() => {
  const { t } = useTranslation();

  const [search, setSearch] = useRecoilState<SearchAtomState>(searchAtom);
  const [, setPagination] = useRecoilState<PaginationAtomState>(employeePaginationAtom);
  const activeFilter = useRecoilValue(activeFilterSelector);
  const { data: organizationsData } = useOrganizations();
  const getEmployeeCapacityExcel = useEmployeeCapacityExcel();

  // store the FavouriteFilterDtos in the recoil atom (and don't access them directly),
  // this way, we have the local & stored filters in the same place
  useFavouriteFilters({
    onSuccess(filters) {
      const defaultFilter = filters.find((x) => x.default);
      setSearch((prev) => ({
        ...prev,
        // only update the activeFilterId with the default from the response, when we currently don't have anything set
        activeFilterId: prev.activeFilterId ? prev.activeFilterId : defaultFilter?.id,
        savedFilters: filters,
      }));
    },
  });

  const setActiveFilterId = useCallback(
    (filterId: string | number | undefined) => {
      setSearch((prev) => ({
        ...prev,
        activeFilterId: filterId ?? EMPTY_FILTER_ID,
      }));
      setPagination((prev) => ({
        ...prev,
        currentPage: 1,
      }));
    },
    [setSearch, setPagination]
  );

  const debouncedSetSearchString = useMemo(
    () =>
      debounce((value: string) => {
        setSearch((prev: SearchAtomState) => ({
          ...prev,
          searchString: value,
        }));
        setPagination((prev) => ({
          ...prev,
          currentPage: 1,
        }));
      }, 300),
    [setSearch, setPagination]
  );

  const handleOnSearchInputChange = useCallback(
    (value) => {
      debouncedSetSearchString.cancel();
      debouncedSetSearchString(value);
    },
    [debouncedSetSearchString]
  );

  const handleAddFilterClick = useCallback(() => {
    setSearch((prev: SearchAtomState) => ({
      ...prev,
      isSearchDialogOpen: true,
      isSearchDialogInEditMode: false,
    }));
  }, [setSearch]);

  const handleEditFilterClick = useCallback(() => {
    setSearch((prev: SearchAtomState) => ({
      ...prev,
      isSearchDialogOpen: true,
      isSearchDialogInEditMode: true,
    }));
  }, [setSearch]);

  const handleApplySearch = useCallback(
    (filter?: LocalFilter) => {
      const currentSearch = { ...search };

      if (search.isSearchDialogInEditMode && currentSearch.activeFilterId) {
        // remove the existing, active filter so we can add the updated one
        currentSearch.unsavedFilters = currentSearch.unsavedFilters.filter(
          (x) => x.id !== currentSearch.activeFilterId
        );
      }
      if (filter) {
        currentSearch.unsavedFilters = [...currentSearch.unsavedFilters, filter];
      }
      currentSearch.activeFilterId = filter?.id;
      setSearch(currentSearch);

      setPagination((prev) => ({
        ...prev,
        currentPage: 1,
      }));
    },
    [search, setSearch, setPagination]
  );

  const handleCloseDialog = useCallback(
    () => setSearch((prev) => ({ ...prev, isSearchDialogOpen: false })),
    [setSearch]
  );

  const removeLocalFilter = useCallback(
    (filter: LocalFilter) =>
      setSearch((prev) => ({
        ...prev,
        unsavedFilters: prev.unsavedFilters.filter((x) => x.id !== filter.id),
      })),
    [setSearch]
  );

  const handleExportClick = useCallback(async () => {
    const { payload: csvUrl } = await getEmployeeCapacityExcel({
      capacityFilter: {
        organizationNumber: activeFilter?.selectedOrganization?.number,
      },
    });
    downloadFile(csvUrl);
  }, [activeFilter?.selectedOrganization?.number, getEmployeeCapacityExcel]);

  return (
    <Flex
      space="between"
      vAlign="center"
      styles={{
        marginBottom: '32px',
      }}
    >
      <Flex gap="gap.small" vAlign="center">
        <Input
          styles={inputStyles}
          fluid
          defaultValue={search.searchString}
          placeholder={t('search.placeholder')}
          onChange={(_, data) => handleOnSearchInputChange(data?.value)}
          clearable
        />
        <FilterBar handleFilterActivation={setActiveFilterId} />
        <Button
          icon={<AddIcon />}
          content={t('search.filter-add')}
          secondary
          onClick={handleAddFilterClick}
        />
        <Button
          icon={<EditIcon />}
          content={t('search.filter-edit')}
          secondary
          disabled={!search.activeFilterId || search.activeFilterId === EMPTY_FILTER_ID}
          onClick={handleEditFilterClick}
        />
      </Flex>
      <Button
        icon={<DownloadIcon />}
        content={t('search.filter-export')}
        secondary
        onClick={handleExportClick}
      />
      <FilterDialog
        closeDialog={handleCloseDialog}
        isDialogOpen={search.isSearchDialogOpen}
        isInEditMode={search.isSearchDialogInEditMode}
        removeLocalFilter={removeLocalFilter}
        handleApplySearch={handleApplySearch}
        setActiveFilterId={setActiveFilterId}
        organizations={organizationsData ?? []}
      />
    </Flex>
  );
});
