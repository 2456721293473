import { Card, Flex } from '@fluentui/react-northstar';
import * as React from 'react';
import BasicLayout from '../Layout/Layout';
import { ReactNode } from 'react';
import { GridLayout } from '../Layout/GridLayout';

type DashboardLayoutProps = {
  breadcrumb?: ReactNode;
  timeNavigation?: ReactNode;
  firstQuadrant?: ReactNode;
  secondQuadrant?: ReactNode;
  secondHalf?: ReactNode;
};

export const DashboardLayout = ({
  breadcrumb,
  timeNavigation,
  firstQuadrant,
  secondQuadrant,
  secondHalf,
}: DashboardLayoutProps) => {
  return (
    <BasicLayout>
      <Flex column style={{ position: 'relative' }}>
        <Card>
          <Flex column gap="gap.medium">
            <Flex gap="gap.medium" column>
              <Flex column gap="gap.medium">
                {breadcrumb}
                {timeNavigation}
              </Flex>
            </Flex>
            <GridLayout>
              <div
                style={{
                  gridColumn: '1 / 3',
                  gridRow: '1 / 2',
                }}
              >
                {firstQuadrant}
              </div>
              <div
                style={{
                  gridColumn: '3 / 4',
                  gridRow: '1 / 3',
                }}
              >
                {secondQuadrant}
              </div>
              <div
                style={{
                  gridColumn: '1 / 3',
                  gridRow: '2 / 3',
                }}
              >
                {secondHalf}
              </div>
            </GridLayout>
          </Flex>
        </Card>
      </Flex>
    </BasicLayout>
  );
};
