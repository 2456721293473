import cx from 'classnames';
import { createSvgIcon, iconClassNames } from '@fluentui/react-northstar';

export const FolderIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="2 2 18 18" className={classes.svg}>
      <g className={cx(iconClassNames.outline, classes.outlinePart)}>
        <path d="M7.167 3c.27 0 .535.073.765.21l.135.09 1.6 1.2H15.5a2.5 2.5 0 0 1 2.479 2.174l.016.162L18 7v7.5a2.5 2.5 0 0 1-2.336 2.495L15.5 17h-11a2.5 2.5 0 0 1-2.495-2.336L2 14.5v-9a2.5 2.5 0 0 1 2.336-2.495L4.5 3h2.667Zm.99 4.034a1.5 1.5 0 0 1-.933.458l-.153.008L3 7.499V14.5a1.5 1.5 0 0 0 1.356 1.493L4.5 16h11a1.5 1.5 0 0 0 1.493-1.355L17 14.5V7a1.5 1.5 0 0 0-1.355-1.493L15.5 5.5H9.617l-1.46 1.534ZM7.168 4H4.5a1.5 1.5 0 0 0-1.493 1.356L3 5.5v.999l4.071.001a.5.5 0 0 0 .302-.101l.06-.054L8.694 5.02 7.467 4.1a.5.5 0 0 0-.22-.093L7.167 4Z" />
      </g>
      <g className={cx(iconClassNames.filled, classes.filledPart)}>
        <path d="M11.065 4.5H16a2.5 2.5 0 0 1 2.479 2.174l.016.162L18.5 7v7.5a2.5 2.5 0 0 1-2.336 2.495L16 17H5a2.5 2.5 0 0 1-2.495-2.336L2.5 14.5v-7h5.07l.154-.008a1.5 1.5 0 0 0 .823-.353l.111-.106L11.065 4.5ZM7.667 3c.27 0 .535.073.765.21l.135.09 1.318.989-1.952 2.055-.06.055a.5.5 0 0 1-.221.094l-.081.007H2.5v-1a2.5 2.5 0 0 1 2.336-2.495L5 3h2.667Z" />
      </g>
    </svg>
  ),
  displayName: 'FolderIcon',
});
