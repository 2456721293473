import { useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AddIcon, Button, Flex } from '@fluentui/react-northstar';
import { RequiredRoles } from './RequiredRoles';
import { ResourceRequestSetter } from './ResourceRequestDialog';
import { generateUUID } from '@microsoft/microsoft-graph-client/lib/es/src/middleware/MiddlewareUtil';
import { ProjectRolesDialogForm } from './ProjectRolesDialogForm';
import { ResourceRequestViewModel } from './viewModels/ResourceRequestViewModel';
import { RoleRequestDto } from '../../../data-access/open-api/queries/models/RoleRequestDto';

interface ProjectRolesDialogFormProps {
  resourceRequest: ResourceRequestViewModel;
  handleResourceRequest: ResourceRequestSetter;
}

export const ProjectRolesDialog = ({
  resourceRequest,
  handleResourceRequest,
}: ProjectRolesDialogFormProps) => {
  const { t } = useTranslation();

  const roles = useMemo(() => resourceRequest.roles, [resourceRequest.roles]);
  const setRoles = useCallback(
    (roles: RoleRequestDto[]) => handleResourceRequest('roles', roles),
    [handleResourceRequest]
  );

  const defaultRole: RoleRequestDto = useMemo(
    () => ({
      id: generateUUID(),
      role: '',
      roleStart: resourceRequest.projectStart?.toISOString() ?? '',
      roleEnd: resourceRequest.projectEnd?.toISOString() ?? '',
      workload: 0,
      skills: [],
      focus: '',
      potentialEmployees: [],
    }),
    [resourceRequest]
  );

  const [role, setRole] = useState<RoleRequestDto>(defaultRole);

  const isTouchedRole = useMemo(
    () =>
      role.role !== '' &&
      role.roleStart !== '' &&
      role.roleEnd !== '' &&
      role.roleStart < role.roleEnd &&
      role.workload !== 0,
    [role]
  );

  const resetRole = useCallback(
    () =>
      setRole({
        ...defaultRole,
        id: generateUUID(),
        workload: role.workload,
        skills: role.skills,
        focus: role.focus,
      }),
    [defaultRole, role, setRole]
  );

  const onAddRole = useCallback(() => {
    setRoles([...roles, role]);
    resetRole();
  }, [setRoles, roles, role, resetRole]);

  const onUpdateRole = useCallback(() => {
    setRoles(roles.map((r) => (r.id !== role.id ? r : { ...r, ...role })));
    resetRole();
  }, [setRoles, roles, resetRole, role]);

  const onDeleteRole = useCallback(
    (id: string) => setRoles(roles.filter((r) => r.id !== id)),
    [roles, setRoles]
  );

  const onEditRole = useCallback((editRole: RoleRequestDto) => setRole({ ...editRole }), [setRole]);

  return (
    <>
      <ProjectRolesDialogForm role={role} setRole={setRole} />
      <RequiredRoles
        roles={roles}
        setRole={setRole}
        role={role}
        onConfirmRole={onUpdateRole}
        onDeleteRole={onDeleteRole}
        onEditRole={onEditRole}
      />
      <Flex hAlign="end">
        <Button
          primary
          icon={<AddIcon />}
          content={t('resources.request-dialog.role-dialog.title')}
          onClick={onAddRole}
          styles={{ marginRight: '1rem' }}
          disabled={!isTouchedRole}
        />
      </Flex>
    </>
  );
};
