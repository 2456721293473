import { useTranslation } from 'react-i18next';
import { RoleDialogForm } from './RoleDialogForm';
import { Ti8mDialog } from '../../Common/Ti8mDialog';
import { RoleRequestDto } from '../../../data-access/open-api/queries/models/RoleRequestDto';

interface RoleDialogProps {
  isEditMode: boolean;
  isTouched: boolean;
  isDialogOpen: boolean;
  roleData: RoleRequestDto;
  handleDataChanged: (role: RoleRequestDto) => void;
  onCancel: () => void;
  onConfirm: () => void;
}

export const RoleDialog = ({
  isEditMode,
  isTouched,
  isDialogOpen,
  roleData,
  handleDataChanged,
  onCancel,
  onConfirm,
}: RoleDialogProps) => {
  const { t } = useTranslation();

  return (
    <Ti8mDialog
      cancelButton={t('resources.request-dialog.cancel')}
      onCancel={onCancel}
      confirmButton={{
        content: isEditMode
          ? t('resources.request-dialog.role-dialog.confirm')
          : t('resources.request-dialog.role-dialog.add-role'),
        disabled: !isTouched && !isEditMode,
      }}
      onConfirm={onConfirm}
      header={
        isEditMode
          ? t('resources.request-dialog.role-dialog.title-edit')
          : t('resources.request-dialog.role-dialog.title')
      }
      open={isDialogOpen}
      content={<RoleDialogForm role={roleData} onDataChanged={handleDataChanged} />}
      style={{ overflow: 'unset' }}
    />
  );
};
