export enum PotentialEmployeeStatus {
  AVAILABLE = 'Available',
  LEAD_CONTACTED = 'LeadContacted',
  ACCEPTED_BY_LEAD = 'AcceptedByLead',
  REJECTED_BY_LEAD = 'RejectedByLead',
  SELECTED = 'Selected',
  SUBMITTED_TO_CUSTOMER = 'SubmittedToCustomer',
  REJECTED_BY_CUSTOMER_WITHOUT_INTERVIEW = 'RejectedByCustomerWithoutInterview',
  INTERVIEW_PENDING = 'InterviewPending',
  REJECTED_BY_CUSTOMER = 'RejectedByCustomer',
  ACCEPTED_BY_CUSTOMER = 'AcceptedByCustomer',
}
