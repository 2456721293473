import { useMutation, useQueryClient } from 'react-query';
import { env } from '../../../utils/env';
import { ApiError, ResourceRequestService } from '../../open-api/commands';
import { ResourceRequestService as QueryResourceRequestService } from '../../open-api/queries';
import { Result } from '../../open-api/commands/models/Result';
import * as CommandParams from './command-params';
import { QueryKeys } from '../../query-keys';
import { GuidResult } from '../../open-api/commands/models/GuidResult';
import { useTranslation } from 'react-i18next';
import { waitForEvent } from './wait-for-event';

const updateResourceRequest = (input: CommandParams.UpdateResourceRequestInput) =>
  ResourceRequestService.updateResourceRequest({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const deleteResourceRequest = (input: CommandParams.DeleteResourceRequestInput) =>
  ResourceRequestService.deleteResourceRequest({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const addResourceRequest = (input: CommandParams.AddResourceRequestInput) =>
  ResourceRequestService.addResourceRequest({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const updateAttachment = (input: CommandParams.UpdateAttachment) =>
  ResourceRequestService.updateAttachment({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const uploadAttachment = (input: CommandParams.UploadAttachment) =>
  ResourceRequestService.uploadAttachmentAsync({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const deleteAttachmentById = (input: CommandParams.DeleteAttachmentById) =>
  ResourceRequestService.deleteAttachmentById({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const getEventPosition = (id: string) =>
  QueryResourceRequestService.getEventPosition({
    version: env('REACT_APP_API_VERSION'),
    id,
  });

type GuidResultWithId = Result & {
  id: string;
};

export const useUpdateResourceRequest = (type?: string) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, CommandParams.UpdateResourceRequestInput>(
    async (input) => {
      const result = await updateResourceRequest(input);

      return { ...result, id: input.id };
    },
    {
      onSuccess: async ({ id, storePosition }) =>
        waitForEvent({ id, storePosition }, getEventPosition).then(() =>
          queryClient.refetchQueries([QueryKeys.ResourceRequest, id])
        ),
      meta: {
        type: type ?? t('alert.update-types.edit'),
        requestObject: t('alert.request-objects.request'),
      },
    }
  );
};

export const useDeleteResourceRequest = (requestObject: string, type: string) => {
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, CommandParams.DeleteResourceRequestInput>(
    async (input) => {
      const result = await deleteResourceRequest(input);

      return { ...result, id: input.id };
    },
    {
      onSuccess: async ({ id }) =>
        waitForEvent({ id }, getEventPosition, true).then(() =>
          queryClient.refetchQueries(QueryKeys.ResourceRequestOverview)
        ),
      meta: {
        type: type,
        requestObject: requestObject,
      },
    }
  );
};

export const useAddResourceRequest = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<GuidResult, ApiError, CommandParams.AddResourceRequestInput>(
    async (input) => {
      const result = await addResourceRequest(input);

      return { ...result };
    },
    {
      onSuccess: async ({ payload: id, storePosition }) =>
        waitForEvent({ id, storePosition }, getEventPosition).then(() =>
          queryClient.refetchQueries(QueryKeys.ResourceRequestOverview)
        ),
      meta: {
        type: t('alert.update-types.edit'),
        requestObject: t('alert.request-objects.request'),
      },
    }
  );
};

export const useUpdateAttachment = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<GuidResult, ApiError, CommandParams.UpdateAttachment>(
    async (input) => {
      const result = await updateAttachment(input);

      return { ...result };
    },
    {
      onSuccess: async ({ payload: id, storePosition }) =>
        waitForEvent({ id, storePosition }, getEventPosition).then(() =>
          queryClient.refetchQueries([QueryKeys.Attachments])
        ),
      meta: {
        type: t('alert.update-types.edit'),
        requestObject: t('alert.request-objects.request'),
      },
    }
  );
};

export const useUploadAttachment = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<GuidResult, ApiError, CommandParams.UploadAttachment>(
    async (input) => {
      const result = await uploadAttachment(input);

      return { ...result };
    },
    {
      onSuccess: async ({ payload: id, storePosition }) =>
        waitForEvent({ id, storePosition }, getEventPosition, true).then(() =>
          queryClient.refetchQueries([QueryKeys.ResourceRequest])
        ),
      meta: {
        type: t('alert.update-types.edit'),
        requestObject: t('alert.request-objects.request'),
      },
    }
  );
};

export const useDeleteAttachmentById = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<GuidResultWithId, ApiError, CommandParams.DeleteAttachmentById>(
    async (input) => {
      const result = await deleteAttachmentById(input);

      return { ...result, id: input.resourceRequestAttachmentId };
    },
    {
      onSuccess: async ({ id }) =>
        waitForEvent({ id }, getEventPosition, true).then(() =>
          queryClient.refetchQueries(QueryKeys.ResourceRequest)
        ),
      meta: {
        type: t('alert.update-types.delete'),
        requestObject: t('alert.request-objects.comment'),
      },
    }
  );
};
