import { Flex, PillGroup, Text } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import { Language } from '../../models/language';
import { Ti8mPill } from '../Common/Ti8mPill';
import { EmployeeLanguageViewModel } from './viewModels/EmployeeLanguageViewModel';

interface EmployeeProfileLanguagesProps {
  languages: EmployeeLanguageViewModel[];
}

export const EmployeeProfileLanguages = ({ languages }: EmployeeProfileLanguagesProps) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const language = i18n.resolvedLanguage as Language;

  return (
    <Flex gap="gap.small">
      <Flex column>
        <Text size="large" weight="semibold">
          {t('employee-profile.languages')}
        </Text>
        <Flex>
          <PillGroup>
            {languages.map(({ name, isExpert }, index) => (
              <Ti8mPill
                key={`language_${index}`}
                size="small"
                content={name[language]}
                appearance={isExpert ? 'outline' : 'filled'}
                maxTextLength={18}
                styles={{ marginLeft: '0' }}
              />
            ))}
          </PillGroup>
        </Flex>
      </Flex>
    </Flex>
  );
};
