import { env } from '../../../utils/env';
import { EmployeeService } from '../../open-api/queries';
import type * as QueryParams from './query-params';

const getEmployeeCapacityExcel = (input: QueryParams.EmployeeCapacityExcel) =>
  EmployeeService.employeeCapacityExcel({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

export const useEmployeeCapacityExcel = () => (input: QueryParams.EmployeeCapacityExcel) =>
  getEmployeeCapacityExcel(input);
