import { createSvgIcon } from '@fluentui/react-northstar';

export const PeopleTeamAddIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="0 0 20 20" className={classes.svg}>
      <path d="M12.475 8.014a1 1 0 0 1 .993.884l.007.116v.081c-.346.066-.68.163-1 .29v-.37h-4.97v4.367c0 1.071.68 1.984 1.63 2.333.088.39.217.765.383 1.12a3.485 3.485 0 0 1-3.007-3.262l-.005-.191V9.014a1 1 0 0 1 .883-.993l.117-.007h4.969ZM14.5 9c.134 0 .268.005.4.014h2.09v.581c.359.182.694.402 1 .655V9.014l-.006-.116a1 1 0 0 0-.993-.884h-2.784c.146.253.24.543.262.851l.005.135h.026Zm-8.726-.986a1.988 1.988 0 0 0-.26.82l-.008.18h-2.49v3.74a1.856 1.856 0 0 0 2.618 1.693c.08.329.196.644.344.94a2.856 2.856 0 0 1-3.957-2.466l-.004-.168V9.014a1 1 0 0 1 .883-.993l.117-.007h2.757Zm4.214-5.025a2.227 2.227 0 1 1 0 4.455 2.227 2.227 0 0 1 0-4.455Zm0 1a1.227 1.227 0 1 0 0 2.455 1.227 1.227 0 0 0 0-2.455Zm4.988-.372a1.913 1.913 0 1 1 0 3.827 1.913 1.913 0 0 1 0-3.827Zm0 1a.913.913 0 1 0 0 1.827.913.913 0 0 0 0-1.827Zm-9.96-1a1.913 1.913 0 1 1 0 3.827 1.913 1.913 0 0 1 0-3.827Zm0 1a.913.913 0 1 0 0 1.827.913.913 0 0 0 0-1.827ZM19 14.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm-4-2a.5.5 0 0 0-1 0V14h-1.5a.5.5 0 0 0 0 1H14v1.5a.5.5 0 0 0 1 0V15h1.5a.5.5 0 0 0 0-1H15v-1.5Z" />
    </svg>
  ),
  displayName: 'PeopleTeamAddIcon',
});
