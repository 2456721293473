import { ResourceRequestDtoCommand } from '../../../data-access/open-api/commands/models/ResourceRequestDtoCommand';
import { RoleRequestsDtoCommand } from '../../../data-access/open-api/commands/models/RoleRequestsDtoCommand';
import { ResourceRequestViewModel } from './viewModels/ResourceRequestViewModel';
import { convertYearMonthDay } from '../../../utils/dateConverter';
import { ResourceRequestDto } from '../../../data-access/open-api/queries/models/ResourceRequestDto';
import { AttachmentDto } from '../../../data-access/open-api/queries/models/AttachmentDto';
import { AttachmentDtoCommand } from '../../../data-access/open-api/commands/models/AttachmentDtoCommand';
import { RoleRequestDto } from '../../../data-access/open-api/queries/models/RoleRequestDto';

// View Model to Command Dto

export const mapResourceRequestViewModelToResourceRequestCommand = (
  resourceRequest: ResourceRequestViewModel
): ResourceRequestDtoCommand => {
  return {
    id: resourceRequest.id,
    client: resourceRequest.client,
    project: resourceRequest.project,
    projectStart: convertYearMonthDay(resourceRequest.projectStart),
    projectEnd: convertYearMonthDay(resourceRequest.projectEnd),
    submissionDate: convertYearMonthDay(resourceRequest.submission),
    expectedFeedbackDate: convertYearMonthDay(resourceRequest.expectedFeedbackDate) || null,
    projectDescription: resourceRequest.description,
    roleRequests: resourceRequest.roles.map((role) => mapRoleRequestDtoToRoleRequestCommand(role)),
  };
};

export const mapRoleRequestDtoToRoleRequestCommand = (
  rolesViewData: RoleRequestDto
): RoleRequestsDtoCommand => {
  return {
    id: rolesViewData.id,
    role: rolesViewData.role,
    roleStart: convertYearMonthDay(new Date(rolesViewData.roleStart)),
    roleEnd: convertYearMonthDay(new Date(rolesViewData.roleEnd)),
    workload: rolesViewData.workload,
    skills: rolesViewData.skills.map((s) => s.id),
  };
};

export const mapAttachmentDtoToAttachmentDtoCommand = (
  attachmentDto: AttachmentDto
): AttachmentDtoCommand => {
  return {
    id: attachmentDto.id,
    attachmentFileName: attachmentDto.attachmentFile.name,
    originalFileName: attachmentDto.attachmentFile.displayName,
  };
};

// Query Dto to Command Dto

export const mapResourceRequestDtoToCommand = (
  resourceRequest: ResourceRequestDto | undefined,
  roleRequests: RoleRequestsDtoCommand[]
): ResourceRequestDtoCommand => {
  const resourceRequestInput = resourceRequest ?? defaultResourceRequestDto;
  return {
    client: resourceRequestInput.client,
    project: resourceRequestInput.project,
    projectStart: resourceRequestInput.projectStart,
    projectEnd: resourceRequestInput.projectEnd,
    submissionDate: resourceRequestInput.submissionDate,
    expectedFeedbackDate: resourceRequestInput.expectedFeedbackDate ?? undefined,
    projectDescription: resourceRequestInput.projectDescription,
    roleRequests: roleRequests,
    closingInfo:
      resourceRequestInput.closingInfo !== null
        ? {
            reason: resourceRequestInput.closingInfo.reason,
            date: resourceRequestInput.closingInfo.date,
            description: resourceRequestInput.closingInfo.description,
          }
        : null,
  };
};

export const mapRoleRequestsDtoToCommand = (
  roleRequests: Array<RoleRequestDto>
): Array<RoleRequestsDtoCommand> => {
  return roleRequests.map((roleRequest) => ({
    id: roleRequest.id,
    role: roleRequest.role,
    roleStart: roleRequest.roleStart,
    roleEnd: roleRequest.roleEnd,
    workload: roleRequest.workload,
    skills: roleRequest.skills.map((skills) => skills.id),
  }));
};

const defaultResourceRequestDto: ResourceRequestDto = {
  id: '',
  author: '',
  authorThumbnail: '',
  client: '',
  project: '',
  projectStart: '',
  projectEnd: '',
  submissionDate: '',
  expectedFeedbackDate: '',
  creationTime: '',
  projectDescription: '',
  roleRequests: [],
  attachments: [],
  comments: [],
  closingInfo: {
    reason: '',
    date: '',
    description: '',
  },
};
