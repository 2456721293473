import { ArrowRightIcon, Button, Flex, Loader, Text } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ResourceRequestDto } from '../../../data-access/open-api/queries/models/ResourceRequestDto';
import { selectedResourceRequestAtom } from '../../../state/atoms';
import { convertToMonthDayYearWithTime } from '../../../utils/dateConverter';
import { Ti8mCard } from '../../Common/Ti8mCard';
import { detailPaneRootStyle } from '../../EmployeeTable/EmployeeTableDetailPane/employeeTableDetailPaneStyles';
import { ResourceRequestAttachments } from '../../ResourceRequestDetailView/ResourceRequestAttachments';
import { useResourceRequestData } from '../hooks/useResourceRequestData';
import { CommentList } from './CommentList';
import { RequiredRoles } from './RequiredRoles';

export const ResourceTableDetailPane = () => {
  const selectedResourceRequest = useRecoilValue(selectedResourceRequestAtom);
  const resourceRequest: ResourceRequestDto | undefined = useResourceRequestData({
    resourceRequestId: selectedResourceRequest,
  });
  const { t } = useTranslation();
  const navigate = useHistory();
  const navigateToDetailsView = () => {
    navigate.push({
      pathname: `/resourceRequestDetailView/${selectedResourceRequest}`,
    });
  };

  return (
    <Ti8mCard cardStyles={detailPaneRootStyle}>
      {resourceRequest ? (
        <Flex column gap="gap.large">
          <Flex column styles={{ gap: '0.2rem' }}>
            <Text weight="semibold" as="h2" styles={{ margin: 0 }}>
              {resourceRequest.project}
            </Text>
            <Text>{resourceRequest.client}</Text>
          </Flex>

          <Flex column gap="gap.small">
            {resourceRequest.projectDescription && (
              <Text styles={{ wordBreak: 'break-word' }}>{resourceRequest.projectDescription}</Text>
            )}
            <Text
              size="small"
              content={t('resource-details-pane.created-on-dynamic', {
                value: convertToMonthDayYearWithTime(resourceRequest.creationTime),
              })}
            />
            <ResourceRequestAttachments resourceRequest={resourceRequest} />
          </Flex>

          <RequiredRoles roleRequests={resourceRequest.roleRequests} />
          {selectedResourceRequest && (
            <CommentList
              resourceRequestId={selectedResourceRequest}
              comments={resourceRequest.comments}
            />
          )}

          <Button
            icon={<ArrowRightIcon />}
            primary
            flat
            fluid
            onClick={navigateToDetailsView}
            disabled={selectedResourceRequest ? Boolean(resourceRequest.closingInfo) : false}
            content={t('resource-details-pane.view-full-request')}
          />
        </Flex>
      ) : (
        <Loader />
      )}
    </Ti8mCard>
  );
};
