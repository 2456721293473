import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { UInt64NullableResult } from '../core/UInt64NullableResult';
import { ResourceRequestDtoResult } from '../models/ResourceRequestDtoResult';
import { ResourceRequestOverviewDtoResult } from '../models/ResourceRequestOverviewDtoResult';
import { GuidResult } from '../../commands/models/GuidResult';
import { RoleRequestDtoResult } from '../models/RoleRequestDtoResult';

export class ResourceRequestService {
  /**
   * @returns ResourceRequestOverviewDtoResult Success
   * @throws ApiError
   */
  public static resourceRequests({
    skip,
    take,
    open,
    orderBy,
    version,
  }: {
    skip?: number;
    take?: number;
    open?: boolean;
    orderBy?: 'SubmissionDate' | 'ClosingDate' | 'Author';
    version: string;
  }): CancelablePromise<ResourceRequestOverviewDtoResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/resource-requests',
      path: {
        version: version,
      },
      query: {
        Skip: skip,
        Take: take,
        Open: open,
        OrderBy: orderBy,
      },
    });
  }

  /**
   * @returns ResourceRequestDtoResult Success
   * @throws ApiError
   */
  public static resourceRequestsById({
    id,
    version,
  }: {
    id: string;
    version: string;
  }): CancelablePromise<ResourceRequestDtoResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/resource-requests/{id}',
      path: {
        id: id,
        version: version,
      },
    });
  }

  /**
   * @returns RoleRequestDtoResult Success
   * @param resourceRequestId
   * @param roleRequestId
   * @param version
   */
  public static roleRequests({
    resourceRequestId,
    roleRequestId,
    version,
  }: {
    resourceRequestId: string;
    roleRequestId: string;
    version: string;
  }): CancelablePromise<RoleRequestDtoResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/resource-requests/{resourceRequestId}/role-requests/{roleRequestId}',
      path: {
        version: version,
        resourceRequestId: resourceRequestId,
        roleRequestId: roleRequestId,
      },
    });
  }

  public static attachmentUrlByAttachmentId({
    resourceRequestId,
    resourceRequestAttachmentId,
    version,
  }: {
    resourceRequestId: string;
    resourceRequestAttachmentId: string;
    version: string;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/resource-requests/{resourceRequestId}/attachment/loadFileUrl/{resourceRequestAttachmentId}',
      path: {
        version: version,
        resourceRequestId: resourceRequestId,
        resourceRequestAttachmentId: resourceRequestAttachmentId,
      },
    });
  }

  /**
   * @returns UInt64NullableResult Success
   * @throws ApiError
   */
  public static getEventPosition({
    id,
    version,
  }: {
    id: string;
    version: string;
  }): CancelablePromise<UInt64NullableResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/resource-requests/{id}/event-position',
      path: {
        id: id,
        version: version,
      },
    });
  }
}
