import { AttachmentDto } from '../../../../data-access/open-api/queries/models/AttachmentDto';
import { RoleRequestDto } from '../../../../data-access/open-api/queries/models/RoleRequestDto';

export interface ResourceRequestViewModel {
  id?: string;
  client: string;
  project: string;
  projectStart?: Date;
  projectEnd?: Date;
  submission?: Date;
  expectedFeedbackDate?: Date;
  description: string;
  attachments: Array<AttachmentDto>;
  roles: RoleRequestDto[];
}

export const blankResourceRequestViewModel: ResourceRequestViewModel = {
  client: '',
  project: '',
  description: '',
  attachments: [],
  roles: [],
};
