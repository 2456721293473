import {
  AcceptIcon,
  Alert,
  ComponentSlotStyle,
  ExclamationTriangleIcon,
  Text,
} from '@fluentui/react-northstar';
import { Flex, FlexProps } from '@fluentui/react-northstar/dist/es/components/Flex/Flex';
import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

interface BasicLayoutProps extends FlexProps {
  children?: any;
}

export const layoutStyles: ComponentSlotStyle = () => {
  return {
    flex: '1',
  };
};

export const BasicLayout: FunctionComponent<BasicLayoutProps> = (props) => {
  const { t } = useTranslation();
  const [alertData, setAlertData] = useState({
    isError: false,
    isVisible: false,
    message: '',
    title: '',
  });
  const unsubscribe = useQueryClient()
    .getMutationCache()
    .subscribe((event) => {
      const e = event as any;
      // check if mutation is finished
      if (e.state.data) {
        if (e.state.data.resultCode === 'OK' && e.meta?.suppressAlert === false) {
          setAlertData({
            isError: false,
            isVisible: true,
            message: t('alert.success')
              .replace('{1}', e.options.meta.requestObject)
              .replace('{2}', e.options.meta.type),
            title: '',
          });
        } else if (e.state.data.resultCode !== 'OK') {
          setAlertData({
            isError: true,
            isVisible: true,
            message: t('alert.error-message'),
            title: t('alert.error-title'),
          });
        }
        const timer = setTimeout(() => {
          setAlertData((prev) => {
            return {
              ...prev,
              isVisible: false,
            };
          });
          clearTimeout(timer);
        }, 3000);
      }
    });

  // unsubscribe on unmount
  useEffect(() => {
    return () => {
      unsubscribe();
    };
  }, [unsubscribe]);

  return (
    <Flex column>
      <Alert
        icon={
          alertData.isError ? <ExclamationTriangleIcon outline /> : <AcceptIcon circular bordered />
        }
        style={{
          position: 'fixed',
          top: 0,
          width: '100%',
          zIndex: 900,
          paddingTop: '3px',
          paddingBottom: '3px',
        }}
        title={alertData.title}
        content={<Text size="small">{alertData.message}</Text>}
        visible={alertData.isVisible}
        onVisibleChange={() =>
          setAlertData((prev) => {
            return { ...prev, isVisible: false };
          })
        }
        success={!alertData.isError}
        danger={alertData.isError}
        dismissible
        dismissAction={{
          'aria-label': 'close',
        }}
      />

      <Flex column {...props} style={{ padding: '2rem 3rem' }} styles={layoutStyles}>
        {props.children}
      </Flex>
    </Flex>
  );
};

export default BasicLayout;
