import { useQuery, UseQueryOptions } from 'react-query';
import { env } from '../../../utils/env';
import {
  ApiError,
  EmployeeOverviewDetailsDto,
  EmployeeOverviewDetailsDtoResult,
  EmployeeService,
} from '../../open-api/queries';
import { QueryKeys } from '../../query-keys';

const getEmployeeOverview = (id: string) =>
  EmployeeService.employeeOverviewById({
    version: env('REACT_APP_API_VERSION'),
    id,
  });

export const useEmployeeOverview = (
  id?: string,
  queryOptions?: UseQueryOptions<
    EmployeeOverviewDetailsDtoResult,
    ApiError,
    EmployeeOverviewDetailsDto,
    [QueryKeys.EmployeeOverview, string | undefined]
  >
) =>
  useQuery([QueryKeys.EmployeeOverview, id], () => getEmployeeOverview(id!), {
    enabled: !!id,
    select: (data) => ({
      ...data.payload,
      // map month to month - 1 bc JS starts month numbering at 0
      capacityOutlook: data.payload.capacityOutlook.map((co) => ({
        ...co,
        month: co.month - 1,
      })),
    }),
    ...queryOptions,
  });
