import { CommentDto } from '../../../data-access/open-api/queries/models/CommentDto';
import React from 'react';
import { Comment } from './Comment';

interface CommentsListProps {
  resourceRequestId: string;
  comments?: CommentDto[];
}

export const CommentList = ({ resourceRequestId, comments }: CommentsListProps) => {
  return (
    <div className="comment-list" style={{ flexGrow: 1, overflowY: 'auto' }}>
      {comments
        ?.slice()
        .sort(
          (a, b) =>
            new Date(b.modifiedAt || b.createdAt).getTime() -
            new Date(a.modifiedAt || a.createdAt).getTime()
        )
        .map((comment) => (
          <div key={comment.id}>
            <Comment comment={comment} resourceRequestId={resourceRequestId} />
          </div>
        ))}
    </div>
  );
};
