import { getDateForRequest } from '../../../../utils/dateConverter';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { GuidResult } from '../models/GuidResult';
import { ResourceRequestDtoCommand } from '../models/ResourceRequestDtoCommand';
import { Result } from '../models/Result';
import { Result_1 } from '../models/Result_1';
import { AttachmentDtoCommand } from '../models/AttachmentDtoCommand';

/**
 * @return any Success
 * @throws ApiError
 */
export class ResourceRequestService {
  public static addResourceRequest({
    version,
    requestBody,
  }: {
    version: string;
    requestBody?: ResourceRequestDtoCommand;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/resource-requests',
      path: {
        version: version,
      },
      body: {
        ...requestBody,
        projectStart: getDateForRequest(requestBody?.projectStart),
        projectEnd: getDateForRequest(requestBody?.projectEnd),
        submissionDate: getDateForRequest(requestBody?.submissionDate),
        roleRequests: requestBody?.roleRequests.map((roleRequest) => ({
          ...roleRequest,
          roleStart: getDateForRequest(roleRequest.roleStart),
          roleEnd: getDateForRequest(roleRequest.roleEnd),
        })),
        closingInfo: requestBody?.closingInfo
          ? {
              ...requestBody.closingInfo,
              date: getDateForRequest(requestBody.closingInfo.date),
            }
          : null,
      },
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * @return any Success
   * @throws ApiError
   * @param id
   * @param version
   * @param requestBody
   */
  public static updateResourceRequest({
    id,
    version,
    requestBody,
  }: {
    id: string;
    version: string;
    requestBody?: ResourceRequestDtoCommand;
  }): CancelablePromise<Result | Result_1> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/c/api/v{version}/resource-requests/{id}',
      path: {
        version: version,
        id: id,
      },
      body: {
        ...requestBody,
        projectStart: getDateForRequest(requestBody?.projectStart),
        projectEnd: getDateForRequest(requestBody?.projectEnd),
        submissionDate: getDateForRequest(requestBody?.submissionDate),
        roleRequests: requestBody?.roleRequests.map((roleRequest) => ({
          ...roleRequest,
          roleStart: getDateForRequest(roleRequest.roleStart),
          roleEnd: getDateForRequest(roleRequest.roleEnd),
        })),
        closingInfo: requestBody?.closingInfo
          ? {
              ...requestBody.closingInfo,
              date: getDateForRequest(requestBody.closingInfo.date),
            }
          : null,
      },
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * @return any Success
   * @throws ApiError
   * @param id
   * @param version
   */
  public static deleteResourceRequest({
    id,
    version,
  }: {
    id: string;
    version: string;
  }): CancelablePromise<Result | Result_1> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/c/api/v{version}/resource-requests/{id}',
      path: {
        version: version,
        id: id,
      },
    });
  }

  /**
   * @returns any Success
   * @param resourceRequestId
   * @param roleRequestId
   * @param employeeId
   * @param version
   */
  public static updatePotentialEmployeeStateSelect({
    resourceRequestId,
    roleRequestId,
    employeeId,
    version,
  }: {
    resourceRequestId: string;
    roleRequestId: string;
    employeeId: string;
    version: string;
  }): CancelablePromise<Result | Result_1> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/resource-requests/{resourceRequestId}/role-requests/{roleRequestId}/employees/{employeeId}/select',
      path: {
        resourceRequestId: resourceRequestId,
        roleRequestId: roleRequestId,
        employeeId: employeeId,
        version: version,
      },
    });
  }

  /**
   * @returns any Success
   * @param resourceRequestId
   * @param roleRequestId
   * @param employeeId
   * @param version
   */
  public static updatePotentialEmployeeStateContact({
    resourceRequestId,
    roleRequestId,
    employeeId,
    version,
  }: {
    resourceRequestId: string;
    roleRequestId: string;
    employeeId: string;
    version: string;
  }): CancelablePromise<Result | Result_1> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/resource-requests/{resourceRequestId}/role-requests/{roleRequestId}/employees/{employeeId}/contact',
      path: {
        resourceRequestId: resourceRequestId,
        roleRequestId: roleRequestId,
        employeeId: employeeId,
        version: version,
      },
    });
  }

  /**
   * @return any Success
   * @param resourceRequestId
   * @param roleRequestId
   * @param employeeId
   * @param version
   */
  public static updatePotentialEmployeeStateAcceptByLead({
    resourceRequestId,
    roleRequestId,
    employeeId,
    version,
  }: {
    resourceRequestId: string;
    roleRequestId: string;
    employeeId: string;
    version: string;
  }): CancelablePromise<Result | Result_1> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/resource-requests/{resourceRequestId}/role-requests/{roleRequestId}/employees/{employeeId}/acceptByLead',
      path: {
        resourceRequestId: resourceRequestId,
        roleRequestId: roleRequestId,
        employeeId: employeeId,
        version: version,
      },
    });
  }

  /**
   * @returns any Success
   * @param resourceRequestId
   * @param roleRequestId
   * @param employeeId
   * @param version
   */
  public static updatePotentialEmployeeStateRejectByLead({
    resourceRequestId,
    roleRequestId,
    employeeId,
    version,
  }: {
    resourceRequestId: string;
    roleRequestId: string;
    employeeId: string;
    version: string;
  }): CancelablePromise<Result | Result_1> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/resource-requests/{resourceRequestId}/role-requests/{roleRequestId}/employees/{employeeId}/rejectByLead',
      path: {
        resourceRequestId: resourceRequestId,
        roleRequestId: roleRequestId,
        employeeId: employeeId,
        version: version,
      },
    });
  }

  /**
   * @returns any Success
   * @param resourceRequestId
   * @param roleRequestId
   * @param employeeId
   * @param version
   */
  public static updatePotentialEmployeeStateSubmit({
    resourceRequestId,
    roleRequestId,
    employeeId,
    version,
  }: {
    resourceRequestId: string;
    roleRequestId: string;
    employeeId: string;
    version: string;
  }): CancelablePromise<Result | Result_1> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/resource-requests/{resourceRequestId}/role-requests/{roleRequestId}/employees/{employeeId}/submit',
      path: {
        resourceRequestId: resourceRequestId,
        roleRequestId: roleRequestId,
        employeeId: employeeId,
        version: version,
      },
    });
  }

  /**
   *
   * @param resourceRequestId
   * @param roleRequestId
   * @param employeeId
   * @param version
   */
  public static updatePotentialEmployeeStateRejectedByCustomerWithoutInterview({
    resourceRequestId,
    roleRequestId,
    employeeId,
    version,
  }: {
    resourceRequestId: string;
    roleRequestId: string;
    employeeId: string;
    version: string;
  }): CancelablePromise<Result | Result_1> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/resource-requests/{resourceRequestId}/role-requests/{roleRequestId}/employees/{employeeId}/rejectByCustomerWithoutInterview',
      path: {
        resourceRequestId: resourceRequestId,
        roleRequestId: roleRequestId,
        employeeId: employeeId,
        version: version,
      },
    });
  }

  /**
   *
   * @param resourceRequestId
   * @param roleRequestId
   * @param employeeId
   * @param version
   */
  public static updatePotentialEmployeeStateInterview({
    resourceRequestId,
    roleRequestId,
    employeeId,
    version,
  }: {
    resourceRequestId: string;
    roleRequestId: string;
    employeeId: string;
    version: string;
  }): CancelablePromise<Result | Result_1> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/resource-requests/{resourceRequestId}/role-requests/{roleRequestId}/employees/{employeeId}/interview',
      path: {
        resourceRequestId: resourceRequestId,
        roleRequestId: roleRequestId,
        employeeId: employeeId,
        version: version,
      },
    });
  }

  /**
   *
   * @param resourceRequestId
   * @param roleRequestId
   * @param employeeId
   * @param version
   */
  public static updatePotentialEmployeeStateRejectedByCustomer({
    resourceRequestId,
    roleRequestId,
    employeeId,
    version,
  }: {
    resourceRequestId: string;
    roleRequestId: string;
    employeeId: string;
    version: string;
  }): CancelablePromise<Result | Result_1> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/resource-requests/{resourceRequestId}/role-requests/{roleRequestId}/employees/{employeeId}/rejectByCustomer',
      path: {
        resourceRequestId: resourceRequestId,
        roleRequestId: roleRequestId,
        employeeId: employeeId,
        version: version,
      },
    });
  }

  /**
   * @param resourceRequestId
   * @param roleRequestId
   * @param employeeId
   * @param version
   */
  public static updatePotentialEmployeeStateAcceptedByCustomer({
    resourceRequestId,
    roleRequestId,
    employeeId,
    version,
  }: {
    resourceRequestId: string;
    roleRequestId: string;
    employeeId: string;
    version: string;
  }): CancelablePromise<Result | Result_1> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/resource-requests/{resourceRequestId}/role-requests/{roleRequestId}/employees/{employeeId}/acceptByCustomer',
      path: {
        resourceRequestId: resourceRequestId,
        roleRequestId: roleRequestId,
        employeeId: employeeId,
        version: version,
      },
    });
  }

  /**
   *
   * @param resourceRequestId
   * @param version
   * @param attachmentBody
   */
  public static updateAttachment({
    resourceRequestId,
    version,
    attachmentBody,
  }: {
    resourceRequestId: string;
    version: string;
    attachmentBody: AttachmentDtoCommand;
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/resource-requests/{resourceRequestId}/attachments',
      path: {
        resourceRequestId: resourceRequestId,
        version: version,
      },
      body: attachmentBody,
    });
  }

  /**
   *
   * @param resourceRequestId
   * @param attachmentId
   * @param originalFileName
   * @param version
   * @param formData
   */
  public static uploadAttachmentAsync({
    resourceRequestId,
    attachmentId,
    originalFileName,
    version,
    formData,
  }: {
    resourceRequestId: string;
    attachmentId: string;
    originalFileName: string;
    version: string;
    formData: {
      content: Blob;
      contentType: string;
    };
  }): CancelablePromise<GuidResult> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/resource-requests/{resourceRequestId}/attachments',
      path: {
        resourceRequestId: resourceRequestId,
        version: version,
      },
      formData: {
        attachmentId: attachmentId,
        originalFileName: originalFileName,
        content: formData.content,
        contentType: formData.contentType,
      },
      mediaType: 'multipart/form-data',
    });
  }

  /**
   *
   * @param resourceRequestId
   * @param attachmentName
   * @param version
   */
  public static deleteAttachmentById({
    resourceRequestId,
    resourceRequestAttachmentId,
    version,
  }: {
    resourceRequestId: string;
    resourceRequestAttachmentId: string;
    version: string;
  }): CancelablePromise<Result | Result_1> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/c/api/v{version}/resource-requests/{resourceRequestId}/attachments/{resourceRequestAttachmentId}',
      path: {
        resourceRequestId: resourceRequestId,
        resourceRequestAttachmentId: resourceRequestAttachmentId,
        version: version,
      },
    });
  }

  public static deleteAttachmentByName({
    attachmentFileName,
    version,
  }: {
    attachmentFileName: string;
    version: string;
  }): CancelablePromise<Result> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/c/api/v{version}/resource-requests/attachments/{attachmentFileName}',
      path: {
        resourceRequestAttachmentId: attachmentFileName,
        version: version,
      },
    });
  }

  /**
   * @param resourceRequestId
   * @param roleRequestId
   * @param employeeId
   * @param version
   */
  public static suggestPotentialEmployee({
    resourceRequestId,
    roleRequestId,
    employeeId,
    version,
  }: {
    resourceRequestId: string;
    roleRequestId: string;
    employeeId: string;
    version: string;
  }): CancelablePromise<Result | Result_1> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/resource-requests/{resourceRequestId}/role-requests/{roleRequestId}/employees/{employeeId}/suggest',
      path: {
        resourceRequestId: resourceRequestId,
        roleRequestId: roleRequestId,
        employeeId: employeeId,
        version: version,
      },
    });
  }

  /**
   * @param resourceRequestId
   * @param roleRequestId
   * @param employeeId
   * @param version
   */
  public static suggestPotentialEmployeeByLead({
    resourceRequestId,
    roleRequestId,
    employeeId,
    version,
  }: {
    resourceRequestId: string;
    roleRequestId: string;
    employeeId: string;
    version: string;
  }): CancelablePromise<Result | Result_1> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/resource-requests/{resourceRequestId}/role-requests/{roleRequestId}/employees/{employeeId}/suggestByLead',
      path: {
        resourceRequestId: resourceRequestId,
        roleRequestId: roleRequestId,
        employeeId: employeeId,
        version: version,
      },
    });
  }
}
