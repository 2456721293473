import { FolderRegular, PeopleTeamAddRegular } from '@fluentui/react-icons';
import { Menu } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { resourcePaginationAtom, selectedRequestFilterAtom } from '../../state/atoms';

export const ResourceRequestMenu = () => {
  const { t } = useTranslation();
  const setPagination = useSetRecoilState(resourcePaginationAtom);
  const setSelectedRequestFilter = useSetRecoilState(selectedRequestFilterAtom);

  const handleLinkClick = (value: boolean) => {
    setSelectedRequestFilter({ open: value });
    setPagination((prev) => ({
      ...prev,
      currentPage: 1,
    }));
  };

  return (
    <Menu
      underlined
      primary
      defaultActiveIndex={0}
      items={[
        {
          key: 'openrequests',
          content: t('resources.open-requests'),
          icon: <PeopleTeamAddRegular />,
          onClick: () => handleLinkClick(true),
        },
        {
          key: 'closedrequests',
          content: t('resources.closed-requests'),
          icon: <FolderRegular />,
          onClick: () => handleLinkClick(false),
        },
      ]}
    />
  );
};
