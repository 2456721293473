import { Flex, Grid, Text } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import { EmployeeTimeBalancesDto } from '../../data-access/open-api/queries';

interface EmployeeInfoGridProps {
  manager: string;
  pensum: number;
  domicile: string;
  department?: string;
  timeBalances?: EmployeeTimeBalancesDto;
}

export const EmployeeInfoGrid = ({
  manager,
  pensum,
  domicile,
  department,
  timeBalances,
}: EmployeeInfoGridProps) => {
  const { t } = useTranslation();

  return (
    <Grid
      as="dl"
      rows="1fr 1fr 40px"
      columns="repeat(2, 1fr)"
      styles={{
        rowGap: '0.5rem',
        margin: 0,
      }}
    >
      <div>
        <dt>
          <Text weight="bold" size="small">
            {t('employee-overview-table-detail.principal')}
          </Text>
        </dt>
        <dd>{manager}</dd>
      </div>
      <div>
        <dt>
          <Text weight="bold" size="small">
            {t('employee-overview-table-detail.department')}
          </Text>
        </dt>
        <dd>{department ? department : 'N/A'}</dd>
      </div>
      <div>
        <Flex column styles={{ gap: '4px' }}>
          <div>
            <dt>
              <Text weight="bold" size="small">
                {t('employee-overview-table-detail.domicile')}
              </Text>
            </dt>
            <dd>{domicile}</dd>
          </div>
        </Flex>
      </div>
      <div>
        <Text
          weight="bold"
          size="small"
          content={t('employee-overview-table-detail.timebalances')}
        />
        <Flex column>
          <Flex>
            <Text
              style={{ flexBasis: '5rem' }}
              content={t('employee-overview-table-detail.holidays')}
            />
            <Text
              weight="semibold"
              style={{ flexBasis: '4rem' }}
              align="end"
              content={timeBalances?.holidayBalanceDays ?? 'N/A'}
            />
          </Flex>
          <Flex>
            <Text
              style={{ flexBasis: '5rem' }}
              content={t('employee-overview-table-detail.flextime')}
            />
            <Text
              weight="semibold"
              align="end"
              style={{ flexBasis: '4rem' }}
              content={
                timeBalances?.flexTimeBalanceHours ?? 'N/A'
              }
            />
          </Flex>
          <Flex>
            <Text
              style={{ flexBasis: '5rem' }}
              content={t('employee-overview-table-detail.overtime')}
            />
            <Text
              weight="semibold"
              align="end"
              style={{ flexBasis: '4rem' }}
              content={
                timeBalances?.overTimeBalanceHours ?? 'N/A'
              }
            />
          </Flex>
        </Flex>
      </div>
      <div>
        <dt>
          <Text weight="bold" size="small">
            {t('employee-overview-table-detail.pensum')}
          </Text>
        </dt>
        <dd>{pensum} %</dd>
      </div>
    </Grid>
  );
};
