import { Flex, Grid, Text } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import { EmployeeTimeBalancesDto } from '../../../data-access/open-api/queries';

interface TopGridProps {
  manager: string;
  pensum: number;
  domicile: string;
  department?: string;
  timeBalances?: EmployeeTimeBalancesDto;
}

export const TopGrid = ({ manager, pensum, domicile, department, timeBalances }: TopGridProps) => {
  const { t } = useTranslation();

  return (
    <Grid
      as="dl"
      columns={'repeat(3, 1fr)'}
      styles={{
        rowGap: '0.5rem',
        margin: 0,
      }}
    >
      <Flex column gap="gap.smaller">
        <div>
          <dt>
            <Text weight="bold" size="small">
              {t('employee-overview-table-detail.principal')}
            </Text>
          </dt>
          <dd>{manager}</dd>
        </div>
        <div>
          <dt>
            <Text weight="bold" size="small">
              {t('employee-overview-table-detail.domicile')}
            </Text>
          </dt>
          <dd>{domicile}</dd>
        </div>
      </Flex>
      <Flex column gap="gap.smaller">
        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <dt>
            <Text weight="bold" size="small">
              {t('employee-overview-table-detail.department')}
            </Text>
          </dt>
          <Text content={department ? department : 'N/A'} truncated />
        </div>
        <div>
          <dt>
            <Text weight="bold" size="small">
              {t('employee-overview-table-detail.pensum')}
            </Text>
          </dt>
          <dd>{pensum} %</dd>
        </div>
      </Flex>
      <div>
        <Text
          weight="bold"
          size="small"
          content={t('employee-overview-table-detail.timebalances')}
        />
        <Flex column>
          <Flex>
            <Text
              style={{ flexBasis: '5rem' }}
              content={t('employee-overview-table-detail.holidays')}
            />
            <Text
              weight="semibold"
              style={{ flexBasis: '4rem' }}
              align="end"
              content={timeBalances?.holidayBalanceDays ?? 'N/A'}
            />
          </Flex>
          <Flex>
            <Text
              style={{ flexBasis: '5rem' }}
              content={t('employee-overview-table-detail.flextime')}
            />
            <Text
              weight="semibold"
              align="end"
              style={{ flexBasis: '4rem' }}
              content={
                timeBalances?.flexTimeBalanceHours ?? 'N/A'
              }
            />
          </Flex>
          <Flex>
            <Text
              style={{ flexBasis: '5rem' }}
              content={t('employee-overview-table-detail.overtime')}
            />
            <Text
              weight="semibold"
              align="end"
              style={{ flexBasis: '4rem' }}
              content={
                timeBalances?.overTimeBalanceHours ?? 'N/A'
              }
            />
          </Flex>
        </Flex>
      </div>
    </Grid>
  );
};
