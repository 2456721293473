import {
  Text,
  Table,
  ShorthandCollection,
  TableRowProps,
  ComponentStyleFunctionParam,
  Flex,
  Tooltip,
} from '@fluentui/react-northstar';
import {
  outlookTableStyle,
  billabilityClickableCellStyle,
  billabilityNameColumnStyle,
  billabilityNameHeaderStyle,
  billiabilityFrequencyHeaderStyle,
  billabilityFrequencyColumnStyle,
} from '../dashboardStyles';
import { useRecoilState } from 'recoil';
import { NodeNavigation } from '../models/NavigationPointer';
import { executivesDashboardNavigationPointerAtom } from '../../../state/atoms/executivesDashboardNavigationPointerAtom';
import { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useBillabilityDrillDown } from '../../../data-access/hooks/queries/use-billability-drill-down';
import { executivesDashboardDateNavigationAtom } from '../../../state/atoms';
import { convertYearMonthDay } from '../../../utils/dateConverter';
import { useExecutivesDashboardNodeNavigation } from '../hooks/useNodeNavigation';
import { useDrillDown } from '../hooks/useDrillDown';

export const OutlookBillabilityTable = () => {
  const getFirstDayOfMonth = (date: Date) => new Date(date.getFullYear(), date.getMonth(), 1);
  const getLastDayOfMonth = (date: Date) => new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const formatFrequency = (frequency: number) => `${Math.round(frequency)} %`;

  const { t } = useTranslation();

  const [navigationPointer, setNavigationPointer] = useRecoilState<NodeNavigation>(
    executivesDashboardNavigationPointerAtom
  );

  const [selectedDate] = useRecoilState<Date>(executivesDashboardDateNavigationAtom);

  const { data: workloadBillabilityWholeTreeData } = useBillabilityDrillDown({
    startDate: convertYearMonthDay(getFirstDayOfMonth(selectedDate)),
    endDate: convertYearMonthDay(getLastDayOfMonth(selectedDate)),
    precision: 2,
    includeEmployees: true,
  });

  useEffect(() => {
    if (workloadBillabilityWholeTreeData && navigationPointer.length === 0) {
      setNavigationPointer([
        {
          level: workloadBillabilityWholeTreeData.roots[0].level,
          name: workloadBillabilityWholeTreeData.roots[0].name,
          organizationNumber: workloadBillabilityWholeTreeData.roots[0].organizationNumber,
        },
      ]);
    }
  }, [workloadBillabilityWholeTreeData, setNavigationPointer, navigationPointer]);

  const workloadBillabilitySelectedData = useExecutivesDashboardNodeNavigation(
    workloadBillabilityWholeTreeData
  );

  const drillDown = useDrillDown();

  const rows = useMemo(() => {
    const rows: ShorthandCollection<TableRowProps> = [];
    const workloadBillabilityData = workloadBillabilitySelectedData.at(0);
    if (workloadBillabilityData !== undefined) {
      rows.push(
        ...[
          {
            // fake aggregate unit
            organizationNumber: workloadBillabilityData.organizationNumber,
            name: workloadBillabilityData.name,
            principal: '',
            level: 9999,
            height: 0,
            isPerson: false,
            relativeFrequencyByCategory: workloadBillabilityData.relativeFrequencyByCategory,
            units: [],
          },
          ...workloadBillabilityData.units,
        ].map((unit, r) => ({
          height: unit.height,
          level: unit.level,
          key: `unit_row_${r}`,
          name: unit.name,
          items: [
            // name, then frequencies
            unit.name,
            ...unit.relativeFrequencyByCategory.map(formatFrequency),
          ].map((text, c) => ({
            key: `unit_cell_${r}_${c}`,
            content: <Text content={text} />,
            styles: (data: ComponentStyleFunctionParam) => ({
              ...(c === 0 && unit.height > 0 && billabilityClickableCellStyle(data)),
              ...(c < 1 ? billabilityNameColumnStyle(data) : billabilityFrequencyColumnStyle(data)),
            }),
          })),
          style: {
            cursor: unit.height > 0 ? 'pointer' : 'default',
          },
          onClick: () => {
            if (unit.height === 0) return;
            drillDown(unit);
          },
        }))
      );
    }

    return rows;
  }, [drillDown, workloadBillabilitySelectedData]);

  const header = useMemo(
    () => ({
      items: [
        {
          key: 'header_organisation',
          content: <Text content={t('dashboard-executives.department-workload-team')} />,
          styles: billabilityNameHeaderStyle,
        },
        ...(workloadBillabilityWholeTreeData?.categories.map((category) => ({
          key: `header_${category}`,
          content: <Tooltip content={category} trigger={<Text content={category} />} />,
          styles: billiabilityFrequencyHeaderStyle,
        })) ?? []),
      ],
    }),
    [t, workloadBillabilityWholeTreeData?.categories]
  );

  return (
    <Flex column gap="gap.medium">
      <Text
        weight="semibold"
        size="large"
        content={t('dashboard-executives.billability-table')}
      ></Text>
      <Table styles={outlookTableStyle} header={header} rows={rows} />
    </Flex>
  );
};
