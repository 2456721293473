import { atom } from 'recoil';

export const selectedResourceRequestAtom = atom<string | undefined>({
  key: 'selectedResourceRequestAtom',
  default: undefined,
});

export const selectedRequestFilterAtom = atom<{
  open: boolean;
}>({
  key: 'selectedRequestFilter',
  default: {
    open: true,
  },
});
