import { Flex } from '@fluentui/react-northstar';
import { FunctionComponent } from 'react';
import BasicLayout from '../components/Layout/Layout';
import { ResourceRequestFilter } from '../components/ResourceRequestFilter/ResourceRequestFilter';
import { ResourceTable } from '../components/ResourceTable/ResourceTable';
import { ResourceTableDetailPane } from '../components/ResourceTable/ResourceTableDetailPane/ResourceTableDetailPane';

export const ResourceRequestPage: FunctionComponent = () => {
  return (
    <BasicLayout>
      <ResourceRequestFilter />
      <Flex>
        <ResourceTable />
        <ResourceTableDetailPane />
      </Flex>
    </BasicLayout>
  );
};
