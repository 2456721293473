import Markdown from 'marked-react';
import { useTranslation } from 'react-i18next';
import { ReleaseNotesDto } from '../../data-access/open-api/queries/models/ReleaseNotesDto';
import { Ti8mDialog } from '../Common/Ti8mDialog';
import { useEffect, useRef } from 'react';

type ReleaseNotesDialogProps = {
  open: boolean;
  releaseNotes: ReleaseNotesDto[];
  onClose: () => void;
};

/**
 * Simple dialog, that renders the provided Release Notes.
 * The content of the individual notes are rendered as Markdown.
 */
export const ReleaseNotesDialog = ({ open, onClose, releaseNotes }: ReleaseNotesDialogProps) => {
  const { t } = useTranslation();
  const buttonRef = useRef<HTMLButtonElement>();

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.focus();
    }
  }, [open]);

  return (
    <Ti8mDialog
      header={t('release-notes.title')}
      open={open}
      onCancel={onClose}
      cancelButton={{
        ref: buttonRef,
        primary: true,
        content: t('release-notes.close-button'),
      }}
      content={releaseNotes.map((note, i) => {
        return (
          <div key={`note_${i}`} style={{ marginBottom: '2rem' }}>
            <h2 style={{ marginBottom: '4px' }}>{note.releaseDate.toLocaleDateString()}</h2>
            <div>
              <small>
                Build: {note.buildVersion}, Teams: {note.teamsVersion}
              </small>
            </div>
            <Markdown>{note.content || undefined}</Markdown>
          </div>
        );
      })}
    />
  );
};
