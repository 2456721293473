import { ComponentSlotStyle } from '@fluentui/react-northstar';

export const transparentTableStyle: ComponentSlotStyle = () => ({
  '> .ui-table__row > .ui-table__cell': {
    paddingLeft: '0',
    paddingRight: '0',
  },
  '> .ui-table__row': {
    borderColor: 'transparent',
    backgroundColor: 'transparent',
  },
  '> .ui-table__row:hover': {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
  },
});
