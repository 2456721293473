import { AddIcon, Button, CheckmarkCircleIcon, Flex, Text } from '@fluentui/react-northstar';
import { generateUUID } from '@microsoft/microsoft-graph-client/lib/es/src/middleware/MiddlewareUtil';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Ti8mDialog } from '../Common/Ti8mDialog';
import { resourceButtonStyles } from '../SearchWithFilter/searchWithFilterStyles';
import { ResourceRequestDialog } from './ResourceRequestDialog/ResourceRequestDialog';
import { ResourceRequestMenu } from './ResourceRequestMenu';

export const ResourceRequestFilter = () => {
  const { t } = useTranslation();
  const navigate = useHistory();
  const [isRequestDialogOpen, setRequestDialogVisibility] = useState<boolean>(false);
  const [isSuccessDialogOpen, setSuccessDialogVisibility] = useState<boolean>(false);
  const [newResourceRequestId] = useState<string>(generateUUID());

  const handleCloseRequest = () => {
    setRequestDialogVisibility(false);
    setSuccessDialogVisibility(false);
  };

  const handleHeaderActionRequest = () => {
    setRequestDialogVisibility(false);
  };

  const handleCloseSuccessDialog = () => {
    setSuccessDialogVisibility(false);
    navigate.push({ pathname: '/resourceRequests' });
  };

  const handleConfirmSuccessDialog = () => {
    setSuccessDialogVisibility(false);
    navigate.push({
      pathname: `/resourceRequestDetailView/${newResourceRequestId}`,
    });
  };

  return (
    <>
      <ResourceRequestMenu />
      <Button
        icon={<AddIcon />}
        content={t('resources.add-request')}
        primary
        flat
        styles={resourceButtonStyles}
        size="medium"
        onClick={() => setRequestDialogVisibility(true)}
      />
      {isRequestDialogOpen && (
        <ResourceRequestDialog
          newResourceRequestId={newResourceRequestId}
          isDialogOpen={isRequestDialogOpen}
          handleCloseRequest={handleCloseRequest}
          handleHeaderActionRequest={handleHeaderActionRequest}
          setSuccessDialogVisibility={setSuccessDialogVisibility}
        />
      )}
      <Ti8mDialog
        header={t('resources.request-dialog.success-dialog.header')}
        open={isSuccessDialogOpen}
        content={
          <Flex column gap="gap.large" hAlign="center">
            <CheckmarkCircleIcon size="largest" styles={{ color: '#6264A7' }} />
            <Flex column hAlign="center">
              <Text content={t('resources.request-dialog.success-dialog.content-1')} />
              <Text content={t('resources.request-dialog.success-dialog.content-2')} />
            </Flex>
          </Flex>
        }
        confirmButton={t('resources.request-dialog.success-dialog.confirm-button')}
        onConfirm={handleConfirmSuccessDialog}
        cancelButton={t('resources.request-dialog.success-dialog.cancel-button')}
        onCancel={handleCloseSuccessDialog}
      />
    </>
  );
};
