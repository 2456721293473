import React, { useState, useCallback } from 'react';
import BasicLayout from '../components/Layout/Layout';
import { NavigationButton } from '../components/ResourceRequestDetailView/NavigationButton';
import { Flex, Grid, Loader, Text } from '@fluentui/react-northstar';
import { ResourceRequestDetailViewButtonBar } from '../components/ResourceRequestDetailView/ResourceRequestDetailViewButtonBar';
import { ProjectDetails } from '../components/ResourceRequestDetailView/ProjectDetails';
import { useTranslation } from 'react-i18next';
import { useResourceRequestData } from '../components/ResourceTable/hooks/useResourceRequestData';
import { ResourceRequestDto } from '../data-access/open-api/queries/models/ResourceRequestDto';
import { convertYearMonthDay } from '../utils/dateConverter';
import { ResourceRequestDtoCommand } from '../data-access/open-api/commands/models/ResourceRequestDtoCommand';
import { RoleRequestsDtoCommand } from '../data-access/open-api/commands/models/RoleRequestsDtoCommand';
import {
  useDeleteResourceRequest,
  useUpdateResourceRequest,
} from '../data-access/hooks/commands/use-update-resource-requests';
import { CloseRequestData } from '../components/ResourceRequestDetailView/CloseRequestDialog/model/CloseRequestData';
import { Ti8mCard } from '../components/Common/Ti8mCard';
import { useParams } from 'react-router-dom';
import {
  mapResourceRequestDtoToCommand,
  mapRoleRequestsDtoToCommand,
} from '../components/ResourceRequestFilter/ResourceRequestDialog/resourceRequestMapper';
import { ResourceRequestDetailViewRoles } from '../components/ResourceRequestDetailView/ResourceRequestDetailViewRoles';
import { ResourceRequestComments } from '../components/ResourceRequestDetailView/ResourceRequestComments';

export const ResourceRequestDetailView = () => {
  const { t } = useTranslation();
  const { resourceRequestId: resourceRequestIdParam } = useParams<{
    resourceRequestId: string;
  }>();

  const { mutate: closeResourceRequest } = useUpdateResourceRequest(t('alert.update-types.closed'));
  const { mutate: deleteResourceRequest } = useDeleteResourceRequest(
    t('alert.request-objects.request'),
    t('alert.update-types.delete')
  );

  const [isButtonsVisible, setButtonsVisibility] = useState<boolean>(true);

  // load data from backend:
  const resourceRequest: ResourceRequestDto | undefined = useResourceRequestData({
    resourceRequestId: resourceRequestIdParam,
  });

  // For Close Request Dialog:
  const [closeRequestData, setCloseRequestData] = useState<CloseRequestData>({
    reason: '',
    date: '',
    description: '',
  });
  const onCloseResourceRequest = useCallback(() => {
    const roleRequests: RoleRequestsDtoCommand[] =
      resourceRequest !== undefined
        ? mapRoleRequestsDtoToCommand(resourceRequest.roleRequests)
        : [];
    const closingInfo = {
      reason: closeRequestData.reason,
      date: convertYearMonthDay(new Date()),
      description: closeRequestData.description,
    };

    const updatedResourceRequest: ResourceRequestDtoCommand = mapResourceRequestDtoToCommand(
      resourceRequest,
      roleRequests
    );
    Object.assign(updatedResourceRequest, { closingInfo: closingInfo });

    closeResourceRequest({
      id: resourceRequest !== undefined ? resourceRequest.id : '',
      requestBody: updatedResourceRequest,
    });
  }, [closeRequestData, closeResourceRequest, resourceRequest]);

  // For Delete Request:
  const onDeleteResourceRequest = () => {
    deleteResourceRequest({
      id: resourceRequest !== undefined ? resourceRequest.id : '',
    });
    setButtonsVisibility(false);
  };

  return (
    <BasicLayout>
      <NavigationButton goBack={true} />
      <Ti8mCard
        style={{
          marginTop: '46px',
          flex: '1',
          minWidth: '60rem', // this breaks on small screens, but the layout does not adjust currently
        }}
      >
        {resourceRequest ? (
          <Flex column gap={'gap.large'}>
            <Grid columns="repeat(8, 12.5%)">
              <Flex column vAlign="end" styles={{ gridColumn: '1 / 4', gridRow: '1' }}>
                <Text weight="semibold" size="large">
                  {resourceRequest.project}
                </Text>
                <Text>{resourceRequest.client}</Text>
              </Flex>
              <Flex vAlign="end" hAlign="end" styles={{ gridColumn: '5 /  9', gridRow: '1' }}>
                <ResourceRequestDetailViewButtonBar
                  onCloseResourceRequest={onCloseResourceRequest}
                  closeRequestData={closeRequestData}
                  setCloseRequestData={setCloseRequestData}
                  editRequestData={resourceRequest}
                  onDeleteResourceRequest={onDeleteResourceRequest}
                  isButtonsVisible={isButtonsVisible}
                  setButtonsVisibility={setButtonsVisibility}
                />
              </Flex>
            </Grid>
            <ProjectDetails resourceRequest={resourceRequest} />
            <ResourceRequestDetailViewRoles
              resourceRequest={resourceRequest}
              roleRequests={resourceRequest.roleRequests}
              isButtonsVisible={isButtonsVisible}
            />
            <ResourceRequestComments
              resourceRequestId={resourceRequest.id}
              comments={resourceRequest.comments}
              isButtonsVisible={isButtonsVisible}
            />
          </Flex>
        ) : (
          <Loader />
        )}
      </Ti8mCard>
    </BasicLayout>
  );
};
