import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { NodeNavigation } from '../models/NavigationPointer';
import { executivesDashboardNavigationPointerAtom } from '../../../state/atoms';

interface TreeNode {
  organizationNumber: number;
  units?: TreeNode[];
}

function findNodesByPointerList<T extends TreeNode>(
  treeData: { roots: T[] } | undefined,
  pointerList: { organizationNumber: number }[]
): T[] {
  if (!treeData || pointerList.length === 0) {
    return [];
  }

  let currentLevelData = treeData.roots;
  const result: T[] = [];

  for (let i = 0; i < pointerList.length; i++) {
    const pointer = pointerList[i];
    const node = currentLevelData.find((n) => n.organizationNumber === pointer.organizationNumber);

    if (!node) break;

    if (i === pointerList.length - 1) {
      result.push(node);
    }

    currentLevelData = (node.units as T[] | undefined) ?? [];
  }

  return result;
}

export function useExecutivesDashboardNodeNavigation<T extends TreeNode>(
  treeData: { roots: T[] } | undefined
): T[] {
  const [navigationPointer] = useRecoilState<NodeNavigation>(
    executivesDashboardNavigationPointerAtom
  );
  const [result, setResult] = useState<T[]>([]);

  useEffect(() => {
    if (navigationPointer.length > 0) {
      const fetchedNodes = findNodesByPointerList(treeData, navigationPointer);
      setResult(fetchedNodes);
    }
  }, [treeData, navigationPointer]);

  return result;
}
