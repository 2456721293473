export const workloads = [
  '10 %',
  '20 %',
  '30 %',
  '40 %',
  '50 %',
  '60 %',
  '70 %',
  '80 %',
  '90 %',
  '100 %',
];
