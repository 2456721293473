import { ComponentSlotStyle } from '@fluentui/react-northstar';

export const dialogStyle: ComponentSlotStyle = () => {
  return {
    '& .ui-dialog__content': {
      maxHeight: 616,
      overflowY: 'scroll',
    },
  };
};
