import { DropdownProps, Flex, Grid, Text } from '@fluentui/react-northstar';
import { Ti8mDropdown } from '../../Common/Ti8mDropdown';
import { Ti8mDatepicker } from '../../Common/Ti8mDatepicker';
import { workloads } from '../../../data/workloads';
import { useCallback, useMemo, useState } from 'react';
import { skillsItemStyle } from './roleDialogFormStyles';
import { useTranslation } from 'react-i18next';
import { useInfiniteSkills } from '../../../data-access/hooks/queries/use-infinite-skills';
import { SkillsViewModel } from './viewModels/SkillsViewModel';
import { convertWorkload } from '../../../utils/workloadConverter';
import { useRoles } from '../../../data-access/hooks/queries/use-roles';
import { RoleRequestDto } from '../../../data-access/open-api/queries/models/RoleRequestDto';

interface ProjectRolesDialogFormProps {
  role: RoleRequestDto;
  setRole: (role: RoleRequestDto) => void;
}

export const ProjectRolesDialogForm = ({ role, setRole }: ProjectRolesDialogFormProps) => {
  const { t } = useTranslation();
  const [skillSearchString, setSkillSearchString] = useState('');
  const { skills, fetchMoreSkills, hasMoreSkills, isSkillsError } =
    useInfiniteSkills(skillSearchString);
  const onSkillsSearchQueryChange = useCallback((data: DropdownProps) => {
    if (data.searchQuery !== undefined) {
      setSkillSearchString(data.searchQuery);
    }
  }, []);
  const { data: rolesDropDown, error: rolesError } = useRoles();
  const skillsDropDown = useMemo(
    () => skills.map((skill) => ({ key: skill.id, header: skill.name })),
    [skills]
  );

  const onRoleChanged = (roleName: string) => {
    setRole({ ...role, role: roleName });
  };
  const onRoleStartDateChanged = (startDate: Date) => {
    setRole({ ...role, roleStart: startDate.toISOString() });
  };
  const onRoleEndDateChanged = (endDate: Date) => {
    setRole({ ...role, roleEnd: endDate.toISOString() });
  };
  const onWorkloadChanged = (workload: string) => {
    setRole({ ...role, workload: convertWorkload(workload) });
  };
  const onSkillChanged = (data: DropdownProps) => {
    const selectedValues = data.value as Array<{ header: string; key: string }>;
    const skills: Array<SkillsViewModel> = [];
    selectedValues.forEach((skill) => skills.push({ id: skill.key, name: skill.header }));
    setRole({ ...role, skills: skills });
  };

  return (
    <>
      <Flex column>
        <Text weight="semibold" content={t('resources.request-dialog.role-dialog.dd-role-label')} />
        <Ti8mDropdown
          items={rolesDropDown ?? []}
          hasDataError={rolesError != null}
          placeholder={t('resources.request-dialog.role-dialog.dd-role-placeholder')}
          onItemChange={(data) => onRoleChanged(data.value as string)}
          search={true}
          value={role.role}
        />
      </Flex>
      <Grid columns="repeat(auto-fit, minmax(13rem, 1fr))" styles={{ gap: '1rem' }}>
        <Flex column>
          <Text weight="semibold" content={t('resources.request-dialog.start-date-label')} />
          <Ti8mDatepicker
            placeholder={t('resources.request-dialog.date-placeholder')}
            onDateChange={(date) => onRoleStartDateChanged(date ?? new Date())}
            defaultSelectedDate={new Date(role.roleStart)}
          />
        </Flex>
        <Flex column>
          <Text weight="semibold" content={t('resources.request-dialog.end-date-label')} />
          <Ti8mDatepicker
            placeholder={t('resources.request-dialog.date-placeholder')}
            onDateChange={(date) => onRoleEndDateChanged(date ?? new Date())}
            defaultSelectedDate={new Date(role.roleEnd)}
          />
        </Flex>
        <Flex column>
          <Text
            weight="semibold"
            content={t('resources.request-dialog.role-dialog.dd-workload-label')}
          />
          <Ti8mDropdown
            items={workloads}
            placeholder={t('resources.request-dialog.role-dialog.dd-workload-placeholder')}
            onItemChange={(data) => onWorkloadChanged(data.value as string)}
            value={role.workload}
          />
        </Flex>
      </Grid>
      <Grid columns="1" styles={{ gap: '1rem' }}>
        <Flex column>
          <Text
            weight="semibold"
            content={t('resources.request-dialog.role-dialog.dd-skills-label')}
          />
          <Ti8mDropdown
            items={skillsDropDown}
            canLoadMoreItems={hasMoreSkills}
            onScrollLoadMoreItems={fetchMoreSkills}
            onSearchQueryChange={onSkillsSearchQueryChange}
            hasDataError={isSkillsError}
            multiple={true}
            position="above"
            align="top"
            placeholder={t('resources.request-dialog.role-dialog.dd-skills-placeholder')}
            search={true}
            renderSelectedItem={(SelectedItem, props) => (
              <SelectedItem {...props} header={`${props.header}`} styles={skillsItemStyle} />
            )}
            value={role.skills.map((skill) => ({ key: skill.id, header: skill.name }))}
            onItemChange={onSkillChanged}
          />
        </Flex>
        {/* TODO: implement Focus when ready in backend */
        /*
        <Flex column>
          <Text
            weight="semibold"
            content={t('resources.request-dialog.role-dialog.dd-focus-label')}
          />
          <Ti8mDropdown
            items={focusDropDown}
            placeholder={t('resources.request-dialog.role-dialog.dd-focus-placeholder')}
            value={role.focus}
            onItemChange={(data) => onFocusChanged(data.value as string)}
          />
        </Flex>
        */}
      </Grid>
    </>
  );
};
