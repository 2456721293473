import { t } from 'i18next';
import { useMemo } from 'react';
import { WorkloadBarChart } from '../WorkloadBarChart';
import { useBillabilityPerMonthByOrganisation } from '../../../data-access/hooks/queries/use-billability-per-month-by-organisation';
import { getMonthNameShort } from '../../../utils/dateConverter';
import { useRecoilState } from 'recoil';
import {
  executivesDashboardDateNavigationAtom,
  executivesDashboardNavigationPointerAtom,
} from '../../../state/atoms';
import { NodeNavigation } from '../models/NavigationPointer';

export const OutlookBillabilityChart = () => {
  const getMonthNumName = (month: number) => getMonthNameShort(new Date(0, month, 0));

  const [selectedDate] = useRecoilState<Date>(executivesDashboardDateNavigationAtom);
  const [navigationPointer] = useRecoilState<NodeNavigation>(
    executivesDashboardNavigationPointerAtom
  );

  const { data: workloadBillabilityDrillDownOrganisation } = useBillabilityPerMonthByOrganisation({
    organizationNumber:
      navigationPointer.length > 0
        ? navigationPointer[navigationPointer.length - 1].organizationNumber
        : undefined,
    startYear: selectedDate.getFullYear(),
    startMonth: selectedDate.getMonth() + 1,
    monthCount: 6,
    precision: 2,
  });

  const barChartData = useMemo(
    () =>
      workloadBillabilityDrillDownOrganisation?.map((month) => ({
        name: getMonthNumName(month.month),
        ...Object.fromEntries(
          month.categoryRelativeFrequency?.map((relativeFrequency) => [
            relativeFrequency.categoryName,
            relativeFrequency.frequency,
          ]) ?? []
        ),
      })) ?? [],
    [workloadBillabilityDrillDownOrganisation]
  );

  const barChartDataTooltips = useMemo(
    () =>
      // The tooltips cant be part of the object in the bar chunk.
      // Here the tooltips (subcategory relative frequencies of the relative frequencies) are grouped by month
      // and then by the dynamic property key so the correct tooltip can be loaded per month
      workloadBillabilityDrillDownOrganisation
        ?.map((month) =>
          month.categoryRelativeFrequency
            ?.map((relativeFrequency) => {
              const labels: { [key: string]: string } = {};
              Object.getOwnPropertyNames(relativeFrequency.subcategoryRelativeFrequencies).forEach(
                (prop) => {
                  const data = relativeFrequency.subcategoryRelativeFrequencies[prop];

                  labels[prop] = `${data.percentage.toFixed(1)}% (${data.numberOfEmployees})`;
                }
              );

              return {
                [`${relativeFrequency.categoryName}`]: {
                  [getMonthNumName(month.month)]: labels,
                },
              };
            })
            .reduce((r, c) => ({ ...r, ...c }), {})
        )
        .reduce((r, c) => {
          const result = { ...r };
          const current = { ...c };
          const resultProps = Object.getOwnPropertyNames(result);
          resultProps.forEach((prop) => {
            result[prop] = { ...result[prop], ...current[prop] };
          });
          const currentProps = Object.getOwnPropertyNames(current).filter(
            (p) => !resultProps.includes(p)
          );
          currentProps.forEach((prop) => {
            result[prop] = { ...result[prop], ...current[prop] };
          });
          return result;
        }, {}),
    [workloadBillabilityDrillDownOrganisation]
  );

  return (
    <WorkloadBarChart
      title={t('dashboard-executives.billability-chart')}
      chartData={barChartData}
      tooltips={barChartDataTooltips}
    />
  );
};
