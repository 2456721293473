import * as CommandParams from './command-params';
import { ApiError, ResourceRequestService } from '../../open-api/commands';
import { env } from '../../../utils/env';
import { useMutation, useQueryClient } from 'react-query';
import { QueryKeys } from '../../query-keys';
import { Result } from '../../open-api/commands/models/Result';
import { ResourceRequestService as QueryResourceRequestService } from '../../open-api/queries';
import { waitForEvent } from './wait-for-event';
import { PotentialEmployeeStatus } from '../../../enum/PotentialEmployeeStatus';

const updatePotentialEmployeeStateSelect = (
  input: CommandParams.UpdatePotentialEmployeeStateSelectInput
) =>
  ResourceRequestService.updatePotentialEmployeeStateSelect({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const updatePotentialEmployeeStateContact = (
  input: CommandParams.UpdatePotentialEmployeeStateContactInput
) =>
  ResourceRequestService.updatePotentialEmployeeStateContact({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const updatePotentialEmployeeStateAcceptByLead = (
  input: CommandParams.UpdatePotentialEmployeeStateAcceptByLeadInput
) =>
  ResourceRequestService.updatePotentialEmployeeStateAcceptByLead({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const updatePotentialEmployeeStateRejectByLead = (
  input: CommandParams.UpdatePotentialEmployeeStateRejectByLeadInput
) =>
  ResourceRequestService.updatePotentialEmployeeStateRejectByLead({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const updatePotentialEmployeeStateSubmit = (
  input: CommandParams.UpdatePotentialEmployeeStateSubmitInput
) =>
  ResourceRequestService.updatePotentialEmployeeStateSubmit({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const updatePotentialEmployeeStateRejectByCustomerWithoutInterview = (
  input: CommandParams.UpdatePotentialEmployeeStateRejectedByCustomerWithoutInterviewInput
) =>
  ResourceRequestService.updatePotentialEmployeeStateRejectedByCustomerWithoutInterview({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const updatePotentialEmployeeStateInterview = (
  input: CommandParams.UpdatePotentialEmployeeStateInterviewInput
) =>
  ResourceRequestService.updatePotentialEmployeeStateInterview({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const updatePotentialEmployeeStateRejectByCustomer = (
  input: CommandParams.UpdatePotentialEmployeeStateRejectedByCustomerInput
) =>
  ResourceRequestService.updatePotentialEmployeeStateRejectedByCustomer({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const updatePotentialEmployeeStateAcceptByCustomer = (
  input: CommandParams.UpdatePotentialEmployeeStateAcceptedByCustomerInput
) =>
  ResourceRequestService.updatePotentialEmployeeStateAcceptedByCustomer({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const suggestPotentialEmployee = (input: CommandParams.SuggestPotentialEmployeeInput) =>
  ResourceRequestService.suggestPotentialEmployee({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const suggestPotentialEmployeeByLead = (input: CommandParams.SuggestPotentialEmployeeInput) =>
  ResourceRequestService.suggestPotentialEmployeeByLead({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

const getEventPosition = (id: string) =>
  QueryResourceRequestService.getEventPosition({
    version: env('REACT_APP_API_VERSION'),
    id,
  });

type GuidResult = Result & {
  readonly resourceRequestId: string;
  readonly roleRequestId: string;
};

type Input = {
  resourceRequestId: string;
  roleRequestId: string;
  employeeId: string;
  stateChange: PotentialEmployeeStatus;
};

export const useUpdatePotentialEmployeeStateChange = (requestObject: string, type: string) => {
  const queryClient = useQueryClient();

  return useMutation<GuidResult, ApiError, Input>(
    async (input) => {
      let result;

      switch (input.stateChange) {
        case PotentialEmployeeStatus.SELECTED:
          result = await updatePotentialEmployeeStateSelect(input);
          break;
        case PotentialEmployeeStatus.LEAD_CONTACTED:
          result = await updatePotentialEmployeeStateContact(input);
          break;
        case PotentialEmployeeStatus.ACCEPTED_BY_LEAD:
          result = await updatePotentialEmployeeStateAcceptByLead(input);
          break;
        case PotentialEmployeeStatus.REJECTED_BY_LEAD:
          result = await updatePotentialEmployeeStateRejectByLead(input);
          break;
        case PotentialEmployeeStatus.SUBMITTED_TO_CUSTOMER:
          result = await updatePotentialEmployeeStateSubmit(input);
          break;
        case PotentialEmployeeStatus.REJECTED_BY_CUSTOMER_WITHOUT_INTERVIEW:
          result = await updatePotentialEmployeeStateRejectByCustomerWithoutInterview(input);
          break;
        case PotentialEmployeeStatus.INTERVIEW_PENDING:
          result = await updatePotentialEmployeeStateInterview(input);
          break;
        case PotentialEmployeeStatus.ACCEPTED_BY_CUSTOMER:
          result = await updatePotentialEmployeeStateAcceptByCustomer(input);
          break;
        case PotentialEmployeeStatus.REJECTED_BY_CUSTOMER:
          result = await updatePotentialEmployeeStateRejectByCustomer(input);
          break;
      }

      return {
        ...result,
        resourceRequestId: input.resourceRequestId,
        roleRequestId: input.roleRequestId,
      };
    },
    {
      onSuccess: ({ storePosition, resourceRequestId, roleRequestId }) =>
        waitForEvent({ id: resourceRequestId, storePosition }, getEventPosition).then(() => {
          queryClient.refetchQueries([QueryKeys.ResourceRequest, resourceRequestId]);
          queryClient.refetchQueries([
            QueryKeys.RoleRequests,
            { resourceRequestId, roleRequestId },
          ]);
        }),
      meta: {
        type: type,
        requestObject: requestObject,
      },
    }
  );
};

export const useSuggestPotentialEmployee = (requestObject: string, type: string) => {
  const queryClient = useQueryClient();

  return useMutation<GuidResult, ApiError, CommandParams.SuggestPotentialEmployeeInput>(
    async (input) => {
      const result = await suggestPotentialEmployee(input);

      return {
        ...result,
        resourceRequestId: input.resourceRequestId,
        roleRequestId: input.roleRequestId,
      };
    },
    {
      onSuccess: ({ storePosition, resourceRequestId, roleRequestId }) =>
        waitForEvent({ id: resourceRequestId, storePosition }, getEventPosition).then(() => {
          queryClient.refetchQueries([QueryKeys.ResourceRequest, resourceRequestId]);
          queryClient.refetchQueries([
            QueryKeys.RoleRequests,
            { resourceRequestId, roleRequestId },
          ]);
        }),
      meta: {
        type: type,
        requestObject: requestObject,
      },
    }
  );
};

export const useSuggestPotentialEmployeeByLead = (requestObject: string, type: string) => {
  const queryClient = useQueryClient();

  return useMutation<GuidResult, ApiError, CommandParams.SuggestPotentialEmployeeByLeadInput>(
    async (input) => {
      const result = await suggestPotentialEmployeeByLead(input);

      return {
        ...result,
        resourceRequestId: input.resourceRequestId,
        roleRequestId: input.roleRequestId,
      };
    },
    {
      onSuccess: ({ storePosition, resourceRequestId, roleRequestId }) =>
        waitForEvent({ id: resourceRequestId, storePosition }, getEventPosition).then(() => {
          queryClient.refetchQueries([QueryKeys.ResourceRequest, resourceRequestId]);
          queryClient.refetchQueries([
            QueryKeys.RoleRequests,
            { resourceRequestId, roleRequestId },
          ]);
        }),
      meta: {
        type: type,
        requestObject: requestObject,
      },
    }
  );
};
