import { RoleRequestDto } from '../../../data-access/open-api/queries/models/RoleRequestDto';

export const buildSkillsString = (role: RoleRequestDto): string => {
  let skillsString = '';
  role.skills.forEach((skill, index) => {
    skillsString = skillsString.concat(skill.name);
    if (index <= role.skills.length - 2) {
      skillsString = skillsString.concat(', ');
    }
  });
  return skillsString;
};
