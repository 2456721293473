import { QueryFunctionContext, useInfiniteQuery } from 'react-query';
import { QueryKeys } from '../../query-keys';
import { withApiVersion, DEFAULT_TAKE, getNextPageParam, unpack } from '../../helpers';
import { EmployeeService } from '../../open-api/queries';

const getEmployees = (context: QueryFunctionContext) => {
  const withVersion = withApiVersion(EmployeeService.employees);
  return withVersion({
    searchString: `${context.queryKey[1]}`,
    skip: context.pageParam,
    organizationNumber: undefined,
    sortColumn: 'Name',
    sortDescending: false,
    hasEndDate: undefined,
    hasOpenEducation: undefined,
    hasProjectChangeRequest: undefined,
    take: DEFAULT_TAKE,
  });
};

export const useInfiniteEmployees = (searchString?: string) => {
  const { fetchNextPage, hasNextPage, data, isError } = useInfiniteQuery({
    queryKey: [QueryKeys.Employees, searchString],
    queryFn: getEmployees,
    getNextPageParam: getNextPageParam,
  });

  return {
    employees: unpack(data),
    hasMoreEmployees: hasNextPage,
    fetchMoreEmployees: fetchNextPage,
    isEmployeesError: isError,
  };
};
