import { useTranslation } from 'react-i18next';
import { Ti8mDialog } from '../../Common/Ti8mDialog';

export type StateChangeDialogProps = {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  title: string;
  content: string;
  confirmButtonText: string;
};

export const StateChangeDialog = ({
  open,
  onCancel,
  onConfirm,
  title,
  content,
  confirmButtonText,
}: StateChangeDialogProps) => {
  const { t } = useTranslation();

  return (
    <Ti8mDialog
      open={open}
      header={title}
      onConfirm={onConfirm}
      confirmButton={confirmButtonText}
      onCancel={onCancel}
      cancelButton={t('state-change-dialog.cancel')}
      content={content}
    />
  );
};
