import { FluentIconComponent, createNorthstarIcon } from './IconCreator';

// TODO: this icon is not structured to be used like this.
const PlanningIconRegular: FluentIconComponent = (props) => {
  return (
    <svg viewBox="0 0 192 192" {...props}>
      <rect
        x="48"
        y="48"
        width="28"
        height="28"
        rx="1"
        fill="none"
        stroke="currentColor"
        strokeWidth="6"
      />
      <path
        d="M82 49a1 1 0 0 1 1-1h26a1 1 0 0 1 1 1v94a1 1 0 0 1-1 1H83a1 1 0 0 1-1-1V49ZM116 114.568a.975.975 0 0 0 1.184.962C132.539 112.037 144 98.301 144 81.888c0-16.412-11.461-30.148-26.816-33.64a.974.974 0 0 0-1.184.96v65.36Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="6"
      />
    </svg>
  );
};
PlanningIconRegular.displayName = 'PlanningIconRegular';

const PlanningIconFilled: FluentIconComponent = (props) => {
  return (
    <svg viewBox="0 0 192 192" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="48" y="48" width="28" height="28" rx="1" fill="currentColor" stroke="currentColor" />
      <path
        d="M82 49a1 1 0 0 1 1-1h26a1 1 0 0 1 1 1v94a1 1 0 0 1-1 1H83a1 1 0 0 1-1-1V49ZM116 114.568a.975.975 0 0 0 1.184.962C132.539 112.037 144 98.301 144 81.888c0-16.412-11.461-30.148-26.816-33.64a.974.974 0 0 0-1.184.96v65.36Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  );
};
PlanningIconFilled.displayName = 'PlanningIconFilled';

export const PlanningIcon = createNorthstarIcon(PlanningIconRegular, PlanningIconFilled);
