import { ComponentSlotStyle, SiteVariablesPrepared, StatusProps } from '@fluentui/react-northstar';
import { always, cond, equals, T } from 'ramda';

export const statusStyle: ComponentSlotStyle<StatusProps> = (data) => {
  const borderColor = cond([
    [equals('success'), always(statusDarkColorVariable(data.theme.siteVariables).green)],
    [equals('error'), always(statusDarkColorVariable(data.theme.siteVariables).red)],
    [equals('warning'), always(statusDarkColorVariable(data.theme.siteVariables).yellow)],
    [equals('unknown'), always(statusDarkColorVariable(data.theme.siteVariables).unknown)],
    [equals(''), always(undefined)],
    [T, always(undefined)],
  ])(data.props.state ?? '');

  return {
    border: borderColor ? `1px solid ${borderColor}` : undefined,
  };
};

export const statusVariables = (data: SiteVariablesPrepared) => ({
  successBackgroundColor: successLightColorVariable(data).green,
  errorBackgroundColor: successLightColorVariable(data).red,
  warningBackgroundColor: successLightColorVariable(data).yellow,
  defaultBackgroundColor: successLightColorVariable(data).unknown,
});

export const statusDarkColorVariable = (data: SiteVariablesPrepared) => ({
  green: data.colors['green'][600],
  red: data.colors['red'][600],
  yellow: data.colors['yellow'][600],
  unknown: data.colors['grey'][600],
});

export const successLightColorVariable = (data: SiteVariablesPrepared) => ({
  green: data.colors['green'][100],
  red: data.colors['red'][100],
  yellow: data.colors['yellow'][100],
  unknown: data.colors['grey'][100],
});
