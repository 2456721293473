import { Avatar } from '@fluentui/react-northstar';
import React from 'react';

interface EmployeeAvatarProps {
  firstName: string;
  lastName: string;
  size?: 'small' | 'medium' | 'large';
  photo?: string;
}

export const EmployeeAvatar = React.memo(
  ({ firstName, lastName, size = 'small', photo }: EmployeeAvatarProps) => {
    const getName = () => {
      return `${firstName} ${lastName}`;
    };

    return <Avatar size={size} image={photo} name={getName()} />;
  }
);
