import { useTranslation } from 'react-i18next';
import { Ti8mDialog } from '../Common/Ti8mDialog';
import { useDeleteRoleRequest } from '../../data-access/hooks/commands/use-update-role-requests';
import { ResourceRequestDto } from '../../data-access/open-api/queries/models/ResourceRequestDto';

type DeleteRoleDialogProps = {
  isDeleteDialogOpen: boolean;
  setDeleteRoleDialogVisibility: (isDeleteDialogOpen: boolean) => void;
  resourceRequest?: ResourceRequestDto;
  roleRequestId: string;
};

export const DeleteRoleDialog = ({
  isDeleteDialogOpen,
  setDeleteRoleDialogVisibility,
  resourceRequest,
  roleRequestId,
}: DeleteRoleDialogProps) => {
  const { t } = useTranslation();
  const { mutate: deleteRole } = useDeleteRoleRequest();

  const onConfirmDeleteRole = () => {
    deleteRole({
      resourceRequestId: resourceRequest?.id ?? '',
      roleRequestId: roleRequestId,
    });
    setDeleteRoleDialogVisibility(false);
  };

  return (
    <Ti8mDialog
      showCloseHeaderAction={false}
      cancelButton={t('resources.request-dialog.cancel')}
      onCancel={() => setDeleteRoleDialogVisibility(false)}
      confirmButton={t('resources.request-dialog.delete')}
      onConfirm={onConfirmDeleteRole}
      header={t('resources.request-dialog.delete-header')}
      open={isDeleteDialogOpen}
      content={t('resources.request-dialog.delete-message')}
    />
  );
};
