import React, { FunctionComponent } from 'react';
import { Text } from '@fluentui/react-northstar';
import BasicLayout from '../components/Layout/Layout';

export const HelpPage: FunctionComponent = () => {
  return (
    <BasicLayout>
      <Text>
        Look{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://confluence.ti8m.ch/pages/viewpage.action?pageId=8037073"
        >
          here
        </a>{' '}
        to find solutions for your problem,
      </Text>
      <br />
      <Text>
        or create a Jira ticket{' '}
        <a target="_blank" rel="noreferrer" href="https://jira.ti8m.ch/projects/TT/summary">
          here
        </a>
      </Text>
    </BasicLayout>
  );
};
