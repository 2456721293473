/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmployeeProjectAssignmentDto } from '../models/EmployeeProjectAssignmentDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { EmployeeTemporaryStateDto } from '../models/EmployeeTemporaryStateDto';
import { Result } from '../models/Result';
import { Result_1 } from '../models/Result_1';
import { EmployeeProjectAssignmentsChangesDto } from '../models/EmployeeProjectsChangesDto';
import { getDateForRequest } from '../../../../utils/dateConverter';

export class EmployeeService {
  /**
   * @returns any Success
   * @throws ApiError
   */
  public static addEmployeeProject({
    employeeId,
    version,
    requestBody,
  }: {
    employeeId: string;
    version: string;
    requestBody?: EmployeeProjectAssignmentDto;
  }): CancelablePromise<Result | Result_1> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/c/api/v{version}/employees/{employeeId}/project-assignments',
      path: {
        employeeId: employeeId,
        version: version,
      },
      body: {
        ...requestBody,
        workloads: requestBody?.workloads.map((w) => {
          return {
            ...w,
            start: getDateForRequest(w.start),
            end: getDateForRequest(w.end),
          };
        }),
      },
      mediaType: 'application/json-patch+json',
    });
  }

  /**
   * @return any Success
   * @throws ApiError
   */
  public static changeEmployeeProject({
    id,
    version,
    requestBody,
  }: {
    id: string;
    version: string;
    requestBody: EmployeeProjectAssignmentDto;
  }): CancelablePromise<Result | Result_1> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/c/api/v{version}/employees/{id}/project-assignments/{employeeProjectId}',
      path: {
        id: id,
        employeeProjectId: requestBody.id,
        version: version,
      },
      body: {
        ...requestBody,
        workloads: requestBody?.workloads.map((w) => {
          return {
            ...w,
            start: getDateForRequest(w.start),
            end: getDateForRequest(w.end),
          };
        }),
      },
      mediaType: 'application/json-path+json',
    });
  }

  /**
   * @return any Success
   * @throws ApiError
   */
  public static editTemporaryState({
    id,
    version,
    requestBody,
  }: {
    id: string;
    version: string;
    requestBody: EmployeeTemporaryStateDto;
  }): CancelablePromise<Result | Result_1> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/c/api/v{version}/employees/{id}/temporarystate',
      path: {
        id: id,
        version: version,
      },
      body: requestBody,
      mediaType: 'application/json-path+json',
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static deleteEmployeeProject({
    employeeId,
    projectId,
    version,
  }: {
    employeeId: string;
    projectId: string;
    version: string;
  }): CancelablePromise<Result | Result_1> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/c/api/v{version}/employees/{employeeId}/projects/{projectId}',
      path: {
        employeeId: employeeId,
        projectId: projectId,
        version: version,
      },
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static updateEmployeeProjects({
    employeeId,
    version,
    requestBody,
  }: {
    employeeId: string;
    version: string;
    requestBody: EmployeeProjectAssignmentsChangesDto;
  }): CancelablePromise<Result | Result_1> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/c/api/v{version}/employees/{employeeId}/project-assignments/changes',
      path: {
        employeeId: employeeId,
        version: version,
      },
      body: {
        ...requestBody,
        modifiedProjectAssignmentsDtos: requestBody.modifiedProjectAssignmentsDtos?.map((m) => ({
          ...m,
          workloads: m.workloads.map((w) => {
            return {
              ...w,
              start: getDateForRequest(w.start),
              end: getDateForRequest(w.end),
            };
          }),
        })),
      },
      mediaType: 'application/json-path+json',
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static ignoreEmployee({
    employeeId,
    version,
  }: {
    employeeId: string;
    version: string;
  }): CancelablePromise<Result | Result_1> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/c/api/v{version}/employees/{employeeId}/ignore',
      path: {
        employeeId: employeeId,
        version: version,
      },
      mediaType: 'application/json-path+json',
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static unignoreEmployee({
    employeeId,
    version,
  }: {
    employeeId: string;
    version: string;
  }): CancelablePromise<Result | Result_1> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/c/api/v{version}/employees/{employeeId}/unignore',
      path: {
        employeeId: employeeId,
        version: version,
      },
      mediaType: 'application/json-path+json',
    });
  }
}
