import React, { FunctionComponent, useState } from 'react';
import { SearchWithFilter } from '../../components/SearchWithFilter/SearchWithFilter';
import BasicLayout from '../../components/Layout/Layout';
import { EmployeeTable } from '../../components/EmployeeTable/EmployeeTable';
import { Flex } from '@fluentui/react-northstar';
import { EmployeeTableDetailPane } from '../../components/EmployeeTable/EmployeeTableDetailPane/EmployeeTableDetailPane';
import { useParams } from 'react-router-dom';

export const IndexPage: FunctionComponent = () => {
  const { employeeId } = useParams<{ employeeId?: string }>();
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string | undefined>(employeeId);

  return (
    <BasicLayout>
      <SearchWithFilter />
      <Flex>
        <EmployeeTable
          onSelectedEmployeeChanged={setSelectedEmployeeId}
          selectedEmployee={selectedEmployeeId}
        />
        <EmployeeTableDetailPane employeeId={selectedEmployeeId} />
      </Flex>
    </BasicLayout>
  );
};
