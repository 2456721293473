import { useEmployee } from '../../../data-access/hooks/queries/use-employee';

export interface UseEmployeeDataHookParams {
  employeeId: string | null;
}

export type EmployeeData = ReturnType<typeof useEmployee>['data'];

export const useEmployeeData = ({
  employeeId,
}: UseEmployeeDataHookParams): EmployeeData | undefined => {
  const result = useEmployee(employeeId);
  return result.data;
};
