import { useState } from 'react';
import dayjs from 'dayjs';
import { useReleaseNotes } from '../../../data-access/hooks/queries/use-release-notes';

const LOCAL_STORAGE_KEY = 'last_seen_talents_planner_version';

/**
 * Custom hook, that only gets the release notes, that have changed since the last application start.
 *
 * This is done by remembering the last seen version in LocalStorage.
 * Also returns state to handle the visiblity of the (release notes) dialog.
 */
export const useFreshReleaseNotes = () => {
  const lastVersionItem = window.localStorage.getItem(LOCAL_STORAGE_KEY);

  const [showDialog, setShowDialog] = useState(true);
  const [noPreviousVersionInfo] = useState(lastVersionItem === null);

  const lastVersion = lastVersionItem ? parseInt(lastVersionItem, 10) : 0;

  const { data } = useReleaseNotes(lastVersion, (data) => {
    if (data && data.length > 0) {
      const versions = data.map((x) => x.releaseVersion);
      const maxVersion = Math.max(...versions);

      window.localStorage.setItem(LOCAL_STORAGE_KEY, `${maxVersion}`);
    }
  });

  let releaseNotes = data ? [...data] : [];
  releaseNotes?.sort((a, b) => b.releaseVersion - a.releaseVersion);

  if (noPreviousVersionInfo && data && data.length > 0) {
    const latest = releaseNotes[0];
    if (dayjs(latest.releaseDate).add(3, 'days').valueOf() >= dayjs().valueOf()) {
      releaseNotes = [latest];
    } else {
      releaseNotes = [];
    }
  }

  return {
    releaseNotes: releaseNotes,
    showDialog: showDialog && releaseNotes.length > 0,
    setShowDialog,
  };
};
