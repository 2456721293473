import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';
import { OpenAPI } from '../core/OpenAPI';
import { WorkloadRelativeFrequenciesPerMonthDtoIEnumerableResult } from '../models/WorkloadRelativeFrequenciesPerMonthDtoIEnumerableResult';
import { WorkloadRelativeFrequenciesForestDtoResult } from '../models/WorkloadRelativeFrequenciesForestDtoResult';
import { UnusedCapacityFtePerMonthForestDtoResult } from '../models/UnusedCapacityFtePerMonthForestDtoResult';

export class ChartsService {
  /**
   * @returns WorkloadRelativeFrequenciesForestDtoResult Success
   * @param version
   * @param startYear
   * @param startMonth
   * @param monthCount
   * @param precision
   */
  public static billabilityPerMonth({
    version,
    startYear,
    startMonth,
    monthCount,
    precision,
  }: {
    version: string;
    startYear?: number;
    startMonth?: number;
    monthCount?: number;
    precision?: number;
  }): CancelablePromise<WorkloadRelativeFrequenciesPerMonthDtoIEnumerableResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/charts/workload-relative-frequency-per-month',
      path: {
        version: version,
      },
      query: {
        startYear: startYear,
        startMonth: startMonth,
        monthCount: monthCount,
        precision: precision,
      },
    });
  }

  /**
   * @returns WorkloadRelativeFrequenciesForestDtoResult Success
   * @param version
   * @param organizationNumber
   * @param startYear
   * @param startMonth
   * @param monthCount
   * @param precision
   */
  public static billabilityPerMonthByOrganization({
    version,
    organizationNumber,
    startYear,
    startMonth,
    monthCount,
    precision,
  }: {
    version: string;
    organizationNumber?: number;
    startYear: number;
    startMonth: number;
    monthCount: number;
    precision: number;
  }): CancelablePromise<WorkloadRelativeFrequenciesPerMonthDtoIEnumerableResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/charts/workload-relative-frequency-per-month/{organizationNumber}',
      path: {
        version: version,
        organizationNumber: organizationNumber,
      },
      query: {
        startYear: startYear,
        startMonth: startMonth,
        monthCount: monthCount,
        precision: precision,
      },
    });
  }

  /**
   * @returns WorkloadRelativeFrequenciesForestDtoResult Success
   * @param version
   * @param startDate
   * @param endDate
   * @param precision
   * @param includeEmployees
   */
  public static billabilityDrillDown({
    version,
    startDate,
    endDate,
    precision,
    includeEmployees,
  }: {
    version: string;
    startDate?: string;
    endDate?: string;
    precision?: number;
    includeEmployees?: boolean;
  }): CancelablePromise<WorkloadRelativeFrequenciesForestDtoResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/charts/workload-relative-frequency-drill-down',
      path: {
        version: version,
      },
      query: {
        startDate: startDate,
        endDate: endDate,
        precision: precision,
        includeEmployees: includeEmployees,
      },
    });
  }

  /**
   * @returns WorkloadRelativeFrequenciesForestDtoResult Success
   * @param version
   * @param organizationNumber
   * @param startDate
   * @param endDate
   * @param precision
   * @param includeEmployees
   */
  public static billabilityDrillDownByOrganization({
    version,
    organizationNumber,
    startDate,
    endDate,
    precision,
    includeEmployees,
  }: {
    version: string;
    organizationNumber: number;
    startDate: string;
    endDate: string;
    precision: number;
    includeEmployees: boolean;
  }): CancelablePromise<WorkloadRelativeFrequenciesForestDtoResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/charts/workload-relative-frequency-drill-down/{organizationNumber}',
      path: {
        version: version,
        organizationNumber: organizationNumber,
      },
      query: {
        version: version,
        organizationNumber: organizationNumber,
        startDate: startDate,
        endDate: endDate,
        precision: precision,
        includeEmployees: includeEmployees,
      },
    });
  }

  /**
   * @returns UnusedCapacityFtePerMonthForestDtoResult Success
   * @param version
   * @param startYear
   * @param startMonth
   * @param monthCount
   * @param includeEmployees
   */
  public static ftePerMonthDrillDown({
    version,
    startYear,
    startMonth,
    monthCount,
    includeEmployees,
  }: {
    version: string;
    startYear: number;
    startMonth: number;
    monthCount: number;
    includeEmployees: boolean;
  }): CancelablePromise<UnusedCapacityFtePerMonthForestDtoResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/charts/unused-capacity-fte-per-month-drill-down',
      path: {
        version: version,
      },
      query: {
        startYear: startYear,
        startMonth: startMonth,
        monthCount: monthCount,
        includeEmployees: includeEmployees,
      },
    });
  }

  public static ftePerMonthDrillDownByOrganization({
    version,
    organizationNumber,
    startYear,
    startMonth,
    monthCount,
    includeEmployees,
  }: {
    version: string;
    organizationNumber: number;
    startYear: number;
    startMonth: number;
    monthCount: number;
    includeEmployees: boolean;
  }): CancelablePromise<UnusedCapacityFtePerMonthForestDtoResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/charts/unused-capacity-fte-per-month-drill-down/{organizationNumber}',
      path: {
        version: version,
        organizationNumber: organizationNumber,
      },
      query: {
        startYear: startYear,
        startMonth: startMonth,
        monthCount: monthCount,
        includeEmployees: includeEmployees,
      },
    });
  }
}
