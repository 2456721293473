import { Flex, Grid, Status, Text } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import { MonthlyCapacityDto } from '../../../data-access/open-api/queries';
import { getMonthNameShort } from '../../../utils/dateConverter';
import { statusStyle, statusVariables } from '../../Common/statusStyles';
import { statusGrid } from './capacityOutlookSectionStyles';

interface CapacityOutlookSectionProps {
  data: MonthlyCapacityDto[];
  employeePensum: number;
}

const getStatusMonth = (item: MonthlyCapacityDto) => getMonthNameShort(new Date(0, item.month, 1));

const getStatusValue = (item: MonthlyCapacityDto, pensum: number) => {
  if (item.hasResigned) return 'unknown';
  if (item.capacity === 0) return 'success';
  if (item.capacity === pensum) return 'error';
  return 'warning';
};

export const CapacityOutlookSection = ({ data, employeePensum }: CapacityOutlookSectionProps) => {
  const { t } = useTranslation();

  return (
    <Flex column gap="gap.smaller">
      <Text weight="semibold" size="large">
        {t('employee-overview-table-detail.capacity-outlook')}
      </Text>
      <Grid styles={statusGrid} columns="repeat(6, 1fr)" rows="repeat(2, 1fr)">
        {data.map((item) => (
          <Text key={`capacityoutlook_monthlabel_${item.month}`}>{getStatusMonth(item)}</Text>
        ))}
        {data.map((item) => (
          <Flex key={`capacityoutlook_status_${item.month}`} gap="gap.smaller" vAlign="center">
            <Status
              styles={statusStyle}
              variables={statusVariables}
              state={getStatusValue(item, employeePensum)}
            />
            <Text size="small">
              {item.hasResigned ? t('employee-overview-table-detail.left') : `${item.capacity}%`}
            </Text>
          </Flex>
        ))}
      </Grid>
    </Flex>
  );
};
