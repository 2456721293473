import { AcceptIcon, CloseIcon } from '@fluentui/react-northstar';
import { PotentialEmployeeStatus } from '../../../enum/PotentialEmployeeStatus';
import { Ti8mPill } from '../../Common/Ti8mPill';
import { statusPillStyle } from './potentialEmployeesDialogStyles';

export const mapStatusToIcon = (status?: PotentialEmployeeStatus) => {
  switch (status) {
    case PotentialEmployeeStatus.AVAILABLE:
      return (
        <Ti8mPill
          content={'Available'}
          maxTextLength={20}
          size="smaller"
          styles={statusPillStyle}
        />
      );
    case PotentialEmployeeStatus.LEAD_CONTACTED:
      return (
        <Ti8mPill
          content={'Contacted'}
          maxTextLength={20}
          size="smaller"
          styles={statusPillStyle}
        />
      );
    case PotentialEmployeeStatus.ACCEPTED_BY_LEAD:
      return (
        <Ti8mPill
          content={'by Lead'}
          maxTextLength={20}
          size="smaller"
          styles={statusPillStyle}
          icon={<AcceptIcon />}
        />
      );
    case PotentialEmployeeStatus.REJECTED_BY_LEAD:
      return (
        <Ti8mPill
          content={'by Lead'}
          maxTextLength={20}
          size="smaller"
          styles={statusPillStyle}
          icon={<CloseIcon />}
        />
      );
    case PotentialEmployeeStatus.SELECTED:
      return (
        <Ti8mPill content={'Selected'} maxTextLength={20} size="smaller" styles={statusPillStyle} />
      );
    case PotentialEmployeeStatus.SUBMITTED_TO_CUSTOMER:
      return (
        <Ti8mPill
          content={'Submitted'}
          maxTextLength={20}
          size="smaller"
          styles={statusPillStyle}
        />
      );
    case PotentialEmployeeStatus.REJECTED_BY_CUSTOMER_WITHOUT_INTERVIEW:
      return (
        <Ti8mPill
          content={'by Customer'}
          maxTextLength={20}
          size="smaller"
          styles={statusPillStyle}
          icon={<CloseIcon />}
        />
      );
    case PotentialEmployeeStatus.INTERVIEW_PENDING:
      return (
        <Ti8mPill
          content={'Interview'}
          maxTextLength={20}
          size="smaller"
          styles={statusPillStyle}
        />
      );
    case PotentialEmployeeStatus.REJECTED_BY_CUSTOMER:
      return (
        <Ti8mPill
          content={'by Customer'}
          maxTextLength={20}
          size="smaller"
          styles={statusPillStyle}
          icon={<CloseIcon />}
        />
      );
    case PotentialEmployeeStatus.ACCEPTED_BY_CUSTOMER:
      return (
        <Ti8mPill
          content={'by Customer'}
          maxTextLength={20}
          size="smaller"
          styles={statusPillStyle}
          icon={<AcceptIcon />}
        />
      );
    default:
      break;
  }
};
