import { atom, selector } from 'recoil';
import { DialogFormToggles } from '../../enum/DialogFormToggles';
import { LocalFilter } from '../../components/SearchWithFilter/LocalFilter';
import { FavouriteFilterDto } from '../../data-access/open-api/queries/models/FavouriteFilterDto';
import { concat } from 'ramda';

export interface SearchAtomState {
  // activeFilter?: FavouriteFilterDto | LocalFilter;
  // Only stores the activeFilterId here, because that makes it easier when the data gets refreshed.
  activeFilterId?: string | number;

  searchString?: string;

  isSearchDialogOpen: boolean;
  isSearchDialogInEditMode: boolean;

  savedFilters: FavouriteFilterDto[];
  unsavedFilters: LocalFilter[];
}

export type TogglesState = {
  [value in DialogFormToggles]: boolean;
};

export const searchAtom = atom<SearchAtomState>({
  key: 'searchStateAtom',
  default: {
    activeFilterId: undefined,

    isSearchDialogOpen: false,
    isSearchDialogInEditMode: false,
    searchString: undefined,

    savedFilters: [],
    unsavedFilters: [],
  },
});

export const allFiltersSelector = selector<Array<LocalFilter | FavouriteFilterDto>>({
  key: 'searchFilters',
  get: ({ get }) => {
    const search = get(searchAtom);
    const allFilters = concat(search.savedFilters, search.unsavedFilters);
    allFilters.sort((a, b) => a.filterName.localeCompare(b.filterName));
    return allFilters;
  },
});

export const activeFilterSelector = selector<LocalFilter | FavouriteFilterDto | undefined>({
  key: 'searchActiveFilter',
  get: ({ get }) => {
    const search = get(searchAtom);
    const filters = get(allFiltersSelector);
    if (!search.activeFilterId) {
      return undefined;
    } else {
      return filters.find((x) => x.id === search.activeFilterId);
    }
  },
});
