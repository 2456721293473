import { ChartsService } from '../../open-api/queries/services/ChartsService';
import { env } from '../../../utils/env';
import { useQuery, UseQueryOptions } from 'react-query';
import { QueryKeys } from '../../query-keys';
import { ApiError } from '../../open-api/queries';
import type * as QueryParams from './query-params';
import { UnusedCapacityFtePerMonthForestDtoResult } from '../../open-api/queries/models/UnusedCapacityFtePerMonthForestDtoResult';
import { UnusedCapacityFtePerMonthForestDto } from '../../open-api/queries/models/UnusedCapacityFtePerMonthForestDto';

const getFtePerMonthDrillDown = (input: QueryParams.ftePerMonthDrillDown) =>
  ChartsService.ftePerMonthDrillDown({
    version: env('REACT_APP_API_VERSION'),
    ...input,
  });

export const useFtePerMonthDrillDown = (
  queryParams: QueryParams.ftePerMonthDrillDown,
  queryOptions?: UseQueryOptions<
    UnusedCapacityFtePerMonthForestDtoResult,
    ApiError,
    UnusedCapacityFtePerMonthForestDto,
    [QueryKeys.FtePerMonthDrillDown, QueryParams.ftePerMonthDrillDown]
  >
) =>
  useQuery(
    [QueryKeys.FtePerMonthDrillDown, queryParams],
    ({ queryKey: [, input] }) => getFtePerMonthDrillDown(input),
    { select: (data) => data.payload, ...queryOptions }
  );
