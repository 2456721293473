import { ArrowRight16Regular, ArrowUp16Regular, ArrowUpRight16Regular } from '@fluentui/react-icons';
import { ProbabilityOfExtension } from '../../data-access/open-api/queries/models/EmployeeOverviewDto';
import { Tooltip } from '@fluentui/react-northstar';
import React from 'react';

interface ProbabilityOfExtensionIconProps {
    value?: ProbabilityOfExtension;
}

export const ProbabilityOfExtensionIcon = React.memo(
    ({ value }: ProbabilityOfExtensionIconProps) => {
        if (value === 'MostLikely') {
            return <Tooltip trigger={<ArrowUp16Regular />} content={value} />;
        } else if (value === 'Probably') {
            return <Tooltip trigger={<ArrowUpRight16Regular />} content={value} />;
        } else if (value === 'Maybe') {
            return <Tooltip trigger={<ArrowRight16Regular />} content={value} />;
        } else {
            return <></>;
        }
    }
)