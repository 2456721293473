import { AddIcon, Button, Flex, Text } from '@fluentui/react-northstar';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  suggestedEmployeesButton,
  suggestedEmployeesSubHeader,
} from './suggestedEmployeesTableStyles';
import SuggestEmployeeDialog from './SuggestEmployeeDialog';
import { Ti8mDialog } from '../../Common/Ti8mDialog';
import { PotentialEmployeesTable } from './PotentialEmployeesTable';
import { ResourceRequestDto } from '../../../data-access/open-api/queries/models/ResourceRequestDto';
import { RoleRequestDto } from '../../../data-access/open-api/queries/models/RoleRequestDto';

type PotentialEmployeesDialogProps = {
  open: boolean;
  onCancel: () => void;
  resourceRequest?: ResourceRequestDto;
  role: RoleRequestDto;
};

const PotentialEmployeesDialog = ({
  open,
  onCancel,
  resourceRequest,
  role,
}: PotentialEmployeesDialogProps) => {
  const { t } = useTranslation();
  const [isSuggestEmployeeDialogOpen, setSuggestEmployeeDialogVisibility] =
    useState<boolean>(false);

  return (
    <Ti8mDialog
      open={open}
      header={t('resources-detail-view.potential-employees')}
      onCancel={onCancel}
      resetOverflow
      content={
        <>
          <Flex column>
            <Flex column gap="gap.large">
              <PotentialEmployeesTable
                resourceRequest={resourceRequest}
                selectedRoleRequest={role}
                tableTitle={t('resources-detail-view.potential-employees-dialog.selected-header')}
                noItemsWarning={t('no-potential-employees.selected-employees-text')}
                isAvailable={false}
              />
              <PotentialEmployeesTable
                resourceRequest={resourceRequest}
                selectedRoleRequest={role}
                tableTitle={t('resources-detail-view.potential-employees-dialog.available-header')}
                noItemsWarning={t('no-potential-employees.available-employees-text')}
                isAvailable={true}
              />
            </Flex>
            <Text
              content={t(
                'resources-detail-view.potential-employees-dialog.employee-suggestions-sub-header'
              )}
              styles={suggestedEmployeesSubHeader}
            />
            <Button
              icon={<AddIcon size="small" />}
              size="small"
              primary={true}
              styles={suggestedEmployeesButton}
              onClick={() => setSuggestEmployeeDialogVisibility(true)}
            />
            <SuggestEmployeeDialog
              isDialogOpen={isSuggestEmployeeDialogOpen}
              onCancel={() => setSuggestEmployeeDialogVisibility(false)}
              onConfirm={() => setSuggestEmployeeDialogVisibility(false)}
              resourceRequestId={resourceRequest?.id ?? ''}
              roleRequestId={role.id}
            />
          </Flex>
        </>
      }
      styles={{
        width: '70rem',
        '& > .ui-dialog__content': {
          overflowY: 'auto',
        },
      }}
    />
  );
};

export default PotentialEmployeesDialog;
