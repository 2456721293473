import { Button, Flex, FlexItem, Text, TrashCanIcon } from '@fluentui/react-northstar';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { AttachmentsUpLoader } from './AttachmentUploader';
import { AttachmentDto } from '../../../data-access/open-api/queries/models/AttachmentDto';
import { useDeleteAttachmentById } from '../../../data-access/hooks/commands/use-update-resource-requests';
import { ResourceRequestViewModel } from './viewModels/ResourceRequestViewModel';
import { AttachmentFile } from './ResourceRequestDialog';

interface ProjectInfoAttachmentsProps {
  isEditMode: boolean;
  resourceRequest: ResourceRequestViewModel;
  attachmentFiles: AttachmentFile[];
  setAttachmentFiles: (attachments: AttachmentFile[]) => void;
  onAttachmentsChanged: (attachments: AttachmentDto[]) => void;
}

export const ProjectInfoAttachments = ({
  isEditMode,
  resourceRequest,
  attachmentFiles,
  setAttachmentFiles,
  onAttachmentsChanged,
}: ProjectInfoAttachmentsProps) => {
  const { t } = useTranslation();

  const { mutate: deleteAttachmentById } = useDeleteAttachmentById();

  const onDeleteAttachment = (attachmentId: string) => {
    const updatedAttachments = resourceRequest.attachments.filter(
      (attachment) => attachment.id !== attachmentId
    );
    onAttachmentsChanged(updatedAttachments);

    const updatedAttachmentFiles = attachmentFiles.filter(
      (attachmentFiles) => attachmentFiles.attachmentId !== attachmentId
    );
    setAttachmentFiles([...updatedAttachmentFiles]);

    if (isEditMode) {
      deleteAttachmentById({
        resourceRequestId: resourceRequest.id ?? '',
        resourceRequestAttachmentId: attachmentId,
      });
    }
  };

  return (
    <Flex column gap="gap.medium">
      <Text content={t('resources.request-dialog.add-attachment-label')} weight="semibold" />
      <AttachmentsUpLoader
        attachmentFiles={attachmentFiles}
        setAttachmentFiles={setAttachmentFiles}
      />
      <Flex column gap="gap.small">
        {resourceRequest.attachments !== null &&
          resourceRequest.attachments.map((attachment) => (
            <Flex key={attachment.id}>
              <Text content={attachment.attachmentFile.displayName}></Text>
              <FlexItem push>
                <Button
                  iconOnly
                  text
                  primary
                  size="small"
                  icon={<TrashCanIcon size="medium" />}
                  onClick={() => onDeleteAttachment(attachment.id)}
                />
              </FlexItem>
            </Flex>
          ))}
        {attachmentFiles.length !== null &&
          attachmentFiles.map((attachmentFile) => {
            return (
              <Flex key={attachmentFile.attachmentId}>
                <Text content={attachmentFile.file?.name}></Text>
                <FlexItem push>
                  <Button
                    iconOnly
                    text
                    primary
                    size="small"
                    icon={<TrashCanIcon size="medium" />}
                    onClick={() => onDeleteAttachment(attachmentFile.attachmentId)}
                  />
                </FlexItem>
              </Flex>
            );
          })}
      </Flex>
    </Flex>
  );
};
