import { Pill } from '@fluentui/react-northstar';
import { FunctionComponent, useCallback } from 'react';
import { activeFilterStyles, filterStyles } from './filterStyles';
import { FavouriteFilterDto } from '../../../data-access/open-api/queries/models/FavouriteFilterDto';
import { prepend } from 'ramda';
import { useChangeDefaultFilter } from '../../../data-access/hooks/commands/use-add-favourite-filter';
import {
  LocalFilter,
  isFavoriteFilter,
  isLocalFilter,
  EMPTY_FILTER_ID,
  emptyFilter,
} from '../LocalFilter';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { allFiltersSelector, activeFilterSelector } from '../../../state/atoms/searchAtom';

/**
 * Component displays a list of Pills, that are used to select the filter to apply
 */

export interface FilterSelectionProps {
  handleFilterActivation: (filterId: string | number) => void;
}

const FilterBar: FunctionComponent<FilterSelectionProps> = ({ handleFilterActivation }) => {
  const { t } = useTranslation();

  const { mutateAsync: changeDefaultFilter, isLoading: isMutating } = useChangeDefaultFilter(true);

  const filters = useRecoilValue(allFiltersSelector);
  const selectedFilter = useRecoilValue(activeFilterSelector);

  const fullList = prepend(emptyFilter(t), filters);

  const handleDefaultFilterChange = useCallback(
    async (filter: FavouriteFilterDto | LocalFilter) => {
      if (isMutating) {
        // Skipping changeDefaultFilter, as we're already busy
        // (this means that this clicked filter may not be set as the default. which was deemed acceptable)
        return;
      }

      const prevDefault = filters.find((f) => isFavoriteFilter(f) && f.default);
      if (!prevDefault && isLocalFilter(filter)) {
        return;
      }

      changeDefaultFilter({
        newFilterId: isFavoriteFilter(filter) ? filter.id : undefined,
        currentFilterId: prevDefault?.id as string,
      });
    },
    [filters, changeDefaultFilter, isMutating]
  );

  const onFilterClick = useCallback(
    async (isActive: boolean, filter: FavouriteFilterDto | LocalFilter) => {
      if (isActive) return;

      handleFilterActivation(filter.id);
      if (!isMutating) {
        handleDefaultFilterChange(filter);
      }
    },
    [handleFilterActivation, handleDefaultFilterChange, isMutating]
  );

  return (
    <div>
      {fullList.map((filter) => {
        const isActive = filter.id === (selectedFilter?.id || EMPTY_FILTER_ID);
        return (
          <Pill
            key={`${filter.id}`}
            content={`${filter.filterName}${
              isLocalFilter(filter) && filter.id !== EMPTY_FILTER_ID ? '*' : ''
            }`}
            rectangular
            styles={isActive ? activeFilterStyles : filterStyles}
            onClick={() => onFilterClick(isActive, filter)}
          />
        );
      })}
    </div>
  );
};

export default FilterBar;
