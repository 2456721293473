import { DropdownProps, Flex, Grid, Text } from '@fluentui/react-northstar';
import { FunctionComponent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gridStyles } from './resourceRequestDialogFormStyles';
import { skillsItemStyle } from './roleDialogFormStyles';
import { workloads } from '../../../data/workloads';
import { Ti8mDropdown } from '../../Common/Ti8mDropdown';
import { Ti8mDatepicker } from '../../Common/Ti8mDatepicker';
import { useInfiniteSkills } from '../../../data-access/hooks/queries/use-infinite-skills';
import { convertWorkload } from '../../../utils/workloadConverter';
import { useRoles } from '../../../data-access/hooks/queries/use-roles';
import { RoleRequestDto } from '../../../data-access/open-api/queries/models/RoleRequestDto';

export interface RoleDialogFormProps {
  role: RoleRequestDto;
  onDataChanged: (data: RoleRequestDto) => void;
}

export const RoleDialogForm: FunctionComponent<RoleDialogFormProps> = ({ role, onDataChanged }) => {
  const { t } = useTranslation();
  const [skillSearchString, setSkillSearchString] = useState('');
  const { skills, fetchMoreSkills, hasMoreSkills, isSkillsError } =
    useInfiniteSkills(skillSearchString);

  const onSkillsSearchQueryChange = (data: DropdownProps) => {
    if (data.searchQuery !== undefined) {
      setSkillSearchString(data.searchQuery);
    }
  };

  const { data: roles } = useRoles();

  const onRoleDropdownSelectionChanged = (data: DropdownProps) => {
    onDataChanged({ ...role, role: data.value as string });
  };

  const onWorkloadDropdownSelectionChanged = (data: DropdownProps) => {
    onDataChanged({ ...role, workload: convertWorkload(data.value as string) });
  };

  const onRoleStartChanged = (date: Date | null) => {
    onDataChanged({ ...role, roleStart: date?.toISOString() ?? '' });
  };

  const onRoleEndChanged = (date: Date | null) => {
    onDataChanged({ ...role, roleEnd: date?.toISOString() ?? '' });
  };

  const onTagsChanged = (data: DropdownProps) => {
    const selectedValues = data.value as Array<{ key: string; header: string }>;
    onDataChanged({
      ...role,
      skills: selectedValues.map((v) => ({ id: v.key, name: v.header })),
    });
  };

  return (
    <>
      <Grid columns="repeat(2, 1fr)" rows="repeat(3, auto)" styles={gridStyles}>
        <Flex column styles={{ gridColumn: 'span 2' }}>
          <Text
            weight="semibold"
            content={t('resources.request-dialog.role-dialog.dd-role-label')}
          />
          <Ti8mDropdown
            items={roles ?? []}
            placeholder={t('resources.request-dialog.role-dialog.dd-role-placeholder')}
            onItemChange={onRoleDropdownSelectionChanged}
            defaultValue={role.role}
            search={true}
          />
        </Flex>
        <Flex gap="gap.small">
          <Flex column>
            <Text weight="semibold" content={t('resources.request-dialog.start-date-label')} />
            <Ti8mDatepicker
              placeholder={t('resources.request-dialog.date-placeholder')}
              onDateChange={onRoleStartChanged}
              defaultSelectedDate={role.roleStart ? new Date(role.roleStart) : undefined}
            />
          </Flex>
          <Flex column>
            <Text weight="semibold" content={t('resources.request-dialog.end-date-label')} />
            <Ti8mDatepicker
              placeholder={t('resources.request-dialog.date-placeholder')}
              onDateChange={onRoleEndChanged}
              defaultSelectedDate={role.roleEnd ? new Date(role.roleEnd) : undefined}
            />
          </Flex>
        </Flex>
        <Flex column>
          <Text
            weight="semibold"
            content={t('resources.request-dialog.role-dialog.dd-workload-label')}
          />
          <Ti8mDropdown
            items={workloads}
            placeholder={t('resources.request-dialog.role-dialog.dd-workload-placeholder')}
            onItemChange={onWorkloadDropdownSelectionChanged}
            defaultValue={role.workload === 0 ? undefined : role.workload}
          />
        </Flex>
        <Flex column styles={{ gridColumn: 'span 2' }}>
          <Text
            weight="semibold"
            content={t('resources.request-dialog.role-dialog.dd-skills-label')}
          />
          <Ti8mDropdown
            items={useMemo(
              () => skills.map((skill) => ({ key: skill.id, header: skill.name })),
              [skills]
            )}
            canLoadMoreItems={hasMoreSkills}
            onScrollLoadMoreItems={fetchMoreSkills}
            onSearchQueryChange={onSkillsSearchQueryChange}
            hasDataError={isSkillsError}
            multiple={true}
            position="above"
            align="top"
            placeholder={t('resources.request-dialog.role-dialog.dd-skills-placeholder')}
            search={true}
            renderSelectedItem={(SelectedItem, props) => (
              <SelectedItem {...props} header={`${props.header}`} styles={skillsItemStyle} />
            )}
            onItemChange={onTagsChanged}
            defaultValue={role.skills.map((skill) => ({
              key: skill.id,
              header: skill.name,
            }))}
          />
        </Flex>
      </Grid>
    </>
  );
};
