import { ComponentSlotStyle } from '@fluentui/react-northstar';

export const skillsItemStyle: ComponentSlotStyle = (data) => {
  return {
    backgroundColor: data.theme.siteVariables.colors['skillTagBackground'],
    borderRadius: 0,
    marginTop: '0.2rem',
    fontWeight: data.theme.siteVariables['fontWeightLight'],
    color: data.theme.siteVariables.colors['bodyColor'],
  };
};
