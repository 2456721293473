import { Flex, Menu, Text } from '@fluentui/react-northstar';
import { useContext, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { RunsInTeamsContext } from '../../data-access/providers/tokenProvider';

export interface AppBrowserHeaderLink {
  name: string;
  path: string;
}

/**
 * Replacement for the Teams tab bar when running in a browser
 */
export const AppBrowserHeader = ({ links }: { links: AppBrowserHeaderLink[] }) => {
  const history = useHistory();
  const location = useLocation();
  const inTeams = useContext(RunsInTeamsContext);

  const items = useMemo(
    () =>
      links.map((route) => ({
        key: route.path,
        content: route.name,
        onClick: () => history.push(route.path),
      })),
    [history, links]
  );

  const index = useMemo(() => {
    return items.findIndex((item) => location.pathname.startsWith(item.key));
  }, [items, location.pathname]);

  return inTeams ? null : (
    <header
      style={{
        paddingLeft: 20,
        paddingRight: 12,
        paddingTop: 15,
        height: 60,
      }}
    >
      <Flex gap="gap.large">
        <Flex vAlign="center">
          <Text weight="bold" content="Planner" style={{ marginLeft: 12 }} />
        </Flex>
        <Flex vAlign="center" gap="gap.medium">
          <Menu underlined primary activeIndex={index} items={items} />
        </Flex>
      </Flex>
    </header>
  );
};
