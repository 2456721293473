/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmployeeOverviewDetailsDtoResult } from '../models/EmployeeOverviewDetailsDtoResult';
import type { EmployeeOverviewDtoPaginatedListDtoResult } from '../models/EmployeeOverviewDtoPaginatedListDtoResult';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { EmployeeDtoResult } from '../models/EmployeeDtoResult';
import { UInt64NullableResult } from '../core/UInt64NullableResult';
import { EmployeeResourceDetailsDto } from '../models/EmployeeResourceDetailsDto';
import { EmployeeMonthlyWorkloadDtoResult } from '../models/EmployeeMonthlyWorkloadDtoResult';
import { EmployeeProjectAssignmentDtoResult } from '../models/EmployeeProjectAssignmentDtoResult';
import { Result } from '../../commands/models/Result';
import { Result_1 } from '../../commands/models/Result_1';
import { EmployeeCapacityExcelResultDto } from '../models/EmployeeCapacityExcelResultDto';

export class EmployeeService {
  /**
   * @returns EmployeeOverviewDtoPaginatedListDtoResult Success
   * @throws ApiError
   */
  public static employees({
    version,
    organizationNumber,
    hasProjectChangeRequest,
    isOnMentoringProgram,
    hasOpenEducation,
    hasEndDate,
    includeIgnored,
    remainingHolidays,
    sortColumn,
    sortDescending,
    skip,
    take,
    searchString,
  }: {
    version: string;
    organizationNumber?: number;
    hasProjectChangeRequest?: boolean;
    isOnMentoringProgram?: boolean;
    hasOpenEducation?: boolean;
    hasEndDate?: boolean;
    includeIgnored?: boolean;
    remainingHolidays?: number;
    sortColumn?: string;
    sortDescending: boolean;
    skip?: number;
    take?: number;
    searchString?: string;
  }): CancelablePromise<EmployeeOverviewDtoPaginatedListDtoResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/employees',
      path: {
        version: version,
      },
      query: {
        Name: searchString,
        OrganizationNumber: organizationNumber,
        HasProjectChangeRequest: hasProjectChangeRequest,
        IsOnMentoringProgram: isOnMentoringProgram,
        HasOpenEducation: hasOpenEducation,
        HasEndDate: hasEndDate,
        IncludeIgnored: includeIgnored,
        RemainingHolidays: remainingHolidays,
        SortColumn: sortColumn,
        sortDescending: sortDescending,
        Skip: skip,
        Take: take,
      },
    });
  }

  /**
   * @returns EmployeeOverviewDetailsDtoResult Success
   * @throws ApiError
   */
  public static employeeOverviewById({
    id,
    version,
  }: {
    id: string;
    version: string;
  }): CancelablePromise<EmployeeOverviewDetailsDtoResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/employees/{id}/overview',
      path: {
        id: id,
        version: version,
      },
    });
  }

  /**
   * @returns EmployeeDtoResult Success
   * @throws ApiError
   */
  public static employeeById({
    id,
    version,
  }: {
    id?: string | null;
    version: string;
  }): CancelablePromise<EmployeeDtoResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/employees/{id}',
      path: {
        id: id,
        version: version,
      },
    });
  }

  /**
   * @returns EmployeeOverviewDto Success
   * @throws ApiError
   */
  public static employeeByName({
    name,
    version,
  }: {
    name?: string | null;
    version: string;
  }): CancelablePromise<EmployeeResourceDetailsDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/employees',
      path: {
        version: version,
      },
      query: {
        Name: name,
      },
    });
  }

  /**
   * @returns EmployeeDtoResult Success
   * @throws ApiError
   */
  public static employeeMonthlyWorkloads({
    id,
    version,
    startDate,
    endDate,
  }: {
    id: string | undefined | null;
    version: string;
    startDate: Date | undefined;
    endDate: Date | undefined;
  }): CancelablePromise<EmployeeMonthlyWorkloadDtoResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/employees/{id}/monthly-workload',
      path: {
        id: id,
        version: version,
      },
      query: {
        StartDate: startDate?.toDateString(),
        EndDate: endDate?.toDateString(),
      },
    });
  }

  /**
   * @returns EmployeeDtoResult Success
   * @throws ApiError
   */
  public static employeeProjectAssignments({
    id,
    version,
    startDate,
    endDate,
  }: {
    id: string | undefined | null;
    version: string;
    startDate: Date | undefined;
    endDate: Date | undefined;
  }): CancelablePromise<EmployeeProjectAssignmentDtoResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/employees/{id}/project-assignments',
      path: {
        id: id,
        version: version,
      },
      query: {
        StartDate: startDate?.toISOString(),
        EndDate: endDate?.toISOString(),
      },
    });
  }

  /**
   * @returns string Success
   * @throws ApiError
   */
  public static employeeCapacityExcel({
    version,
    capacityFilter,
  }: {
    version: string;
    capacityFilter: {
      organizationNumber?: number;
    };
  }): CancelablePromise<EmployeeCapacityExcelResultDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/employeereportings/capacity-csv',
      path: {
        version: version,
      },
      query: {
        capacityFilter: capacityFilter,
      },
    });
  }

  /**
   * @returns UInt64NullableResult Success
   * @throws ApiError
   */
  public static getEventPosition({
    id,
    version,
  }: {
    id: string;
    version: string;
  }): CancelablePromise<UInt64NullableResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/employees/{id}/event-position',
      path: {
        id: id,
        version: version,
      },
    });
  }
}
