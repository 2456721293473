import { Table, Flex, PersonIcon, Text } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import { Pagination } from '../../EmployeeTable/Pagination';
import { usePotentialEmployeesData } from '../hooks/usePotentialEmloyeesData';
import {
  potentialEmployeesDialogTable,
  potentialEmployeesDialogTableHeaderStyleAvatar,
  potentialEmployeesDialogTableHeaderStyleSkills,
  potentialEmployeesDialogTableHeaderStyleName,
  potentialEmployeesDialogTableHeaderStyleStatus,
  potentialEmployeesDialogTableHeaderStyleAction,
  potentialEmployeesDialogSubHeader,
} from './potentialEmployeesDialogStyles';
import { mapStatusToIcon } from './potentialEmployeeStatusMapper';
import { EmployeeAvatar } from '../../EmployeeTable/EmployeeAvatar';
import { getFirstName, getLastName } from '../../../utils/nameConverter';
import { MapActionIcon } from './MapActionItem';
import { useState } from 'react';
import { PotentialEmployeeSkills } from './PotentialEmployeeSkills';
import { ResourceRequestDto } from '../../../data-access/open-api/queries/models/ResourceRequestDto';
import { RoleRequestDto } from '../../../data-access/open-api/queries/models/RoleRequestDto';

interface AvailableEmployeesTableProps {
  resourceRequest?: ResourceRequestDto;
  selectedRoleRequest: RoleRequestDto;
  tableTitle: string;
  noItemsWarning: string;
  isAvailable: boolean;
}

export const PotentialEmployeesTable = ({
  resourceRequest,
  selectedRoleRequest,
  tableTitle,
  noItemsWarning,
  isAvailable,
}: AvailableEmployeesTableProps) => {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState({ currentPage: 1, pageSize: 5 });
  const { potentialEmployees, totalItems } = usePotentialEmployeesData({
    resourceRequestId: resourceRequest?.id ?? '',
    roleRequestId: selectedRoleRequest.id,
    isAvailable: isAvailable,
    paginationOptions: pagination,
  });

  const headers = {
    items: [
      {
        key: 'avatarHeader',
        content: <PersonIcon outline styles={{ marginLeft: '0.3rem' }} />,
        styles: potentialEmployeesDialogTableHeaderStyleAvatar,
      },
      {
        key: 'nameHeader',
        content: t('resources-detail-view.potential-employees-dialog.name'),
        styles: potentialEmployeesDialogTableHeaderStyleName,
      },
      {
        key: 'relevantSkillsHeader',
        content: t('resources-detail-view.potential-employees-dialog.skills'),
        styles: potentialEmployeesDialogTableHeaderStyleSkills,
      },
      {
        key: 'actionHeader',
        content: t('resources-detail-view.potential-employees-dialog.action'),
        styles: potentialEmployeesDialogTableHeaderStyleAction,
      },
      {
        key: 'statusHeader',
        content: t('resources-detail-view.potential-employees-dialog.status'),
        styles: potentialEmployeesDialogTableHeaderStyleStatus,
      },
    ],
  };

  return (
    <Flex column>
      <Text content={tableTitle} styles={potentialEmployeesDialogSubHeader} />
      {potentialEmployees.length > 0 ? (
        <Flex column>
          <Table
            styles={potentialEmployeesDialogTable}
            header={headers}
            rows={potentialEmployees.map((employee) => ({
              key: employee.id,
              items: [
                {
                  key: `${employee.id}_avatar`,
                  content: (
                    <EmployeeAvatar
                      firstName={getFirstName(employee.name)}
                      lastName={getLastName(employee.name)}
                      photo={employee.thumbnail}
                    />
                  ),
                  styles: { maxWidth: '8%' },
                },
                {
                  key: `${employee.id}_name`,
                  content: (
                    <Flex column>
                      <Text size="medium" weight="semibold" content={`${employee.name}`} />
                      <Text content={employee.role} />
                    </Flex>
                  ),
                  styles: { maxWidth: '20%' },
                },
                {
                  key: `${employee.id}_skills`,
                  content: <PotentialEmployeeSkills skills={employee.skills} />,
                  styles: { maxWidth: '50%' },
                },
                {
                  key: `${employee.id}_action`,
                  content: (
                    <MapActionIcon
                      employee={employee}
                      resourceRequest={resourceRequest}
                      role={selectedRoleRequest}
                    />
                  ),
                  styles: { maxWidth: '12%' },
                },
                {
                  key: `${employee.id}_status`,
                  content: (
                    <Flex gap="gap.small" hAlign="center" vAlign="center">
                      {mapStatusToIcon(employee.requestStatus)}
                    </Flex>
                  ),
                  styles: { maxWidth: '14%' },
                },
              ],
            }))}
          />
          <Pagination
            currentPage={pagination.currentPage}
            pageSize={pagination.pageSize}
            totalItems={totalItems}
            onPageChange={(page) =>
              setPagination((prev) => {
                return {
                  ...prev,
                  currentPage: page,
                };
              })
            }
          />
        </Flex>
      ) : (
        <Text content={noItemsWarning} />
      )}
    </Flex>
  );
};
