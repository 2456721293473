import { QueryFunctionContext, useInfiniteQuery } from 'react-query';
import { QueryKeys } from '../../query-keys';
import { withApiVersion, DEFAULT_TAKE, getNextPageParam, unpack } from '../../helpers';
import { SkillService } from '../../open-api/queries/services/SkillService';

const getSkills = (context: QueryFunctionContext) => {
  const withVersion = withApiVersion(SkillService.skills);
  return withVersion({
    searchString: `${context.queryKey[1]}`,
    skip: context.pageParam,
    take: DEFAULT_TAKE,
  });
};

export const useInfiniteSkills = (searchString?: string) => {
  const { fetchNextPage, hasNextPage, data, isError } = useInfiniteQuery({
    queryKey: [QueryKeys.Skills, searchString],
    queryFn: getSkills,
    getNextPageParam: getNextPageParam,
  });

  return {
    skills: unpack(data),
    hasMoreSkills: hasNextPage,
    fetchMoreSkills: fetchNextPage,
    isSkillsError: isError,
  };
};
