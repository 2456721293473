import { Avatar, Button, Flex } from '@fluentui/react-northstar';
import { useState } from 'react';
import { ResourceRequestDto } from '../../data-access/open-api/queries/models/ResourceRequestDto';
import { PeopleAddIcon } from '../../icons';
import { getFirstName, getLastName } from '../../utils/nameConverter';
import { EmployeeAvatar } from '../EmployeeTable/EmployeeAvatar';
import PotentialEmployeesDialog from './PotentialEmployeesDialog/PotentialEmployeesDialog';
import { avatarStyle } from './resourceRequestDetailStyles';
import { RoleRequestDto } from '../../data-access/open-api/queries/models/RoleRequestDto';
import { PotentialEmployeeDto } from '../../data-access/open-api/queries/models/PotentialEmployeeDto';

type PotentialEmployeesProps = {
  resourceRequest?: ResourceRequestDto;
  potentialEmployees?: Array<PotentialEmployeeDto>;
  role: RoleRequestDto;
};

export const PotentialEmployees = ({
  resourceRequest,
  potentialEmployees,
  role,
}: PotentialEmployeesProps) => {
  const [isPotentialEmployeeDialogOpen, setPotentialEmployeeDialogVisibility] =
    useState<boolean>(false);

  return (
    <>
      <Button
        text
        iconOnly
        primary
        onClick={() => setPotentialEmployeeDialogVisibility(true)}
        style={{
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          cursor: 'pointer',
        }}
      >
        <Flex gap={'gap.small'}>
          {potentialEmployees !== undefined && potentialEmployees.length !== 0 ? (
            <>
              <EmployeeAvatar
                firstName={getFirstName(potentialEmployees[0].name)}
                lastName={getLastName(potentialEmployees[0].name)}
                size={'medium'}
                photo={potentialEmployees[0].thumbnail}
              />
              {potentialEmployees.slice(1, 4).map((employee) => (
                <Flex styles={avatarStyle} key={employee.id}>
                  <EmployeeAvatar
                    firstName={getFirstName(employee.name)}
                    lastName={getLastName(employee.name)}
                    size={'medium'}
                    photo={employee.thumbnail}
                  />
                </Flex>
              ))}
            </>
          ) : (
            <PeopleAddIcon size="large" />
          )}
          {potentialEmployees !== undefined && potentialEmployees.length > 4 && (
            <Avatar
              icon={`+${potentialEmployees.length - 4}`}
              styles={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft: '-1rem',
                '> .ui-avatar__icon': { justifyContent: 'center', background: '#989AC9' },
              }}
            />
          )}
        </Flex>
      </Button>
      <PotentialEmployeesDialog
        open={isPotentialEmployeeDialogOpen}
        onCancel={() => setPotentialEmployeeDialogVisibility(false)}
        resourceRequest={resourceRequest}
        role={role}
      />
    </>
  );
};
