import dayjs from 'dayjs';

export const convertToMonthDayYear = (dateString?: string): string => {
  // Convert to Date Format: "Jul 24, 2022"
  return dateString === undefined ? '' : dayjs(dateString).format('MMM DD, YYYY');
};

export const convertToMonthDayYearWithTime = (dateString?: string): string => {
  return dateString === undefined ? '' : dayjs(dateString).format('MMM DD, YYYY HH:mm');
};

export const convertToMonthDayYearSlash = (date: Date) => dayjs(date).format('MM/DD/YYYY');

export const convertMonthYear = (
  dateString: Date | string | undefined,
  fullMonth = false
): string => {
  // Convert to Date Format: "Jul 2022" / "July 2022"

  if (dateString === undefined) {
    return '';
  }

  const monthFormat = fullMonth ? 'MMMM' : 'MMM';
  return dayjs(dateString).format(`${monthFormat} YYYY`);
};

export const convertDayMonthYear = (dateString: string): string => {
  return dayjs(dateString).format('DD.MM.YYYY');
};

export const convertYearMonthDay = (date?: Date): string => {
  // Convert to Date Format: "2022-11-24"

  if (date === undefined) {
    return '';
  }

  return dayjs(date).format('YYYY-MM-DD');
};

export const convertMonthDayYear = (date?: Date | null): string => {
  if (date === undefined || date === null) {
    return '';
  }
  return dayjs(date).format('MM/DD/YYYY');
};

export const getMonthName = (date: Date): string => dayjs(date).format('MMMM');
export const getMonthNameShort = (date: Date): string => dayjs(date).format('MMM');

export const getDateForRequest = (dateString?: string): string | undefined => {
  if (dateString) {
    const date = new Date(dateString);
    // Counteract timezone
    const dateOffset = date.getTimezoneOffset();
    return new Date(date.getTime() - dateOffset * 60 * 1000).toISOString().split('T')[0];
  }
};
