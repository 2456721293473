import { PotentialEmployeeStatus } from '../../../enum/PotentialEmployeeStatus';
import {
  Button,
  FilesUploadIcon,
  Flex,
  Text,
  Tooltip,
  QnaIcon,
  ParticipantAddIcon,
  ParticipantRemoveIcon,
  MicIcon,
} from '@fluentui/react-northstar';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { StateChangeDialog } from './StateChangeDialog';
import { useTranslation } from 'react-i18next';
import { useAccount, useMsal } from '@azure/msal-react';
import { useUpdatePotentialEmployeeStateChange } from '../../../data-access/hooks/commands/use-update-potential-employee';
import { app, authentication } from '@microsoft/teams-js';
import jwt from 'jwt-decode';
import { ResourceRequestDto } from '../../../data-access/open-api/queries/models/ResourceRequestDto';
import { RoleRequestDto } from '../../../data-access/open-api/queries/models/RoleRequestDto';
import { PotentialEmployeeDto } from '../../../data-access/open-api/queries/models/PotentialEmployeeDto';

type MapActionItemProps = {
  resourceRequest?: ResourceRequestDto;
  role: RoleRequestDto;
  employee: PotentialEmployeeDto;
};

export const MapActionIcon = ({ resourceRequest, role, employee }: MapActionItemProps) => {
  const { t } = useTranslation();
  const [isStateChangeDialogOpen, setStageChangeDialogVisibility] = useState<boolean>(false);
  const [prevState, setPrevState] = useState<PotentialEmployeeStatus>(employee.requestStatus);
  const [currentState, setCurrentState] = useState<PotentialEmployeeStatus>(employee.requestStatus);
  const { principalId, principalName, requestStatus, id: employeeId } = employee;

  const { mutate: mutatePotentialEmployeeState } = useUpdatePotentialEmployeeStateChange(
    t('alert.request-objects.request'),
    t('alert.update-types.edit')
  );

  const { accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const [isPrincipal, setIsPrincipal] = useState(false);

  useEffect(() => {
    if (app.isInitialized()) {
      const getIsPrincipal = async () =>
        authentication.getAuthToken().then((token) => {
          setIsPrincipal(jwt<{ oid: string }>(token).oid === principalId);
        });
      getIsPrincipal();
    } else {
      const loggedInId = account?.localAccountId;
      setIsPrincipal(loggedInId === principalId);
    }
  }, [principalId, setIsPrincipal, account?.localAccountId]);

  // These are the actions carried out after confirming the state change dialog.
  // This includes the mutation of the employee state.

  const onConfirmSelect = useCallback(() => {
    setStageChangeDialogVisibility(false);
    // no contact dialog here
    mutatePotentialEmployeeState({
      resourceRequestId: resourceRequest?.id ?? '',
      roleRequestId: role.id,
      employeeId: employeeId,
      stateChange: PotentialEmployeeStatus.SELECTED,
    });
  }, [employeeId, mutatePotentialEmployeeState, resourceRequest?.id, role.id]);

  const onConfirmContact = useCallback(() => {
    setStageChangeDialogVisibility(false);
    mutatePotentialEmployeeState({
      resourceRequestId: resourceRequest?.id ?? '',
      roleRequestId: role.id,
      employeeId: employeeId,
      stateChange: PotentialEmployeeStatus.LEAD_CONTACTED,
    });
  }, [employeeId, mutatePotentialEmployeeState, resourceRequest?.id, role.id]);

  const onConfirmRejectByLead = useCallback(() => {
    setStageChangeDialogVisibility(false);
    mutatePotentialEmployeeState({
      resourceRequestId: resourceRequest?.id ?? '',
      roleRequestId: role.id,
      employeeId: employeeId,
      stateChange: PotentialEmployeeStatus.REJECTED_BY_LEAD,
    });
  }, [employeeId, mutatePotentialEmployeeState, resourceRequest?.id, role.id]);

  const onConfirmAcceptByLead = useCallback(() => {
    setStageChangeDialogVisibility(false);
    mutatePotentialEmployeeState({
      resourceRequestId: resourceRequest?.id ?? '',
      roleRequestId: role.id,
      employeeId: employeeId,
      stateChange: PotentialEmployeeStatus.ACCEPTED_BY_LEAD,
    });
  }, [employeeId, mutatePotentialEmployeeState, resourceRequest?.id, role.id]);

  const onConfirmSubmit = useCallback(() => {
    setStageChangeDialogVisibility(false);
    // no contact dialog here
    mutatePotentialEmployeeState({
      resourceRequestId: resourceRequest?.id ?? '',
      roleRequestId: role.id,
      employeeId: employeeId,
      stateChange: PotentialEmployeeStatus.SUBMITTED_TO_CUSTOMER,
    });
  }, [employeeId, mutatePotentialEmployeeState, resourceRequest?.id, role.id]);

  const onConfirmRejectByCustomerWithoutInterview = useCallback(() => {
    setStageChangeDialogVisibility(false);
    mutatePotentialEmployeeState({
      resourceRequestId: resourceRequest?.id ?? '',
      roleRequestId: role.id,
      employeeId: employeeId,
      stateChange: PotentialEmployeeStatus.REJECTED_BY_CUSTOMER_WITHOUT_INTERVIEW,
    });
  }, [employeeId, mutatePotentialEmployeeState, resourceRequest?.id, role.id]);

  const onConfirmInterview = useCallback(() => {
    setStageChangeDialogVisibility(false);
    // no contact dialog here
    mutatePotentialEmployeeState({
      resourceRequestId: resourceRequest?.id ?? '',
      roleRequestId: role.id,
      employeeId: employeeId,
      stateChange: PotentialEmployeeStatus.INTERVIEW_PENDING,
    });
  }, [employeeId, mutatePotentialEmployeeState, resourceRequest?.id, role.id]);

  const onConfirmRejectByCustomer = useCallback(() => {
    setStageChangeDialogVisibility(false);
    mutatePotentialEmployeeState({
      resourceRequestId: resourceRequest?.id ?? '',
      roleRequestId: role.id,
      employeeId: employeeId,
      stateChange: PotentialEmployeeStatus.REJECTED_BY_CUSTOMER,
    });
  }, [employeeId, mutatePotentialEmployeeState, resourceRequest?.id, role.id]);

  const onConfirmAcceptByCustomer = useCallback(() => {
    setStageChangeDialogVisibility(false);
    mutatePotentialEmployeeState({
      resourceRequestId: resourceRequest?.id ?? '',
      roleRequestId: role.id,
      employeeId: employeeId,
      stateChange: PotentialEmployeeStatus.ACCEPTED_BY_CUSTOMER,
    });
  }, [employeeId, mutatePotentialEmployeeState, resourceRequest?.id, role.id]);

  const generateStateChangeDialog = useCallback(
    (title: string, content: string, confirmButtonText: string, onConfirm: () => void) => {
      return (
        <StateChangeDialog
          open={isStateChangeDialogOpen}
          onCancel={() => {
            setStageChangeDialogVisibility(false);
            setCurrentState(prevState);
          }}
          onConfirm={onConfirm}
          title={title}
          content={content}
          confirmButtonText={confirmButtonText}
        />
      );
    },
    [isStateChangeDialogOpen, prevState]
  );

  // These are the dialogs shown after hitting an action
  // By confirming dialog a mutation of the potential employee state is triggered

  const stateChangeDialog = useMemo(() => {
    switch (currentState) {
      case PotentialEmployeeStatus.SELECTED:
        return generateStateChangeDialog(
          t('state-change-dialog.select-title'),
          t('state-change-dialog.select-text'),
          t('state-change-dialog.select-confirm-button'),
          onConfirmSelect
        );
      case PotentialEmployeeStatus.LEAD_CONTACTED:
        return generateStateChangeDialog(
          t('state-change-dialog.contact-title'),
          t('state-change-dialog.contact-text'),
          t('state-change-dialog.contact-confirm-button'),
          onConfirmContact
        );
      case PotentialEmployeeStatus.ACCEPTED_BY_LEAD:
        return generateStateChangeDialog(
          t('state-change-dialog.accept-title'),
          t('state-change-dialog.accept-text'),
          t('state-change-dialog.accept-confirm-button'),
          onConfirmAcceptByLead
        );
      case PotentialEmployeeStatus.REJECTED_BY_LEAD:
        return generateStateChangeDialog(
          t('state-change-dialog.reject-title'),
          t('state-change-dialog.reject-text'),
          t('state-change-dialog.reject-confirm-button'),
          onConfirmRejectByLead
        );
      case PotentialEmployeeStatus.SUBMITTED_TO_CUSTOMER:
        return generateStateChangeDialog(
          t('state-change-dialog.submit-title'),
          t('state-change-dialog.submit-text'),
          t('state-change-dialog.submit-confirm-button'),
          onConfirmSubmit
        );
      case PotentialEmployeeStatus.REJECTED_BY_CUSTOMER_WITHOUT_INTERVIEW:
        return generateStateChangeDialog(
          t('state-change-dialog.reject-title'),
          t('state-change-dialog.reject-text'),
          t('state-change-dialog.reject-confirm-button'),
          onConfirmRejectByCustomerWithoutInterview
        );
      case PotentialEmployeeStatus.INTERVIEW_PENDING:
        return generateStateChangeDialog(
          t('state-change-dialog.interview-title'),
          t('state-change-dialog.interview-text'),
          t('state-change-dialog.interview-confirm-button'),
          onConfirmInterview
        );
      case PotentialEmployeeStatus.ACCEPTED_BY_CUSTOMER:
        return generateStateChangeDialog(
          t('state-change-dialog.accept-title'),
          t('state-change-dialog.accept-text'),
          t('state-change-dialog.accept-confirm-button'),
          onConfirmAcceptByCustomer
        );
      case PotentialEmployeeStatus.REJECTED_BY_CUSTOMER:
        return generateStateChangeDialog(
          t('state-change-dialog.reject-title'),
          t('state-change-dialog.reject-text'),
          t('state-change-dialog.reject-confirm-button'),
          onConfirmRejectByCustomer
        );
    }
  }, [
    currentState,
    generateStateChangeDialog,
    onConfirmAcceptByCustomer,
    onConfirmAcceptByLead,
    onConfirmContact,
    onConfirmInterview,
    onConfirmRejectByCustomer,
    onConfirmRejectByCustomerWithoutInterview,
    onConfirmRejectByLead,
    onConfirmSelect,
    onConfirmSubmit,
    t,
  ]);

  return (
    <>
      {requestStatus === PotentialEmployeeStatus.LEAD_CONTACTED &&
        (isPrincipal ? (
          <Flex>
            <Tooltip
              content={
                <Text
                  content={t('potential-employee-actions.accept-employee-lead', {
                    PrincipalName: principalName,
                  })}
                />
              }
              subtle={false}
              trigger={
                <Button
                  icon={<ParticipantRemoveIcon size="large" />}
                  text
                  iconOnly
                  primary
                  onClick={() => {
                    setPrevState(currentState);
                    setCurrentState(PotentialEmployeeStatus.REJECTED_BY_LEAD);
                    setStageChangeDialogVisibility(true);
                  }}
                />
              }
            />
            <Tooltip
              content={
                <Text
                  content={t('potential-employee-actions.reject-employee-lead', {
                    PrincipalName: principalName,
                  })}
                />
              }
              subtle={false}
              trigger={
                <Button
                  icon={<ParticipantAddIcon size="large" />}
                  text
                  iconOnly
                  primary
                  onClick={() => {
                    setPrevState(currentState);
                    setCurrentState(PotentialEmployeeStatus.ACCEPTED_BY_LEAD);
                    setStageChangeDialogVisibility(true);
                  }}
                />
              }
            />
          </Flex>
        ) : (
          <Button icon={<QnaIcon size={'large'} />} text iconOnly primary disabled={true} />
        ))}
      {requestStatus === PotentialEmployeeStatus.AVAILABLE && (
        <Tooltip
          content={
            <Text
              content={t('potential-employee-actions.select-employee', {
                PrincipalName: principalName,
              })}
            />
          }
          subtle={false}
          trigger={
            <Button
              icon={<ParticipantAddIcon size={'large'} />}
              text
              iconOnly
              primary
              onClick={() => {
                setPrevState(PotentialEmployeeStatus.AVAILABLE);
                setCurrentState(PotentialEmployeeStatus.SELECTED);
                setStageChangeDialogVisibility(true);
              }}
            />
          }
        />
      )}
      {requestStatus === PotentialEmployeeStatus.SELECTED && (
        <Tooltip
          content={
            <Text
              content={t('potential-employee-actions.contact-employee-principal', {
                PrincipalName: principalName,
              })}
            />
          }
          subtle={false}
          trigger={
            <Button
              icon={<QnaIcon size={'large'} />}
              text
              iconOnly
              primary
              onClick={() => {
                setPrevState(PotentialEmployeeStatus.SELECTED);
                setCurrentState(PotentialEmployeeStatus.LEAD_CONTACTED);
                setStageChangeDialogVisibility(true);
              }}
            />
          }
        />
      )}
      {requestStatus === PotentialEmployeeStatus.ACCEPTED_BY_LEAD && (
        <Tooltip
          content={
            <Text
              content={t('potential-employee-actions.submit-employee', {
                PrincipalName: principalName,
              })}
            />
          }
          subtle={false}
          trigger={
            <Button
              icon={<FilesUploadIcon size={'large'} />}
              text
              iconOnly
              primary
              disabled={false}
              onClick={() => {
                setPrevState(PotentialEmployeeStatus.ACCEPTED_BY_LEAD);
                setCurrentState(PotentialEmployeeStatus.SUBMITTED_TO_CUSTOMER);
                setStageChangeDialogVisibility(true);
              }}
            />
          }
        />
      )}
      {requestStatus === PotentialEmployeeStatus.SUBMITTED_TO_CUSTOMER && (
        <>
          <Tooltip
            content={
              <Text
                content={t('potential-employee-actions.interview-employee', {
                  PrincipalName: principalName,
                })}
              />
            }
            subtle={false}
            trigger={
              <Button
                icon={<MicIcon size={'large'} />}
                text
                iconOnly
                primary
                onClick={() => {
                  setPrevState(PotentialEmployeeStatus.SUBMITTED_TO_CUSTOMER);
                  setCurrentState(PotentialEmployeeStatus.INTERVIEW_PENDING);
                  setStageChangeDialogVisibility(true);
                }}
              />
            }
          />
          <Tooltip
            content={
              <Text
                content={t('potential-employee-actions.reject-employee-customer', {
                  PrincipalName: principalName,
                })}
              />
            }
            subtle={false}
            trigger={
              <Button
                icon={<ParticipantRemoveIcon size={'large'} />}
                text
                iconOnly
                primary
                onClick={() => {
                  setPrevState(PotentialEmployeeStatus.SUBMITTED_TO_CUSTOMER);
                  setCurrentState(PotentialEmployeeStatus.REJECTED_BY_CUSTOMER);
                  setStageChangeDialogVisibility(true);
                }}
              />
            }
          />
        </>
      )}
      {requestStatus === PotentialEmployeeStatus.INTERVIEW_PENDING && (
        <Flex>
          <Tooltip
            content={
              <Text
                content={t('potential-employee-actions.accept-employee-customer', {
                  PrincipalName: principalName,
                })}
              />
            }
            subtle={false}
            trigger={
              <Button
                icon={<ParticipantAddIcon size={'large'} />}
                text
                iconOnly
                primary
                onClick={() => {
                  setPrevState(PotentialEmployeeStatus.INTERVIEW_PENDING);
                  setCurrentState(PotentialEmployeeStatus.ACCEPTED_BY_CUSTOMER);
                  setStageChangeDialogVisibility(true);
                }}
              />
            }
          />
          <Tooltip
            content={
              <Text
                content={t('potential-employee-actions.reject-employee-customer', {
                  PrincipalName: principalName,
                })}
              />
            }
            subtle={false}
            trigger={
              <Button
                icon={<ParticipantRemoveIcon size={'large'} />}
                text
                iconOnly
                primary
                onClick={() => {
                  setPrevState(PotentialEmployeeStatus.INTERVIEW_PENDING);
                  setCurrentState(PotentialEmployeeStatus.REJECTED_BY_CUSTOMER);
                  setStageChangeDialogVisibility(true);
                }}
              />
            }
          />
        </Flex>
      )}
      {stateChangeDialog}
    </>
  );
};
