import { DropdownProps, Flex, Text } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { Ti8mDropdown } from '../../Common/Ti8mDropdown';
import {
  useSuggestPotentialEmployee,
  useSuggestPotentialEmployeeByLead,
} from '../../../data-access/hooks/commands/use-update-potential-employee';
import { useInfiniteEmployees } from '../../../data-access/hooks/queries/use-infinite-employees';
import { Ti8mDialog } from '../../Common/Ti8mDialog';
import { useAccount, useMsal } from '@azure/msal-react';
import { app, authentication } from '@microsoft/teams-js';
import jwt from 'jwt-decode';

type SuggestEmployeeDialogProps = {
  isDialogOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  resourceRequestId: string;
  roleRequestId: string;
};
const SuggestEmployeeDialog = ({
  isDialogOpen,
  onCancel,
  onConfirm,
  resourceRequestId,
  roleRequestId,
}: SuggestEmployeeDialogProps) => {
  const { t } = useTranslation();
  const defaultEmployee = {
    employeeId: '',
    principalId: '',
  };
  const [suggestedEmployee, setSuggestedEmployee] = useState<{
    employeeId: string;
    principalId: string;
  }>(defaultEmployee);
  const [employeeSearchString, setEmployeeSearchString] = useState('');
  const [loggedInUserId, setLoggedInUserId] = useState<string>('');
  const { accounts } = useMsal();
  const account = useAccount(accounts[0]);

  useEffect(() => {
    if (app.isInitialized()) {
      const getIsPrincipal = async () =>
        authentication.getAuthToken().then((token) => {
          setLoggedInUserId(jwt<{ oid: string }>(token).oid);
        });
      getIsPrincipal();
    } else {
      const loggedInId = account?.localAccountId;
      setLoggedInUserId(loggedInId ?? '');
    }
  }, []);

  // reset local state, when the dialog is opened/closed
  useEffect(() => {
    setEmployeeSearchString('');
    setSuggestedEmployee(defaultEmployee);
  }, [isDialogOpen]);

  const { employees, fetchMoreEmployees, hasMoreEmployees, isEmployeesError } =
    useInfiniteEmployees(employeeSearchString);

  type Item = {
    header: string;
    image: string;
    content: string;
    employeeId: string;
    principalId: string;
  };

  const dropDownItems = employees.map((employee) => {
    return {
      header: `${employee.firstName} ${employee.lastName}`,
      image: employee.thumbnail,
      content: employee.role,
      employeeId: employee.id,
      principalId: employee.principalId,
    };
  });

  const onEmployeesSearchQueryChange = useCallback(
    (data: DropdownProps) => {
      if (data.searchQuery !== undefined) {
        setEmployeeSearchString(data.searchQuery);
      }
    },
    [setEmployeeSearchString]
  );

  const { mutate: mutateSuggestPotentialEmployee } = useSuggestPotentialEmployee(
    t('alert.request-objects.request'),
    t('alert.update-types.edit')
  );

  const { mutate: mutateSuggestPotentialEmployeeByLead } = useSuggestPotentialEmployeeByLead(
    t('alert.request-objects.request'),
    t('alert.update-types.edit')
  );

  const onDataChanged = (data?: DropdownProps) => {
    if (!data?.value) {
      setSuggestedEmployee(defaultEmployee);
      return;
    }
    const value: Item = data?.value as Item;
    setSuggestedEmployee({
      employeeId: value.employeeId,
      principalId: value.principalId,
    });
  };

  const suggestEmployee = () => {
    if (suggestedEmployee.principalId === loggedInUserId) {
      mutateSuggestPotentialEmployeeByLead({
        resourceRequestId: resourceRequestId,
        roleRequestId: roleRequestId,
        employeeId: suggestedEmployee.employeeId,
      });
    } else {
      mutateSuggestPotentialEmployee({
        resourceRequestId: resourceRequestId,
        roleRequestId: roleRequestId,
        employeeId: suggestedEmployee.employeeId,
      });
    }
    onConfirm();
  };

  return (
    <Ti8mDialog
      open={isDialogOpen}
      onCancel={onCancel}
      onConfirm={() => suggestEmployee()}
      cancelButton={t('resources-detail-view.potential-employees-dialog.cancel')}
      confirmButton={{
        disabled: suggestedEmployee.employeeId === '',
        content: t('resources-detail-view.potential-employees-dialog.add'),
      }}
      header={t(
        'resources-detail-view.potential-employees-dialog.employee-suggestions-dialog-header'
      )}
      content={
        <Flex column>
          <Text content={t('resources-detail-view.potential-employees-dialog.name')}></Text>
          <Ti8mDropdown
            items={dropDownItems}
            canLoadMoreItems={hasMoreEmployees}
            onScrollLoadMoreItems={fetchMoreEmployees}
            hasDataError={isEmployeesError}
            onSearchQueryChange={onEmployeesSearchQueryChange}
            placeholder={t(
              'resources-detail-view.potential-employees-dialog.employee-suggestions-dialog-search'
            )}
            position={'below'}
            align={'top'}
            search={true}
            onItemChange={onDataChanged}
          />
        </Flex>
      }
      resetOverflow
    />
  );
};

export default SuggestEmployeeDialog;
