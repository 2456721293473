import { ComponentSlotStyle, SiteVariablesPrepared } from '@fluentui/react-northstar';

export const tableRootStyle: ComponentSlotStyle = (data) => {
  return {
    marginBottom: '0.5rem',
    '& > .ui-table__row': {
      minHeight: '56px',
      '&[aria-selected="true"]': {
        backgroundColor: data.theme.siteVariables.colorScheme['default']['background1'],
        border: data.theme.componentStyles.Card?.root(data).border,
      },
    },
  };
};

export const tableHeaderPointerStyle: ComponentSlotStyle = (data) => ({
  fontWeight: data.theme.siteVariables['fontWeightSemibold'],
  fontSize: data.theme.siteVariables.fontSizes['medium'],
  cursor: 'pointer',
});

export const tableAvatarHeaderStyle: ComponentSlotStyle = {
  maxWidth: '60px',
  justifyContent: 'center',
};

export const primaryCellStyle: ComponentSlotStyle = (data) => ({
  fontSize: data.theme.siteVariables.fontSizes['small'],
});

export const secondaryCellStyle: ComponentSlotStyle = (data) => ({
  fontSize: data.theme.siteVariables.fontSizes['small'],
  color: data.theme.siteVariables['colors']['grey'][450],
});

export const lowerTextCellStyle: ComponentSlotStyle = (data) => ({
  fontSize: data.theme.siteVariables.fontSizes['smaller'],
  color: data.theme.siteVariables['colors']['grey'][450],
});

export const avatarCellStyle: ComponentSlotStyle = {
  maxWidth: '60px',
  justifyContent: 'center',
};

export const emphasizedSecondaryCellStyle: ComponentSlotStyle = (data) => {
  return {
    ...secondaryCellStyle(data),
    fontWeight: data.theme.siteVariables['fontWeightSemibold'],
  };
};

export const tableRowVariablesOverride = (data: SiteVariablesPrepared) => {
  return {
    backgroundColorHover: data.colorScheme['default']['background6'],
  };
};
