import { Flex, Text } from '@fluentui/react-northstar';

type ProjectDetailSectionItemProps = {
  title: string;
  content: React.ReactNode;
};

export const ProjectDetailSectionItem = ({ title, content }: ProjectDetailSectionItemProps) => {
  return (
    <Flex
      column
      gap="gap.smaller"
      styles={{ flexBasis: '32%', marginBottom: 16, overflow: 'hidden' }}
    >
      <Text content={title} weight="semibold" size="small" />
      {content}
    </Flex>
  );
};
