import { ComponentSlotStyle } from '@fluentui/react-northstar';

export const notificationStyle = {
  fontWeight: 'fontWeightSemibold',
  fontSize: '12',
};

export const closeRequestTextAreaStyles: ComponentSlotStyle = (data) => ({
  border: `1px solid ${data.theme.siteVariables.colors.grey['400']}`,
});
