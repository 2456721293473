import { List, Pill, Tooltip } from '@fluentui/react-northstar';
import { SkillsDto } from '../../data-access/open-api/queries/models/SkillsDto';
import { Ti8mPill } from '../Common/Ti8mPill';
import { Ti8mTruncatedList } from '../Common/Ti8mTruncatedList';
import { overflowPillStyle, skillPillStyle } from './resourceRequestDetailStyles';

type RelevantSkillsProps = {
  skills?: Array<SkillsDto>;
};

export const RelevantSkills = ({ skills = [] }: RelevantSkillsProps) => {
  return (
    <Ti8mTruncatedList
      renderTruncator={({ hiddenItemsCount }) => (
        <Tooltip
          content={
            <List
              items={skills.slice(skills.length - hiddenItemsCount).map((skill) => ({
                key: skill.id,
                content: skill.name,
                styles: { minHeight: 'unset' },
              }))}
              styles={{ '> li': { padding: 0 } }}
            />
          }
          trigger={
            <Pill
              rectangular
              content={`+${hiddenItemsCount}`}
              size="small"
              styles={overflowPillStyle}
            />
          }
        />
      )}
      styles={{
        padding: 0,
        margin: 0,
        maxHeight: '40px',
        alignContent: 'flex-start',
        alignItems: 'center',
        '& > li': { listStyleType: 'none' },
      }}
    >
      {skills.length > 0
        ? skills.map((skill) => (
            <Ti8mPill
              key={skill.id}
              itemId={skill.id}
              content={skill.name}
              showTooltip
              maxTextLength={16}
              size="small"
              styles={(data) => ({
                ...skillPillStyle(data),
                minWidth: '2rem',
                minHeight: '1rem',
              })}
            />
          ))
        : null}
    </Ti8mTruncatedList>
  );
};
