import { CloseIcon, Dialog } from '@fluentui/react-northstar';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useAddResourceRequest,
  useUpdateResourceRequest,
  useUploadAttachment,
} from '../../../data-access/hooks/commands/use-update-resource-requests';
import { ResourceRequestDto } from '../../../data-access/open-api/queries/models/ResourceRequestDto';
import { ResourceRequestDialogForm } from './ResourceRequestDialogForm';
import { mapResourceRequestViewModelToResourceRequestCommand as mapToViewModel } from './resourceRequestMapper';
import {
  ResourceRequestViewModel,
  blankResourceRequestViewModel,
} from './viewModels/ResourceRequestViewModel';

export type MenuStateType = 'projectInfo' | 'projectRole' | 'requestSubmission';

export type ResourceRequestSetter = <K extends keyof ResourceRequestViewModel>(
  key: K,
  value: ResourceRequestViewModel[K]
) => void;

export type AttachmentFile = {
  attachmentId: string;
  originalFileName: string;
  file?: File;
};

interface RequestDialogProps {
  newResourceRequestId?: string;
  isDialogOpen: boolean;
  editRequestData?: ResourceRequestDto;
  handleCloseRequest: () => void;
  handleHeaderActionRequest: () => void;
  setSuccessDialogVisibility?: (successDialogVisibility: boolean) => void;
}

export const ResourceRequestDialog = ({
  newResourceRequestId,
  isDialogOpen,
  editRequestData,
  handleCloseRequest,
  handleHeaderActionRequest,
  setSuccessDialogVisibility,
}: RequestDialogProps) => {
  const { t } = useTranslation();

  const toDate = (value?: string) => (value ? new Date(value) : undefined);

  const defaultRequestData: ResourceRequestViewModel = editRequestData
    ? {
        id: editRequestData.id,
        client: editRequestData.client,
        project: editRequestData.project,
        projectStart: toDate(editRequestData.projectStart),
        projectEnd: toDate(editRequestData.projectEnd),
        submission: toDate(editRequestData.submissionDate),
        expectedFeedbackDate: editRequestData.expectedFeedbackDate
          ? toDate(editRequestData.expectedFeedbackDate)
          : undefined,
        description: editRequestData.projectDescription,
        attachments: editRequestData.attachments,
        roles: editRequestData.roleRequests,
      }
    : blankResourceRequestViewModel;

  const [newAttachmentFiles, setNewAttachmentFiles] = useState<AttachmentFile[]>([]);
  const [resourceRequest, setResourceRequest] =
    useState<ResourceRequestViewModel>(defaultRequestData);
  const [isTouchedInfo, setTouchedInfo] = useState<boolean>(false);
  const [isConfirmButtonEnabled, setConfirmButtonEnabled] = useState<boolean>(false);
  const [menuState, setMenuState] = useState<MenuStateType>('projectInfo');
  const [loading, setLoading] = useState(false);

  const handleSetResourceRequest: ResourceRequestSetter = (key, value) => {
    setResourceRequest({ ...resourceRequest, [key]: value });
  };

  const { mutate: addResourceRequest, data: createdResourceRequest } = useAddResourceRequest();
  const { mutate: mutateResourceRequest, data: updatedResourceRequest } =
    useUpdateResourceRequest();
  const { mutateAsync: uploadAttachment } = useUploadAttachment();

  const uploadAttachmentFiles = useCallback(
    async (requestId: string) => {
      for (const file of newAttachmentFiles) {
        await uploadAttachment({
          resourceRequestId: requestId,
          attachmentId: file.attachmentId,
          originalFileName: file.originalFileName,
          formData: {
            content: file.file as Blob,
            contentType: file.file?.type.split('/')[1] ?? '',
          },
        });
      }
    },
    [newAttachmentFiles, uploadAttachment]
  );

  // File upload handling for existing resource requests
  useEffect(() => {
    if (updatedResourceRequest?.id) {
      uploadAttachmentFiles(updatedResourceRequest.id).finally(() => {
        setLoading(false);
        handleCloseRequest();
      });
    }
  }, [updatedResourceRequest, uploadAttachmentFiles, handleCloseRequest]);

  // File upload handling for new resource requests
  useEffect(() => {
    if (createdResourceRequest) {
      uploadAttachmentFiles(createdResourceRequest.payload);
      handleCloseRequest();
      setLoading(false);
      setSuccessDialogVisibility?.(true);
    }
  }, [
    createdResourceRequest,
    uploadAttachmentFiles,
    handleCloseRequest,
    setSuccessDialogVisibility,
  ]);

  const handleConfirm = useCallback(() => {
    switch (menuState) {
      case 'projectInfo':
        setMenuState('projectRole');
        break;
      case 'projectRole':
        setMenuState('requestSubmission');
        break;
      case 'requestSubmission':
        setLoading(true);
        if (editRequestData) {
          mutateResourceRequest({
            id: editRequestData.id,
            requestBody: mapToViewModel(resourceRequest),
          });
        } else {
          addResourceRequest({
            requestBody: mapToViewModel({
              ...resourceRequest,
              id: newResourceRequestId,
            }),
          });
        }
        break;
      default:
        break;
    }
  }, [
    addResourceRequest,
    mutateResourceRequest,
    newResourceRequestId,
    menuState,
    resourceRequest,
    editRequestData,
  ]);

  const handleCancelButton = useCallback(() => {
    if (menuState === 'projectInfo') handleCloseRequest();
    if (menuState === 'projectRole') setMenuState('projectInfo');
    if (menuState === 'requestSubmission') setMenuState('projectRole');
  }, [handleCloseRequest, menuState]);

  const dialogHeaderText = useMemo(() => {
    const isEditMode = Boolean(editRequestData);

    switch (menuState) {
      case 'projectInfo':
        return isEditMode
          ? t('resource-detail-edit-dialog.dialog-header-info')
          : t('resources.request-dialog.title-add-project-info');
      case 'projectRole':
        return isEditMode
          ? t('resource-detail-edit-dialog.dialog-header-roles')
          : t('resources.request-dialog.title-add-project-roles');
      case 'requestSubmission':
        return t('resources.request-dialog.title-add-project-submission');
      default:
        return '';
    }
  }, [menuState, editRequestData, t]);

  const confirmButtonText = useMemo(() => {
    switch (menuState) {
      case 'projectInfo':
      case 'projectRole':
        return t('resources.request-dialog.next');
      case 'requestSubmission':
        const isEditMode = Boolean(editRequestData);
        if (isEditMode) {
          return t('resources.request-dialog.save');
        }
        return t('resources.request-dialog.submit');
    }
  }, [editRequestData, menuState, t]);

  const cancelButtonText = useMemo(() => {
    switch (menuState) {
      case 'projectInfo':
        return t('resources.request-dialog.cancel');
      case 'projectRole':
      case 'requestSubmission':
        return t('resources.request-dialog.back');
    }
  }, [menuState, t]);

  useEffect(() => {
    setConfirmButtonEnabled(false);
    if (
      editRequestData ||
      (menuState === 'projectInfo' && isTouchedInfo) ||
      (menuState === 'projectRole' && isTouchedInfo && resourceRequest.roles.length >= 1) ||
      (menuState === 'requestSubmission' && isTouchedInfo && resourceRequest.roles.length >= 1)
    ) {
      setConfirmButtonEnabled(true);
    }
  }, [editRequestData, menuState, isTouchedInfo, resourceRequest.roles.length]);

  return (
    <Dialog
      closeOnOutsideClick={false}
      header={dialogHeaderText}
      headerAction={<CloseIcon onClick={handleHeaderActionRequest} />}
      cancelButton={{ content: cancelButtonText, disabled: loading }}
      onCancel={handleCancelButton}
      confirmButton={{
        content: confirmButtonText,
        loading,
        disabled: !isConfirmButtonEnabled || loading,
      }}
      onConfirm={handleConfirm}
      content={
        <ResourceRequestDialogForm
          resourceRequest={resourceRequest}
          isEditMode={editRequestData !== undefined}
          handleSetResourceRequest={handleSetResourceRequest}
          menuState={menuState}
          setMenuState={setMenuState}
          isTouchedInfo={isTouchedInfo}
          setTouchedInfo={setTouchedInfo}
          attachmentFiles={newAttachmentFiles}
          setAttachmentFiles={setNewAttachmentFiles}
        />
      }
      open={isDialogOpen}
      style={{
        width: '50rem',
        gridTemplateRows: 'auto 1fr auto',
      }}
    />
  );
};
