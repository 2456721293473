import { TFunction } from 'i18next';
import { FavouriteFilterDto } from '../../data-access/open-api/queries/models/FavouriteFilterDto';
import { FilterOrganizationDto } from '../../data-access/open-api/queries/models/FilterOrganizationDto';

/**
 * A LocalFilter only lives on the client and isn't stored yet
 * (in which case it would be a FavouriteFilterDto).
 */
export type LocalFilter = {
  id: number;
  isLocal: boolean;
  filterName: string;
  selectedHolidaysNumber?: number;
  changeRequest: boolean;
  mentoringProgram: boolean;
  openEducation: boolean;
  workloadTermination: boolean;
  ignoredEmployees: boolean;
  selectedOrganization?: FilterOrganizationDto;
};

export const EMPTY_FILTER_ID = -1;

/**
 * The default filter, that doesn't filter anything at all.
 */
export function emptyFilter(t: TFunction): LocalFilter {
  return {
    id: EMPTY_FILTER_ID,
    isLocal: true,
    changeRequest: false,
    filterName: t('search.filter-all'),
    ignoredEmployees: false,
    mentoringProgram: false,
    openEducation: false,
    workloadTermination: false,
    selectedHolidaysNumber: undefined,
    selectedOrganization: undefined,
  };
}

/**
 * Type guard to check, if we have a LocalFilter (and not a FavouriteFilterDto).
 */
export function isLocalFilter(
  filter: LocalFilter | FavouriteFilterDto | undefined
): filter is LocalFilter {
  return (filter as LocalFilter)?.isLocal !== undefined;
}

/**
 * Type guard to check, if we have a FavouriteFilterDto (and not a LocalFilter).
 */
export function isFavoriteFilter(
  filter: LocalFilter | FavouriteFilterDto | undefined
): filter is FavouriteFilterDto {
  return (filter as FavouriteFilterDto)?.userId !== undefined;
}
