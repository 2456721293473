/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { FavouriteFilterDto } from '../models/FavouriteFilterDto';
import { GenericResult } from '../models/GenericResult';

export class FavouriteFilterService {
  /**
   * @returns UInt64NullableResult Success
   * @throws ApiError
   */
  public static favouriteFilters({
    version,
  }: {
    version: string;
  }): CancelablePromise<GenericResult<Array<FavouriteFilterDto>>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/q/api/v{version}/favourite-filter',
      path: {
        version: version,
      },
    });
  }
}
