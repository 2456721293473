/* eslint-disable */
import { mergeThemes, teamsV2Theme, teamsDarkV2Theme } from '@fluentui/react-northstar';

export function generateTheme(theme: 'dark' | 'light' = 'light') {
  const lightTheme = {
    siteVariables: {
      colorScheme: {
        default: {
          background1: '#F7F7FB',
        },
      },
      dividerColor: '#E1E1F1',
    },
    componentStyles: {
      Alert: {
        root: {
          border: 'none',
          borderRadius: 'none',
        },
      },
      Pill: {
        root: {
          borderColor: '#6264A7',
          background: '#FFFFFF',
          minWidth: '1rem',
          marginLeft: '0',
          paddingLeft: '2px',
          paddingRight: '2px',
          '&:hover': {
            backgroundColor: '#FFFFFF',
          },
        },
      },
      Card: {
        root: {
          border: '1px solid #E1E1F1',
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.08)',
          borderRadius: '20px 20px 20px 20px',
          width: '100%',
          minWidth: '30rem',
          padding: '2rem 2rem 2rem 2rem',
          '&:hover': {
            border: '1px solid #E1E1F1',
            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.08)',
            borderRadius: '20px 20px 20px 20px',
          },
        },
      },
      Button: {
        root: {
          boxShadow: 0,
        },
      },
      Input: {
        root: {
          '& ::placeholder': {
            color: '#605E5C',
          },
          fontWeight: '600',
        },
      },
      BreadcrumbItem: {
        root: {
          cursor: 'pointer',
        },
      },
      Dropdown: {
        root: {
          borderRadius: `2px`,
          width: 'auto',
        },
      },
      Dialog: {
        header: {
          fontSize: '20px',
          fontWeight: 600,
          marginBottom: '2rem',
        },
      },
      FormField: {
        root: {
          '&:last-child': {
            marginTop: 'initial',
          },
        },
      },
      FormLabel: {
        root: {
          fontWeight: '600',
          marginBottom: '8px',
        },
      },
      FormMessage: {
        root: {
          paddingLeft: '0px',
        },
      },
      MenuItem: {
        root: {
          paddingBottom: '0.5rem',
          ':hover': {
            paddingBottom: '0.5rem',
          },
        },
      },
      Menu: {
        root: {
          border: 'none',
        },
      },
      TextArea: {
        root: {
          outline: 'none',
        },
      },
      Table: {
        root: {
          cursor: 'default',
        },
      },
    },
    componentVariables: {
      Card: {
        backgroundColorHover: '#F7F7FB',
      },
      FilterPill: {
        activeColor: '#989AC9',
        activeHoverColor: '#6B6EC9 !important',
      },
    },
  };

  const darkTheme = {
    siteVariables: {
      colorScheme: {
        brand: {
          background: '#9EA2FF',
        },
        default: {
          background1: '#323348',
        },
      },
      dividerColor: '#3D3E66',
    },
    componentStyles: {
      Alert: {
        root: {
          border: 'none',
          borderRadius: 'none',
        },
      },
      Pill: {
        root: {
          borderColor: '#9EA2FF',
          background: '#2D2D2D',
          minWidth: '1rem',
          marginLeft: '0',
          paddingLeft: '2px',
          paddingRight: '2px',
          '&:hover': {
            backgroundColor: '#2D2D2D',
          },
        },
      },
      Card: {
        root: {
          border: '1px solid #9EA2FF',
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.08)',
          borderRadius: '20px 20px 20px 20px',
          width: '100%',
          minWidth: '30rem',
          padding: '2rem 2rem 2rem 2rem',
          '&:hover': {
            border: '1px solid #9EA2FF',
            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.08)',
            borderRadius: '20px 20px 20px 20px',
          },
        },
      },
      Button: {
        root: {
          boxShadow: 0,
        },
      },
      TextArea: {
        root: {
          outline: 'none',
        },
      },
      BreadcrumbItem: {
        root: {
          cursor: 'pointer',
        },
      },
      Dropdown: {
        root: {
          borderRadius: `2px`,
          width: 'auto',
        },
      },
      Dialog: {
        header: {
          fontSize: '20px',
          fontWeight: 600,
          marginBottom: '2rem',
        },
      },
      FormField: {
        root: {
          '&:last-child': {
            marginTop: 'initial',
          },
        },
      },
      FormLabel: {
        root: {
          fontWeight: '600',
          marginBottom: '8px',
        },
      },
      FormMessage: {
        root: {
          paddingLeft: '0px',
        },
      },
      MenuItem: {
        root: {
          paddingBottom: '0.5rem',
          ':hover': {
            paddingBottom: '0.5rem',
          },
        },
      },
      Menu: {
        root: {
          border: 'none',
        },
      },
      Table: {
        root: {
          cursor: 'default',
        },
      },
    },
    componentVariables: {
      Card: {
        backgroundColorHover: '#323348',
      },
      FilterPill: {
        activeColor: '#989AC9',
        activeHoverColor: '#6B6EC9 !important',
      },
    },
  };

  return theme === 'dark'
    ? mergeThemes(teamsDarkV2Theme, darkTheme)
    : mergeThemes(teamsV2Theme, lightTheme);
}
