export enum QueryKeys {
  Attachments = 'Attachments',
  Employees = 'Employees',
  FavouriteFilters = 'FavouriteFilters',
  EmployeeDetail = 'EmployeeDetail',
  EmployeeOverview = 'EmployeeOverview',
  EmployeeProjects = 'EmployeeProjects',
  EmployeeMonthlyWorkloads = 'EmployeeMonthlyWorkloads',
  EmployeeCapacityExcel = 'EmployeeCapacityExcel',
  Projects = 'Projects',
  ResourceRequest = 'ResourceRequest',
  ResourceRequestOverview = 'ResourceRequestOverview',
  ReleaseNotes = 'ReleaseNotes',
  Skills = 'Skills',
  Roles = 'Roles',
  Organization = 'Organizations',
  RoleRequests = 'RoleRequests',
  BillabilityPerMonth = 'BillabilityPerMonth',
  BillabilityPerMonthByOrganisation = 'BillabilityPerMonthByOrganisation',
  BillabilityDrillDown = 'BillabilityDrillDown',
  BillabilityDrillDownByOrganisation = 'BillabilityDrillDownByOrganisation',
  FtePerMonthDrillDown = 'FtePerMonthDrillDown',
  FtePerMonthDrillDownByOrganisation = 'FtePerMonthDrillDownByOrganisation',
}
