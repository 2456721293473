import { FunctionComponent, useEffect } from 'react';
import { LocalFilter, isFavoriteFilter } from '../LocalFilter';
import { FavouriteFilterDto } from '../../../data-access/open-api/queries/models/FavouriteFilterDto';
import { FilterOrganizationDto } from '../../../data-access/open-api/queries/models/FilterOrganizationDto';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  Button,
  Checkbox,
  DropdownItemProps,
  Flex,
  FormInput,
  ShorthandCollection,
  Text,
} from '@fluentui/react-northstar';
import { Ti8mDropdown } from '../../Common/Ti8mDropdown';
import { OrganizationDto } from '../../../data-access/open-api/queries/models/OrganizationDto';
import { TFunction, useTranslation } from 'react-i18next';

export type Inputs = {
  filterName: string;
  selectedOrganization: FilterOrganizationDto;
  selectedHolidaysNumber?: number;

  changeRequest: boolean;
  mentoringProgram: boolean;
  openEducation: boolean;
  workloadTermination: boolean;
  ignoredEmployees: boolean;

  // (a somewhat hacky way, to) track if we pressed Apply or Save Button (useState does not directly work for the use-case)
  submitMode: 'apply' | 'save';
};

export interface DialogFormProps {
  editFilter: LocalFilter | FavouriteFilterDto | undefined;
  filters: (LocalFilter | FavouriteFilterDto)[];
  organizations: Array<OrganizationDto>;
  confirmDeleteDialogOpenToggle: () => void;
  handleRemoveButtonClick: () => void;
  closeDialog: () => void;
  applyFilter: (data: Inputs) => void;
  saveFilter: (data: Inputs) => void;
}

const DialogForm: FunctionComponent<DialogFormProps> = ({
  editFilter,
  filters,
  organizations,
  confirmDeleteDialogOpenToggle,
  handleRemoveButtonClick,
  closeDialog,
  applyFilter,
  saveFilter,
}) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors, isDirty, isValid, isSubmitted },
  } = useForm<Inputs>({
    mode: 'all',
    defaultValues: {
      filterName: editFilter?.filterName,
      selectedOrganization: editFilter?.selectedOrganization,
      selectedHolidaysNumber: editFilter?.selectedHolidaysNumber,

      changeRequest: editFilter?.changeRequest,
      mentoringProgram: editFilter?.mentoringProgram,
      openEducation: editFilter?.openEducation,
      workloadTermination: editFilter?.workloadTermination,
      ignoredEmployees: editFilter?.ignoredEmployees,
      submitMode: 'save',
    },
  });

  useEffect(() => {
    reset();
  }, [editFilter, reset]);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (data.submitMode === 'apply') {
      applyFilter(data);
    } else if (data.submitMode === 'save') {
      saveFilter(data);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ marginBottom: 0 }}>
      <Flex column gap="gap.smaller">
        <FormInput
          fluid
          autoFocus
          label={t('search.dialog.name-input')}
          {...register('filterName', {
            required: t('search.dialog.name-required'),
            validate: (value) => {
              const sameName = filters.find(
                (x) => x.id !== editFilter?.id && x.filterName === value
              );
              return !sameName || t('search.dialog.name-not-unique');
            },
          })}
          defaultValue={editFilter?.filterName}
          errorMessage={errors.filterName?.message}
        />
        <Text size="small" weight="semibold" content={t('search.dialog.firstDropdown.label')} />
        <Controller
          name="selectedOrganization"
          control={control}
          render={({ field }) => (
            <Ti8mDropdown
              placeholder={t('search.dialog.firstDropdown.placeholder')}
              onBlur={field.onBlur}
              onItemChange={(data: any) => {
                if (!data?.value?.value) {
                  field.onChange(undefined);
                } else {
                  const value: { value: number } = data.value;
                  field.onChange({
                    number: value.value,
                    // get the actual name of the organization (not the formatted header)
                    name: organizations.find((x) => x.number === value.value)?.name,
                  });
                }
              }}
              search={true}
              defaultValue={organizationToDropdownItem(editFilter?.selectedOrganization)}
              items={organizations.map(organizationToDropdownItem)}
            />
          )}
        />
        <Flex vAlign="center">
          <Controller
            name="ignoredEmployees"
            control={control}
            render={({ field }) => (
              <Checkbox
                toggle
                {...field}
                defaultChecked={field.value}
                onChange={(_, target) => field.onChange(target?.checked)}
              />
            )}
          />
          <Text size="small" weight="semibold" content={t('search.dialog.ignoredEmployees')} />
        </Flex>
        <Flex vAlign="center">
          <Controller
            name="changeRequest"
            control={control}
            render={({ field }) => (
              <Checkbox
                toggle
                {...field}
                defaultChecked={field.value}
                onChange={(_, target) => field.onChange(target?.checked)}
              />
            )}
          />
          <Text size="small" weight="semibold" content={t('search.dialog.changeRequest')} />
        </Flex>

        <Flex vAlign="center">
          <Controller
            name="mentoringProgram"
            control={control}
            render={({ field }) => (
              <Checkbox
                toggle
                {...field}
                defaultChecked={field.value}
                onChange={(_, target) => field.onChange(target?.checked)}
              />
            )}
          />
          <Text size="small" weight="semibold" content={t('search.dialog.mentoringProgram')} />
        </Flex>
        <Flex vAlign="center">
          <Controller
            name="openEducation"
            control={control}
            render={({ field }) => (
              <Checkbox
                toggle
                {...field}
                defaultChecked={field.value}
                onChange={(_, target) => field.onChange(target?.checked)}
              />
            )}
          />
          <Text size="small" weight="semibold" content={t('search.dialog.openEducation')} />
        </Flex>
        <Flex vAlign="center">
          <Controller
            name="workloadTermination"
            control={control}
            render={({ field }) => (
              <Checkbox
                toggle
                {...field}
                defaultChecked={field.value}
                onChange={(_, target) => field.onChange(target?.checked)}
              />
            )}
          />
          <Text size="small" weight="semibold" content={t('search.dialog.workloadTermination')} />
        </Flex>
        <Text size="small" weight="semibold" content={t('search.dialog.secondDropdown.label')} />
        <Controller
          name="selectedHolidaysNumber"
          control={control}
          render={({ field }) => (
            <Ti8mDropdown
              placeholder={t('search.dialog.secondDropdown.placeholder')}
              onItemChange={(data: any) => {
                field.onChange(data.value.value);
              }}
              defaultValue={
                editFilter?.selectedHolidaysNumber &&
                mapHolidaysForDropdown(t).find(
                  (x: any) => x.value === editFilter.selectedHolidaysNumber
                )
              }
              items={mapHolidaysForDropdown(t)}
            />
          )}
        />
      </Flex>
      <Flex space="between" style={{ marginTop: '1rem' }}>
        {editFilter ? (
          isFavoriteFilter(editFilter) ? (
            <Button
              type="button"
              content={t('search.dialog.delete-button')}
              onClick={confirmDeleteDialogOpenToggle}
            />
          ) : (
            <Button
              type="button"
              content={t('search.dialog.remove-button')}
              onClick={handleRemoveButtonClick}
            />
          )
        ) : (
          <span />
        )}

        <Flex gap="gap.small" hAlign="end">
          <Button
            content={t('search.dialog.cancel')}
            type="button"
            disabled={isSubmitted}
            onClick={closeDialog}
          />
          {!isFavoriteFilter(editFilter) && (
            <Button
              type="button"
              content={t('search.dialog.apply-button')}
              disabled={!isDirty || !isValid || isSubmitted}
              onClick={(evt) => {
                evt.preventDefault();
                setValue('submitMode', 'apply');
                handleSubmit(onSubmit)();
              }}
            />
          )}

          <Button
            type="submit"
            content={t('search.dialog.save-button')}
            disabled={!isDirty || !isValid || isSubmitted}
            onClick={() => setValue('submitMode', 'save')}
            primary
          />
        </Flex>
      </Flex>
    </form>
  );
};

function organizationToDropdownItem(value?: OrganizationDto) {
  if (!value) {
    return value;
  }

  return {
    header: `${value.number} ${value.name}`,
    value: value.number,
  };
}

function mapHolidaysForDropdown(
  translator: TFunction<'resource-management', undefined>
): ShorthandCollection<DropdownItemProps> {
  return generateHolidaysLabels().map(({ min }) => ({
    header: translator('search.dialog.secondDropdown.option', { value: min }),
    value: min,
  }));
}

function generateHolidaysLabels(): Array<{ min: number }> {
  return [{ min: 10 }, { min: 15 }, { min: 20 }, { min: 25 }];
}

export default DialogForm;
