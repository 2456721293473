import {
  Flex,
  Table,
  Text,
  Button,
  EditIcon,
  TrashCanIcon,
  AddIcon,
} from '@fluentui/react-northstar';
import {
  addRoleButton,
  detailsTable,
  detailsTableStyleDuration,
  detailsTableStyleEdit,
  detailsTableStylePotential,
  detailsTableStyleRole,
  detailsTableStyleSkills,
  detailsTableStyleWorkload,
  requiredRolesHeader,
} from './resourceRequestDetailStyles';
import { RelevantSkills } from './RelevantSkills';
import { PotentialEmployees } from './PotentialEmployees';
import { useTranslation } from 'react-i18next';
import { convertMonthYear } from '../../utils/dateConverter';
import { useCallback, useMemo, useState } from 'react';
import { DeleteRoleDialog } from './DeleteRoleDialog';
import { RoleDialog } from '../ResourceRequestFilter/ResourceRequestDialog/RoleDialog';
import {
  useAddRoleRequest,
  useEditRoleRequest,
} from '../../data-access/hooks/commands/use-update-role-requests';
import { ResourceRequestDto } from '../../data-access/open-api/queries/models/ResourceRequestDto';
import { RoleRequestDto } from '../../data-access/open-api/queries/models/RoleRequestDto';
import { mapRoleRequestDtoToRoleRequestCommand } from '../ResourceRequestFilter/ResourceRequestDialog/resourceRequestMapper';

type ResourceRequestDetailViewTableProps = {
  resourceRequest?: ResourceRequestDto;
  roleRequests: RoleRequestDto[];
  isButtonsVisible: boolean;
};

export const ResourceRequestDetailViewRoles = ({
  resourceRequest,
  roleRequests,
  isButtonsVisible,
}: ResourceRequestDetailViewTableProps) => {
  const { t } = useTranslation();
  const headers = {
    items: [
      {
        key: 'roleHeader',
        content: <Text weight="semibold" size="medium" content={t('resources-detail-view.role')} />,
        styles: detailsTableStyleRole,
      },
      {
        key: 'durationHeader',
        content: (
          <Text weight="semibold" size="medium" content={t('resources-detail-view.duration')} />
        ),
        styles: detailsTableStyleDuration,
      },
      {
        key: 'workloadHeader',
        content: (
          <Text weight="semibold" size="medium" content={t('resources-detail-view.workload')} />
        ),
        styles: detailsTableStyleWorkload,
      },
      {
        key: 'relevantSkillsHeader',
        content: (
          <Text
            weight="semibold"
            size="medium"
            content={t('resources-detail-view.relevant-skills')}
          />
        ),
        styles: detailsTableStyleSkills,
      },
      {
        key: 'potentialEmployeesHeader',
        content: (
          <Text
            weight="semibold"
            size="medium"
            content={t('resources-detail-view.potential-employees')}
          />
        ),
        styles: detailsTableStylePotential,
      },
      {
        key: 'editHeader',
        styles: detailsTableStyleEdit,
      },
    ],
  };

  const defaultRole: RoleRequestDto = useMemo(
    () => ({
      id: '',
      role: '',
      roleStart: '',
      roleEnd: '',
      workload: 0,
      skills: [],
      focus: '',
      potentialEmployees: [],
    }),
    []
  );

  const [role, setRole] = useState<RoleRequestDto>(defaultRole);

  const [isAddRoleDialogVisible, setAddRoleDialogVisibility] = useState<boolean>(false);
  const [isEditRoleDialogVisible, setEditRoleDialogVisibility] = useState<boolean>(false);
  const [isDeleteDialogOpen, setDeleteDialogVisibility] = useState<boolean>(false);

  const [isTouched, setTouched] = useState<boolean>(false);

  const { mutate: addRole } = useAddRoleRequest();
  const { mutate: editRole } = useEditRoleRequest();

  const handleDataChanged = useCallback(
    (roleRequestData: RoleRequestDto) => {
      setRole(roleRequestData);
      setTouched([role.role, role.roleStart, role.roleEnd].every((value) => value === ''));
    },
    [role, setRole]
  );

  const onAddRole = () => {
    setRole(defaultRole);
    setTouched(false);
    setAddRoleDialogVisibility(true);
  };

  const onCancelAddRole = () => {
    setAddRoleDialogVisibility(false);
    setRole(defaultRole);
    setTouched(false);
  };

  const onConfirmAddRole = useCallback(() => {
    addRole({
      resourceRequestId: resourceRequest?.id ?? '',
      roleRequestBody: mapRoleRequestDtoToRoleRequestCommand(role),
    });
    setTouched(false);
    setAddRoleDialogVisibility(false);
    setRole(defaultRole);
  }, [resourceRequest, role, addRole, defaultRole]);

  const onEditRole = (roleItem: RoleRequestDto) => {
    setRole(roleItem);
    setEditRoleDialogVisibility(true);
  };

  const onConfirmEditRole = useCallback(() => {
    editRole({
      resourceRequestId: resourceRequest?.id ?? '',
      roleRequestId: role.id,
      roleRequestBody: mapRoleRequestDtoToRoleRequestCommand(role),
    });
    setTouched(false);
    setEditRoleDialogVisibility(false);
    setRole(defaultRole);
  }, [resourceRequest, role, defaultRole, editRole]);

  const onCancelEditRole = () => {
    setEditRoleDialogVisibility(false);
    setRole(defaultRole);
    setTouched(false);
  };

  const onDeleteRole = () => {
    setDeleteDialogVisibility(true);
  };

  return (
    <Flex column gap="gap.small">
      <Text
        weight="semibold"
        styles={requiredRolesHeader}
        content={t('resource-details-pane.required-roles')}
      />
      <Button
        iconOnly
        flat
        disabled={!isButtonsVisible}
        icon={<AddIcon />}
        primary
        onClick={onAddRole}
        size="small"
        styles={addRoleButton}
      ></Button>
      <RoleDialog
        isEditMode={false}
        isTouched={isTouched}
        isDialogOpen={isAddRoleDialogVisible}
        roleData={role}
        handleDataChanged={handleDataChanged}
        onCancel={onCancelAddRole}
        onConfirm={onConfirmAddRole}
      />
      {roleRequests.length > 0 && (
        <Table
          styles={detailsTable}
          style={{ backgroundColor: 'transparent' }}
          header={headers}
          rows={roleRequests.map((item) => ({
            key: item.id,
            items: [
              {
                key: `${item.id}_role`,
                content: <Text content={item.role} />,
                styles: detailsTableStyleRole,
              },
              {
                key: `${item.id}_duration`,
                content: `${convertMonthYear(item.roleStart)} - ${convertMonthYear(item.roleEnd)}`,
                styles: detailsTableStyleDuration,
              },
              {
                key: `${item.id}_workload`,
                content: `${item.workload} %`,
                styles: detailsTableStyleWorkload,
              },
              {
                key: `${item.id}_relevantSkills`,
                content: <RelevantSkills skills={item.skills} />,
                styles: {
                  ...detailsTableStyleSkills,
                  display: 'flex',
                  flex: 1,
                  overflow: 'hidden',
                  '> .ui-table__cell__content': {
                    // Ensure the child elment can make use of the full width.
                    width: '100%',
                  },
                },
              },
              {
                key: `${item.id}_potentialEmployees`,
                content: (
                  <PotentialEmployees
                    resourceRequest={resourceRequest}
                    potentialEmployees={item.potentialEmployees}
                    role={item}
                  />
                ),
                styles: detailsTableStylePotential,
              },
              {
                key: `${item.id}_icons`,
                content: (
                  <Flex gap={'gap.small'}>
                    <Button
                      iconOnly
                      text
                      disabled={!isButtonsVisible}
                      primary
                      icon={<EditIcon size="large" />}
                      onClick={() => onEditRole(item)}
                    />
                    <RoleDialog
                      isEditMode={true}
                      isTouched={isTouched}
                      isDialogOpen={isEditRoleDialogVisible}
                      roleData={role}
                      handleDataChanged={handleDataChanged}
                      onCancel={onCancelEditRole}
                      onConfirm={onConfirmEditRole}
                    />
                    <Button
                      iconOnly
                      text
                      disabled={!isButtonsVisible}
                      primary
                      icon={<TrashCanIcon size="large" />}
                      onClick={onDeleteRole}
                    />
                    <DeleteRoleDialog
                      isDeleteDialogOpen={isDeleteDialogOpen}
                      setDeleteRoleDialogVisibility={setDeleteDialogVisibility}
                      resourceRequest={resourceRequest}
                      roleRequestId={item.id}
                    />
                  </Flex>
                ),
                styles: detailsTableStyleEdit,
              },
            ],
          }))}
        />
      )}
    </Flex>
  );
};
